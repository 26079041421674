import { useSelector } from 'react-redux';

import { Tab, Tabs } from 'react-bootstrap';

// Tabs
import DataCollectionTab from './dataCollectionTab';
import FlightBoundaryTab from './flightBoundaryTab';
import OrderConstructionTab from './OrderConstructionTab';
import OrderDataTab from './OrderDataTab';
import OrderEditDetailsTab from './OrderEditDetailsTab/OrderEditDetailsTab';
import OrderFinancialsTab from './OrderFinancialsTab';
import WorkflowTab from './workflowTab';

/**
 * Contains the tabs for the order edit form. Include details, data, and
 * financials.
 * @param props
 * @returns {*}
 * @constructor
 */
const OrderEditTabs = ({ solarFarmData, solarFarm, selectedOrder, orderActions }) => {
  const allOrgs = useSelector(state => state.session.orgs);

  return (
    <Tabs id="order-edit-modal-tabs" defaultActiveKey="workflow">
      <Tab eventKey="workflow" title="Workflow">
        <WorkflowTab order={selectedOrder} orderActions={orderActions} orgs={solarFarm?.org} />
      </Tab>
      <Tab eventKey="details" title="Details">
        <OrderEditDetailsTab
          order={selectedOrder}
          solarFarm={solarFarmData}
          orderActions={orderActions}
          orgs={allOrgs}
        />
      </Tab>
      <Tab eventKey="data" title="Data">
        <OrderDataTab order={selectedOrder} solarFarm={solarFarmData} />
      </Tab>
      <Tab eventKey="financials" title="Accounting">
        <OrderFinancialsTab order={selectedOrder} orderActions={orderActions} />
      </Tab>
      <Tab eventKey="data-collection" title="Data Collection">
        <DataCollectionTab
          order={selectedOrder}
          orderActions={orderActions}
          orgs={allOrgs}
        />
      </Tab>
      <Tab eventKey="flight-boundary" title="Flight Boundary">
        <FlightBoundaryTab
          order={selectedOrder}
          orderActions={orderActions}
          solarFarm={solarFarm}
        />
      </Tab>
      <Tab eventKey="construction-order" title="Construction">
        <OrderConstructionTab order={selectedOrder} />
      </Tab>
    </Tabs>
  );
};

export default OrderEditTabs;
