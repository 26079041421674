// @ts-nocheck
import { FunctionComponent, useEffect, useRef } from 'react';

// Icon
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Styled components
import { ModalBody, ModalContainer, ModalHeader, ModalOverlay } from './style/modal_style';

interface IModalProps {
  title: string;
  children: any;
  close?: any;
  show?: any;
  overlayClick?: boolean;
}

const RaptorModal: FunctionComponent<IModalProps> = ({
  title,
  children,
  close,
  show,
  overlayClick = true
}) => {
  const modalRef = useRef(null);

  // Verify the modal overlay was clicked
  const handleOverlayClick = (e) => {
    if (modalRef.current !== null && !e.target.closest(`#${modalRef.current.id}`) && overlayClick) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOverlayClick);
    return () => {
      document.removeEventListener('click', handleOverlayClick);
    };
  });

  return (
    <>
      {show && (
        <ModalOverlay>
          <ModalContainer id="RaptorModal" ref={modalRef}>
            <ModalHeader>
              <div><strong>{title}</strong></div>
              <div onClick={() => close()}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};

export default RaptorModal;
