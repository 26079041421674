// @ts-nocheck
import { Formik } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import toastr from 'toastr';
import * as Yup from 'yup';
import DefaultOrgGroupsApiClient from '../../apiClient/DefaultOrgGroupApiClient';
import { ORG_TYPES } from '../../util';
import { IDefaultOrgGroup, IEditOrgGroup } from './InterfaceUtil';

interface IAddOrgGroupProps {
  orgs: [];
  adminUsers: [];
  editOrgGroup: IEditOrgGroup | {};
  displayEditModal: boolean;
  handleUpdateRefresh: Function;
  handleSubmitRefresh: Function;
  handleRemoveInputField: Function;
}

const AddOrgGroup: FunctionComponent<IAddOrgGroupProps> = ({
  orgs,
  adminUsers,
  editOrgGroup,
  displayEditModal,
  handleUpdateRefresh,
  handleSubmitRefresh,
  handleRemoveInputField
}) => {
  const [hasEditOrg, setHasEditOrg] = useState(false);

  useEffect(() => {
    if (Object.keys(editOrgGroup).length) {
      setHasEditOrg(true);
    }
  }, [editOrgGroup]);

  /**
   *  Makes API call to submit new default org group
   * @param defaultOrgGroup - the default org group being submitted
   * @returns
   */
  const submitDefaultOrgGroup = (defaultOrgGroup: IDefaultOrgGroup) => {
    const defaultOrgApi = new DefaultOrgGroupsApiClient();

    defaultOrgApi
      .submitDefaultOrgGroup(defaultOrgGroup)
      .then(res => {
        if (res === 500) {
          toastr.error('Check if organization already exists as default org group', 'DB Error.');
        } else {
          toastr.success('Successfully added default org group.');
          handleSubmitRefresh();
        }
      })
      .catch(err => toastr.error(err));
  };

  /**
   * Makes API call to update an existing default org group
   * @param defaultOrgGroup  - the default org group being updated
   * @returns
   */
  const updateDefaultOrg = (editOrgGroup: IEditOrgGroup) => {
    const defaultOrgApi = new DefaultOrgGroupsApiClient();

    defaultOrgApi
      .updateDefaultOrgGroup(editOrgGroup)
      .then(res => {
        handleUpdateRefresh();
        toastr.success('Successfully updated default org group');
      })
      .catch(err => toastr.error(err));
  };

  /**
   * Combines default org group formValue data with editOrg data for update API call
   * @param {object} formValues - default org group from form inputs that holds updated values
   * @param {object} editOrg - the original org selected for edits
   * @returns {object} Update org containing updated vals plus identifiers of original org
   */
  const createUpdateOrgData = (formValues, editOrg) => {
    let updateOrg = { ...formValues };
    updateOrg.id = editOrg.id;
    updateOrg.uuid = editOrg.uuid;
    return updateOrg;
  };

  /**
   * On save prompts api submit or update call on default org group
   * @param values - form input values
   * @returns
   */
  const saveDefaultOrgGroup = (values: IDefaultOrgGroup) => {
    // If modal open perform update otherwise perform submission
    if (displayEditModal) {
      const updateOrgGroup = createUpdateOrgData(values, editOrgGroup);
      updateDefaultOrg(updateOrgGroup);
    } else {
      const defaultOrgGroup = { ...values };
      submitDefaultOrgGroup(defaultOrgGroup);
    }
  };

  /**
   * Warning label to display when invalid form inputs are attempted on submit
   * @param props
   * @returns
   */
  const Warn = props => (
    <span style={{ color: 'red' }} {...props}>
      {props.children}
    </span>
  );

  // Form Validation Schema
  const schema = Yup.object().shape({
    org_id: Yup.number()
      .required('Client Org is required!')
      .nullable(false),
    project_manager_id: Yup.number().nullable(true),
    data_collection_manager_id: Yup.number().nullable(true),
    data_collection_org_id: Yup.number().nullable(true)
  });

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={saveDefaultOrgGroup}
      initialValues={{
        org_id: hasEditOrg ? editOrgGroup.org_id : null,
        project_manager_id: hasEditOrg ? editOrgGroup.project_manager_id : null,
        data_collection_manager_id: hasEditOrg ? editOrgGroup.data_collection_manager_id : null,
        data_collection_org_id: hasEditOrg ? editOrgGroup.data_collection_org_id : null
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        isValidating,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="onboard-form-input-label">Customer Org</Form.Label>
              <InputGroup>
                <Typeahead
                  id="org_id"
                  name="org_id"
                  className="add_group_edit_modal_org_id"
                  placeholder="Type to search..."
                  defaultInputValue={editOrgGroup.org_name ? editOrgGroup.org_name : ''}
                  options={orgs}
                  maxResults={5}
                  labelKey={o => o.name}
                  onBlur={handleBlur}
                  onChange={org => {
                    org.length ? setFieldValue('org_id', org[0].id) : setFieldValue('org_id', null);
                  }}
                />
              </InputGroup>
              {touched.org_id && errors.org_id && <Warn>{'Client Org must be selected!'}</Warn>}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="onboard-form-input-label">Project Manager</Form.Label>
              <InputGroup>
                <Typeahead
                  id="project_manager_id"
                  name="project_manager_id"
                  className="project_manager_id"
                  placeholder={'Type to search'}
                  defaultInputValue={
                    editOrgGroup.project_manager_name ? editOrgGroup.project_manager_name : ''
                  }
                  options={adminUsers}
                  maxResults={5}
                  labelKey={o => o.name}
                  onChange={org => {
                    console.warn(org);
                    org.length
                      ? setFieldValue('project_manager_id', org[0].id)
                      : setFieldValue('project_manager_id', null);
                  }}
                />
              </InputGroup>
              {touched.project_manager_id && errors.project_manager_id && (
                <Warn>{'Project Manager must be selected from dropdown!'}</Warn>
              )}
              {displayEditModal ? (
                <Button
                  variant={'warning'}
                  onClick={() => handleRemoveInputField('project_manager', setFieldValue)}
                >
                  Confirm Remove
                </Button>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="onboard-form-input-label">Data Collection Manager</Form.Label>
              <InputGroup>
                <Typeahead
                  id="data_collection_manager_id"
                  name="data_collection_manager_id"
                  className="data_collection_manager_id"
                  placeholder="Type to search..."
                  defaultInputValue={
                    editOrgGroup.data_collection_manager_name
                      ? editOrgGroup.data_collection_manager_name
                      : ''
                  }
                  options={adminUsers}
                  maxResults={5}
                  labelKey={o => o.name}
                  onChange={org => {
                    org.length
                      ? setFieldValue('data_collection_manager_id', org[0].id)
                      : setFieldValue('data_collection_manager_id', null);
                  }}
                />
              </InputGroup>
              {touched.data_collection_manager_id && errors.data_collection_manager_id && (
                <Warn>{'Data Collection Manager must be selected from dropdown!'}</Warn>
              )}
              {displayEditModal ? (
                <Button
                  variant={'warning'}
                  onClick={() => handleRemoveInputField('data_collection_manager', setFieldValue)}
                >
                  Confirm Remove
                </Button>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="onboard-form-input-label">Data Collection Org</Form.Label>
              <InputGroup>
                <Typeahead
                  id="data_collection_org_id"
                  name="data_collection_org_id"
                  className="data_collection_org_id"
                  placeholder="Type to search..."
                  defaultInputValue={
                    editOrgGroup.data_collection_org_name
                      ? editOrgGroup.data_collection_org_name
                      : ''
                  }
                  options={orgs.filter(o => o.type === ORG_TYPES.DATA_COLLECTOR)}
                  maxResults={5}
                  labelKey={o => o.name}
                  onChange={org => {
                    org.length
                      ? setFieldValue('data_collection_org_id', org[0].id)
                      : setFieldValue('data_collection_org_id', null);
                  }}
                />
              </InputGroup>
              {touched.data_collection_org_id && errors.data_collection_org_id && (
                <Warn>{'Data Collection Org must be selected from dropdown!'}</Warn>
              )}
              {displayEditModal ? (
                <Button
                  variant={'warning'}
                  onClick={() => handleRemoveInputField('data_collection_org', setFieldValue)}
                >
                  Confirm Remove
                </Button>
              ) : (
                <></>
              )}
            </Form.Group>
          </Row>
          <Button type="submit" variant="success">
            Save Org Defaults
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddOrgGroup;
