import React from 'react';
import moment from 'moment';
import { getTsecsFromDate } from '../../OrderStatus/util';
import DatePicker from '../../shared/OrderDatePicker';

/**
 * Date picker component for sales onboarding form
 * @param value {int} - current value tsecs
 * @param onUpdate {function} - update handler
 * @returns {*}
 * @constructor
 */
const SalesOnboardingDatePicker = ({ value, onUpdate, className, disabled }) => {
  let date = value ? value : null;
  return (
    <DatePicker
      popperPlacement="left"
      style={{ width: '100%' }}
      value={date}
      onUpdate={newValue => onUpdate(newValue)}
      className={`sales-onboarding-date-picker ${className}`}
      disabled={disabled}
    />
  );
};

export default SalesOnboardingDatePicker;
