/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import moment from "moment";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormCheck,
  Row,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { OrderActionCreator } from "../../../../../actions/orderActions";
import UsersApiClient from "../../../../../apiClient/UsersApiClient";
import OrderDatePicker from "../../../../shared/OrderDatePicker";
import OrderServiceInstruction from "../../../OrderServiceInstruction";
import {
  getDateStringFromTsecs,
  getFarmBuilderLink,
  getFarmSize,
  getOrgName,
  getPowerInspected,
  getReportViewerLink,
  getTsecsFromDate,
  getEventStatusView,
} from "../../../util";
import {
  orderStatuses,
  orderValuesets,
  VALID_CHANNEL_ORDER_STATUSES,
  VALID_CLIENT_ORDER_STATUSES,
  historicalDigitization,
} from "../../../util/constants";
import PermissionsWarningModal from "../../PermissionsWarningModal";

// styled Components
import { InfoItem, InfoItemTitle } from '../styles/OrderEditTabs_styles';
import { AlertContainer } from './styles/OrderEditDetailsTab_styles';

const schema = Yup.object({
  priority: Yup.number().min(0).max(10),
});
/**
 * Renders the view to edit the order details. This includes
 * order fields and services to be added to the order
 *
 * @param {*} order - Order to display or edit
 * @param {*} solarFarm - solar farm belonging to the order
 * @param {*} orderActions - action creator for actions related to orders
 */
const OrderEditDetailsTab = ({
  order,
  solarFarm,
  orderActions,
  orgs,
  reportProviders,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showPermissionsWarning, setShowPermissionsWarning] = useState("");
  const [updatedOrder, setUpdatedOrder] = useState({});
  const [pmUsers, setPmUsers] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [selectedReportProvider, setSelectedReportProvider] = useState(
    order?.id_report_provider
  );
  const [selectedOrderType, setSelectedOrderType] = useState(
    orderValuesets?.order_type[order?.order_type]
  );

  // ref
  const clientEmailRef = useRef(null);

  // list of all orgs captured in local state to avoid redux changes to orgs list
  const selectableOrgs = useSelector((state) => state.session.orgs);

  // Get services on page load
  useEffect(() => {
    getRaptorAdminUsers();
    orderActions.getReportProviders();
  }, []);

  useEffect(() => {
    if (order.id) {
      orderActions.getEventsByOrderId(order.id);
    }
  }, [order]);

  /**
   * Makes call to users client to get admin users
   */
  const getRaptorAdminUsers = () => {
    const usersApi = new UsersApiClient();
    usersApi.getRaptorAdminUsers().then((users) => setPmUsers(users));
  };
  // Get permissions warning text when going to client
  const getToClientText = (status) =>
    `Setting the order to  ${orderValuesets.status[status]} status will allow remove ALL selected channel permissions to access all inspections attached` +
    " to the order. The client org(s) will now be able to access all inspections.";

  // Get permissions warning when going to bpo
  const getToChannelText = (status) =>
    `Setting the order to ${orderValuesets.status[status]} status will allow ALL selected channels to access all inspections attached to the order.` +
    " The client org(s) will no longer be able to access all inspections.";

  /**
   * Object mapping for permissions change warning text
   * @type {{[p: string]: *, [p: number]: *, CLIENT_ORG_ID: string}}
   */
  const permissionsTextObject = {
    [orderStatuses.DISPATCHED]: getToChannelText(orderStatuses.DISPATCHED),
    [orderStatuses.DELIVERED]: getToClientText(orderStatuses.DELIVERED),
    [orderStatuses.SITE_SETUP]: getToChannelText(orderStatuses.SITE_SETUP),
    [orderStatuses.STARTED]: getToClientText(orderStatuses.STARTED),
    [orderStatuses.PROCESSED]: getToChannelText(orderStatuses.PROCESSED),
    CLIENT_ORG_ID:
      "Changing the client org(s) will remove farm and inspection permissions from the previous client and add them" +
      " for the new one(s).",
  };

  const handleSelectedReportProvider = (id_report_provider) => {
    if (!id_report_provider || id_report_provider === "") return null;

    let selectedReportProviderObj = null;
    if (id_report_provider && id_report_provider !== "") {
      reportProviders &&
        Object.keys(reportProviders).map((report) => {
          if (reportProviders[report].id === id_report_provider) {
            selectedReportProviderObj = reportProviders[report];
          }
        });
    }
    return selectedReportProviderObj;
  };

  /**
   * Handles updating an order on form save
   * @param {*} formValues
   */
  const handleSave = (formValues) => {
    mixpanel.track("Order edit save");
    setIsEditable(!isEditable);
    const updatedOrder = _.cloneDeep(order);

    //Flip valuesets from display to db values
    if (formValues.status) {
      updatedOrder.status = parseInt(
        _.invert(orderValuesets.status)[formValues.status]
      );
    }
    if (selectedOrderType) {
      updatedOrder.order_type = _.invert(orderValuesets.order_type)[
        selectedOrderType
      ];
    }

    if (selectedReportProvider) {
      updatedOrder.id_report_provider = selectedReportProvider;
    } else {
      updatedOrder.id_report_provider = null;
    }

    if (formValues.contract_type) {
      updatedOrder.contract_type = formValues.contract_type;
    }
    if (formValues.channel_orgs) {
      updatedOrder.channel_orgs = formValues.channel_orgs;
    }


    //Add text fields
    updatedOrder.special_instructions = formValues.special_instructions;
    updatedOrder.comments_from_channel = formValues.comments_from_channel;
    updatedOrder.comments_to_channel = formValues.comments_to_channel;
    updatedOrder.internal_comments = formValues.internal_comments;
    updatedOrder.priority = formValues.priority;
    updatedOrder.email_updates_enabled = formValues.email_updates_enabled;

    //Add dates
    if (formValues.submitted_tsecs) {
      updatedOrder.submitted_tsecs = formValues.submitted_tsecs;
    }
    if (formValues.data_received_tsecs) {
      updatedOrder.data_received_tsecs = formValues.data_received_tsecs;
    }
    if (formValues.delivered_tsecs) {
      updatedOrder.delivered_tsecs = formValues.delivered_tsecs;
    }

    if (formValues.requested_delivery_date) {
      updatedOrder.requested_delivery_date = formValues.requested_delivery_date;
      updatedOrder.estimated_delivery_date = formValues.requested_delivery_date;
    }

    // commented code for reference
    // if (formValues.estimated_delivery_date) {
    //   updatedOrder.estimated_delivery_date = formValues.estimated_delivery_date;
    // }

    if (formValues.client_orgs) {
      updatedOrder.client_orgs = formValues.client_orgs;
    }
    //Add checkboxes
    updatedOrder.processing_hold = formValues.processing_hold;

    //Add pm user id
    updatedOrder.pm_user_id = formValues.pm_user_id;

    // Add data_collection_manager_id
    updatedOrder.data_collection_manager_id =
      formValues.data_collection_manager_id;

    //Add client email list
    updatedOrder.mailing_list = handleEmailsArrayToString(
      formValues.client_email_list
    );
    // show the warning if we are moving to a state where the client
    // or bpo will no longer see the inspection
    let showWarning;
    if (order.status !== updatedOrder.status) {
      if (VALID_CLIENT_ORDER_STATUSES.includes(updatedOrder.status)) {
        showWarning = true;
      } else if (VALID_CHANNEL_ORDER_STATUSES.includes(updatedOrder.status)) {
        showWarning = true;
      } else {
        showWarning = false;
      }
    }
    if (showWarning) {
      setShowPermissionsWarning("STATUS");
      setUpdatedOrder(updatedOrder);
    } else {
      orderActions.updateOrder(updatedOrder).then(() => {
        if (order.status !== updatedOrder.status) {
          orderActions.updateOrder(updatedOrder, true);
        }
      });
    }
  };

  /**
   * Gets a dropdown for the a particular order valueset
   * @param {*} value - The value of the dropdown
   * @param {*} property - the property on the order for the dropdown
   * @param {*} setFieldValue - handle to update the formik field value
   */
  const getValueset = (value, property, setFieldValue) => (
    <DropdownButton size="sm" title={value || `Select ${property}`}>
      {Object.keys(orderValuesets[property]).map((p) => (
        <Dropdown.Item
          value={orderValuesets[property][p]}
          name={property}
          onSelect={() => {
            setFieldValue(property, orderValuesets[property][p]);
            if (property === "order_type")
              setSelectedOrderType(orderValuesets[property][p]);
          }}
        >
          {orderValuesets[property][p]}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );

  /**
   * Displays the status dropdown with a restricted list of available statuses.
   */
  const getStatusDropdown = (value, setFieldValue) => {
    // Order the statuses chronologically
    const orderedOrderStatuses = [
      orderStatuses.STARTED,
      orderStatuses.SUBMITTED,
      orderStatuses.SITE_SETUP,
      orderStatuses.DISPATCHED,
      orderStatuses.PROCESSED,
      orderStatuses.DELIVERED,
    ];
    return (
      <DropdownButton size="sm" title={value || `Select Status`}>
        {orderedOrderStatuses.map((p) => (
          <Dropdown.Item
            value={orderValuesets.editable_statuses[p]}
            onSelect={() =>
              setFieldValue("status", orderValuesets.editable_statuses[p])
            }
          >
            {orderValuesets.editable_statuses[p]}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };
  /**
   * Returns a date picker for a date value on an order
   * @param {*} value - formatted as a date string 'MM/DD/YYYY'
   * @param {*} property - property on the order that the value corresponds to
   * @param {*} setFieldValue - handle to update the formik field value
   */
  const getDateField = (value, property, setFieldValue) => {
    let date = value ? moment.unix(value).format("MM/DD/YYYY") : null;
    return (
      <OrderDatePicker
        value={date}
        onUpdate={(newValue) =>
          setFieldValue(property, getTsecsFromDate(newValue))
        }
      />
    );
  };

  /**
   * Derives the initial form values from an order
   * @param {} order
   */
  const getInitialValues = (order) => {
    return {
      submitted_tsecs: order && order.submitted_tsecs,
      client_orgs: order && order.client_orgs,
      channel_orgs: order && order.channel_orgs,
      priority: order.priority,
      order_type: orderValuesets.order_type[order && order.order_type],
      id_report_provider: selectedReportProvider,
      status: orderValuesets.status[order && order.status],
      contract_type: order && order.contract_type,
      special_instructions: order && order.special_instructions,
      comments_to_channel: order && order.comments_to_channel,
      comments_from_channel: order && order.comments_from_channel,
      internal_comments: order && order.internal_comments,
      processing_hold: order && order.processing_hold,
      data_received_tsecs: order && order.data_received_tsecs,
      delivered_tsecs: order && order.delivered_tsecs,
      estimated_delivery_date: order && order.estimated_delivery_date,
      requested_delivery_date: order && order.requested_delivery_date,
      email_updates_enabled: order && order.email_updates_enabled,
      pm_user_id: order && order.pm_user_id,
      data_collection_manager_id: order && order.data_collection_manager_id,
      client_email_list: order
        ? handleEmailsStringToArray(order.mailing_list)
        : [],
    };
  };

  const getChannelOrgs = (orgs) => orgs?.filter((org) => org.type === "BPO");

  // Filters the list of all orgs for those included in the order.channel_orgs
  const getSelectedChannelOrgs = (channelOrgs, allOrgs) => {
    if (allOrgs && channelOrgs && channelOrgs.length > 0) {
      const channelOrgIds = channelOrgs.map((org) => org.id);
      return [...allOrgs.filter((org) => channelOrgIds.includes(org.id))];
    }
    return [];
  };

  /**
   * Gets message for the warning modal
   * @returns {string}
   */
  const getPermissionsWarningMessage = () => {
    const orderStatus = updatedOrder.status;
    let text = "";
    if (showPermissionsWarning === "STATUS") {
      text = permissionsTextObject[orderStatus];
    } else {
      text = permissionsTextObject["CLIENT_ORG_ID"];
    }
    text += " Are you sure you want to continue?";
    return text;
  };

  /**
   * Safely fetch the full client org for the selected order's client org id
   * @param {*} orgs - list of all org objects
   * @param {*} clientOrgs - order client orgs
   */
  const getSelectedClientOrgs = (clientOrgs, allOrgs) => {
    if (allOrgs && clientOrgs && clientOrgs.length > 0) {
      const clientOrgIds = clientOrgs.map((org) => org.id);
      return (orgs = [
        ...allOrgs.filter((org) => clientOrgIds.includes(org.id)),
      ]);
    }
    return [];
  };

  /**
   * Safely fetch the full pm user obj for the selected order's pmUserid
   * @param {*} pmUsers - all pm users
   * @param {*} pmUserId - order pm user id
   */
  const getSelectedPMUser = (pmUsers, pmUserId) => {
    if (pmUsers && pmUsers.length && pmUsers.length > 0 && pmUserId) {
      const selectedPMUser = pmUsers.find((user) => user.id === pmUserId);
      if (selectedPMUser) {
        return [selectedPMUser];
      }
    }
    return [];
  };

  /**
   * Handles adding an email on enter in the email field
   * Validates that the email is properly formatted
   *
   * @param e {Event}
   * @param values {object}
   * @param setFieldValue {function}
   */
  const handleAddEmail = (e, values, setFieldValue) => {
    const emailRegex = /^\S+@\S+$/;

    // prevent the form from submitting
    e.stopPropagation();
    if (e.keyCode === 13 && e.target.value.length > 0) {
      // set up the field val, clear the input
      if (!emailRegex.test(e.target.value)) {
        setEmailError("Invalid email!");
      } else {
        setEmailError(null);
        setFieldValue(
          "client_email_list",
          values.client_email_list.concat(e.target.value)
        );
        clientEmailRef.current.getInstance().clear();
      }
    }
  };

  const handleEmailsStringToArray = (emails) => {
    let formattedEmails = emails?.replace(/[{()}]/g, "");
    let splittedEmails = formattedEmails?.split(",");
    return splittedEmails || [];
  };

  const handleEmailsArrayToString = (emails) => {
    let formattedEmails = `{${emails?.toString()}}`;
    return formattedEmails;
  };

  const handleShowWarning = (formValues) => {
    const {
      email_updates_enabled,
      client_orgs,
      client_email_list,
      order_type,
      contract_type,
      pm_user_id,
      data_collection_manager_id,
    } = formValues;

    let show = false;

    if (email_updates_enabled) {
      if (
        client_email_list.length >= 1 ||
        !order_type ||
        !contract_type ||
        !pm_user_id ||
        client_orgs.length >= 1
      ) {
        show = true;
      }
    }

    return show;
  };

  const Warn = (props) => (
    <span style={{ color: "red" }} {...props}>
      {props.children}
    </span>
  );

  /**
   * Displays the Report Provider dropdown with a restricted list of available statuses.
   */
  const getReportProviderDropdown = (value, property, setFieldValue) => {
    if (reportProviders && Object.keys(reportProviders).length === 0)
      return false;

    let selectedReportProvider = handleSelectedReportProvider(value);
    return (
      <DropdownButton
        size="sm"
        title={
          (selectedReportProvider && selectedReportProvider?.value) ||
          `Select Report Provider`
        }
      >
        {Object.values(reportProviders).map((p) => {
          return (
            <Dropdown.Item
              onSelect={() => {
                setSelectedReportProvider(p?.id);
                setFieldValue(property, p?.id);
              }}
            >
              {p?.value}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    );
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={schema}
        initialValues={getInitialValues(order)}
        onSubmit={(values) => handleSave(values)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
        }) => {
          return (
            <>
              <Row className="order-edit-row">
                <strong>Site Detail:</strong>
                <div
                  className="order-edit-icon"
                  style={isEditable ? { color: "#f37c20" } : {}}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => {
                      mixpanel.track("Edit order");
                      setIsEditable((isEditable) => !isEditable);
                    }}
                  />
                </div>

                <br />
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Site Address: </InfoItemTitle>
                    {solarFarm?.address}
                  </InfoItem>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Site Size (MW): </InfoItemTitle>
                    {getFarmSize(solarFarm)}
                  </InfoItem>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Power Inspected (MW): </InfoItemTitle>
                    {getPowerInspected(order)}
                  </InfoItem>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Org Name: </InfoItemTitle>
                    {getOrgName(order)}
                  </InfoItem>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Link to site builder: </InfoItemTitle>
                    {solarFarm && (
                      <a
                        href={getFarmBuilderLink(solarFarm.id)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getFarmBuilderLink(solarFarm.id)}
                      </a>
                    )}
                  </InfoItem>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItem>
                    <InfoItemTitle>Link to report viewer: </InfoItemTitle>
                    {order.inspections[0] && (
                      <a
                        href={getReportViewerLink(
                          order.inspections[0].uuid,
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getReportViewerLink(
                          order.inspections[0].uuid,
                        )}
                      </a>
                    )}
                  </InfoItem>
                </Col>
              </Row>

              <div className="order-edit-divider" />
              <Row className="order-edit-row">
                <strong>Order Detail:</strong>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItemTitle>Status: </InfoItemTitle>
                  {isEditable
                    ? getStatusDropdown(values.status, setFieldValue)
                    : orderValuesets.status[order.status]}
                </Col>
                <Col>
                  <InfoItemTitle>Special Instructions: </InfoItemTitle>
                  {(order.special_instructions || isEditable) && (
                    <div className="order-edit-text-area">
                      {isEditable ? (
                        <Form.Control
                          name="special_instructions"
                          as="textarea"
                          rows="2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.special_instructions}
                        />
                      ) : (
                        order.special_instructions
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <Form.Group>
                    <InfoItemTitle>Email Updates: </InfoItemTitle>
                    <Form.Check
                      id={"email-updates-on"}
                      type={"radio"}
                      label={"ON"}
                      name="email-updates-on"
                      inline
                      checked={values.email_updates_enabled}
                      onChange={(e) => {
                        setFieldValue("email_updates_enabled", true);
                      }}
                      disabled={!isEditable}
                    ></Form.Check>
                    <Form.Check
                      id={"email-updates-off"}
                      type={"radio"}
                      label={"OFF"}
                      name="email-updates-off"
                      inline
                      checked={!values.email_updates_enabled}
                      onChange={(e) => {
                        setFieldValue("email_updates_enabled", false);
                      }}
                      disabled={!isEditable}
                    ></Form.Check>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <InfoItemTitle>Email List: </InfoItemTitle>
                    <Typeahead
                      id="client-email-list"
                      labelKey="client-email-list"
                      className="client-email-list-typeahead"
                      multiple
                      ref={clientEmailRef}
                      selected={values.client_email_list}
                      options={values.client_email_list}
                      open={false}
                      disabled={!isEditable}
                      allowNew
                      placeholder="Press enter after each email address to add"
                      onKeyDown={(e) =>
                        handleAddEmail(e, values, setFieldValue)
                      }
                      onChange={(val) =>
                        setFieldValue("client_email_list", val)
                      }
                    />
                    {emailError && <Warn>{emailError}</Warn>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItemTitle>Job Type: </InfoItemTitle>
                  {isEditable
                    ? getValueset(
                        values.contract_type,
                        "contract_type",
                        setFieldValue
                      )
                    : orderValuesets.contract_type[order.contract_type]}
                </Col>
                {selectedOrderType &&
                  selectedOrderType === historicalDigitization && (
                    <Col>
                      <InfoItemTitle>Report Provider: </InfoItemTitle>
                      {isEditable
                        ? getReportProviderDropdown(
                            selectedReportProvider,
                            "id_report_provider",
                            setFieldValue
                          )
                        : selectedReportProvider !== "" &&
                          handleSelectedReportProvider(selectedReportProvider)
                            ?.value}
                    </Col>
                  )}
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItemTitle>Order Type: </InfoItemTitle>
                  {isEditable
                    ? getValueset(
                        selectedOrderType,
                        "order_type",
                        setFieldValue
                      )
                    : selectedOrderType}
                </Col>
                <Col>
                  <InfoItemTitle>Comments to channel: </InfoItemTitle>
                  {(order.comments_to_channel || isEditable) && (
                    <div className="order-edit-text-area">
                      {isEditable ? (
                        <Form.Control
                          name="comments_to_channel"
                          as="textarea"
                          rows="2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.comments_to_channel}
                        />
                      ) : (
                        order.comments_to_channel
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Channel: </InfoItemTitle>
                  {orgs && (
                    <Typeahead
                      selected={getSelectedChannelOrgs(
                        values.channel_orgs,
                        selectableOrgs
                      )}
                      maxResults={5}
                      id="channel-org-id"
                      name="channel-org-id"
                      multiple
                      labelKey="name"
                      disabled={!isEditable}
                      options={getChannelOrgs(selectableOrgs)}
                      placeholder="Search for a channel"
                      onChange={(val) => {
                        console.log(val);
                        setFieldValue("channel_orgs", val);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col>
                  <InfoItemTitle>Priority: </InfoItemTitle>
                  {isEditable ? (
                    <div className="order-edit-field">
                      <Form.Control
                        name="priority"
                        as="input"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.priority}
                      />
                      <span className="order-edit-error">
                        {errors.priority}
                      </span>
                    </div>
                  ) : (
                    order.priority
                  )}
                </Col>
                <Col>
                  <InfoItemTitle>Comments from channel: </InfoItemTitle>
                  {(order.comments_from_channel || isEditable) && (
                    <div className="order-edit-text-area">
                      {isEditable ? (
                        <Form.Control
                          name="comments_from_channel"
                          as="textarea"
                          rows="2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.comments_from_channel}
                        />
                      ) : (
                        order.comments_from_channel
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Submitted Date: </InfoItemTitle>
                  {isEditable
                    ? getDateField(
                        values.submitted_tsecs,
                        "submitted_tsecs",
                        setFieldValue
                      )
                    : getDateStringFromTsecs(order.submitted_tsecs)}
                </Col>
                <Col xs={6}>
                  <InfoItemTitle>Internal Comments: </InfoItemTitle>
                  {(order.internal_comments || isEditable) && (
                    <div className="order-edit-text-area">
                      {isEditable ? (
                        <Form.Control
                          name="internal_comments"
                          as="textarea"
                          rows="2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.internal_comments}
                        />
                      ) : (
                        order.internal_comments
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Data Received Date: </InfoItemTitle>
                  {isEditable
                    ? getDateField(
                        values.data_received_tsecs,
                        "data_received_tsecs",
                        setFieldValue
                      )
                    : getDateStringFromTsecs(order.data_received_tsecs)}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Requested Delivery Date: </InfoItemTitle>
                  {isEditable
                    ? getDateField(
                        values.requested_delivery_date,
                        "requested_delivery_date",
                        setFieldValue
                      )
                    : getDateStringFromTsecs(order.requested_delivery_date)}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Anticipated Delivery Date: </InfoItemTitle>
                  {getDateStringFromTsecs(order.estimated_delivery_date)}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Processing Hold: </InfoItemTitle>
                  <FormCheck
                    checked={values.processing_hold}
                    name="processing_hold"
                    type="checkbox"
                    onChange={(newValue) =>
                      setFieldValue("processing_hold", newValue.target.checked)
                    }
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Client Org: </InfoItemTitle>
                  {orgs && (
                    <Typeahead
                      selected={getSelectedClientOrgs(
                        values.client_orgs,
                        selectableOrgs
                      )}
                      maxResults={5}
                      id="client-org-id"
                      name="client-org-id"
                      labelKey="name"
                      multiple
                      disabled={!isEditable}
                      options={selectableOrgs}
                      placeholder="Search for an Org"
                      onChange={(val) => {
                        // Warn about a change to client org id
                        setShowPermissionsWarning("CLIENT_ORG_ID");
                        setFieldValue("client_orgs", val);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Project Manager: </InfoItemTitle>
                  {pmUsers && (
                    <Typeahead
                      selected={getSelectedPMUser(pmUsers, values.pm_user_id)}
                      maxResults={5}
                      id="pm-user-id"
                      name="pm-user-id"
                      labelKey={(o) => o.name}
                      disabled={!isEditable}
                      options={pmUsers}
                      placeholder="Search for a PM"
                      onChange={(val) => {
                        if (val && val.length > 0 && val[0].id) {
                          setFieldValue("pm_user_id", val[0].id);
                        }
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  <InfoItemTitle>Data Manager: </InfoItemTitle>
                  {pmUsers && (
                    <Typeahead
                      selected={getSelectedPMUser(
                        pmUsers,
                        values.data_collection_manager_id
                      )}
                      maxResults={5}
                      id="pm-user-id"
                      name="pm-user-id"
                      labelKey={(o) => o.name}
                      disabled={!isEditable}
                      options={pmUsers}
                      placeholder="Search for a Data Manager"
                      onChange={(val) => {
                        if (val && val.length > 0 && val[0].id) {
                          setFieldValue(
                            "data_collection_manager_id",
                            val[0].id
                          );
                        }
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="order-edit-row">
                <Col xs={6}>
                  {order.status === orderStatuses.DELIVERED && (
                    <>
                      <InfoItemTitle>Delivered Date: </InfoItemTitle>
                      {isEditable
                        ? getDateField(
                            values.delivered_tsecs,
                            "delivered_tsecs",
                            setFieldValue
                          )
                        : getDateStringFromTsecs(order.delivered_tsecs)}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <OrderServiceInstruction editable={isEditable} order={order} />
              </Row>
              {isEditable && false && (
                <Row>
                  <AlertContainer>
                    <p>
                      Required fields for delivery emails are not all populated.
                      Please update all fields OR turn off email updates in
                      order to proceed
                    </p>
                  </AlertContainer>
                </Row>
              )}
              <Row>
                {isEditable && <Button onClick={handleSubmit}>Save</Button>}
              </Row>
            </>
          );
        }}
      </Formik>
      <PermissionsWarningModal
        show={showPermissionsWarning}
        toggle={() => {
          // clear the updated order id status
          if (showPermissionsWarning === "STATUS") {
            setUpdatedOrder({});
          }
          setShowPermissionsWarning("");
        }}
        onContinue={() => {
          // if status change, update the order
          if (showPermissionsWarning === "STATUS") {
            orderActions.updateOrder(updatedOrder).then(() => {
              orderActions.updateOrder(updatedOrder, true);
            });

            const search = window.location.search;
            const params = new URLSearchParams(search);
            let filterType = params.get("type");
            let filterText = params.get("value");

            if (
              ![null, "", " ", undefined].includes(filterType) &&
              ![null, "", " ", undefined].includes(filterText)
            ) {
              orderActions.getFilteredOrderSummaries(filterType, filterText);
            }
          } else {
            // else just hide the modal
            setShowPermissionsWarning("");
          }
        }}
        message={getPermissionsWarningMessage()}
      />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  reportProviders: state.order.report_providers,
  events: state.order.events,
});

const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderEditDetailsTab);
