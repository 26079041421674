import React, { useState, useEffect } from 'react';
import OrderEditConfirmCancelModal from './OrderEditConfirmCancelModal';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { orderStatuses } from '../util/constants';

import SolarOrdersApiClient from '../../../apiClient/SolarOrdersApiClient';
/**
 * Renders the header for the order edit modal.
 * If order status is not cancelled, allow orders to be cancelled.
 * If order status is cancelled, allow order to be un-cancelled.
 * @param order
 * @param solarFarms - current solar farm for order
 * @param orderActions
 * @returns {*}
 * @constructor
 */
const OrderEditHeader = ({ order, solarFarm, orderActions, close }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const farmName = (solarFarm && solarFarm.name) || '';

  const handleModalShow = () => setShowCancelModal(true);
  const handleModalClose = () => setShowCancelModal(false);

  const handleModalSubmit = () => {
    if (order.status !== orderStatuses.CANCELLED) {
      handleConfirmCancel();
    } else {
      handleRestoreCancelledOrder();
    }
  };

  const handleConfirmCancel = () => {
    handleModalClose();
    orderActions.cancelOrder(order.uuid);
    close();
  };
  

  const handleRestoreCancelledOrder = () => {
    const solarOrdersApi = new SolarOrdersApiClient();
    solarOrdersApi
      .restoreCancelledOrder(order.uuid)
      .then(res => {
        handleModalClose();
      })
      .catch(err => {
        console.log(err);
        handleModalClose();
      });
  };

  return (
    <Row>
      <Col sm={10}>{`Order #${order && order.id}: ${farmName}`}</Col>
      <Col sm={2}>
        {order && order.status === orderStatuses.CANCELLED ? (
          <Button className="delete-order-button">
            <FontAwesomeIcon icon={faTrashRestore} onClick={handleModalShow} />
          </Button>
        ) : (
          <Button className="delete-order-button">
            <FontAwesomeIcon icon={faTrash} onClick={handleModalShow} />
          </Button>
        )}
        <OrderEditConfirmCancelModal
          showCancelModal={showCancelModal}
          handleSubmit={handleModalSubmit}
          handleModalClose={handleModalClose}
          isOrderCancelled={order.status === orderStatuses.CANCELLED}
          orderUuid={order.uuid}
        />
      </Col>
    </Row>
  );
};

export default OrderEditHeader;
