const GlobalColor = {
  rOrange1: '#F07C20',
  rOrange2: '#b56535',
  rBlack1: '#131313',
  rBlack2: '#242424',
  rBlack3: '#3D3D3D',
  rBlack4: '#000000',
  rWhite: '#ffffff',
  rGrey1: '#8E8E8E',
  rGrey2: '#C4C4C4',
  rGrey3: '#666666',
  rGrey4: '#898989',
  rGrey5: '#eaeaea',
  rRed1: '#d21c1c',
  rGreen1: '#2ea958',
  rYellow1: '#ffcc00'
};

export const colors = { GlobalColor };
