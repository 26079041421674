import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

/**
 * Typeahead component for sales onboarding form
 * @param selectedId {number} - selected id
 * @param options {[]} - list of options
 * @param onChange {function} - callback
 * @param rest {object} - other props
 * @returns {*}
 * @constructor
 */
const SalesOnboardingTypeahead = ({ selectedId, disabled, options, onChange, ...rest }) => {
  return (
    <Typeahead
      selected={selectedId ? [options.find(o => o.id === selectedId)] : []}
      options={options}
      placeholder="Type to search..."
      labelKey={o => o.name}
      disabled={disabled}
      onChange={val => {
        if (val && val.length > 0 && val[0].id) {
          onChange(val[0].id);
        }
      }}
      {...rest}
    />
  );
};

export default SalesOnboardingTypeahead;
