import styled, { css } from 'styled-components';


export const RaptorTable = styled.table.attrs(() => ({
  className: 'Raptor_Table',
}))`
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.thead.attrs(() => ({
  className: 'Raptor_Table__Header'
}))`
  background: #48494a;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: solid 1px #48494a;
  
  tr {
    th{
      padding: 10px;
      height: 40px;

      svg{
        margin-left: 10px;
      }
    }
  }

  input{
    background: none;
    border: solid 1px #58595a;
    border-radius: 5px;
    color: #ffffff;
    &:focus{
      border: solid 1px #b9b9b9;
      outline: none
    }
    &:focus-visible{
      outline: none
    }
  }

  select {
    background: none;
    color: #fff;
    width: 100%;
    border-radius: 5px;
    &:focus-visible{
      outline: none
    }
  }
`;

export const ThContainer = styled.div.attrs(() => ({
  className: 'ThContainer'
}))`
  display: flex;
  flex-direction: column;
`

export const TableBody = styled.tbody.attrs(() => ({
  className: 'Raptor_Table__Body'
}))`

  tr > td{
    padding: 4px 10px;
    border: solid 1px #48494a;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
`;

export const TableTd = styled.td.attrs(() => ({
  className: 'Raptor_Table_td'
}))`
  padding: 3px;
  padding: 2px 10px;
`