import React from 'react';

import { Row } from 'react-bootstrap';
import OrderInspections from '../../OrderInspections';
import OrderDownloads from '../../OrderDownloads';
import OrderUploadRequests from '../../OrderUploadRequests';

/**
 * Contains components to form the order data tab. Components include
 * order inspections, order downloads, and upload requests.
 * @param order {Order}
 * @returns {*}
 * @constructor
 */
const OrderDataTab = ({ order, solarFarm }) => {
  return (
    <>
      <Row>
        <OrderInspections solarFarm={solarFarm} order={order} />
      </Row>
      <div className="order-edit-divider" />
      <Row>
        <OrderDownloads order={order} />
      </Row>
      <Row>
        <OrderUploadRequests order={order} />
      </Row>
    </>
  );
};

export default OrderDataTab;
