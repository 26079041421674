import React from 'react';
import { Pagination } from 'react-bootstrap';

/**
 * Reusable pagination component that paginates on a one dimensional array.
 * @param {Array.<number>} paginatedIndices Array of sorted numbers to build each numbered pagination button
 * @param {Callable} onNavClick Callable function that accepts a single number used to update the index of the paginated view
 * @param {number} activePaginateIndex The index used to highlight the current active paginated button
 * @param {string} size 'sm' | 'lg' Optional sizing for the pagination buttons. Defaults to 'md'
 */
const DumbPagination = ({ paginatedIndices, onNavClick, activePaginateIndex, size }) => {
  /**
   * Decrement the active index highlight if it's not the first index
   */
  const goPrevious = () => {
    const previousPaginatedIndex = activePaginateIndex - 1;
    if (previousPaginatedIndex >= 0) {
      onNavClick(previousPaginatedIndex);
    }
  };

  /**
   * Increment the active index highlight if it's not the last index
   */
  const goNext = () => {
    const paginatedLength = paginatedIndices.length - 1;
    const nextPaginatedIndex = activePaginateIndex + 1;
    if (nextPaginatedIndex <= paginatedLength) {
      onNavClick(nextPaginatedIndex);
    }
  };

  /**
   * Jump to a specific paginated index if it exists within the paginatedIndices
   * @param {number} idx The index of the clicked paginated item
   */
  const goIndex = idx => {
    if (paginatedIndices.includes(idx)) {
      onNavClick(idx);
    }
  };

  return (
    <Pagination size={size || 'md'}>
      <Pagination.Prev onClick={goPrevious} />
      {paginatedIndices.map(i => (
        <Pagination.Item
          key={i}
          onClick={() => goIndex(i)}
          active={i === activePaginateIndex}
          value={i}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={goNext} />
    </Pagination>
  );
};

export default DumbPagination;
