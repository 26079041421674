import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Spinner } from 'react-bootstrap';


import {
  ViewAllFarms,
  EditAllFarms,
  CreateFarms,
  DeleteFarms,
  RemoveUserButton,
} from './OrganizationEditHelper';
import { FormattedMessage } from 'react-intl';

/**@const FarmPermittance - Table that renders the permissions for solar farms.
 * Formatting of ther headers can be found in the OrganizationEditHelper file. */
const FarmPermittance = props => {
  return (
    <Row>
      <Col xs={12}>
        {props.users?.length ? (
          <div className="panel">
            <BootstrapTable
              data={props.users}
              keyField="email"
              headerStyle={{ fontSize: '12px' }}
              bordered={false}
              className="rm-farm-permissions-table"
            >
              <TableHeaderColumn
                dataField="email"
                width="20%"
                dataAlign="left"
                className="rm-column-header"
              >
                <FormattedMessage
                  id="app.orgeditpage.email"
                  defaultMessage="Email"
                  description="Email column header"
                />
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={props.formatData}
                formatExtraData="can_view_farms_by_default"
                dataAlign="center"
                width="5%"
                className="rm-column-header"
              >
                {ViewAllFarms}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={props.formatData}
                formatExtraData="can_edit_farms_by_default"
                dataAlign="center"
                className="rm-column-header"
                width="5%"
              >
                {EditAllFarms}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={props.formatData}
                formatExtraData="can_create_farms"
                dataAlign="center"
                width="5%"
                className="rm-column-header"
              >
                {CreateFarms}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={props.formatData}
                formatExtraData="can_delete_farms"
                dataAlign="center"
                width="5%"
                className="rm-column-header"
              >
                {DeleteFarms}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={props.formatData}
                formatExtraData="is_admin"
                dataAlign="center"
                width="5%"
                className="rm-column-header"
              >
                Admin
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={(row, cell) => (
                  <RemoveUserButton
                    onRemoveUser={props.onRemoveUser}
                    cell={cell}
                    users={props.users}
                  />
                )}
                dataAlign="center"
                width="5%"
                className="rm-column-header"
              >
                <FormattedMessage
                  id="app.orgeditpage.remove"
                  defaultMessage="Remove"
                  description="Remove column header"
                />
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        ) : (
          <Spinner pillColor={"#EAE9E7"} size={80} width={10} />
        )}
      </Col>
    </Row>
  );
};

PropTypes.FarmPermittance = {
  users: PropTypes.array.isRequired,
  formatData: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
};

export default FarmPermittance;
