// @ts-nocheck
import { FunctionComponent, useEffect, useState } from 'react';

//API

// components
// @ts-ignore
import { Button } from '@raptormaps/raptor-ui-legacy';
import RaptorModal from '../../../components/shared/Modal';

//styled components
import {
  CustomSelect, ModalContent,
  ModalInputsContainer,
  ModalOrgTitle
} from '../styles/DefaultClientOrg_styles';

interface IEditModal {
  channelOptions: any,
  row: any,
  handleUpdateOrgChannel: any
}

const EditModal: FunctionComponent<IEditModal> = ({
  channelOptions,
  row,
  handleUpdateOrgChannel
}) => {
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    setSelectedRow(row);
  }, [row]);

  const handleSelectedChannel = (ChannelId: number) => {
    let selectedOrg = channelOptions.find(item => item.id === ChannelId);
    handleUpdateOrgChannel(selectedOrg);
    setSelectedRow(null);
  };

  return (
    <RaptorModal
      title="Client Org Channel"
      show={selectedRow}
      close={() => setSelectedRow(null)}
      overlayClick={false}
    >
      <ModalContent>
        <ModalInputsContainer>
          <ModalOrgTitle>{selectedRow?.org_name}</ModalOrgTitle>
          <CustomSelect
            value={selectedRow?.channel_name}
            onChange={e => handleSelectedChannel(Number(e.target.value))}
          >
            <option value="">All</option>
            {channelOptions?.map(item => (
              <option value={item?.id}>{item?.name}</option>
            ))}
          </CustomSelect>
        </ModalInputsContainer>
        <Button width={100}>Save</Button>
      </ModalContent>
    </RaptorModal>
  );
};

export default EditModal;
