import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Pagination } from 'react-bootstrap';

import DumbPagination from './DumbPagination';
import DumbTable from './DumbTable';
import PermissionModal from './PermissionModal';

import OrgsApiClient from '../../apiClient/OrgsApiClient';
import FeatureApiClient from '../../apiClient/FeatureApiClient';

import './orgPermissions.css';
import SolarFarmsApiClient from '../../apiClient/SolarFarmsApiClient';

const dumbTableStyles = {
  display: 'flex',
  width: '100%',
  minHeight: '360px',
  maxHeight: '1200px',
  overflow: 'auto'
};

const validSearchKeyMap = {
  client_org_id: 'Organization ID',
  client_orgs_names: 'Organization Name',
  id: 'Solar Farm ID',
  name: 'Solar Farm Name'
};

/**
 * Adds a solar org's data to the solar farm object if the id of the org matches
 * the solar farm's org_id property.
 * @param {object} solarFarm The solar farm to add org data to
 * @param {object} solarOrgs A giant object containing all orgs keyed on org_id
 */
const coalesceOrgToFarm = (solarFarm, solarOrgs) => {
    const org = solarOrgs[solarFarm?.client_org_id];
    if (org?.id === solarFarm?.client_org_id) {
      solarFarm.org = org;
      solarFarm.org_name = org.name;
  }
  return solarFarm;
};
/**
 * Component to let admins search for orgs and enable feature toggling.
 */
const FarmPermissions = () => {
  const [solarOrgs, setSolarOrgs] = useState({});
  const [solarFarms, setSolarFarms] = useState([]);

  //   Pagination related state
  const paginateBy = 400;
  const [paginatedIndices, setPaginatedIndices] = useState();
  const [paginatedTables, setPaginatedTables] = useState([]);
  const [activePaginateIndex, setActivePaginateIndex] = useState(0);

  //   Search related state
  const [searchInput, setSearchInput] = useState('');

  //   Farm Modal related state
  const [showModal, setShowModal] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState({});
  const [farmFeatures, setFarmFeatures] = useState([]);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    new OrgsApiClient()
      .getOrgs()
      // Convert returned array of solar orgs into an object keyed on org_id of orgs
      .then(res => res.reduce((obj, org) => (obj[org.id]=org, obj), {}))
      .then(res => setSolarOrgs(res));
    new FeatureApiClient().getCreatedFeatures().then(res => setFeatures(res));
  }, []);

  useEffect(() => {
    new SolarFarmsApiClient()
      .getAllSolarFarms()
      .then(res => res.map(sf => coalesceOrgToFarm(sf, solarOrgs)))
      .then(res => res.sort((a, b) =>  a.org_name?.localeCompare(b.org_name)))
      .then(res => setSolarFarms(res));
  }, [solarOrgs]);

  /**
   * setPaginatedTables transforms a list of farms into a 2-dimensional list where each list has
   * {paginateBy} number of elements.
   *
   * setPaginatedIndices takes the length of the list of lists and returns a 1-dimensional
   * list of containing the indicies of the paginatedTables
   *
   */
  useEffect(() => {
    const chunky = _.chunk(solarFarms, paginateBy);
    setPaginatedTables(chunky);
    setPaginatedIndices(chunky.map((_, idx) => idx));
  }, [solarFarms]);

  /**
   * Cast search value to string and lowercase then fuzzy match on solar farms.
   * @param {InputEvent} e event fired when a user types in the search bar.
   */
  const handleInput = e => {
    setSearchInput(e.target.value);
    const input = (e.target.value + '').toLowerCase().replace(/\s/g, '');
    const filteredResults = solarFarms.filter(
      sf => (sf?.id + ' ' + sf?.org_id + ' ' + sf?.name.toLowerCase()
      .replace(/\s/g, '') +  ' ' + (sf?.org?.name + '')
      .toLowerCase()
      .replace(/\s/g, '')).includes(input)
    );
    const chunky = _.chunk(filteredResults, paginateBy);
    setPaginatedTables(chunky);
    setPaginatedIndices(chunky.map((_, idx) => idx));
  };

  const handleTableClick = o => {
    const params = { solar_farm_id: o.id };
    new FeatureApiClient()
      .getOrgFeatures(params)
      .then(res => setFarmFeatures(res))
      .then(() => setSelectedFarm(o))
      .then(() => setShowModal(true));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const tableObjectKeys = Object.keys(validSearchKeyMap);
  const tableHeaders = Object.values(validSearchKeyMap);
  const SEARCH_PLACEHOLDER = "Filter by site name, site ID, or org name..."
  return (
    <div className="parent-div-org-permissions">
      <PermissionModal
        show={showModal}
        handleClose={handleModalClose}
        selectedOrg={selectedFarm}
        orgFeatures={farmFeatures}
        setOrgFeatures={setFarmFeatures}
        allFeatures={features}
        farmLevel={true}
      />
      {!_.isEmpty(paginatedIndices) && !_.isEmpty(paginatedTables) ? (
        <div className="child-div-org-permissions">
          <InputGroup className="mb-3">
            <FormControl
              autoFocus
              placeholder={SEARCH_PLACEHOLDER}
              onChange={handleInput}
              value={searchInput}
            />
          </InputGroup>
          <div style={dumbTableStyles}>
            <DumbTable
              objectArray={Array.from(paginatedTables[activePaginateIndex])}
              objectRowValueKeys={tableObjectKeys}
              headers={tableHeaders}
              onClick={handleTableClick}
              tdStyle={{ maxWidth: '120px' }}
            />
          </div>
          <DumbPagination
            paginatedIndices={paginatedIndices}
            onNavClick={setActivePaginateIndex}
            activePaginateIndex={activePaginateIndex}
          />
        </div>
      ) : (
        <div className="child-div-org-permissions">
          <InputGroup className="mb-3">
            <FormControl
              autoFocus
              placeholder={SEARCH_PLACEHOLDER}
              onChange={handleInput}
              value={searchInput}
            />
            <div style={dumbTableStyles} />
          </InputGroup>
          <Pagination>
            <Pagination.Prev disabled />
            <Pagination.Item disabled>1</Pagination.Item>
            <Pagination.Next disabled />
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default FarmPermissions;
