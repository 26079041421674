import React from "react";

import { Formik } from "formik";
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row } from "react-bootstrap";
import * as Yup from "yup";

import { accountingItemTypes } from "@raptormaps/raptor-lib";

const schema = Yup.object({
  amount: Yup.number()
    .min(0, 'Amount cannot be negative')
    .max(10000000, 'Amount cannot be greater than 10 million')
    .required()
});

/**
 * Renders the form row for adding a new accounting info item to an order.
 * User must select a type and enter an amount to submit the form.
 *
 * @param accountingInfoItems {array} -
 * @param onSubmit {function} -
 * @returns {*}
 * @constructor
 */
const OrderFinancialsEditRow = ({ accountingInfoItems, onSubmit }) => {
  return (
    <Row>
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          internal_code: null,
          name: "Select One",
          amount: "",
          item_type: accountingItemTypes.REVENUE
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, errors}) => (
          <>
            <Col xs={2}>
              <div style={{ marginTop: "calc(14px + .5rem)" }}>
                <Form.Check
                  type="radio"
                  name="item_type"
                  label="Revenue"
                  checked={values.item_type === accountingItemTypes.REVENUE}
                  onChange={() =>
                    setFieldValue("item_type", accountingItemTypes.REVENUE)
                  }
                />
                <Form.Check
                  type="radio"
                  name="item_type"
                  label="Expense"
                  checked={values.item_type === accountingItemTypes.EXPENSE}
                  onChange={() =>
                    setFieldValue("item_type", accountingItemTypes.EXPENSE)
                  }
                />
              </div>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Accounting Type</Form.Label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={values.name}
                  variant="primary"
                >
                  {accountingInfoItems && accountingInfoItems
                    .filter(i => i.item_type === values.item_type)
                    .map(i => (
                      <Dropdown.Item
                        onClick={() => {
                          setFieldValue("internal_code", i.internal_code);
                          setFieldValue("name", i.name);
                        }}
                      >
                        {i.name}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    name="amount"
                    aria-describedby="inputGroupPrepend"
                    value={values.amount}
                    onChange={handleChange}
                    isInvalid={!!errors.amount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.amount}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={2} style={{ textAlign: "right" }}>
              <Button
                onClick={handleSubmit}
                disabled={!values.internal_code || !values.amount}
                style={{ marginTop: "calc(24px + .5rem)" }}
              >
                Save
              </Button>
            </Col>
          </>
        )}
      </Formik>
    </Row>
  );
};

export default OrderFinancialsEditRow;
