import React from 'react';

import { Button, Modal } from 'react-bootstrap'

/** @const <b>DeleteWarningModal</b>- Generic Modal that will warn the user when either deleteing
 * a solar farm or solar inspection.
 * @property (bool) show - bool to show or hide the modal.
 * @property (func) toggle - toggles the show or hide variable.
 * @property (string) header - the header string for the modal.
 * @property (string) body - the body text for the modal.
 * @property (func) - deleteItem - function to delete whatever is being deleted.
 *
 */
const DeleteWarningModal = ({ show, toggle, header, body, deleteItem }) => {
  return <Modal show={show} onHide={() => toggle()}>
    <Modal.Header closeButton>
      <Modal.Title style={{ color: 'red' }}>{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{body}</p>
      <br />
      <p>This action CANNOT be undone. Please proceed with caution.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => toggle()} className='btn btn-primary'>
        Cancel
      </Button>
      <Button
        onClick={() => {
          deleteItem();
          toggle();
        }}
        className='btn btn-danger'>Delete
      </Button>
    </Modal.Footer>
  </Modal>
};

export default DeleteWarningModal;