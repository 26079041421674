import styled from "styled-components";

export const EventTitle = styled.p.attrs(() => ({
  className: "event__title",
}))`
  margin: 0;
  font-weight: 600;
  margin-top: 15px;
`;

export const EventSubTitle = styled.p.attrs(() => ({
  className: "event__subtitle",
}))`
  margin: 0;
  font-weight: 500;
`;

export const ButtonContainer = styled.div.attrs(() => ({
  className: "event__button_container",
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  button {
    margin-bottom: 5px;
  }
`;
