import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import toastr from 'toastr';

import { formatFloatToString, getFileType } from '@raptormaps/raptor-lib';
import { SolarFarm } from '@raptormaps/raptor-models';

import OrdersApiClient from '../../apiClient/OrdersApiClient';
import RaptorAdminRoutesApiClient from '../../apiClient/RaptorAdminRoutesApiClient';
import UsersApiClient from '../../apiClient/UsersApiClient';
import { ORG_TYPES } from '../../util';
import CreateOrganizationModal from './CreateOrganizationModal';
import FarmOrdersComponent from './FarmOrdersComponent';

// Form components
import FormInput from './formComponents/FormInput';
import OrderValueSetDropdown from './formComponents/OrderValueSetDropdown';
import SalesOnboardingDatePicker from './formComponents/SalesOnboardingDatePicker';
import SalesOnboardingTypeahead from './formComponents/SalesOnboardingTypeahead';

import CSVDropzoneModal from './CSVDropzoneModal';
import OrderListCSVProcessor from './OrderListCSVProcessor';
import listSchema from './schema/orderListSchema';
import formSchema from './schema/salesOnboardingFormSchema';

import * as util from '../OrderStatus/util';

const ACCOUNTING_INFO_ITEM_CODES = {
  FLIGHT_REVENUE: 'FLIGHT-REVENUE',
  ANALYTICS_REVENUE: 'ANALYTICS-REVENUE'
};

const GOOGLE_SHEET_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1LLfmcVAFXHE-gk2mH2MGTfe3mdKpWurUKZzuEcpgR-k/edit#gid=0';

// Id for service RGB Orthomosaic and Expedited
const RGB_ORTHOMOSAIC_SERVICE_ID = 4;
const EXPEDITED_SERVICE_ID = 6;


/**
 * Component to hold all form for the sales onboarding page
 *
 * @param orgs {[Org]} - all orgs
 * @returns {*}
 * @constructor
 */
const SalesOnboardingForm = ({ orgs }) => {
  // either 'small' or 'data collector'
  const [createOrgType, setCreateOrgType] = useState(null);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [orderListTooShort, setOrderListTooShort] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isLoadingCSVValidate, setIsLoadingCSVValidate] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  // Get admin users and services on page load
  useEffect(() => {
    getRaptorAdminUsers();
    getAllServices();
  }, []);

  /**
   * Makes call to users client to get admin users
   */
  const getRaptorAdminUsers = () => {
    const usersApi = new UsersApiClient();
    usersApi.getRaptorAdminUsers().then(users => setAdminUsers(users));
  };

  /**
   * Gets all services from order client
   */
  const getAllServices = () => {
    const ordersApi = new OrdersApiClient();
    ordersApi.getAllServices().then(services => setServices(services));
  };

  /**
   * Prevent the form from submitting when the user hits enter
   * @param e
   */
  const onFormKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  /**
   * Callback when a new org is created
   * @param orgId {number} -
   * @param setFieldValue {function} -
   */
  const handleCreateOrgSuccess = (org, setFieldValue) => {
    setShowCreateOrgModal(false);
    if (createOrgType === ORG_TYPES.SMALL) {
      setFieldValue('client_orgs', org);
    } else if (createOrgType === ORG_TYPES.DATA_COLLECTOR) {
      setFieldValue('data_collection_org_id', org.id);
    }
  };

  /**
   * Handle form data on submission
   * @param formValues {object} - form values being submitted
   */
  const handleSubmit = formValues => {
    // validate the rows in the order list

    validateOrderList(orderList).then(isValid => {
      if (!isValid) {
        return;
      }

      // Take the default values and apply them to each order
      const order_list = orderList.map(order => {
        // Format string dates to unix. Keeps dates as strings in frontend but keeps formatting validation
        let unixRequestedDeliveryDate = util.getTsecsFromDate(order.requested_delivery_date);
        let unixRequestedFlightDate = util.getTsecsFromDate(order.requested_flight_date);
        order = {
          data_collection_org_id: order.data_collection_org_id,
          order_type: order.order_type,
          client_orgs: formValues.client_orgs,
          contract_type: order.contract_type,
          site_contact_uuid: order.contact,
          requested_delivery_date: unixRequestedDeliveryDate,
          requested_flight_date: unixRequestedFlightDate,
          turnkey_services: order.is_turnkey,
          solar_farm: SolarFarm.formatForInsertUpdate(order.solar_farm),
          solar_farm_uuid: order.farm?.uuid,
          services: formValues.services,
          accounting_info_items: getOrderAccountingInfoItems(order)
        };
        return order;
      });
      
      // Build data for request body
      const submitData = {
        project_name: formValues.project_name,
        signed_proposal_link: formValues.signed_proposal_link,
        dsp_contract_link: formValues.dsp_contract_link,
        other_context: formValues.other_context,
        mailing_list: formValues.client_email_list,
        pm_user_id: formValues.project_manager_user_id,
        data_collection_manager_id: formValues.data_collection_manager_id,
        hubspot_deal_url: formValues.hubspot_deal_url,
        order_list: order_list
      };

      // Submit, show toastr
      const raptorAdminApi = new RaptorAdminRoutesApiClient();
      setIsLoadingSubmission(true);
      raptorAdminApi
        .submitSalesOnboarding(submitData)
        .then(_ => {
          // eslint-disable-next-line no-undef
          mixpanel.track('Raptor Admin - Submit Project Onboarding');
          toastr.success('Successfully submitted customer onboarding form');
        })
        .catch(err => toastr.error(err))
        .finally(_ => setIsLoadingSubmission(false));
    });
  };

  /**
   * Gets the accounting info items array for a single order
   *
   * @param order {Order} -
   * @returns {[]}
   */
  const getOrderAccountingInfoItems = order => {
    let items = [];
    if (order.flight_revenue && order.flight_revenue !== '') {
      items.push({
        accounting_item_codekey: ACCOUNTING_INFO_ITEM_CODES.FLIGHT_REVENUE,
        amount: order.flight_revenue
      });
    }
    if (order.analytics_revenue && order.analytics_revenue !== '') {
      items.push({
        accounting_item_codekey: ACCOUNTING_INFO_ITEM_CODES.ANALYTICS_REVENUE,
        amount: order.analytics_revenue
      });
    }
    return items;
  };

  /**
   * Validates the length and content of the order list
   *
   * @param orderList {object}
   */
  const validateOrderList = (orderList) => {
    return new Promise(resolve => {
      // There must be at least one order in the list to submit
      if (orderList.length === 0) {
        setOrderListTooShort(true);
        return;
      }

      let isValid = true;
      // Check that the farms exist first on the order
      const updatedOrderList = [...orderList];
    
      listSchema
        .validate(orderList, { abortEarly: false, context: { orderList } })
        .catch(err => {
          isValid = false;
          // Have to use a regex with this since there's no better way to get
          // the error for the position in the array. The regex gets the position (ex. 0)
          // and the portion of text after the last period (ex. size)
          // example: [0].solar_farm.size
          let pathRegex = /^\[(\d+)\].*\.(\w+)$/;
          let position, value;
          // iterate the errors from validation
          err.inner.map(error => {
            // get the position and value and set the message
            // eslint-disable-next-line no-undef
            [_, position, value] = error.path.match(pathRegex);
            updatedOrderList[position].errors[value] = error.message;
          });
        })
        .finally(_ => {
          // lastly set the order list and return is valid
          setOrderList([...updatedOrderList]);
          resolve(isValid);
        });
    });
  };

  const clientEmailRef = React.createRef();

  /**
   * Handles adding an email on enter in the email field
   * Validates that the email is properly formatted
   *
   * @param e {Event}
   * @param values {object}
   * @param setFieldValue {function}
   */
  const handleAddEmail = (e, values, setFieldValue) => {
    const emailRegex = /^\S+@\S+$/;

    // prevent the form from submitting
    e.stopPropagation();
    if (e.keyCode === 13 && e.target.value.length > 0) {
      // set up the field val, clear the input
      if (!emailRegex.test(e.target.value)) {
        setEmailError('Invalid email!');
      } else {
        setEmailError(null);
        setFieldValue('client_email_list', values.client_email_list.concat(e.target.value));
        clientEmailRef.current.getInstance().clear();
      }
    }
  };

  const Warn = props => (
    <span style={{ color: 'red' }} {...props}>
      {props.children}
    </span>
  );

  /**
   * Handles when the user drops files into the dropzone
   * @param acceptedFiles {array} - array of files dropped
   * @param clientOrgId {number} -
   */
  const handleCSVDrop = (acceptedFiles, clientOrgId) => {
    // Should only be a single file, also needs to be .csv
    if (acceptedFiles.length > 1) {
      toastr.error('Please only drop a single file');
      return;
    }

    const { extension } = getFileType(acceptedFiles[0]);
    if (extension !== 'csv') {
      toastr.error('File must be .csv');
      return;
    }

    setIsLoadingCSVValidate(true);

    // Pass the file and client org id into the processor
    const csvProcessor = new OrderListCSVProcessor(acceptedFiles[0], clientOrgId);
    csvProcessor
      .processCSV()
      .then(validatedOrderList => {
        // Need to specially format the farm size here for the form validation
        const updatedOrderList = validatedOrderList.map(order => {
          if (order.solar_farm && order.solar_farm.size) {
            order.solar_farm.size = formatFloatToString(order.solar_farm.size);
          }
          return order;
        });
        setOrderList(updatedOrderList);
      })
      .catch(err => {
        toastr.error(err);
        console.log(err);
      })
      .finally(_ => {
        setIsLoadingCSVValidate(false);
        setShowCSVModal(false);
      });
  };

  return (
    <Formik
      validationSchema={formSchema}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
      initialValues={{
        project_name: null,
        client_orgs: [],
        client_email_list: [],
        project_manager_user_id: null,
        data_collection_manager_id: null,
        signed_proposal_link: null,
        dsp_contract_link: null,
        hubspot_deal_url: null,
        services: services.filter(s => s.id !== RGB_ORTHOMOSAIC_SERVICE_ID && s.id !== EXPEDITED_SERVICE_ID),
        other_context: null,
        data_collection_org_id: null,
        contract_type: null,
        order_type: null,
        requested_delivery_date: null,
        requested_flight_date: null,
        is_turnkey: false
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue }) => (
        <Form
          noValidate
          id="sales-onboarding-form"
          onSubmit={handleSubmit}
          onKeyPress={onFormKeyPress}
          style={{ marginBottom: '2rem' }}
        >
          <Row className="sales-onboarding-form-section">
            <Col md={12}>
              <Row style={{ marginBottom: '.5rem' }}>
                <Col md={12}>
                  <h6>General Info</h6>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormInput
                    label="Project Name"
                    name="project_name"
                    value={values.project_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.project_name && errors.project_name && (
                    <Warn>{errors.project_name}</Warn>
                  )}
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">Customer Org</Form.Label>
                    <InputGroup>
                      <Typeahead
                        id="client_orgs"
                        name="client_orgs"
                        labelKey="name"
                        className="sales-onboarding-typeahead-with-button customer-org-typeahead"
                        selected={values.client_orgs.length ? values.client_orgs : null}
                        maxResults={5}
                        options={orgs}
                        onChange={val => setFieldValue('client_orgs', val)}
                      />
                      <InputGroup.Prepend>
                        <Button
                          variant="primary"
                          style={{ width: 60 }}
                          onClick={() => {
                            setCreateOrgType(ORG_TYPES.SMALL);
                            setShowCreateOrgModal(true);
                          }}
                        >
                          New
                        </Button>
                      </InputGroup.Prepend>
                      {touched.client_orgs && errors.client_orgs && (
                        <Warn>{errors.client_orgs}</Warn>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Customer Email List</Form.Label>
                    <Typeahead
                      id="client-email-list"
                      labelKey="client-email-list"
                      className="client-email-list-typeahead"
                      multiple
                      ref={clientEmailRef}
                      selected={values.client_email_list}
                      options={values.client_email_list}
                      open={false}
                      allowNew
                      placeholder="Press enter after each email address to add"
                      onKeyDown={e => handleAddEmail(e, values, setFieldValue)}
                      onChange={val => setFieldValue('client_email_list', val)}
                    />
                    {emailError && <Warn>{emailError}</Warn>}
                    {touched.client_email_list && errors.client_email_list && (
                      <Warn>{errors.client_email_list}</Warn>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">Project Manager</Form.Label>
                    <SalesOnboardingTypeahead
                      className="project-manager-typeahead"
                      id="project_manager_id"
                      selectedId={values.project_manager_user_id}
                      options={adminUsers}
                      maxResults={5}
                      labelKey={u => u.email}
                      onChange={userId => setFieldValue('project_manager_user_id', userId)}
                    />
                    {touched.project_manager_user_id && errors.project_manager_user_id && (
                      <Warn>{errors.project_manager_user_id}</Warn>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">Data Collection Manager</Form.Label>
                    <SalesOnboardingTypeahead
                      className="project-manager-typeahead"
                      id="data_collection_manager_id"
                      selectedId={values.data_collection_manager_id}
                      options={adminUsers}
                      maxResults={5}
                      labelKey={u => u.email}
                      onChange={userId => setFieldValue('data_collection_manager_id', userId)}
                    />
                    {touched.data_collection_manager_id && errors.data_collection_manager_id && (
                      <Warn>{errors.data_collection_manager_id}</Warn>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <FormInput
                    label="Link to Hubspot Deal"
                    name="hubspot_deal_url"
                    value={values.hubspot_deal_url}
                    placeholder="Please enter link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.hubspot_deal_url && errors.hubspot_deal_url && (
                    <Warn>{errors.hubspot_deal_url}</Warn>
                  )}
                  <FormInput
                    label="Link to Signed Proposal"
                    name="signed_proposal_link"
                    value={values.signed_proposal_link}
                    placeholder="Please enter link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.signed_proposal_link && errors.signed_proposal_link && (
                    <Warn>{errors.signed_proposal_link}</Warn>
                  )}
                  <FormInput
                    label="Link to DSP Contract"
                    name="dsp_contract_link"
                    value={values.dsp_contract_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.dsp_contract_link && errors.dsp_contract_link && (
                    <Warn>{errors.dsp_contract_link}</Warn>
                  )}
                  <Row>
                    {services.map(service => (
                      <Col key={ service.name } md={6}>
                        <div style={{ marginBottom: '1rem' }}>
                          <Form.Label className="onboard-form-input-label">
                            {service.name}
                          </Form.Label>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            checked={values.services.find(s => s.uuid === service.uuid)}
                            onChange={() =>
                              setFieldValue('services', values.services.concat(service))
                            }
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            checked={!values.services.find(s => s.uuid === service.uuid)}
                            onChange={() =>
                              setFieldValue(
                                'services',
                                values.services.filter(s => s.uuid !== service.uuid)
                              )
                            }
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Other Context</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="other_context"
                      value={values.other_context}
                      rows="3"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: '.5rem' }}>
            <Col>
              <h6>Order Defaults</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button disabled={!values.client_orgs.length} onClick={() => setShowCSVModal(true)}>
                CSV Upload
              </Button>
              <a href={GOOGLE_SHEET_TEMPLATE_URL} style={{ paddingLeft: '10px' }} target="_blank" rel="noreferrer">
                Template
              </a>
            </Col>
          </Row>
          {!values.client_orgs.length && (
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Warn>Must have customer org selected to upload CSV.</Warn>
              </Col>
            </Row>
          )}
          <Row className="sales-onboarding-form-section">
            <Col md={2}>
              <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                <Form.Check
                  type="checkbox"
                  label="Turnkey Services"
                  checked={values.is_turnkey}
                  onChange={() => setFieldValue('is_turnkey', !values.is_turnkey)}
                />
              </div>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label className="onboard-form-input-label">Data Collection Org</Form.Label>
                <InputGroup>
                  <SalesOnboardingTypeahead
                    id="data_collection_org_id"
                    className="sales-onboarding-typeahead-with-button"
                    selectedId={values.data_collection_org_id}
                    options={orgs.filter(o => o.type === ORG_TYPES.DATA_COLLECTOR)}
                    onChange={orgId => setFieldValue('data_collection_org_id', orgId)}
                  />
                  <InputGroup.Prepend>
                    <Button
                      variant="primary"
                      style={{ width: 60 }}
                      onClick={() => {
                        setCreateOrgType(ORG_TYPES.DATA_COLLECTOR);
                        setShowCreateOrgModal(true);
                      }}
                    >
                      New
                    </Button>
                  </InputGroup.Prepend>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Label className="onboard-form-input-label">Type of Project</Form.Label>
              <OrderValueSetDropdown
                type="contract_type"
                selectedValue={values.contract_type}
                onSelect={type => setFieldValue('contract_type', type)}
              />
            </Col>
            <Col md={2}>
              <Form.Label className="onboard-form-input-label">Type of Inspection</Form.Label>
              <OrderValueSetDropdown
                type="order_type"
                selectedValue={values.order_type}
                onSelect={type => setFieldValue('order_type', type)}
              />
            </Col>
            <Col md={1}>
              <Form.Label className="onboard-form-input-label">Requested Flight Date</Form.Label>
              <div style={{ display: 'block', marginBottom: '1rem' }}>
                <SalesOnboardingDatePicker
                  value={values.requested_flight_date}
                  onUpdate={val => setFieldValue('requested_flight_date', val)}
                />
              </div>
            </Col>
            <Col md={1}>
              <Form.Label className="onboard-form-input-label">Requested Delivery Date</Form.Label>
              <div style={{ display: 'block', marginBottom: '1rem' }}>
                <SalesOnboardingDatePicker
                  value={values.requested_delivery_date}
                  onUpdate={val => setFieldValue('requested_delivery_date', val)}
                />
              </div>
            </Col>
          </Row>
          <FarmOrdersComponent
            orderList={orderList}
            setOrderList={setOrderList}
            clientOrgId={values.client_orgs.length ? values.client_orgs[0].id : null}
            defaultValues={{
              data_collection_org_id: values.data_collection_org_id,
              contract_type: values.contract_type,
              order_type: values.order_type,
              requested_delivery_date: values.requested_delivery_date,
              is_turnkey: values.is_turnkey,
              requested_flight_date: values.requested_flight_date
            }}
          />
          {orderListTooShort && (
            <Warn>At least one order must be added to the list for submission</Warn>
          )}
          <Row>
            <Col>
              <Button style={{ float: 'right' }} type="submit" disabled={isLoadingSubmission}>
                Submit
              </Button>
            </Col>
          </Row>
          <CreateOrganizationModal
            show={showCreateOrgModal}
            toggle={() => setShowCreateOrgModal(false)}
            orgType={createOrgType}
            onCreateOrgSuccess={org => handleCreateOrgSuccess(org, setFieldValue)}
          />
          <CSVDropzoneModal
            show={showCSVModal}
            toggle={() => setShowCSVModal(false)}
            onDrop={acceptedFiles => handleCSVDrop(acceptedFiles, (values.client_orgs.length ? values.client_orgs[0].id : null) )}
            isLoading={isLoadingCSVValidate}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  orgs: state.session.orgs
});

export default connect(mapStateToProps, {})(SalesOnboardingForm);
