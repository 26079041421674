import React from 'react';

import { Container, Row } from 'react-bootstrap';
import DuplicateConstructionOrderForm from './constructionTab/DuplicateConstructionOrderForm';

/**
 * Renders construction related actions for orders with order_type === construction
 * Currently this will only display the construction order copying form and will be
 * disabled for any orders that aren't of type construction.
 *
 * @param {*} param0
 */
const OrderConstructionTab = ({ order }) => (
  <Container style={{ paddingTop: '30px', paddingLeft: '15px', paddingBottom: '80px' }}>
    <Row>
      <h5>Copy Construction Order</h5>
    </Row>
    <Row style={{ maxWidth: '75%', marginBottom: '15px' }}>
      <p>
        Launches a task to copy a construction order and its associated inspections. Sends a
        'success' email if completed, otherwise an email with the error message will be delivered.
      </p>
    </Row>
    <DuplicateConstructionOrderForm order={order} />
  </Container>
);

export default OrderConstructionTab;
