import React from "react";

import BootstrapTable from "react-bootstrap-table-next";

/**
 * Renders a single Order Financials data table
 * @param data {}
 * @param onSelectRow {function}
 * @param selectedItem {string}
 * @returns {*}
 * @constructor
 */
const OrderFinancialsTable = ({ data, onSelectRow, selectedItem }) => {
  /**
   * Build the table columns
   * @type {*[]}
   */
  const columns = [
    {
      dataField: "accounting_item_codekey",
      text: "",
      hidden: true
    },
    {
      dataField: "name",
      text: "Item Name"
    },
    {
      dataField: "amount",
      text: "Amount ($)"
    }
  ];

  /**
   * Handles row click
   * @type {{onClick: onClick}}
   */
  const rowEvents = {
    onClick: (e, row) => {
      onSelectRow(row.accounting_item_codekey);
    }
  };

  /**
   * Changes the row style if the row is selected
   * @param row
   * @returns {*}
   */
  const rowStyle = row =>
    selectedItem === row.accounting_item_codekey
      ? {
          backgroundColor: "#007bff",
          color: "#ffff"
        }
      : null;

  return (
    <BootstrapTable
      keyField="internal_code"
      bootstrap4
      data={data}
      rowEvents={rowEvents}
      rowStyle={rowStyle}
      columns={columns}
    />
  );
};

export default OrderFinancialsTable;
