import React from 'react';

import CreateOrganizationComponent from "./CreateOrganizationComponent";
import { ORG_TYPES } from "../util";

/**
 * View for creating a new organization
 * @returns {*}
 * @constructor
 */
const CreateOrganizationView = () => {
  return (
    <div className="raptor-admin-view">
      <CreateOrganizationComponent type={ORG_TYPES.SMALL}/>
    </div>
  );
};

export default CreateOrganizationView;
