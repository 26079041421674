import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";
import theme from "@raptormaps/theme";
import { Text } from "@raptormaps/text";

export const CancelOrderModalBody = styled.table.attrs(() => ({
  className: "order-edit-cancel-order-modal-body",
}))`
  text-align: center;
`;

export const CancelOrderModalTitle = styled.div.attrs(() => ({
  className: "order-edit-cancel-order-modal-title",
}))`
  text-align: center;
`;

export const CancelOrderModalConfirmBtn = styled.div.attrs((Button) => ({
  className: "order-edit-cancel-order-modal-title",
}))`
  margin-right: 15px;
`;

export const OrderDataItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 8px;
  background-color: ${theme.colors.neutral_100};
  padding: 8px;
  border-radius: 4px;

  svg {
    width: 12px;
  }
  :hover {
    cursor: pointer;
    background-color: ${theme.colors.primary_200};
  }
`;
