// @ts-nocheck
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DefaultOrgGroupsApiClient from '../../apiClient/DefaultOrgGroupApiClient';
import UsersApiClient from '../../apiClient/UsersApiClient';
import TableContainer from '../shared/Table';
import AddOrgGroup from './AddOrgGroup';
import EditOrgGroupModal from './EditOrgGroupModal';

interface IDefaultOrgGroupsProps {
  location: object;
  user: object;
  actions: any;
  orgs: [];
}

const DefaultOrgGroups: FunctionComponent<IDefaultOrgGroupsProps> = ({ orgs }) => {
  const [defaultOrgGroups, setDefaultOrgGroups] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [editOrgGroup, setEditOrgGroup] = useState({});
  const [displayEditModal, setDisplayEditModal] = useState(false);

  interface IColumn {
    Header: string;
    accessor: string;
  }
  interface IColumns extends Array<IColumn> {}

  // Table Columns
  const columns: IColumns = [
    {
      Header: 'Organization Id',
      accessor: 'org_id'
    },
    {
      Header: 'Organization Name',
      accessor: 'org_name'
    },
    {
      Header: 'Project Manager',
      accessor: 'project_manager_name'
    },
    {
      Header: 'Data Collection Manager',
      accessor: 'data_collection_manager_name'
    },
    {
      Header: 'Data Collection Org',
      accessor: 'data_collection_org_name'
    }
  ];

  const handleModalClose = () => {
    setEditOrgGroup({});
    setDisplayEditModal(false);
  };

  const handleModalOpen = () => {
    setDisplayEditModal(true);
  };

  /**
   * Close edit modal and refresh table list after updating org group
   * @returns {*}
   */
  const handleUpdateRefresh = () => {
    handleModalClose();
    setDefaultOrgGroups([]);
  };

  /**
   * Clear and refresh table list after submitting org group
   * @returns {*}
   */
  const handleSubmitRefresh = () => {
    setDefaultOrgGroups([]);
    setEditOrgGroup({});
    fetchDefaultOrgs();
  };

  /**
   * Resets input field to null
   * @param fieldName - name of the field
   * @param setFieldValue - formik setFieldValue function
   * @returns {*}
   */
  const handleRemoveInputField = (fieldName, setFieldValue) => {
    setFieldValue(fieldName, null);

    let alterOrgGroup = { ...editOrgGroup };
    alterOrgGroup[`${fieldName}_id`] = null;
    alterOrgGroup[`${fieldName}_name`] = null;

    setEditOrgGroup(alterOrgGroup);
  };

  useEffect(() => {
    fetchDefaultOrgs();
    fetchAdminUsers();
  }, [displayEditModal]);

  // fetches all default orgs to populate table
  const fetchDefaultOrgs = () => {
    const defaultOrgApi = new DefaultOrgGroupsApiClient();

    defaultOrgApi.getDefaultOrgGroups().then(res => {
      setDefaultOrgGroups(res);
    })
  };

  // Fetches Admin Users to populate typeahead options
  const fetchAdminUsers = () => {
    const usersApi = new UsersApiClient();

    usersApi.getRaptorAdminUsers().then(users => setAdminUsers(users))
  };

  // Saves selected row as edit data
  const rowAction = row => {
    let editOrg = defaultOrgGroups.find(o => o.org_id === row.org_id);
    setEditOrgGroup(editOrg);
    handleModalOpen();
  };

  const paginated = false;

  return (
    <Container>
      <Row>
        <Col>
          <h1 style={{ textAlign: 'center' }}>Default Org Groups</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <AddOrgGroup
            orgs={orgs}
            adminUsers={adminUsers}
            editOrgGroup={editOrgGroup}
            displayEditModal={displayEditModal}
            handleUpdateRefresh={handleUpdateRefresh}
            handleSubmitRefresh={handleSubmitRefresh}
            handleRemoveInputField={handleRemoveInputField}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContainer
            rows={defaultOrgGroups}
            columns={columns}
            rowAction={rowAction}
            paginated={paginated}
          />
        </Col>
      </Row>
      <Row>
        <EditOrgGroupModal
          editOrgGroup={editOrgGroup}
          orgs={orgs}
          adminUsers={adminUsers}
          handleModalClose={handleModalClose}
          displayEditModal={displayEditModal}
          handleUpdateRefresh={handleUpdateRefresh}
          handleSubmitRefresh={handleSubmitRefresh}
          handleRemoveInputField={handleRemoveInputField}
        />
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  orgs: state.session.orgs
});

export default connect(mapStateToProps, {})(DefaultOrgGroups);
