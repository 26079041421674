import react from "react";
import toastr from "toastr";

import RestApiV2 from "../api/RestApiV2";

// APIs

export const useOrg = () => {
  const API = new RestApiV2();

  const getOrgUsers = (orgId: number) => {
    const params = {
      org_id: orgId,
    };

    return new Promise((resolve, reject) => {
      API.get(`orgs/${orgId}/users`, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          toastr.error("Error deleting email");
          reject(err);
        });
    });
  };

  return {
    getOrgUsers,
  };
};
