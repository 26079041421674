import React, {useRef, useEffect} from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import FormInput from './FormInput.jsx';

const schema = Yup.object({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  primary_phone: Yup.string().required(),
  email: Yup.string().required(),
  company_name: Yup.string().required()
});

/**
 * Renders form to create a new contact for an org
 * @param onSubmit {function}
 * @param insertContact {function}
 * @param setSelectedContact {function}
 * @return {*}
 * @constructor
 */
const NewContactForm = ({ onSubmit, setFormRef, addButtons = false, onBack }) => {
  const formRef = useRef();
  useEffect(() => {
    if(setFormRef) setFormRef(formRef);
  }, [formRef]);
  const showEndButtons = () => {
    if (!addButtons) return;
    return (
      <div className="farm-info-form-button-row" style={{ position: 'relative' }}>
        <Button
          variant="rm-secondary"
          style={{ float: 'left' }}
          className="footer-button-secondary"
          onClick={onBack}
        >
          CANCEL
        </Button>
        <Button
          type="submit"
          form="new-contact-form"
          variant="rm-default"
          style={{ float: 'right' }}
          className="footer-button-default button-back"
        >
          ADD CONTACT
        </Button>
      </div>
    );
  };
  return (
    <>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          primary_phone: '',
          company_name: ''
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form noValidate id="new-contact-form" onSubmit={handleSubmit}>
              <FormInput
                label="First Name"
                name="first_name"
                value={values.first_name}
                isInvalid={touched.first_name && errors.first_name}
                isValid={touched.first_name && !errors.first_name}
                error="First name is a required field."
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                label="Last Name"
                name="last_name"
                value={values.last_name}
                isInvalid={touched.last_name && errors.last_name}
                isValid={touched.last_name && !errors.last_name}
                error="Last name is a required field."
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                label="Email"
                name="email"
                value={values.email}
                isInvalid={touched.email && errors.email}
                isValid={touched.email && !errors.email}
                error="Email is a required field."
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                label="Primary Phone"
                name="primary_phone"
                value={values.primary_phone}
                isInvalid={touched.primary_phone && errors.primary_phone}
                isValid={touched.primary_phone && !errors.primary_phone}
                error="Primary phone is a required field."
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                label="Company Name"
                name="company_name"
                value={values.company_name}
                isInvalid={touched.company_name && errors.company_name}
                isValid={touched.company_name && !errors.company_name}
                error="Company name is a required field."
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form>
        )}
      </Formik>
      {showEndButtons()}
    </>
  );
};

export default NewContactForm;
