import styled from 'styled-components';
import {Form } from 'react-bootstrap';


export const AlertContainer = styled.div.attrs(() => ({
  className: 'AlertContainer',
}))`
    background: ${({ theme }) => theme.colors.GlobalColor.rYellow1};
    padding: 20px 15px;
    display: flex;
    align-items: center;
    border: solid 1px ${({ theme }) => theme.colors.GlobalColor.rBlack3};
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;

    p {
      margin: 0;
      font-weight: 600;
    }
`;

export const FormLabel = styled(Form.Label).attrs(() => ({
  className: 'FormLabel'
}))`
  font-weight: 500;
  color: ${({ required, theme }) => {
    if (required) {
      return theme.colors.GlobalColor.rRed1;
    } else {
      return theme.colors.GlobalColor.rBlack1;
    }
  }};
`;
