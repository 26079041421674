const useAppsmith = (currentUrl: string) => {
  let canAccessDev: boolean = false;
  let canAccessQA: boolean = false;
  let canAccessProd: boolean = false;

  if (currentUrl.includes("localhost:3000")) {
    canAccessDev = true;
    canAccessQA = true;
    canAccessProd = true;
  } else if (
    currentUrl.includes("ops.performance.raptormaps.com") ||
    currentUrl.includes("ops.insights.raptormaps.com") ||
    currentUrl.includes("ops.inspections.raptormaps.com") ||
    currentUrl.includes("ops.dev.raptormaps.com") ||
    currentUrl.includes("admin.dev.raptormaps.com")
  ) {
    canAccessDev = true;
  } else if (currentUrl.includes("ops.qa.raptormaps.com")) {
    canAccessQA = true;
  } else if (currentUrl.includes("ops.raptormaps.com")) {
    canAccessProd = true;
  }

  return {
    canAccessDev,
    canAccessQA,
    canAccessProd,
  };
};

export default useAppsmith;
