import MapboxDraw from '@mapbox/mapbox-gl-draw';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { raptorKeys } from "@raptormaps/raptor-lib";
import { useEffect, useState } from 'react';

let mapboxgl = require('mapbox-gl');
mapboxgl.accessToken = raptorKeys.mapboxToken;

/**
 * Interface to draw a polygon boundary around a solar farm inspection
 * @param {*} inputBoundary - Existing boundary to display
 * @param {*} onBoundaryUpdate - call back called every time the boundary is updated
 * @param {*} solarFarm - solar farm associated with boundary
 */
const FlightBoundaryMap = ({ inputBoundary, onBoundaryUpdate, solarFarm, hidden }) => {

  const [map, setMap] = useState(null);
  const [mapboxDraw, setMapboxDraw] = useState(null);
  const [boundingBox, setBoundingBox] = useState(null);

  useEffect(() => initializeMap(), [solarFarm]);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (boundingBox && !_.isEqual(boundingBox, inputBoundary)) {
      onBoundaryUpdate(boundingBox);
    }
  }, [boundingBox]);
  useEffect(() => {
      setBoundingBox(inputBoundary)
      switchBoundingBox()
  }, [inputBoundary]);
  useEffect(() => {
    if (mapboxDraw && inputBoundary) {
      mapboxDraw.add(inputBoundary);
    }
  },[mapboxDraw]);
  useEffect(() => {
    if (map){
      map.resize()
    }
  }, [hidden])
  /**
   * Handles logic to create a new bounding box. If there is an existing box, deletes it.
   * @param {*} draw - mapboxgl draw object
   * @param {*} drawEvent - event generated by mapbox
   */
  const createBoundingBox = (draw, drawEvent) => {
    const featureCollection = draw.getAll();
      if (featureCollection.features.length < 2) {
        // There is no existing box
        const box = drawEvent.features[0].geometry;
        setBoundingBox(box);
      } else {
        // There is an existing box, remove and create the new box
        draw.delete(featureCollection.features[0].id);
        const box = drawEvent.features[0].geometry;
        setBoundingBox(box);
      }
  }

  /**
   * Called when the inspection is switched from the dropdown.
   * Deletes any existing boundary from the display, adds the new inspection boundary
   */
  const switchBoundingBox = () => {
    if (mapboxDraw) {
      const featureCollection = mapboxDraw.getAll();

      if (featureCollection.features[0]) {
        mapboxDraw.delete(featureCollection.features[0].id);
      }
      
      if (inputBoundary) {
        mapboxDraw.add(inputBoundary);
      }   
    }
  }

  /**
   * Initializes the map by creating a new mapboxgl Map and mapboxgl Draw tool.
   * Also adds events to the map that fire on update, create, delete boundary.
   */
  const initializeMap = () => {
    if (!map && solarFarm) {
      const defaultCenter = [solarFarm.location.lng, solarFarm.location.lat];
      const mapboxMap = new mapboxgl.Map({
        container: "flightBoundaryMap",
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: defaultCenter,
        zoom: 17
      });

      let draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });
      setMap(mapboxMap);
      setMapboxDraw(draw);
      
      // These have to be defined here to prevent issues with scope
      const onCreateBoundingBox = drawEvent => {
        createBoundingBox(draw, drawEvent);
      };

      const onUpdateBoundingBox = drawEvent => {
        const box = drawEvent.features[0].geometry;
        setBoundingBox(box);
      }

      const onClearBoundingBox = () => {
        setBoundingBox(null);
      }

      mapboxMap.addControl(draw);
      mapboxMap.on('draw.create', onCreateBoundingBox);
      mapboxMap.on('draw.update', onUpdateBoundingBox);
      mapboxMap.on('draw.delete', onClearBoundingBox);
      mapboxMap.on('load', () => mapboxMap.resize());
      
    }
  }
  return (
    <div id="flightBoundaryMap"/>
  )
}

export default FlightBoundaryMap;