import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";


class OrdersApiClient {
  constructor() {
    this.api = new RestApiV2();
  }

  /**
   * Gets all services
   * @returns {Promise}
   */
  getAllServices = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get('/services')
        .then(res => {
          resolve(res.services);
        })
        .catch(err => {
          reject(err);
          console.log(err)
        });
    });
  };

  getFilesForInspection = inspectionUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`raptor_admin/solar_inspections/${inspectionUuid}/files`)
        .then(res => {
          resolve(res.files);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Launches the Data Quality Score task. Results are delivered via email
   * @param {*} orderUuid - order to run data quality on
   */
  launchDataQualityScore = (orderUuid, filters) => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`orders/${orderUuid}/data_quality_report`, filters)
        .then(res => {
          resolve();
          toastr.success('You will receive a Data Quality email shortly then refresh this page.');
        })
        .catch(err => {
          reject();
        });
    });
  };

  /**
   * Gets latest contact for a given solar farm 
   * api_get_latest_contact
   * @param {string} solarFarmUuid - the id of the solar farm to get the contact form
   */
  getLatestContact = solarFarmUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/orders/${solarFarmUuid}/latest_contact`)
        .then(res => {
          resolve(res.contact);
        })
        .catch(err => {
          toastr.error('Failed to get contact for solar farm');
          reject()
        });
    })
  };
  /**
   * Gets all upload sessions associated with an order.
   * @param {*} orderUuid - order to fetch upload sessions for
   */
  getOrderUploadSessions = orderUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/orders/${orderUuid}/upload_sessions`)
        .then(res => {
          resolve(res.upload_sessions);
        })
        .catch(err => {
          toastr.error('Failed to get contact for solar farm');
          reject()
        });
    })
  }

  /**
   * Adds an upload request to a specified order.
   * The upload request is mapped to a dispatch org based on
   * the org submitting the order.
   * @param {*} order - order to add a new upload request to
   */
  createOrderFormUploadRequest = order => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`orders/${order.uuid}/upload_requests`, order)
        .then(res => {
          resolve(res.order);
        })
        .catch(err => {
          reject();
        });
    });
  };

  /**
   * Creates a cad order
   * @param order {Order} - new order to create
   * @return {function(*=, *=): Promise}
   */
  createCADOrder = order => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`orders/cad-order`, order)
        .then(res => {
          resolve(res.order);
        })
        .catch(err => {
          reject(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Creates a site digitization order
   * @param order {Order} - new order to create
   * @return {function(*=, *=): Promise}
   */
  createDigitizationOrder = order => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`orders/site-digitization-order`, order)
        .then(res => {
          resolve(res.order);
        })
        .catch(err => {
          reject(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Updates the rating on a data quality report
   * @param {*} orderUuid - order associated with data quality report
   * @param {*} dataQualityReportId - id of report to update rating
   * @param {*} updatedRating - new rating value
   */
  updateDataQualityRating = (orderUuid, dataQualityReportId, updatedRating) => {
    const params = {rating: updatedRating}
    return new Promise((resolve, reject) => {
      this.api
        .post(`orders/${orderUuid}/data_quality_reports/${dataQualityReportId}/rating`, params)
        .then(res => {
          resolve();
          toastr.success('Rating saved')
        })
        .catch(err => {
          reject();
        });
    });
  }
  /**
   * Gets order summaries for a specific status at an offset and limit
   * @param {*} status - order status to fetch
   * @param {*} limit - number of orders returned
   * @param {*} offset - number of orders to shift return value list from complete list
   */
  getPartialOrderSummaries = (status, limit, offset) => {
    return new Promise((resolve, reject) => {
      this.api
        .get('load_more_order_summaries', {status, limit, offset})
        .then(res => {
          resolve(res.orders);
        })
        .catch(err => {
          toastr.error('Failed to get orders');
          reject()
        });
    });
  };
  /**
   * Gets order summaries for a specific status
   * @param {*} statuses - order statuses to fetch
   */
  getOrderSummariesForStatuses = statuses => {
    const params = {
      statuses
    };
    return new Promise((resolve, reject) => {
      this.api
        .get('order_summaries', params)
        .then(res => {
          resolve(res.orders);
        })
        .catch(err => {
          toastr.error('Failed to get orders');
          reject()
        });
    })
  }
  /**
   * Gets order summaries based on a field/value pair search
   * @param {*} field - field to search on
   * @param {*} value - value of field to search to
   */
  getFilteredOrderSummaries = (field, value) => {
    const params = {
      field, value
    }
    return new Promise((resolve, reject) => {
      this.api
          .get('search_order_summaries', params)
          .then(res => {
            resolve(res.orders);
          })
          .catch(err => {
            toastr.error('Failed to get orders');
            reject()
          });
      })
    }

  /**
   * Gets Report Providers for Orders
   */
  getReportProviders = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get('report_providers')
        .then(res => {
          resolve(res.orders);
        })
        .catch(err => {
          toastr.error('Failed to get report providers');
          reject()
        });
    });
  };
}

export default OrdersApiClient;