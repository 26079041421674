import React, { useState, useEffect } from 'react';
import '../../static/css/org-settings.css';


import EmptyOrgUsers from './EmptyOrgUsers';
import OrganizationEdit from './OrganizationEdit';

//Bootstrap Typeahead
import { Typeahead } from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';

//Models
import { Org } from '@raptormaps/raptor-models';
import User from '../../models/User';

import { sortUsers } from '../../util/orgUsersUtils'

//API
import RestApiV2 from '../../api/RestApiV2';

declare global {
  interface Window {
    REACT_APP_PLANTLABELER_URL: string;
  }
}

const OrgUsersViewer = () => {
  const [orgs, setOrgs] = useState([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Org | undefined>();
  const [loading, setLoading] = useState(false);

  /**
   * Gets all users for the given org id
   * api - api_raptor_admin_get_org_users
  */
  const getOrgUsers = async () => {
    if (!selectedOrg) return;
    setLoading(true);

    const api = new RestApiV2();

    await api
      .get(`orgs/${selectedOrg.id}/users`, { org_id: selectedOrg.id })
      .then(res => {
        if (res['exit_status'] === 0) {
          const newUserList = res['org_users']
            .map((u: object) => User.initFromDBUser(u))

          setUsers(newUserList)
        }
      })
      .catch(err => {
        console.log(err);
      });

    setLoading(false);
  };


  useEffect(() => {
    setUsers([])
    getOrgUsers();
  }, [selectedOrg]);


  /**
   * Gets all orgs on mount
   * api - api_v2_raptor_admin_get_all_orgs
   */
  useEffect(() => {
    const api = new RestApiV2();
    api
      .get('raptor_admin/orgs', { populate: 0 })
      .then(res => {
        if (res['exit_status'] === 0) {
          setOrgs(res['orgs'].map((o: object) => Org.initFromDBOrg(o)));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const replaceUser = (user: User) => {
    const newUsersList = [...users]
    const userIdx = users.findIndex((u) => u.id === user.id);
    newUsersList[userIdx] = user;
    setUsers(newUsersList);
  }

  const editComponent = <EmptyOrgUsers />;
  if (loading) {
    return (
      <div className='empty-org-users'>
        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
      </div>
    )
  } else if (selectedOrg) {
    return (
      <OrganizationEdit
        key='org-edit-component'
        org={selectedOrg}
        users={users}
        getOrgUsers={getOrgUsers}
        replaceUser={replaceUser}
      />
    )
  };

  return (
    <div className="org-users-container">
      <div className="header">
        <Typeahead
          id="org-typeahead"
          className='org-typeahead'
          options={Object.values(orgs)}
          placeholder="Type to Search Orgs..."
          labelKey={(org: Org) => `${org.name}`}
          onChange={selected => {
            if (selected[0]) setLoading(true);
            setSelectedOrg(selected[0])
          }}
        // defaultInputValue={'RaptorX'}
        />
      </div>

      {editComponent}
    </div>
  );
};

export default OrgUsersViewer;
