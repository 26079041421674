import React, { useState } from "react";

import { Container, Row, Col, Dropdown, Spinner } from "react-bootstrap";
import moment from "moment";
import {
  getDateStringFromTsecs,
  getEventStatusView,
  getTsecsFromDate,
  reasonForChange,
  EventType,
  EventStatusNumber,
} from "../../../util";

import { Button } from "@raptormaps/button";
import DatePicker from "../../../../shared/OrderDatePicker";

import { useDispatch } from "react-redux";
import { OrderActionCreator } from "../../../../../actions/orderActions";

// Styled components
import { EventTitle, EventSubTitle, ButtonContainer } from "./styles";

export const Orderevent = ({
  event,
  orderId,
  disabledDates,
  createFlightWindow,
  createFlightDate,
  uuid,
  solarFarmName,
}) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(
    event?.start_date_tsecs
      ? getDateStringFromTsecs(event?.start_date_tsecs)
      : null
  );
  const [endDate, setEndDate] = useState(
    event?.end_date_tsecs ? getDateStringFromTsecs(event?.end_date_tsecs) : null
  );
  const [reason, setReason] = useState(
    event.description ? event.description : null
  );
  const [editEnabled, setEditEnabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleEventTitle = (eventId) => {
    return eventId === EventType.flightWindow ? "Flight Window" : "Flight Date";
  };

  const handleDisableAction = () => {
    if (startDate === null || endDate === null) {
      return true;
    }
    if (editEnabled && reason === null) {
      return true;
    }
    return false;
  };

  const plusDays = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);
    return moment(newDate).format("MM/DD/YYYY");
  };

  const handleDates = (date) => {
    let saveEndDate = date;
    if (event.event_type_id === EventType.flightWindow) {
      // Only for Flight Window
      let newStartDate = new Date(startDate);
      let newEndDate = date;
      let differenceTime = Math.abs(newEndDate - newStartDate);
      let differenceDays = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
      if (differenceDays < 7) {
        saveEndDate = new Date(plusDays(startDate));
      }
    }
    return saveEndDate;
  };

  const handleCreateEvent = () => {
    const createEvent = { ...event };

    if (startDate !== null) {
      createEvent.start_date_tsecs = getTsecsFromDate(startDate);
    }

    if (endDate !== null) {
      createEvent.end_date_tsecs = getTsecsFromDate(endDate);
    }

    createEvent.uuid = uuid;
    createEvent.title = `${handleEventTitle(
      event.event_type_id
    )} - ${solarFarmName} - ${orderId}`;

    setLoading(true);
    dispatch(OrderActionCreator.createEventById(orderId, createEvent)).then(
      () => setLoading(false)
    );
  };

  const handleUpdateEvent = () => {
    const updatedEvent = { ...event };

    if (startDate !== null) {
      updatedEvent.start_date_tsecs = getTsecsFromDate(startDate);
    }

    if (endDate !== null) {
      updatedEvent.end_date_tsecs = getTsecsFromDate(endDate);
    }

    if (reason !== updatedEvent.description) {
      updatedEvent.description = reason;
    }

    updatedEvent.event_status_id = EventStatusNumber.pending;

    setLoading(true);
    dispatch(OrderActionCreator.updateEventById(orderId, updatedEvent)).then(
      () => setLoading(false)
    );
  };

  const handleApproveEvent = () => {
    const updatedEvent = { ...event };

    if (startDate !== null) {
      updatedEvent.start_date_tsecs = getTsecsFromDate(startDate);
    }

    if (endDate !== null) {
      updatedEvent.end_date_tsecs = getTsecsFromDate(endDate);
    }

    updatedEvent.event_status_id = 2;
    setLoading(true);
    dispatch(OrderActionCreator.updateEventById(orderId, updatedEvent)).then(
      () => setLoading(false)
    );
  };

  return (
    <Container>
      <div>
        <Row>
          <EventTitle>{handleEventTitle(event.event_type_id)}</EventTitle>
        </Row>
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Saving...
          </>
        ) : (
          <>
            <Row>
              <Col xs={10}>
                <Row>
                  <Col>
                    <EventSubTitle>Start Date:</EventSubTitle>
                    <DatePicker
                      value={startDate}
                      onUpdate={(newDate) => {
                        if (newDate !== "Invalid date") {
                          setStartDate(newDate);
                          event.event_type_id === EventType.flightWindow &&
                            setEndDate(plusDays(newDate));
                        }
                      }}
                      includeDateIntervals={
                        event.event_type_id === EventType.flightDate &&
                        disabledDates
                      }
                      disabled={
                        createFlightWindow || createFlightDate
                          ? false
                          : !editEnabled
                      }
                    />
                  </Col>
                  <Col>
                    <EventSubTitle>End Date:</EventSubTitle>
                    <DatePicker
                      value={endDate}
                      onUpdate={(newDate) => {
                        if (newDate !== "Invalid date") {
                          setEndDate(newDate);
                        }
                      }}
                      includeDateIntervals={
                        event.event_type_id === EventType.flightDate &&
                        disabledDates
                      }
                      disabled={
                        createFlightWindow || createFlightDate
                          ? false
                          : !editEnabled
                      }
                      handleDates={handleDates}
                      isFlightWindow={
                        event.event_type_id === EventType.flightWindow
                      }
                    />
                  </Col>
                  <Col>
                    <EventSubTitle>Status:</EventSubTitle>
                    {!createFlightWindow && !createFlightDate && (
                      <span>
                        {getEventStatusView(event.event_status_id - 1)}
                      </span>
                    )}
                  </Col>
                  <Col>
                    <EventSubTitle>Reason for change:</EventSubTitle>
                    {editEnabled ? (
                      <Dropdown
                        id="event_changes_options"
                        onSelect={(i) => setReason(reasonForChange[i])}
                      >
                        <Dropdown.Toggle>{reason}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {reasonForChange.map((item, index) => (
                            <Dropdown.Item
                              active={reason === event?.description}
                              key={index}
                              eventKey={index}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <span>{event?.description}</span>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <ButtonContainer>
                  {createFlightWindow || createFlightDate ? (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => handleCreateEvent()}
                      disabled={handleDisableAction()}
                    >
                      Submit
                    </Button>
                  ) : (
                    <>
                      {!editEnabled && (
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() => setEditEnabled(true)}
                        >
                          Update Event
                        </Button>
                      )}
                      {editEnabled && (
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() => {
                            handleUpdateEvent();
                            setEditEnabled(false);
                          }}
                          disabled={handleDisableAction()}
                        >
                          Update
                        </Button>
                      )}
                      {getEventStatusView(event.event_status_id - 1) ===
                        "Pending" &&
                        !editEnabled && (
                          <Button
                            variant="primary"
                            size="small"
                            onClick={() => handleApproveEvent()}
                          >
                            Approve
                          </Button>
                        )}
                    </>
                  )}
                </ButtonContainer>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Container>
  );
};
