import React, { useEffect, useState } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { ORG_TYPES } from '../../util';
import OrderValueSetDropdown from './formComponents/OrderValueSetDropdown';
import SalesOnboardingDatePicker from './formComponents/SalesOnboardingDatePicker';
import SalesOnboardingTypeahead from './formComponents/SalesOnboardingTypeahead';

import { Contact, SolarFarm } from '@raptormaps/raptor-models';
import RMLocation from '@raptormaps/raptor-models/lib/utils/RMLocation';
import ReactLoading from 'react-loading';
import toastr from 'toastr';
import RestApiV2 from '../../api/RestApiV2';
import ContactPicker from './ContactPicker';
import NewContactForm from './NewContactForm';

// styled components
import {
  TotalRevenueContainer,
  TotalRevenueItem,
  TotalRevenueTitle,
  TotalRevenueValue
} from '../ProjectViewer/styles/ProjectViewer_styles';

/**
 * Table component holding all order objects
 *
 * @param orderList
 * @param handleSelectRow
 * @param handleSelectAllRows
 * @param selectedRows
 * @param updateRowValue
 * @param orgs
 * @param onFarmSearch
 * @param farmsList
 * @returns {*}
 * @constructor
 */
const FarmOrdersTable = ({
  orderList,
  handleSelectRow,
  handleSelectAllRows,
  selectedRows,
  updateRowValue,
  orgs,
  clientOrgId
}) => {
  const [showingNewContact, setShowingNewContact] = useState(false);
  const [newContactRow, setNewContactRow] = useState({ rowIndex: 0, row: null });
  const [addingNewContact, setAddingNewContact] = useState(false);
  const [formRef, setFormRef] = useState();
  const [loadingContacs, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [currentEditingValue, setCurrentEditingValue] = useState('');
  const [currentEditingRow, setCurrentEditingRow] = useState(-1);

  /**
   * Refresh the contacts list whenever the order list changes
   */
  useEffect(() => {
    setLoadingContacts(true);
    refreshContacts()
      .then(res => {
        setContacts(res);
        setLoadingContacts(false);
      })
      .catch(err => {
        setLoadingContacts(false);
      });
  }, [clientOrgId]);

  /**
   * Method to refresh contacts connected to org
   * returns Promise for api call to refresh contacts
   */
  const refreshContacts = () => {
    const api = new RestApiV2();
    return new Promise((resolve, reject) => {
      api
        .get(`raptor_admin/orgs/${clientOrgId}/contacts`)
        .then(res => {
          resolve(res.contacts.map(c => new Contact().deserialize(c)));
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  /**
   * Gets the error for a single columns...needed because
   * the table won't refresh unless this is passed into
   * formatExtraData and called
   *
   * @param rowIndex
   * @param fieldName
   * @returns {*}
   */
  const getOrderListError = (rowIndex, fieldName) => {
    if (orderList[rowIndex] && orderList[rowIndex].errors) {
      return orderList[rowIndex].errors[fieldName];
    }
  };

  const handleAnyUpdate = (updateFunction, rowIndex, row, updatedValue, valueName) => {
    setCurrentEditingRow(rowIndex);
    setCurrentEditingValue(valueName);
    updateFunction(rowIndex, row, updatedValue);
  };

  /**
   * Updates the name size on a newly created farm
   * @param rowIndex {number} - row index in table
   * @param row {obj} -
   * @param farmName {string} -
   */
  const handleUpdateFarmName = (rowIndex, row, farmName) => {
    let { solar_farm } = row;
    if (solar_farm) {
      solar_farm.name = farmName;
      updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
    }
  };

  /**
   * Updates the farm size on a newly created farm
   * @param rowIndex {number} - row index in table
   * @param row {obj} -
   * @param farmSize {number} -
   */
  const handleUpdateFarmSize = (rowIndex, row, farmSize) => {
    let { solar_farm } = row;
    if (solar_farm) {
      solar_farm.size = farmSize;
      updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
    }
  };

  const handleUpdateFarmLat = (rowIndex, row, lat) => {
    const { solar_farm } = row;
    solar_farm.latitudeEditing = lat;
    if (solar_farm && isFloat(lat)) {
      solar_farm.location = new RMLocation(lat, solar_farm.longitudeEditing);
    }
    updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
  };

  const handleUpdateFarmLong = (rowIndex, row, lng) => {
    const { solar_farm } = row;
    solar_farm.longitudeEditing = lng;
    if (solar_farm && isFloat(lng)) {
      solar_farm.location = new RMLocation(solar_farm.latitudeEditing, lng);
    }
    updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
  };

  const isFloat = number => {
    if (
      isNaN(parseFloat(number)) ||
      (number.includes('.') && number.substring(number.indexOf('.') + 1) === '')
    )
      return false;
    return true;
  };

  const handleUpdateFarmAddress = (rowIndex, row, address) => {
    const { solar_farm } = row;
    if (solar_farm) {
      solar_farm.address = address;
      updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
    }
  };

  const handleUpdateFarmPPA = (rowIndex, row, ppa) => {
    const { solar_farm } = row;
    if (solar_farm) {
      solar_farm.ppa_rate = ppa;
      updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
    }
  };

  const handleUpdateFarmAccessCode = (rowIndex, row, accessCode) => {
    const { solar_farm } = row;
    if (solar_farm) {
      solar_farm.access_code = accessCode;
      updateRowValue(rowIndex, row, 'solar_farm', solar_farm);
    }
  };

  const showNewContact = (rowIndex, row) => {
    setShowingNewContact(true);
    setNewContactRow({ rowIndex: rowIndex, row: row });
  };

  const handleSubmitNewContact = contactValues => {
    if (!clientOrgId) {
      setShowingNewContact(false);
      return;
    }
    setAddingNewContact(true);
    const api = new RestApiV2();
    api
      .post(`raptor_admin/orgs/${clientOrgId}/contacts`, { contact: contactValues })
      .then(updateRes => {
        refreshContacts().then(res => {
          setShowingNewContact(false);
          setAddingNewContact(false);
          setContacts(res);
          updateRowValue(
            newContactRow.rowIndex,
            newContactRow.row,
            'contact',
            updateRes.new_contact_uuid
          );
        });
      })
      .catch(err => {
        setShowingNewContact(false);
        setAddingNewContact(false);
        toastr.error(err);
        console.log(err);
      });
  };

  const Warn = props => (
    <span {...props} style={{ color: 'red', ...props.style }}>
      {props.children}
    </span>
  );
  /**
   * Define table columns
   * @param farmsList {[SolarFarm]} - list of solar farm results
   * @returns {*[]}
   */

  const columns = contactList => [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Site Name',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-name"
            value={row.solar_farm && row.solar_farm.name + ''}
            disabled={row.existingFarm}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'name'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmName, rowIndex, row, e.target.value, 'name')
            }
            onBlur={e =>
              handleAnyUpdate(handleUpdateFarmName, rowIndex, row, e.target.value, 'name')
            }
          />
          {params.getOrderListError(rowIndex, 'name') && (
            <Warn>{params.getOrderListError(rowIndex, 'name')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'size',
      text: 'Site Size (MW)',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-size"
            value={row.solar_farm && row.solar_farm.size}
            disabled={row.existingFarm}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'size'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmSize, rowIndex, row, e.target.value, 'size')
            }
            onBlur={e =>
              handleAnyUpdate(handleUpdateFarmSize, rowIndex, row, e.target.value, 'size')
            }
          />
          {params.getOrderListError(rowIndex, 'size') && (
            <Warn>{params.getOrderListError(rowIndex, 'size')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'lat',
      text: 'Site Latitude',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-latitude"
            value={row.solar_farm.latitudeEditing ?? row.solar_farm.location.lat}
            disabled={row.existingFarm}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'lat'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmLat, rowIndex, row, e.target.value, 'lat')
            }
            onBlur={e => handleAnyUpdate(handleUpdateFarmLat, rowIndex, row, e.target.value, 'lat')}
          />
          {params.getOrderListError(rowIndex, 'lat') && (
            <Warn>{params.getOrderListError(rowIndex, 'lat')}</Warn>
          )}
          {params.getOrderListError(rowIndex, 'latitudeEditing') && (
            <Warn>{params.getOrderListError(rowIndex, 'latitudeEditing')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'lng',
      text: 'Site Longitude',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-longitude"
            value={row.solar_farm.longitudeEditing ?? row.solar_farm.location.lng}
            disabled={row.existingFarm}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'lng'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmLong, rowIndex, row, e.target.value, 'lng')
            }
            onBlur={e =>
              handleAnyUpdate(handleUpdateFarmLong, rowIndex, row, e.target.value, 'lng')
            }
          />
          {params.getOrderListError(rowIndex, 'lng') && (
            <Warn>{params.getOrderListError(rowIndex, 'lng')}</Warn>
          )}
          {params.getOrderListError(rowIndex, 'longitudeEditing') && (
            <Warn>{params.getOrderListError(rowIndex, 'longitudeEditing')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'address',
      text: 'Site Address',
      formatter: (cell, row, rowIndex, params) => {
        return (
          <>
            {/* If there is an existing farm, disable it. Or */}
            <Form.Control
              className="solar-farm-address"
              value={row.solar_farm && row.solar_farm.address}
              disabled={row.existingFarm}
              autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'address'}
              onChange={e =>
                handleAnyUpdate(handleUpdateFarmAddress, rowIndex, row, e.target.value, 'address')
              }
              onBlur={e =>
                handleAnyUpdate(handleUpdateFarmAddress, rowIndex, row, e.target.value, 'address')
              }
            />
            {params.getOrderListError(rowIndex, 'address') && (
              <Warn>{params.getOrderListError(rowIndex, 'address')}</Warn>
            )}
          </>
        );
      },
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'ppa_rate',
      text: 'Site PPA Rate ($/kWh)',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-ppa"
            value={row.solar_farm && row.solar_farm.ppa_rate}
            disabled={true}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'ppa'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmPPA, rowIndex, row, e.target.value, 'ppa')
            }
            onBlur={e => handleAnyUpdate(handleUpdateFarmPPA, rowIndex, row, e.target.value, 'ppa')}
          />
          {params.getOrderListError(rowIndex, 'ppa_rate') && (
            <Warn>{params.getOrderListError(rowIndex, 'ppa_rate')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'access_code',
      text: 'Gate Access Code',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="solar-farm-access-code"
            value={row.solar_farm && row.solar_farm.access_code}
            disabled={row.existingFarm}
            autoFocus={currentEditingRow === rowIndex && currentEditingValue === 'code'}
            onChange={e =>
              handleAnyUpdate(handleUpdateFarmAccessCode, rowIndex, row, e.target.value, 'code')
            }
            onBlur={e =>
              handleAnyUpdate(handleUpdateFarmAccessCode, rowIndex, row, e.target.value, 'code')
            }
          />
          {params.getOrderListError(rowIndex, 'access_code') && (
            <Warn>{params.getOrderListError(rowIndex, 'access_code')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'contact',
      text: 'Site Contact',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <ContactPicker
            onSelectContact={contact => updateRowValue(rowIndex, row, 'contact', contact)}
            selectedContactUuid={row.contact}
            contacts={contactList}
            toggleNewContact={() => showNewContact(rowIndex, row)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'contact') && (
            <Warn>{params.getOrderListError(rowIndex, 'contact')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'is_turnkey',
      text: 'Turnkey Services',
      formatter: (cell, row, rowIndex) => (
        <>
          <Form.Check
            type="checkbox"
            className="turnkey-serv-checkbox"
            checked={row.is_turnkey}
            onChange={() => updateRowValue(rowIndex, row, 'is_turnkey', !row.is_turnkey)}
            disabled={row.uuid}
          />
        </>
      ),
      headerStyle: { width: 135 }
    },
    {
      dataField: 'data_collection_org_id',
      text: 'Data Collection Org',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <SalesOnboardingTypeahead
            className="data-collection-selector"
            id={`data-collection-org-id-input-table-${rowIndex}`}
            selectedId={row.data_collection_org_id}
            options={orgs.filter(o => o.type === ORG_TYPES.DATA_COLLECTOR)}
            onChange={orgId => updateRowValue(rowIndex, row, 'data_collection_org_id', orgId)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'data_collection_org_id') && (
            <Warn>{params.getOrderListError(rowIndex, 'data_collection_org_id')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'contract_type',
      text: 'Type of Project',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <OrderValueSetDropdown
            className="contract-type-dropdown"
            type="contract_type"
            selectedValue={row.contract_type}
            onSelect={val => updateRowValue(rowIndex, row, 'contract_type', val)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'contract_type') && (
            <Warn>{params.getOrderListError(rowIndex, 'contract_type')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'order_type',
      text: 'Type of Order',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <OrderValueSetDropdown
            className="order-type-dropdown"
            type="order_type"
            selectedValue={row.order_type}
            onSelect={val => updateRowValue(rowIndex, row, 'order_type', val)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'order_type') && (
            <Warn>{params.getOrderListError(rowIndex, 'order_type')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'requested_flight_date',
      text: 'Requested Flight Date',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <SalesOnboardingDatePicker
            className="flight-date-picker"
            value={row.requested_flight_date}
            onUpdate={val => updateRowValue(rowIndex, row, 'requested_flight_date', val)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'requested_flight_date') && (
            <Warn style={{ display: 'inline-block' }}>
              {params.getOrderListError(rowIndex, 'requested_flight_date')}
            </Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      dataField: 'requested_delivery_date',
      text: 'Requested Delivery Date',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <SalesOnboardingDatePicker
            className="delivery-date-picker"
            value={row.requested_delivery_date}
            onUpdate={val => updateRowValue(rowIndex, row, 'requested_delivery_date', val)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'requested_delivery_date') && (
            <Warn style={{ display: 'inline-block' }}>
              {params.getOrderListError(rowIndex, 'requested_delivery_date')}
            </Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      // This naming has to differ from the property, otherwise the input unfocuses after a single keystroke
      // Not sure why this causes that
      dataField: 'analytics_rev',
      text: 'Analytics Revenue ($)',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="analytics-rev"
            type="number"
            value={row.analytics_revenue}
            onChange={e => updateRowValue(rowIndex, row, 'analytics_revenue', e.target.value)}
            onBlur={e => updateRowValue(rowIndex, row, 'analytics_revenue', e.target.value)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'analytics_revenue') && (
            <Warn>{params.getOrderListError(rowIndex, 'analytics_revenue')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    },
    {
      // This naming has to differ from the property, otherwise the input unfocuses after a single keystroke
      // Not sure why this causes that
      dataField: 'flight_rev',
      text: 'Flight Revenue ($)',
      formatter: (cell, row, rowIndex, params) => (
        <>
          <Form.Control
            className="flight-rev"
            type="number"
            value={row.flight_revenue}
            onChange={e => updateRowValue(rowIndex, row, 'flight_revenue', e.target.value)}
            onBlur={e => updateRowValue(rowIndex, row, 'flight_revenue', e.target.value)}
            disabled={row.uuid}
          />
          {params.getOrderListError(rowIndex, 'flight_revenue') && (
            <Warn>{params.getOrderListError(rowIndex, 'flight_revenue')}</Warn>
          )}
        </>
      ),
      formatExtraData: { getOrderListError },
      headerStyle: { width: 135 }
    }
  ];

  const showTable = () => {
    if (loadingContacs)
      return (
        <ReactLoading
          style={{ marginLeft: 'calc(50% - 30px)' }}
          type="spin"
          className="order-loading-spinner"
        />
      );
    return (
      <BootstrapTable
        responsive="lg"
        keyField="id"
        columns={columns(contacts)}
        selectRow={{
          mode: 'checkbox',
          selected: selectedRows,
          onSelect: handleSelectRow,
          onSelectAll: handleSelectAllRows,
          clickToSelect: false,
          clickToEdit: true,
          headerColumnStyle: { width: 40 }
        }}
        data={orderList}
      />
    );
  };

  /**
 * @param col - Analytics or Flight
 * @returns - Total Analytics or Flight revenue
 */
  const handleSumTotalValue = col => {
    let values = [];

    orderList.map(item => item[col] && values.push(Number(item[col])));
    const reducer = (accumulator, curr) => accumulator + curr;

    let result = values.length > 0 ? values.reduce(reducer) : 0;
    return result.toFixed(2);
  };

  return (
    <>
      <Modal show={showingNewContact} onHide={() => setShowingNewContact(addingNewContact)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addingNewContact && (
            <ReactLoading
              style={{ marginLeft: 'calc(50% - 30px)' }}
              type="spin"
              className="order-loading-spinner"
            />
          )}
          {!addingNewContact && (
            <NewContactForm setFormRef={ref => setFormRef(ref)} onSubmit={handleSubmitNewContact} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={addingNewContact}
            variant="secondary"
            onClick={() => setShowingNewContact(addingNewContact)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => formRef.current.handleSubmit()}
            disabled={addingNewContact}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ overflow: 'scroll', width: 'calc(100vw - 100px)' }}>
        {showTable()}
        <TotalRevenueContainer width={16 * 135 + 40}>
          <TotalRevenueItem>
            <TotalRevenueTitle>Analytics Total</TotalRevenueTitle>
            <TotalRevenueValue>{handleSumTotalValue('analytics_revenue')}</TotalRevenueValue>
          </TotalRevenueItem>
          <TotalRevenueItem>
            <TotalRevenueTitle>Flight Total</TotalRevenueTitle>
            <TotalRevenueValue>{handleSumTotalValue('flight_revenue')}</TotalRevenueValue>
          </TotalRevenueItem>
        </TotalRevenueContainer>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  orgs: state.session.orgs
});

export default connect(mapStateToProps, {})(FarmOrdersTable);
