import React, {useEffect} from 'react';
import moment from 'moment';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

/**
 * Custom component for rendering date strings on the Order table
 */
const RmDatePicker = ({ onUpdate, value, handleDates, isFlightWindow, ...rest }) => {
  const [date, setDate] = useState(value ? new Date(value) : null);
  const getValue = (d) => moment(d).format('MM/DD/YYYY');

  useEffect(() => {
    if(value){
      setDate(new Date(value));
      onUpdate(getValue(value));
    }
  }, [value]);
  
  const handleChange = (dateChange) => {
    let value = dateChange;
    if(isFlightWindow){
      value = handleDates ? handleDates(dateChange) : value;
      setDate(value);
    }else{
      setDate(value);
    }
    onUpdate(getValue(value));
  };

  return (
    <DatePicker
      selected={date}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default RmDatePicker;