import { RaptorObjectInterface, Org } from '@raptormaps/raptor-models';

class User implements RaptorObjectInterface {
  active: boolean;
  paid: boolean;
  first_time: boolean;
  id: number;
  latest_org_id: number;
  uuid: string;
  name: string;
  email: string;
  role: string;
  roles: [string];
  token: string;
  units: string;
  twilio_sid: string | null;
  orgs: Array<Org>;
  permittance: { [key: string]: boolean };

  constructor(public _id: number, public _uuid: string,
    public _name: string, public _email: string,
    public _active: boolean, public _paid: boolean,
    public _first_time: boolean, public _latest_org_id: number,
    public _role: string, public _token: string,
    public _units: string, public _twilio_sid: string,
    public _roles: [string],
    public _orgs: Array<Org> = [],
    public _permittance: { [key: string]: boolean } = {}) {
    this.id = _id;
    this.uuid = _uuid;
    this.name = _name;
    this.email = _email;
    this.orgs = _orgs;
    this.active = _active;
    this.paid = _paid;
    this.first_time = _first_time;
    this.latest_org_id = _latest_org_id;
    this.role = _role;
    this.token = _token;
    this.units = _units;
    this.twilio_sid = _twilio_sid;
    this.roles = _roles;
    this.permittance = _permittance;
  }

  public static initFromDBUser(input: any): User {
    // const orgs: Array<Org> = input?.orgs?.map((o: object) => Org.initFromDBOrg(o));
    return new User(input.id, input.uuid, input.name, input.email, input.active,
      input.paid, input.first_time, input.latest_org_id, input.role,
      input.token, input.units, input.twilio_sid, input.roles, input.orgs, input.permittance);
  }
}

export default User
