import { Link, withRouter } from 'react-router-dom';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { useAuth0 } from "@auth0/auth0-react";
import { CustomToggle } from '@raptormaps/raptor-lib';
import { RAPTOR_MAPS_DOCS_URL } from '../constants';
import '../static/css/navigationbar.css';
import logo from "../static/img/logo_sm.png";
import { newCustomerRedirect } from '../util';

/**
 * @const Navigation - renders the nav bar for the app. Only shows options if
 * the user is logged in. Full credentials allow access to image sets,
 * site builder, report builder, and org navigation.
 * @param org_id {number} - id of current user org
 * to org edit page
 * @param user {Object} - current logged in user
 * @return {*}
 */
const Navigation = ({ user }) => {
  const { logout } = useAuth0();

  return (
    <Navbar className="navbar-bottom" bg="light" fixed="top">
      <Container>
        {/* Raptor Maps company logo */}
        <Navbar.Brand className="navbar-top_brand">
          {/* need refactor - not working weith Auth0 */}
          <Link to="/">
            <img
              alt="Raptor Maps Inc."
              src={logo}
              style={{ verticalAlign: 'unset' }}
              height="100%"
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="navbar-bottom_navbar">
          {/* Nav containing tab links and user dropdown */}
          <Nav className="ml-auto">
            <Nav.Link style={{ marginRight: '10px' }} onClick={() => newCustomerRedirect('/')}>
              <FormattedMessage id="app.nav.App" defaultMessage="App" description="App" />
            </Nav.Link>

            {/* Help icon */}
            <a className="nav-help" href={RAPTOR_MAPS_DOCS_URL} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faQuestionCircle} color="grey" className="nav-help-icon" />
            </a>

            {/* Org and user dropdown menu */}
            <Dropdown style={{ padding: '0 5px 0 15px' }}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <FontAwesomeIcon icon={faUserCircle} color="grey" className="nav-user-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight>
                {/* User profile section with my profile and logout */}
                <Dropdown.Header>{user.email}</Dropdown.Header>
                <Dropdown.Item
                  className="user-menu-item"
                  eventKey={4.1}
                  onClick={() => newCustomerRedirect('/account')}
                >
                  <FormattedMessage
                    id="app.nav.my_profile"
                    defaultMessage="My Profile"
                    description="Navigate to profile settings"
                  />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })} className="user-menu-item" eventKey={4.2}>
                  <FormattedMessage
                    id="app.nav.logout"
                    defaultMessage="Logout"
                    description="Log out of the application"
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withRouter(Navigation);
