import toastr from 'toastr';
import _ from 'lodash';
import RestApiV2 from "../api/RestApiV2";

/**
 * Client API for Feature routes.
 */
class FeatureApiClient {
  constructor() {
    this.api = new RestApiV2();
  }

  /**
   * Fetches all org features.
   * Supports the following optional queryParams
   * @function getFeatures - api_get_all_features_verbose
   * @return {function(*=): Promise}
   */
  getOrgFeatures = (params = {}) => {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/feature/all', params)
        .then(res => {
          if(res.org_features !== undefined) {
            resolve(res.org_features);
          } else {
            resolve(res.farm_features);
          }
        })
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Gets all the created features in the features table
   * @return {function(*=): Promise}
   */
  getCreatedFeatures = () => {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/feature/created_features')
        .then(res => {
          resolve(res.features);
        })
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Enables a feature for an org.
   * @param {number} orgId ID of org to enable feature
   * @param {number} featureId ID of feature to enable
   */
  enableOrgFeature = (orgId, featureId) => {
    return new Promise((resolve, reject) => {
      return this.api
        .post(`/feature/${featureId}/${orgId}/enable_feature`)
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Disables a feature for an org.
   * @param {number} orgId ID of org to disable feature
   * @param {number} featureId ID of feature to disable
   */
  disableOrgFeature = (orgId, featureId) => {
    return new Promise((resolve, reject) => {
      return this.api
        .post(`/feature/${featureId}/${orgId}/disable_feature`)
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Enables a feature for an farm.
   * @param {number} solarFarmId ID of farm to enable feature
   * @param {number} featureId ID of feature to enable
   */
   enableFarmFeature = (solarFarmId, featureId) => {
    return new Promise((resolve, reject) => {
      return this.api
        .post(`/feature/solar_farm/${featureId}/${solarFarmId}/enable_feature`)
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };

  /**
   * Disables a feature for an farm.
   * @param {number} solarFarmId ID of farm to disable feature
   * @param {number} featureId ID of feature to disable
   */
  disableFarmFeature = (solarFarmId, featureId) => {
    return new Promise((resolve, reject) => {
      return this.api
        .delete(`/feature/solar_farm/${featureId}/${solarFarmId}/disable_feature`)
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };  
}

export default FeatureApiClient;
