const SALES_PROJECT_STATUS = {
  REVIEW: 0,
  ONGOING: 1,
  DELIVERED: 2,
  CANCELLED: 3,
  API_CANCELLED_CODE: 98
};

const PROJECT_STATUS_TABS = ['Review', 'Ongoing', 'Delivered', 'Cancelled'];
const SALES_PROJECT_HANDLE_SUBMIT_TYPES = {
  SAVE_CHANGES: 0,
  SUBMIT_TO_ONGOING: 1
};

const SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND = {
  accounting_info_items: [
    {"accounting_item_codekey":"FLIGHT-REVENUE", "amount":null},
    {"accounting_item_codekey":"ANALYTICS-REVENUE","amount":null}
  ],
  data_collection_manager_id: null,
  requested_delivery_date: null,
  requested_flight_date: null,
  solar_farm: {
    address: null,
    latitude: null,
    longitude: null,
    name: 'Error Solar Farm Not Found.',
    solar_inverter_uuids: [],
    solar_module_ids: [],
    size: null,
    access_code: null,
    ppa_rate: null,
    id: null,
    uuid: null
  },
  services: [
    {
      id: 1,
      uuid: 'e6ce2dc7-6fe5-4c85-94ab-82aef02fd8b8',
      name: 'Linked Images',
      description: 'Provides RGB images associated with anomalies marked on thermal images'
    },
    {
      id: 2,
      uuid: '3c881e2a-fa53-46de-941d-7a30752d108a',
      name: 'Custom Numbering',
      description: 'Use client as build numbering to describe anomaly locations'
    },
    {
      id: 3,
      uuid: 'c6547bd7-f6ad-4151-90a7-b916afd1fdbf',
      name: 'Delta Ts',
      description: 'Provide the temperature deltas for marked anomalies'
    },
    {
      id: 4,
      uuid: '3c881e2a-fa53-46de-941d-7a30752d108b',
      name: 'RGB Orthomosaic',
      description: 'Provide an RGB orthomosaic'
    },
    {
      id: 5,
      uuid: '9df9bc62-2f35-4081-ac3d-e5b3d729b6f1',
      name: 'Obliques',
      description: 'Provides an oblique'
    },
    {
      id: 6,
      uuid: '2315fd99-c402-46e1-b1d3-d853325d506d',
      name: 'Expedited',
      description: 'Determines if an order is expedited'
    }
  ]
}

const LATITUDE_EDITING = 'latitudeEditing';
const LONGITUDE_EDITING = 'longitudeEditing';

export { SALES_PROJECT_STATUS, SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND, PROJECT_STATUS_TABS, SALES_PROJECT_HANDLE_SUBMIT_TYPES, LATITUDE_EDITING, LONGITUDE_EDITING }
