import React from 'react';

import { StatusDisplay, OrgDetailsDisplay } from './shared';

import { Button } from 'react-bootstrap';

import { orderValuesets } from '../../../../util/constants';
/**
 * ProcessedDisplaySection - Presentation component for submitted status
 * @param {*} status - status of order
 * @param {[]} nextStatus - array that holds possible next statuses
 * @param {*} clientOrgNames - client org names
 * @param {*} channelOrgNames - array of channel org names
 * @param {*} togglePermissionsModal - opens modal to update order status
 */
const ProcessedDisplaySection = ({
  status,
  nextStatus,
  clientOrgNames,
  channelOrgNames,
  togglePermissionsModal,
  setNextStatus
}) => {
  let displayStatus = orderValuesets.status[status];
  let displayNextStatus = orderValuesets.status[nextStatus];
  return (
    <>
      <div>
        <StatusDisplay label="Current Status" status={displayStatus} />
        <StatusDisplay label="Next Status" status={displayNextStatus} />
        <OrgDetailsDisplay clientOrgNames={clientOrgNames} channelOrgNames={channelOrgNames} />
      </div>

      <div style={{ marginTop: '30px' }}>
        <Button
          onClick={() => {
            setNextStatus(nextStatus);
            togglePermissionsModal();
          }}
        >{`Move to ${displayNextStatus}`}</Button>
      </div>
    </>
  );
};

export default ProcessedDisplaySection;
