import RestApiV2 from "../api/RestApiV2";

class RaptorInternalApiClient {
  constructor() {
    this.api = new RestApiV2(null, `${window.REACT_APP_API_ENDPOINT}/api/v2`);
  }
  /**
   * Gets list of time maps based on name and org id 
   * calls api_tile_map_search()
   * @memberof RaptorInternalApiClient
   */
  searchTileMaps = (name, orgId) => {
    return new Promise((resolve, reject) => {
      const data = {
        request_params: {
          request_type: 'search_by_filename_and_org_id',
          param_1: name,
          param_2: orgId
        }
      };
      this.api
        .post('/tile_maps', data)
        .then(res => resolve(res.tile_maps))
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };
  /**
   * Gets list of upload sessions based on name and org id 
   * calls api_upload_session_search()
   * @memberof RaptorInternalApiClient
   */
  searchUploadSessions = (name, orgId) => {
    return new Promise((resolve, reject) => {
      const data = {
        request_params: {
          request_type: 'search_by_name_and_org_id',
          param_1: name,
          param_2: orgId
        }
      };
      this.api
        .post('/upload_sessions', data)
        .then(res => resolve(res.upload_sessions))
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };
  /**
   * Sends list of upload session ids and org id for updating
   * calls api_update_upload_session_org_ids()
   * @memberof RaptorInternalApiClient
   */
  updateUploadSessionsOrgId = (uploadSessionIds, orgId) => {
    return new Promise((resolve, reject) => {
      const data = {
        upload_session_ids: uploadSessionIds,
        new_org_id: orgId
      };
      this.api
        .post('/upload_sessions/update_org_ids', data)
        .then(res => resolve())
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };

  /**
   * Sends list of tile map ids and org id for updating
   * calls api_update_tile_map_org_ids()
   * @memberof RaptorInternalApiClient
   */
  updateTileMapsOrgId = (tileMapIds, orgId) => {
    return new Promise((resolve, reject) => {
      const data = {
        tile_map_ids: tileMapIds,
        new_org_id: orgId
      };
      this.api
        .post('/tile_maps/update_org_ids', data)
        .then(res => resolve())
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };
}
export default RaptorInternalApiClient;
