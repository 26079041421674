import React, { useState, useEffect } from 'react';

import { Table } from 'react-bootstrap';

/**
 * A dumb, reuseable table that needs a lot of arguments to figure itself out.
 *
 * @param {Array.<Object>} objectArray Array of objects to be displayed in the table
 * @param {Array.<strin>} objectRowValueKeys Array of keys that are accessed on
 *      each object in the objectArray to display the keyed value in the table.
 * @param {Array.<string>} headers Array of headers that is displayed for eachcolumn.
 *
 * @method onClick Event handler that is fired when a user clicks on a row of the table
 * @param {number | string} identifier An identifier passed to the event handler.
 * @listens React.MouseEventHandler<HTMLButtonElement> Click event
 *
 * @param {Map<K,string>} tdStyle Optional styling for the table cell. Useful for forcing neighboring cells to be the same width
 */
const DumbTable = ({ objectArray, objectRowValueKeys, headers, onClick, tdStyle }) => {
  return (
    <Table hover>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {objectArray.map((o, index) => (
          <tr key={index} onClick={() => onClick(o)} value={o}>
            {objectRowValueKeys.map((key, idx) => (
              <td style={tdStyle} key={idx}>
                {o[key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DumbTable;
