import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";

/**
 * Client API for Feature routes.
 */
class SalesProjectApiClient {
  constructor() {
    this.api = new RestApiV2(false, '/');
  }

  getSalesProjectSummaries = (status, page_number, items_per_page) => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${window.REACT_APP_API_ENDPOINT}/sales_project_summaries`, { status, page_number, items_per_page })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }

  getSalesProjectById = (project_id) => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${window.REACT_APP_API_ENDPOINT}/sales_project/${project_id}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }

  submitSalesProject = salesProject => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${window.REACT_APP_API_ENDPOINT}/sales_project/${salesProject.id}/submit`, salesProject)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }

  updateSalesProject = salesProject => {
    return new Promise((resolve, reject) => {
      this.api
        .put(`${window.REACT_APP_API_ENDPOINT}/sales_project/${salesProject.id}`, salesProject)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }
  cancelSalesProject = salesProject => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${window.REACT_APP_API_ENDPOINT}/sales_project/${salesProject.id}/cancel`, salesProject)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }

  getFarmLatLng = address => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${window.REACT_APP_API_ENDPOINT}/solar_farm/lat_lng`, { address })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err)
          toastr.error(err)
          reject(err)
        });
    });
  }
};

export default SalesProjectApiClient;