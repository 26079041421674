import react from 'react';
import toastr from 'toastr';

import { Org } from '@raptormaps/raptor-models';
import RestApiV2 from "../api/RestApiV2";

// APIs

export const useOrgAllowedList = () => {

  /* @ts-ignore comment */
  const API = new RestApiV2(false, '/');

  const getOrgAllowedList = (orgId: number) => {
    const params = {
      org_id: orgId,
    };

    return new Promise((resolve, reject) => {
      
        API.get(`${window.REACT_APP_API_ENDPOINT}/org/allowed_list`, params)
            .then(res => {
            resolve(res);
        })
        .catch(err => {
            toastr.error("Error getting allowed list");
            reject(err);
        });
      });
  };

  const deleteAllowedEmail = (orgId: number, email: string, callback: () => void) => {
    const params = {
      org_id: orgId,
      email
    };

    return new Promise((resolve, reject) => {
        API.delete(`${window.REACT_APP_API_ENDPOINT}/org/allowed_emails`, params)
        .then(res => {
            resolve(res);
            callback();
        })
        .catch(err => {
            toastr.error('Error deleting email');
            reject(err);
        });
      });
  };

  const deleteAllowedDomain = (orgId: number, domain: string, deleteUsers: boolean, callback: () => void) => {
    const params = {
      org_id: orgId,
      domain,
      should_delete_users: deleteUsers
    };

    return new Promise((resolve, reject) => {
        API.delete(`${window.REACT_APP_API_ENDPOINT}/org/allowed_domain`, params)
        .then(res => {
            resolve(res);
            callback();
        })
        .catch(err => {
            toastr.error('Error deleting domain');
            reject(err);
        });
      });
  };

  const addAllowedDomain = (orgId: number, domain: string, callback: () => void) => {
    const params = {
      org_id: orgId,
      domain: domain.toLowerCase()
    };

    return new Promise((resolve, reject) => {
        API.post(`${window.REACT_APP_API_ENDPOINT}/org/allowed_domain`, {}, params)
        .then(res => {
            resolve(res);
            callback();
        })
        .catch(err => {
            toastr.error('Error adding domain');
            reject(err);
        });
      });
  };

  const addAllowedEmail = (orgId: number, email: string, callback: () => void) => {
    const params = {
      org_id: orgId,
      email: email.toLowerCase()
    };

    return new Promise((resolve, reject) => {
        API.post(`${window.REACT_APP_API_ENDPOINT}/org/allowed_emails`, {}, params)
        .then(res => {
            resolve(res);
            callback();
        })
        .catch(err => {
            toastr.error('Error adding email');
            reject(err);
        });
      });
  };

  return {
    getOrgAllowedList,
    deleteAllowedEmail,
    deleteAllowedDomain,
    addAllowedDomain,
    addAllowedEmail
  };
};
