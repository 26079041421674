import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FormInput = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      value,
      isValid,
      isInvalid,
      helpText,
      error,
      onChange,
      onBlur,
      disabled
    },
    ref
  ) => {

    return (
      <Form.Group>
        <Form.Label className="onboard-form-input-label">{label}</Form.Label>
        <FormControl
          ref={ref}
          name={name}
          className="onboard-form-input"
          type="text"
          value={value}
          placeholder={placeholder}
          isValid={isValid}
          isInvalid={isInvalid}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        {helpText && (
          <Form.Text className="text-muted">
            <i>{helpText}</i>
          </Form.Text>
        )}
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      </Form.Group>
    );
  }
);

FormInput.PropTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string
};

export default FormInput;