import React from 'react';

import { Dropdown } from 'react-bootstrap';

import { orderValuesets } from '../../OrderStatus/util/constants';


/**
 * Dropdown to display a list of order value sets
 * @param type
 * @param selectedValue
 * @param onSelect
 * @returns {*}
 * @constructor
 */
const OrderValueSetDropdown = ({ type, selectedValue, onSelect, className, disabled }) => {
  return (
    <Dropdown style={{ marginBottom: '1rem' }} className={ className } >
      <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ width: '100%' }}>
        {orderValuesets[type][selectedValue] || 'None selected'}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: '100%' }}>
        {Object.keys(orderValuesets[type]).map(value => (
          <Dropdown.Item onSelect={_ => onSelect(value)} disabled={disabled}>
            {orderValuesets[type][value]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default OrderValueSetDropdown;
