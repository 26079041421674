// @ts-nocheck
import { FunctionComponent } from 'react';
import {
  ActivePage, PaginationArrow,
  PaginationContainer,
  PaginationItem, PaginationSelect
} from './styles/pagination_styles';

//Icon
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPaginationProps {
  onPageChange: any;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  noNextPage: boolean;
}

const Pagination: FunctionComponent<IPaginationProps> = ({
  onPageChange,
  currentPage,
  pageSize,
  setPageSize,
  noNextPage
}) => {
  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <PaginationContainer>
      <PaginationItem onClick={onPrevious}>
        {currentPage > 1 && (
          <PaginationArrow>
            <FontAwesomeIcon icon={faChevronLeft} />
          </PaginationArrow>
        )}
      </PaginationItem>
      <PaginationItem>
        <ActivePage>{currentPage}</ActivePage>
      </PaginationItem>
      <PaginationItem onClick={onNext}>
        {!noNextPage && (
          <PaginationArrow>
            <FontAwesomeIcon icon={faChevronRight} />
          </PaginationArrow>
        )}
      </PaginationItem>
      <PaginationItem>
        <PaginationSelect
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </PaginationSelect>
      </PaginationItem>
    </PaginationContainer>
  );
};

export default Pagination;
