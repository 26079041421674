// @ts-nocheck
import { Dropdown, DropdownButton } from 'react-bootstrap';

const dropdownValues = [
  {
    key: 'completed',
    val: 'Completed',
  },
  {
    key: 'ongoing',
    val: 'Ongoing',
  },
];

/**
 * Dropdown button to call action to export the queue orders as a csv
 * @param exportSolarOrders {function} - action to export orders
 * @returns {*}
 * @constructor
 */
const ExportOrdersDropdown = ({ exportSolarOrders }) => (
  <>
    <DropdownButton id="export-orders-dropdown" size="sm" title="Export Orders">
      {dropdownValues.map(value => (
        <Dropdown.Item
          value={value.key}
          onSelect={() => {
            //@ts-ignore
            mixpanel.track('Raptor internal export orders');
            exportSolarOrders(value.key);
          }}
        >
          {value.val}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </>
);

export default ExportOrdersDropdown;
