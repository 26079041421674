import { Container, Modal } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { bindActionCreators } from 'redux';
import { OrderActionCreator } from '../../../actions/orderActions';
import OrderEditHeader from './OrderEditHeader';
import OrderEditTabs from './orderEditTabs/OrderEditTabs';

/**
 * Container component for the order edit modal. Passes data from the store to
 * the rest of the order modal components.
 * @param {*} order - Order to display or edit
 * @param {*} solarFarms - list of all solarFarms
 * @param {*} orderActions - action creator for actions related to orders
 */
const OrderEdit = props => {
  const { orderActions, close, orderUuid } = props;

  const solarFarm = useSelector(state => state.order.solarFarm);
  const filteredOrders = useSelector(state => state.order.filteredOrders);
  const rawOrders = useSelector(state => state.order.orders);
  const getSelectedRawOrders = Object.values(rawOrders)?.find(order => order.uuid === orderUuid);
  const solarFarmData = solarFarm ? solarFarm : rawOrders[orderUuid]?.solar_farm ? rawOrders[orderUuid].solar_farm : null;
  // orderUuid
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: '100%' }}>
          <OrderEditHeader
            order={getSelectedRawOrders}
            solarFarm={solarFarmData}
            orderActions={orderActions}
            close={close}
          />
        </Modal.Title>
      </Modal.Header>
      <Container className="order-edit-container">
        <OrderEditTabs
          solarFarmData={solarFarmData}
          solarFarm={solarFarm}
          selectedOrder={getSelectedRawOrders}
          orderActions={orderActions}
        />
      </Container>
    </>
  );
};

// Only get the order values when they change in state
const getOrderSelector = createSelector(
  [
    state => (state.order && state.order.orders && Object.values(state.order.orders)) || [],
    (state, props) => props.orderUuid
  ],
  (orders, selectedOrderUuid) => orders.find(order => order.uuid === selectedOrderUuid)
);

const mapStateToProps = (state, props) => ({
  solarFarm: state.order.solarFarm,
  order: getOrderSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderEdit);
