import react, { useState, useEffect } from "react";

// ApiClient
import OrgsApiClient from "../../apiClient/OrgsApiClient";

// Hooks
import { useOrgAllowedList } from "../../hooks/useOrgAllowedList";
import { useOrg } from "../../hooks/useOrg";

// Components
import { Text, TextVariant } from "@raptormaps/text";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button } from "@raptormaps/button";
import { TextInput } from "@raptormaps/text-input";
import toastr from "toastr";
import RaptorModal from "../../components/shared/Modal";
import { Row, Stack, Gutter } from "@raptormaps/layout";
import { Checkbox } from "@raptormaps/checkbox";

// styled components
import {
  Container,
  Section,
  ListContainer,
  List,
  ListItem,
  ListTitleSection,
  ListRow,
  AddContainer,
  LoadingBar,
  DescriptionContainer,
  WarningText,
  InfoText,
  ErrorText,
  UserContainer,
  UserItem,
} from "./styles";

// Constants
import { raptorMapsConstants } from "../../constants/general_constants";

// Utils
import {
  handleIsValidEmail,
  handleIsValidDomain,
} from "../../util/general_utils";

export const OrgAllowedList = () => {
  const [solarOrgs, setSolarOrgs] = useState([]);
  const [selectedOrg, setSelectOrg] = useState<any>(null);
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [allowedEmails, setAllowedEmails] = useState<string[]>([]);
  const [orgUsers, setOrgUsers] = useState<any[]>([]);
  const [newDomain, setNewDomain] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [deleteDomain, setDeleteDomain] = useState<string>("");

  const orgsApi = new OrgsApiClient();
  const {
    getOrgAllowedList,
    deleteAllowedEmail,
    deleteAllowedDomain,
    addAllowedDomain,
    addAllowedEmail,
  } = useOrgAllowedList();
  const { getOrgUsers } = useOrg();

  const handleGetAllOrgs = () => {
    /* @ts-ignore comment */
    orgsApi.getOrgs().then((res: any) => setSolarOrgs(res));
  };

  useEffect(() => {
    handleGetAllOrgs();
  }, []);

  useEffect(() => {
    if (selectedOrg?.id) {
      setLoading(true);
      handleClearLists();
      handleGetAllowedList();
      handleGetOrgUsers();
    }
  }, [selectedOrg?.id]);

  const handleGetOrgUsers = () => {
    getOrgUsers(selectedOrg?.id)
      .then((res: any) => {
        setOrgUsers(res?.org_users);
      })
      .catch((e) => {
        toastr.error("Error getting orgs");
        setLoading(false);
      });
  };

  const handleGetAllowedList = () => {
    setLoading(true);
    getOrgAllowedList(selectedOrg?.id)
      .then((res: any) => {
        setAllowedDomains(res?.domains);
        setAllowedEmails(res?.emails);
        setNewDomain("");
        setNewEmail("");
        setLoading(false);
      })
      .catch((e) => {
        toastr.error("Error getting orgs");
        setLoading(false);
      });
  };

  const handleClearLists = () => {
    setAllowedDomains([]);
    setAllowedEmails([]);
  };

  const handleDeleteDomain = (domain: string, deleteUsers: boolean) => {
    setLoading(true);
    deleteAllowedDomain(
      selectedOrg?.id,
      domain,
      deleteUsers,
      handleGetAllowedList
    );
  };

  const handleDeleteEmail = (email: string) => {
    setLoading(true);
    deleteAllowedEmail(selectedOrg?.id, email, handleGetAllowedList);
  };

  const handleAddDomain = () => {
    if (!newDomain || !selectedOrg) {
      return;
    }

    setLoading(true);

    if (newDomain === raptorMapsConstants.RAPTOR_MAPS_DOMAIN) {
      toastr.warning(
        "The raptormaps.com domain is automatically allowed for every Org."
      );
    } else if (!handleIsValidDomain(newDomain)) {
      toastr.warning(`${newDomain} - invalid domain format`);
    } else {
      addAllowedDomain(selectedOrg?.id, newDomain, handleGetAllowedList);
    }

    setLoading(false);
  };

  const handleAddEmail = () => {
    if (!newEmail || !selectedOrg) {
      return;
    }

    setLoading(true);

    if (!handleIsValidEmail(newEmail)) {
      toastr.warning(`${newEmail} - invalid email format`);
    } else {
      addAllowedEmail(selectedOrg?.id, newEmail, handleGetAllowedList);
    }

    setLoading(false);
  };

  const handleUpdateSelectedOrg = (org: any[]) => {
    const selectedOrg = org?.length > 0 ? org[0] : null;
    setSelectOrg(selectedOrg);

    if (org?.length === 0) {
      handleClearLists();
    }
  };

  return (
    <Container>
      <Section>
        <Text variant={TextVariant.h3}>Manage Organizations Allowed Lists</Text>
        <DescriptionContainer>
          <Section>
            <Text variant={TextVariant.paragraph_large}>
              This feature governs the email domains and email addresses that
              Customers are allowed to input when adding Users.
            </Text>
            <Section>
              <Text variant={TextVariant.paragraph_medium_bold}>
                Allowed domain
              </Text>
              <ul>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Example: testorg.com
                  </Text>
                </li>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Special characters are not allowed: #, @, $ ,&, ^, !, -
                  </Text>
                </li>
              </ul>

              <Text variant={TextVariant.paragraph_medium_bold}>
                Allowed email
              </Text>
              <ul>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Example: test_admin@testorg.com
                  </Text>
                </li>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Special characters are not allowed: #, $, &, ^, !
                  </Text>
                </li>
              </ul>
            </Section>

            <Section>
              <InfoText variant={TextVariant.paragraph_medium}>
                <strong>Notes</strong>:
              </InfoText>
              <ul>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Existing Customers and Users will not be impacted. Current
                    Users will only be impacted if their email domain is added
                    and then subsequently removed. You will always be warned if
                    you are about to remove Users.
                  </Text>
                </li>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    The Allowed List is inactive until the first Domain or User
                    Email has been added. After a Domain or User Email has been
                    added, new Users must have those properties. No existing
                    Users are impacted.
                  </Text>
                </li>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    The raptormaps.com domain is automatically allowed for every
                    Org, and you will not be able to add it to the allowed
                    Domains.
                  </Text>
                </li>
                <li>
                  <Text variant={TextVariant.paragraph_medium}>
                    Existing Users will be allowed to update their email address
                    even if their new email domain is not on the Allowed List.
                  </Text>
                </li>
              </ul>
              <WarningText variant={TextVariant.paragraph_medium}>
                <strong>Warning:</strong> If you delete an email or domain from
                the allowed list, users with that email or domain will be
                removed from the organization.
              </WarningText>
            </Section>
          </Section>
        </DescriptionContainer>
      </Section>
      <Section>
        <Typeahead
          id="org"
          options={Object.values(solarOrgs)}
          placeholder="Search Orgs..."
          labelKey={(org: any) => `${org.name}`}
          onChange={(selected) => handleUpdateSelectedOrg(selected)}
          defaultInputValue={""}
        />
        {loading && <LoadingBar />}
      </Section>
      <ListContainer>
        <List>
          <ListTitleSection justify="space-between">
            <Text variant={TextVariant.h5}>Allowed Domains</Text>
            <AddContainer>
              <TextInput
                inputSize="small"
                inputStyle="white-box"
                inputType="text"
                onChange={(e) => setNewDomain(e.target.value)}
                placeholder="Type domain..."
                value={newDomain}
              />
              <Button
                variant="primary"
                size="small"
                disabled={loading || !selectedOrg || selectedOrg === ""}
                onClick={() => handleAddDomain()}
              >
                Add
              </Button>
            </AddContainer>
          </ListTitleSection>
          <ListItem>
            {allowedDomains?.length > 0 ? (
              allowedDomains.map((domain) => (
                <ListRow>
                  <Text variant={TextVariant.paragraph_medium_bold}>
                    {domain}
                  </Text>
                  <Button
                    variant="secondary"
                    size="small"
                    disabled={loading}
                    onClick={() => {
                      setDeleteDomain(domain);
                      setShowWarningModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </ListRow>
              ))
            ) : (
              <Text variant={TextVariant.paragraph_medium_bold}>
                No allowed Domains
              </Text>
            )}
          </ListItem>
        </List>

        <List>
          <ListTitleSection justify="space-between" align="center">
            <Text variant={TextVariant.h5}>Allowed Emails</Text>
            <AddContainer>
              <TextInput
                inputSize="small"
                inputStyle="white-box"
                inputType="text"
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Type an email..."
                value={newEmail}
              />
              <Button
                variant="primary"
                size="small"
                disabled={loading || !selectedOrg?.id || selectedOrg?.id === ""}
                onClick={() => handleAddEmail()}
              >
                Add
              </Button>
            </AddContainer>
          </ListTitleSection>
          <ListItem>
            {allowedEmails?.length > 0 ? (
              allowedEmails.map((email) => (
                <ListRow>
                  <Text variant={TextVariant.paragraph_medium_bold}>
                    {email}
                  </Text>
                  <Button
                    variant="secondary"
                    size="small"
                    disabled={loading}
                    onClick={() => handleDeleteEmail(email)}
                  >
                    Delete
                  </Button>
                </ListRow>
              ))
            ) : (
              <Text variant={TextVariant.paragraph_medium_bold}>
                No allowed emails
              </Text>
            )}
          </ListItem>
        </List>
      </ListContainer>
      <RaptorModal
        title=""
        show={showWarningModal}
        close={() => setShowWarningModal(!showWarningModal)}
        overlayClick={false}
      >
        <Section>
          <Section>
            <Text variant={TextVariant.h3}>
              Are you sure you want to delete this domain?
            </Text>
          </Section>
          <Section>
            <ErrorText variant={TextVariant.paragraph_large}>
              Deleting <strong>{`${deleteDomain}`}</strong> will remove the
              following users from the
              <strong>{` ${selectedOrg?.name} `}</strong>
              organization in our system. This is a permanent action. Are you
              sure you want to proceed?
            </ErrorText>
            <UserContainer>
              {orgUsers?.map((item) => {
                if (
                  item?.email
                    ?.toLowerCase()
                    .includes(deleteDomain.toLowerCase())
                ) {
                  return (
                    <UserItem>
                      <Text variant={TextVariant.paragraph_medium}>
                        {`${item?.name} - ${item?.email}`}
                      </Text>
                    </UserItem>
                  );
                }
              })}
            </UserContainer>
          </Section>
          <Section>
            <Row gutter={Gutter.S} justify="end">
              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  setShowWarningModal(!showWarningModal);
                  handleDeleteDomain(deleteDomain, true);
                }}
              >
                Yes, delete domain and users
              </Button>
              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  setShowWarningModal(!showWarningModal);
                  handleDeleteDomain(deleteDomain, false);
                }}
              >
                Yes, delete domain only
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={() => {
                  setShowWarningModal(!showWarningModal);
                }}
              >
                Cancel
              </Button>
            </Row>
          </Section>
        </Section>
      </RaptorModal>
    </Container>
  );
};
