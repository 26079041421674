import { useEffect } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import SalesOnboardingForm from './SalesOnboardingForm';

//styled components
import {
  SalesOnboardingTitle
} from './styles/SalesOnboarding_styles';

/**
 * Sales onboarding view component
 *
 * @returns {*}
 * @constructor
 */
const SalesOnboarding = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    mixpanel.track('Raptor Admin - Open Project Onboarding');
  });

  return (
    <Container style={{paddingLeft: 20, paddingRight: 20}} fluid>
      <Row style={{ paddingLeft: 20, paddingRight: 20, marginBottom: '.5rem' }}>
        <Col sm={12}>
          <SalesOnboardingTitle>Project Onboarding</SalesOnboardingTitle>
        </Col>
      </Row>
      <Row style={{paddingLeft: 20, paddingRight: 20}}>
        <Col sm={12}>
          <SalesOnboardingForm />
        </Col>
      </Row>
    </Container>
  );
};

export default SalesOnboarding;
