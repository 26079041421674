import React from 'react';
import { Modal } from 'react-bootstrap';
import OrderEdit from './orderEdit/OrderEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';

/**
 * Modal to display the Order edit/show view for a specific order
 * @param {}
 */
const OrderModal = ({ show, toggle, orderUuid, editable, onOrderModalClose, orgs }) => {
  const handleClose = () => {
    toggle();
    onOrderModalClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="queue-viewer-order-modal"
      dialogClassName="order-modal"
    >
      <OrderEdit editable={editable} orderUuid={orderUuid} close={handleClose} orgs={orgs}/>
    </Modal>
  );
};

export default OrderModal;
