// @ts-nocheck
import { FunctionComponent } from 'react';
import { Modal } from 'react-bootstrap';
import AddOrgGroup from './AddOrgGroup';
import { IEditOrgGroup } from './InterfaceUtil';

interface IEditOrgGroupModal {
  editOrgGroup: IEditOrgGroup | {};
  orgs: [];
  adminUsers: [];
  handleModalClose: Function;
  displayEditModal: boolean;
  handleUpdateRefresh: Function;
  handleSubmitRefresh: Function;
  handleRemoveInputField: Function;
}

const EditOrgGroupModal: FunctionComponent<IEditOrgGroupModal> = ({
  editOrgGroup,
  orgs,
  adminUsers,
  handleModalClose,
  displayEditModal,
  handleUpdateRefresh,
  handleSubmitRefresh,
  handleRemoveInputField
}) => {
  return (
    <Modal
      show={displayEditModal}
      scrollable={true}
      dialogClassName="edit-default-org-group-modal"
      centered
      onHide={handleModalClose}
      size={'xl'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Default Org Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddOrgGroup
          orgs={orgs}
          adminUsers={adminUsers}
          editOrgGroup={editOrgGroup}
          displayEditModal={displayEditModal}
          handleUpdateRefresh={handleUpdateRefresh}
          handleSubmitRefresh={handleSubmitRefresh}
          handleRemoveInputField={handleRemoveInputField}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditOrgGroupModal;
