import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseAPI,
  Configuration,
  ConfigurationParameters,
  FetchParams,
  RequestContext,
  Middleware,
  ResponseContext,
} from '@raptormaps/raptor-internal-client-ts';

export interface AuthOptions {
  scope: string;
  audience: string;
}

const DEFAULT_AUTH_OPTIONS: AuthOptions = {
  scope: '',
  audience: window.REACT_APP_AUTH0_CONFIG.audience,
};

/**
 * useApi instantiates an API with middleware to add an Auth0
 * access token into the headers. API's must extend BaseAPI.
 */
export const useApi = <T extends BaseAPI>(
  ctor: { new (params?: Configuration): T },
  configurationOptions: ConfigurationParameters = {},
  authOptions: AuthOptions = DEFAULT_AUTH_OPTIONS,
): T => {
  const { getAccessTokenSilently } = useAuth0();

  class AccessTokenMiddleware implements Middleware {
    async pre(context: RequestContext): Promise<FetchParams | void> {
      const accessToken = await getAccessTokenSilently(authOptions);
      return Promise.resolve({
        url: context.url,
        init: {
          ...context.init,
          headers: new Headers({
            ...context.init.headers,
            authorization: `Bearer ${accessToken}`,
          }),
        },
      });
    }

    async post(context: ResponseContext): Promise<Response | void> {
      return Promise.resolve(context.response);
    }
  }

  const api: T = new ctor(
    new Configuration({
      ...configurationOptions,
      middleware: [new AccessTokenMiddleware()],
    }),
  );
  return api;
};
