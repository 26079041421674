// @ts-nocheck
import { FunctionComponent, useEffect, useState } from 'react';

//API
import DefaultClientOrgChannelApiClient from '../../apiClient/DefaultClientOrgChannelApiClient';

// components
import { Container } from 'react-bootstrap';
import TableContainer from '../shared/Table';
import AddNewOrg from './AddNewOrg';
import EditModal from './EditModal';

//styled components
import { Section, TableSection, Title } from './styles/DefaultClientOrg_styles';

interface IDefaultClientOrg {}

const defaultOrgApi = new DefaultClientOrgChannelApiClient();

const DefaultClientOrg: FunctionComponent<IDefaultClientOrg> = () => {
  const [listData, setListData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [channelOptions, setChannelOptions] = useState([]);
  const [notBpoOrgList, setNotBpoOrgList] = useState([]);

  // fetches all default orgs to populate table
  const fetchDefaultOrgs = () => {
    let orgApi = new DefaultClientOrgChannelApiClient();
    orgApi.getDefaultOrgChannelList().then(res => {
      setListData(res);
    });
  };

  const fetchBPOOrgs = () => {
    let orgApi = new DefaultClientOrgChannelApiClient();
    orgApi.getAllBpoOrgs().then(res => {
      setChannelOptions(res);
    });
  };

  const fetchNotBPOOrgs = () => {
    let orgApi = new DefaultClientOrgChannelApiClient();
    orgApi.getAllNotBpoOrgs().then(res => {
      setNotBpoOrgList(res);
    });
  };

  // Table Columns
  const columns = [
    {
      Header: 'Client Org',
      accessor: 'org_name'
    },
    {
      Header: 'Channel',
      accessor: 'channel_name'
    }
  ];

  useEffect(() => {
    fetchDefaultOrgs();
    fetchBPOOrgs();
    fetchNotBPOOrgs();
  }, []);

  useEffect(() => {
    fetchBPOOrgs();
  }, [selectedRow]);

  const handleSelectRow = row => {
    setSelectedRow(row);
  };

  const handleUpdateOrgChannel= (channel: any) => {
    let itemToUpdate = listData.find(elm => elm.org_name === selectedRow.org_name);
    let indexitemToReplace = listData.findIndex(elm => elm.org_name === selectedRow.org_name);
    let newList = listData;
    itemToUpdate.channel_id = channel.id;
    itemToUpdate.channel_name = channel.name;
    newList[indexitemToReplace] = itemToUpdate;

    let orgApi = new DefaultClientOrgChannelApiClient();
    orgApi.updateBPOOrgChannel(itemToUpdate).then(res => {
      setListData(newList);
      console.log(res);
    });
  };

  return (
    <Container>
      <Section>
        <Title>Default Client Org</Title>
      </Section>
      <AddNewOrg
        currentOrgChannelList={listData}
        orgOptions={notBpoOrgList}
        channelOptions={channelOptions}
        handleFetchOrgChannel={fetchDefaultOrgs}
      />
      <TableSection>
        <TableContainer
          rows={listData}
          columns={columns}
          rowAction={handleSelectRow}
          paginated={false}
        />
      </TableSection>
      <EditModal
        channelOptions={channelOptions}
        row={selectedRow}
        handleUpdateOrgChannel={handleUpdateOrgChannel}
      />
    </Container>
  );
};

export default DefaultClientOrg;
