import { useEffect, useState } from 'react';

/**
 * Update global store with the selected map layer
 * @param value - search value
 * @param timeout - time to wait until perform a return
 */
const useDebounce = (value, timeout) => {
  // Save a local copy of `value` in this state which is local to our hook
  const [val, setVal] = useState(value);

  useEffect(() => {
    // Set timeout to run after delay
    const handler = setTimeout(() => setVal(value), timeout);

    // clear the setTimeout listener on unMount
    return () => clearTimeout(handler);
  }, [value, timeout]);

  return val;
};

export default useDebounce;
