import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";

class DefaultOrgGroupsApiClient {
  constructor() {
    this.runMode = sessionStorage.getItem('runMode');
    this.api = new RestApiV2(false, '/');
  };

  getDefaultOrgGroups = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${window.REACT_APP_API_ENDPOINT}/default_org_group`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  submitDefaultOrgGroup = defaultOrgGroup => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${window.REACT_APP_API_ENDPOINT}/default_org_group`, defaultOrgGroup)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  updateDefaultOrgGroup = defaultOrgGroup => {
    return new Promise((resolve, reject) => {
      this.api
        .put(`${window.REACT_APP_API_ENDPOINT}/default_org_group`, defaultOrgGroup)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  }
}

export default DefaultOrgGroupsApiClient;
