// @ts-nocheck
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { PROJECT_STATUS_TABS } from './constants';
import ProjectViewerSalesOnboardingForm from './ProjectViewerSalesOnboardingForm';
import { Spinner } from "react-bootstrap";
/**
 * Modal to display an editable Project Onboarding Form for a selected project.
 * If selected project data is not loaded, displays a loading modal
 * @param {}
 */
const ProjectViewerModal = ({
  showModal,
  handleModalClose,
  selectedProject,
  message,
  activeStatus,
  setActiveStatus,
  refreshProjectViewerTable
}) => {
  const [hasSelectedProject, setHasSelectedProject] = useState(false);

  /**
   * Determines if selected project data is loaded
   * @param {}
   * @returns {}
   */
  const isProjectLoaded = () => {
    if (selectedProject && Object.keys(selectedProject).length !== 0) {
      setHasSelectedProject(true);
    }
    else {
      setHasSelectedProject(false);
    }
  };

  const displayModalTitle = activeStatus => {
    if (activeStatus === 0) {
      return 'Sales Onboarding Form - Review';
    } else {
      return `Status: ${PROJECT_STATUS_TABS[activeStatus]}`;
    }
  };

  useEffect(() => {
    isProjectLoaded();
  }, [selectedProject]);

  /**
   * If selectedProject is not loaded, displays modal with message (either loading or error locating project id)
   * Otherwise displays a Sales Onboarding Modal
   * @returns
   */
  const displayProjectModal = () => {
    if (hasSelectedProject) {
      return (
        <>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            scrollable={true}
            dialogClassName="project-viewer-onboarding-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{displayModalTitle(selectedProject.status)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProjectViewerSalesOnboardingForm
                selectedProject={selectedProject}
                activeStatus={activeStatus}
                handleModalClose={handleModalClose}
                setActiveStatus={setActiveStatus}
                refreshProjectViewerTable={refreshProjectViewerTable}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    } else {
      return (
        <Modal
          show={showModal}
          onHide={handleModalClose}
          scrollable={true}
          dialogClassName="project-viewer-loading-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Project View Loading...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Modal.Body>
          <Modal.Footer/>
        </Modal>
      );
    }
  };

  return <>{displayProjectModal()}</>;
};

export default ProjectViewerModal;
