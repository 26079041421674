import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import { AdminActionCreator } from "../../actions/adminActions";
import OrdersApiClient from "../../apiClient/OrdersApiClient";
import SolarOrdersApiClient from "../../apiClient/SolarOrdersApiClient";
import { ORDER_TYPES } from "../../constants";

const OrderDownloads = ({ order }) => {
  const [fetchedFiles, setFetchedFiles] = useState(false);
  const [inspectionFiles, setInspectionFiles] = useState([]);
  const [farmFiles, setFarmFiles] = useState([]);

  useEffect(() => {
    const orderApi = new OrdersApiClient();
    const openApiOrdersApi = new SolarOrdersApiClient();

    // Get the files for each inspection on an order
    // Checks for the lack of client org to indicate that the summary is gone and the full
    // order has been fetched.
    if (order && !fetchedFiles) {
      if (order.inspections) {
        order.inspections.forEach((i) => {
          if (i.uuid) {
            orderApi.getFilesForInspection(i.uuid, order.uuid).then((files) => {
              setInspectionFiles([...inspectionFiles, ...files]);
            });
          }
        });
      }

      if (order.order_type === ORDER_TYPES.SITE_DIGITIZATION) {
        // farm api call
        openApiOrdersApi
          .getFilesForFarm(order.solar_farm_uuid)
          .then((files) => {
            setFarmFiles([...farmFiles, ...files]);
          });
      }
    }
    setFetchedFiles(true);
  }, [farmFiles, fetchedFiles, inspectionFiles, order]);

  /**
   * Creates download links for each file associated with an order
   * @param {} inspections
   */
  const getDownloadLinks = () => {
    const files = farmFiles.length ? farmFiles : inspectionFiles;
    return files.reduce((acc, file) => {
      if (file) {
        acc.push(
          <li>
            <a href={file.signed_url} download>
              {file.filename}
            </a>
          </li>,
        );
      }
      return acc;
    }, []);
  };

  return (
    <Container>
      <Row>
        <Col>
          <strong>Inspection Files:</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>{getDownloadLinks()}</ul>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  inspectionFiles:
    (state.order.orders &&
      state.order.orders[props.order.uuid] &&
      state.order.orders[props.order.uuid].inspections &&
      state.order.orders[props.order.uuid].inspections.flatMap(
        (i) => i.files,
      )) ||
    [],
});
const mapDispatchToProps = (dispatch) => ({
  adminActions: bindActionCreators(AdminActionCreator, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderDownloads);
