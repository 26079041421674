import { faLink, faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFeature } from "@growthbook/growthbook-react";
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderActionCreator } from '../../actions/orderActions';
import { newCustomerRedirect, getCustomerFEUrl } from '../../util';

const RAPTORX_ID = 146;

/**
 * Formats a date to be displayed in the order upload requests table
 */
const timeFormatter = (cell, row) => {
  let d = new Date(0);
  d.setUTCSeconds(cell);
  return d.toLocaleString();
};

/**
 * Generates the columns for the order upload requests table
 */
const getUploadTableColumns = (toggleAccessToken, deleteRequest, orderUuid, nlc) => [
  {
    dataField: 'id',
    text: 'ID',
    sort: true
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true
  },
  {
    dataField: 'creator.email',
    text: 'Requester',
    sort: true,
    style: {
      wordWrap: 'break-word'
    }
  },
  {
    dataField: 'upload_sessions.length',
    text: 'Uploads',
    sort: false
  },
  {
    dataField: 'created_tsecs',
    text: 'Created',
    sort: true,
    formatter: timeFormatter
  },
  {
    dataField: 'get_link',
    isDummyField: true,
    text: 'Link',
    formatter: (_, row) => {
      if (nlc) {
        const new_row = getCustomerFEUrl(`/upload_request?name=${(row.name).replace(/ /g, '%20')}&access_token=${row.access_token.access_token}`);
        return (
          <CopyToClipboard text={new_row}>
            <Button title={new_row}>
              <FontAwesomeIcon icon={faLink} />
            </Button>
          </CopyToClipboard>
        );
      } else {
        return (
          <CopyToClipboard text={row.url}>
            <Button title={row.url}>
              <FontAwesomeIcon icon={faLink} />
            </Button>
          </CopyToClipboard>
        );
      }
    }
  },
  {
    dataField: 'toggle_access',
    isDummyField: true,
    text: 'Access',
    formatter: (cell, row) => {
      let style = row.access_token.is_active ? 'success' : 'danger';
      let title = row.access_token.is_active
        ? 'Locking will prevent further uploads from anyone who has this link'
        : 'Unlocking will allow anyone with this link to upload to the destination org';

      return (
        <Button onClick={() => toggleAccessToken(orderUuid, row.id)} variant={style} title={title}>
          <FontAwesomeIcon icon={faLock} />
        </Button>
      );
    }
  },
  {
    dataField: 'delete',
    isDummyField: true,
    text: 'Delete',
    formatter: (cell, row) => {
      return (
        <Button onClick={() => deleteRequest(orderUuid, row.uuid)} variant="danger">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      );
    }
  }
]

/**
 *  Allows for display, creation, deletion and toggle of upload requests associated to a specific order
 * @param {*} order - order associated with upload requests
 * @param {*} orderActions - action creator for actions related to orders
 */

const OrderUploadRequests = ({ order, orderActions }) => {
  const newLinkCopy = useFeature("new-link-copy").on;
  // Populate the base request with unchanging values
  const [showNewRequestModal, toggleShowNewRequestModal] = useState(false);
  const [requestName, setRequestName] = useState(null);

  /**
   * Creates an upload request and ties it to the existing order
   */
  const handleCreateRequest = () => {
    // eslint-disable-next-line no-undef
    mixpanel.track('Order create upload request');
    orderActions.createUploadRequest(order, requestName).then(() => toggleShowNewRequestModal(!showNewRequestModal));
  };
  /**
   * Redirects the user to the upload-sessions page with the inspection uuid of the first inspection on the order
   * and the upload session ids as url params
   */
  const handleCreateImagesetRedirect = () => {
    const inspectionWithUploadRequests = order.inspections && order.inspections[0]; // Just pick the first inspection since they are all tied to the order
    const uploadSessionIds =
      order.upload_requests &&
      order.upload_requests.flatMap(
        request => request.upload_sessions && request.upload_sessions.map(session => session.id)
      );
    if (inspectionWithUploadRequests && uploadSessionIds) {
      newCustomerRedirect(
        `/upload-sessions/map?inspectionUuid=${
          inspectionWithUploadRequests.uuid
        }&uploadSessionIds=${uploadSessionIds.toString()}`
      );
    } else {
      newCustomerRedirect('/upload-sessions/map');
    }
  };

  const getCreateImageSetsContent = () => {
    if (order && order.upload_requests && order.upload_requests.length > 0) {
      return (
        <>
          <Button size="sm" onClick={handleCreateImagesetRedirect}>
            Create Image Set
          </Button>
          <small style={{ color: 'red' }}>
            Must be in bpo org in App to create image sets from order upload requests
          </small>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Container>
        <Row>
          <strong>Order Upload Requests:</strong>
        </Row>
        <Row className="justify-content-md-center" style={{ alignItems: 'center' }}>
          <Col lg={8}>
            <BootstrapTable
              keyField="id"
              data={(order && order.upload_requests) || []}
              columns={getUploadTableColumns(
                orderActions.toggleAccessToken,
                orderActions.removeRequestFromOrder,
                order && order.uuid,
                newLinkCopy
              )}
            />
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }} lg={3}>
            {
              <Button size="sm" onClick={() => toggleShowNewRequestModal(!showNewRequestModal)}>
                Create Upload Request
              </Button>
            }
            &nbsp;
            {getCreateImageSetsContent()}
          </Col>
        </Row>
      </Container>
      <Modal
        show={showNewRequestModal}
        onHide={() => toggleShowNewRequestModal(!showNewRequestModal)}
        onShow={() => setRequestName(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Data Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            name={'Request Name'}
            type={'text'}
            value={requestName}
            rows={'1'}
            onChange={e => setRequestName(e.target.value)}
            placeholder={'Name request'}
            maxLength={'50'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreateRequest}>Create</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch)
});

export default connect(null, mapDispatchToProps)(OrderUploadRequests);
