// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import toastr from 'toastr';

import { formatFloatToString, getFileType, handleTrailingZero, LOCATION_PRECISION } from '@raptormaps/raptor-lib';
import { SolarFarm } from '@raptormaps/raptor-models';

import OrdersApiClient from '../../apiClient/OrdersApiClient';
import SalesProjectApiClient from '../../apiClient/SalesProjectApiClient';
import SolarOrdersApiClient from '../../apiClient/SolarOrdersApiClient';
import UsersApiClient from '../../apiClient/UsersApiClient';
import { ORG_TYPES } from '../../util';
import CreateOrganizationModal from '../SalesOnboarding/CreateOrganizationModal';
import ProjectViewerFarmOrdersComponent from './ProjectViewerFarmOrdersComponent';
// Form components
import FormInput from '../SalesOnboarding/formComponents/FormInput';
import OrderValueSetDropdown from '../SalesOnboarding/formComponents/OrderValueSetDropdown';
import SalesOnboardingDatePicker from '../SalesOnboarding/formComponents/SalesOnboardingDatePicker';
import SalesOnboardingTypeahead from '../SalesOnboarding/formComponents/SalesOnboardingTypeahead';

import CSVDropzoneModal from '../SalesOnboarding/CSVDropzoneModal';
import OrderListCSVProcessor from '../SalesOnboarding/OrderListCSVProcessor';
import listSchema from '../SalesOnboarding/schema/orderListSchema';
import formSchema from './projectViewerSalesOnboardingSchema';

import { getDateStringFromTsecs, getTsecsFromDate } from '../OrderStatus/util';

import {
  SALES_PROJECT_HANDLE_SUBMIT_TYPES, SALES_PROJECT_STATUS,
  SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND
} from './constants';

const ACCOUNTING_INFO_ITEM_CODES = {
  FLIGHT_REVENUE: 'FLIGHT-REVENUE',
  ANALYTICS_REVENUE: 'ANALYTICS-REVENUE'
};

const GOOGLE_SHEET_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1LLfmcVAFXHE-gk2mH2MGTfe3mdKpWurUKZzuEcpgR-k/edit#gid=0';

/**
 * Component to hold all form for the sales onboarding page
 *
 * @param orgs {[Org]} - all orgs
 * @returns {*}
 * @constructor
 */
const ProjectViewerSalesOnboardingForm = ({
  orgs,
  selectedProject,
  activeStatus,
  setActiveStatus,
  handleModalClose,
  refreshProjectViewerTable
}) => {
  // either 'small' or 'data collector'
  const [createOrgType, setCreateOrgType] = useState(null);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [orderListTooShort, setOrderListTooShort] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isLoadingCSVValidate, setIsLoadingCSVValidate] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [disableOnCancelledStatus, setDisableOnCancelledStatus] = useState(false);
  const [disableOnReviewStatus, setDisableOnReviewStatus] = useState(false);
  const [disableWhenStatusNotReview, setDisableWhenStatusNotReview] = useState(true);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [disableCancelBtnUntilConfirm, setdisableCancelBtnUntilConfirm] = useState(false);

  // Get admin users and services on page load. Convert solar orders from selected project to populate order list on load.
  useEffect(() => {
    getRaptorAdminUsers();
    getAllServices();
    convertSolarOrders();
    disableFormInputFromStatus(selectedProject.status);
  }, [selectedProject]);

  /**
   * Makes call to users client to get admin users
   */
  const getRaptorAdminUsers = () => {
    const usersApi = new UsersApiClient();
    usersApi.getRaptorAdminUsers().then(users => setAdminUsers(users));
  };

  /**
   * Gets all services from order client
   */
  const getAllServices = () => {
    const ordersApi = new OrdersApiClient();
    ordersApi.getAllServices().then(services => setServices(services));
  };

  /**
   * Get list of client orgs from selected project
   * @returns list of client orgs
   */
     const getClientOrgs = () => {
       if (selectedProject.solar_orders.length) {
        // All orders always have same client orgs - ok to use client_orgs from first index
        return selectedProject.solar_orders[0].client_orgs;
       }
       return orgs.filter(o => o.id === selectedProject.org_id);
    };

  /**
   * Checks status of tab selected to determine which fields are editable
   * If status is cancelled all form fields are not editable.
   */
  const disableFormInputFromStatus = status => {
    if (status === SALES_PROJECT_STATUS.CANCELLED) {
      setDisableOnCancelledStatus(true);
      setDisableOnReviewStatus(true);
    }
    if (status === SALES_PROJECT_STATUS.REVIEW) {
      setDisableOnReviewStatus(true);
      setDisableWhenStatusNotReview(false);
    }
  };

  /**
   * Converts orders from selected project to correct format needed to populate FarmOrdersTable component.
   */
  const convertSolarOrders = () => {
    const CSO = selectedProject.solar_orders.map(so => {
      //if no account info items populated from API call, set default values to null
      if (so.accounting_info_items.length === 0) {
        so.accounting_info_items = SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND.accounting_info_items;
      };

      //if no data collections manager from solar order, set default value as null
      if (!so.data_collection_manager_id) {
        so.data_collection_manager_id = null;
      }

      return {
        solar_farm: {
          name: so.solar_farm.name,
          size: so.solar_farm.size,
          uuid: so.solar_farm.uuid,
          id: so.solar_farm.id,
          location: {
            lat: so.solar_farm.latitude,
            lng: so.solar_farm.longitude
          },
          latitudeEditing: handleTrailingZero(so.solar_farm.latitude, LOCATION_PRECISION),
          longitudeEditing: handleTrailingZero(so.solar_farm.longitude, LOCATION_PRECISION),
          address: so.solar_farm.address,
          ppa_rate: so.solar_farm.ppa_rate,
          access_code: so.solar_farm.access_code
        },
        id: so.id,
        errors: {},
        sales_project_id: selectedProject.id,
        uuid: so.uuid,
        contact: so.site_contact_uuid,
        data_collection_org_id: so.data_collection_org_id,
        data_collection_manager_id: so.data_collection_manager_id,
        contract_type: so.contract_type,
        order_type: so.order_type,
        requested_delivery_date: so.requested_delivery_date
          ? getDateStringFromTsecs(so.requested_delivery_date)
          : null,
        requested_flight_date: so.requested_flight_date
          ? getDateStringFromTsecs(so.requested_flight_date)
          : null,
        is_repeat_inspection: so.is_repeat_inspection,
        is_turnkey: so.turnkey_services,
        analytics_revenue:
          so.accounting_info_items.find(i => i.accounting_item_codekey === ACCOUNTING_INFO_ITEM_CODES.ANALYTICS_REVENUE) &&
          so.accounting_info_items.find(i => i.accounting_item_codekey === ACCOUNTING_INFO_ITEM_CODES.ANALYTICS_REVENUE).amount,
        flight_revenue:
          so.accounting_info_items.find(i => i.accounting_item_codekey === ACCOUNTING_INFO_ITEM_CODES.FLIGHT_REVENUE) &&
          so.accounting_info_items.find(i => i.accounting_item_codekey === ACCOUNTING_INFO_ITEM_CODES.FLIGHT_REVENUE).amount,
      };
    });
    setOrderList(CSO);
  };

  const createSubmitData = (selectedProject, formValues, orderList) => {
    const submitData = {
      id: selectedProject.id,
      creator_id: selectedProject.creator_id,
      updator_id: selectedProject.updator_id,
      status: selectedProject.status,
      project_name: formValues.project_name,
      org_id: formValues.client_orgs[0].id, 
      project_manager_id: formValues.project_manager_user_id,
      data_collection_manager_id: formValues.data_collection_manager_id,
      hubspot_deal_url: formValues.hubspot_deal_url,
      proposal_url: formValues.signed_proposal_link,
      dsp_contract_url: formValues.dsp_contract_url,
      email_list: formValues.client_email_list,
      other_context: formValues.other_context,
      solar_orders: orderList
    };

    return submitData;
  };

  /**
   * Makes API call to update status of sales project to cancelled
   */
  const handleCancelSalesProject = () => {
    const salesProjectApi = new SalesProjectApiClient();
    salesProjectApi
      .cancelSalesProject(selectedProject)
      .then(_ => {
        toastr.success('Successfully cancelled sales project');
      })
      .catch(err => toastr.error(err));
    closeCancelWarning();
    refreshProjectViewerTable();
    setActiveStatus(SALES_PROJECT_STATUS.CANCELLED);
  };

  /**
   * Checks active status to determine whether to display cancel project button
   * @returns
   */
  const handleDisplayCancelProjectBtn = (formValues, setFieldValue) => {
    if (activeStatus !== SALES_PROJECT_STATUS.CANCELLED) {
      return (
        <Button
          style={{ float: 'right', margin: '0 10px' }}
          variant="danger"
          onClick={handleAttemptCancel}
          disabled={isLoadingSubmission || disableCancelBtnUntilConfirm}
        >
          Cancel Project
        </Button>
      );
    } else {
      return <></>;
    }
  };

  /**
   * Opens Cancel Warning Alert if user attempts to cancel a project
   */
  const handleAttemptCancel = () => {
    setShowCancelWarning(true);
    setdisableCancelBtnUntilConfirm(true);
  };

  /**
   * Closes Cancel Warning Alert if user chooses not to cancel a project
   */
  const closeCancelWarning = () => {
    setShowCancelWarning(false);
    setdisableCancelBtnUntilConfirm(false);
  };

  /**
   * Prevent the form from submitting when the user hits enter
   * @param e
   */
  const onFormKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  /**
   * Callback when a new org is created
   * @param orgId {number} -
   * @param setFieldValue {function} -
   */
  const handleCreateOrgSuccess = (org, setFieldValue) => {
    setShowCreateOrgModal(false);
    if (createOrgType === ORG_TYPES.SMALL) {
      setFieldValue('client_orgs', org);
    } else if (createOrgType === ORG_TYPES.DATA_COLLECTOR) {
      setFieldValue('data_collection_org_id', org.id);
    }
  };

  /**
   * Parses a Hubspot Deal URL and returns the deal ID
   * @param hubspotDealUrl {string} - hubspot deal url (ex: https://app.hubspot.com/contacts/3446343/deal/dealID)
   */
  const getHubspotDealIdFromUrl = hubspotDealUrl => {
    const matchedUrlArray = hubspotDealUrl?.match('(?:/deal/)(\\w+)');
    if (!matchedUrlArray) return;

    const dealId = matchedUrlArray[1];
    return dealId;
  };

  /**
   * Validates the length and content of the order list
   *
   * @param orderList {object}
   */
   const validateOrderList = (orderList) => {
      return new Promise(resolve => {
        // There must be at least one order in the list to submit
        if (orderList.length === 0) {
          setOrderListTooShort(true);
          return;
        }
  
        let isValid = true;
        // Check that the farms exist first on the order
        const updatedOrderList = [...orderList];
      
        listSchema
          .validate(orderList, { abortEarly: false, context: { orderList } })
          .catch(err => {
            isValid = false;
            // Have to use a regex with this since there's no better way to get
            // the error for the position in the array. The regex gets the position (ex. 0)
            // and the portion of text after the last period (ex. size)
            // example: [0].solar_farm.size
            let pathRegex = /^\[(\d+)\].*\.(\w+)$/;
            let position, value;
            // iterate the errors from validation
            err.inner.map(error => {
              // get the position and value and set the message
              [_, position, value] = error.path.match(pathRegex);
              updatedOrderList[Number(position)].errors[value] = error.message;
            });
          })
          .finally(_ => {
            // lastly set the order list and return is valid
            setOrderList([...updatedOrderList]);
            resolve(isValid);
          });
      });
    };

  

  /**
   * Handle form data on submission
   * @param formValues {object} - form values being submitted
   */
  const handleSubmit = formValues => {
    // validate the rows in the order list

    validateOrderList(orderList)
    .then(isValid => {
      if (!isValid) {
        return; 
      };

      toastr.options.timeOut = 5000;
      toastr.options.extendedTimeOut = 50;
      toastr.info('Save/Submission in progress.')

      getHubspotDealIdFromUrl(formValues.hubspot_deal_url);

      const formattedOrders = orderList.map((order, ind) => {
        // Format string dates to unix. Keeps dates as strings in frontend but keeps formatting validation
        let unixRequestedDeliveryDate = getTsecsFromDate(order.requested_delivery_date);
        let unixRequestedFlightDate = getTsecsFromDate(order.requested_flight_date);
        order = {
          data_collection_org_id: order.data_collection_org_id,
          data_collection_manager_id: formValues.data_collection_manager_id,
          pm_user_id: formValues.project_manager_user_id,
          order_type: order.order_type,
          uuid: order.uuid,
          updator_id: selectedProject.updator_id,
          contract_type: order.contract_type,
          site_contact_uuid: order.contact,
          requested_delivery_date: unixRequestedDeliveryDate,
          requested_flight_date: unixRequestedFlightDate,
          turnkey_services: order.is_turnkey,
          solar_farm: SolarFarm.formatForInsertUpdate(order.solar_farm),
          solar_farm_uuid: order.solar_farm.uuid,
          services: formValues.services,
          accounting_info_items: getOrderAccountingInfoItems(order),
          org_id: formValues.client_orgs[0].id,
          client_orgs: formValues.client_orgs,
          id: order.id
        };
        order.solar_farm.id = order.solar_farm.id ? order.solar_farm.id : null;
        order.solar_farm.size = parseFloat(order.solar_farm.size);
        order.solar_farm.ppa_rate = parseFloat(order.solar_farm.ppa_rate);
        return order;
      });

      const submitData = createSubmitData(selectedProject, formValues, formattedOrders);

      // Submit, show toastr
      const salesProjectApi = new SalesProjectApiClient();
      const solarOrdersApi = new SolarOrdersApiClient();
      setIsLoadingSubmission(true);

      if (formValues.typeOfSubmit === SALES_PROJECT_HANDLE_SUBMIT_TYPES.SUBMIT_TO_ONGOING) {
        salesProjectApi
          .submitSalesProject(submitData)
          .then(_ => toastr.success('Successfully submitted sales project'))
          .catch(err => toastr.error(err))
          .finally(_ => {
            setIsLoadingSubmission(false);
            refreshProjectViewerTable();
            setActiveStatus(SALES_PROJECT_STATUS.ONGOING);
          });
      } else if (formValues.typeOfSubmit === SALES_PROJECT_HANDLE_SUBMIT_TYPES.SAVE_CHANGES) {
        salesProjectApi
          .updateSalesProject(submitData)
          .then(_ => {
            toastr.success('Successfully saved changes to sales project');
            submitData.solar_orders &&
              submitData.solar_orders.map(order => {
                if (order.uuid) {
                  solarOrdersApi.updateAccountInfoItems(order)
                };
              });
          })
          .catch(err => toastr.error(err))
          .finally(_ => {
            setIsLoadingSubmission(false);
            refreshProjectViewerTable();
            setActiveStatus(SALES_PROJECT_STATUS.ONGOING);
          });
      }
    })
  };

  /**
   * Gets the accounting info items array for a single order
   *
   * @param order {Order} -
   * @returns {[]}
   */
  const getOrderAccountingInfoItems = order => {
    let items = [];
    if (order.flight_revenue && order.flight_revenue !== '') {
      items.push({
        accounting_item_codekey: ACCOUNTING_INFO_ITEM_CODES.FLIGHT_REVENUE,
        amount: order.flight_revenue
      });
    }
    if (order.analytics_revenue && order.analytics_revenue !== '') {
      items.push({
        accounting_item_codekey: ACCOUNTING_INFO_ITEM_CODES.ANALYTICS_REVENUE,
        amount: order.analytics_revenue
      });
    }
    return items;
  };

  const clientEmailRef = React.createRef();

  /**
   * Handles adding an email on enter in the email field
   * Validates that the email is properly formatted
   *
   * @param e {Event}
   * @param values {object}
   * @param setFieldValue {function}
   */
  const handleAddEmail = (e, values, setFieldValue) => {
    const emailRegex = /^\S+@\S+$/;

    // prevent the form from submitting
    e.stopPropagation();
    if (e.keyCode === 13 && e.target.value.length > 0) {
      // set up the field val, clear the input
      if (!emailRegex.test(e.target.value)) {
        setEmailError('Invalid email!');
      } else {
        setEmailError(null);
        setFieldValue('client_email_list', values.client_email_list.concat(e.target.value));
        clientEmailRef.current.getInstance().clear();
      }
    }
  };

  const Warn = props => (
    <span style={{ color: 'red' }} {...props}>
      {props.children}
    </span>
  );

  /**
   * Handles when the user drops files into the dropzone
   * @param acceptedFiles {array} - array of files dropped
   * @param clientOrgId {number} -
   */
  const handleCSVDrop = (acceptedFiles, clientOrgId) => {
    // Should only be a single file, also needs to be .csv
    if (acceptedFiles.length > 1) {
      toastr.error('Please only drop a single file');
      return;
    }

    const { extension } = getFileType(acceptedFiles[0]);
    if (extension !== 'csv') {
      toastr.error('File must be .csv');
      return;
    }

    setIsLoadingCSVValidate(true);

    // Pass the file and client org id into the processor
    const csvProcessor = new OrderListCSVProcessor(acceptedFiles[0], clientOrgId);
    csvProcessor
      .processCSV()
      .then(validatedOrderList => {
        const updatedOrderList = validatedOrderList.map(order => {
          // Need to specially format the farm size here for the form validation
          if (order.solar_farm && order.solar_farm.size) {
            order.solar_farm.size = formatFloatToString(order.solar_farm.size);
          }

          // Need to convert revenue properties to string per api model
          if (order.analytics_revenue) {
            order.analytics_revenue = String(order.analytics_revenue);
          }
          if (order.flight_revenue) {
            order.flight_revenue = String(order.flight_revenue);
          }
          return order;
        });
        setOrderList(updatedOrderList);
      })
      .catch(err => {
        toastr.error(err);
        console.log(err);
      })
      .finally(_ => {
        setIsLoadingCSVValidate(false);
        setShowCSVModal(false);
      });
  };

  return (
    <Formik
      validationSchema={formSchema}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
      initialValues={{
        project_name: selectedProject.project_name,
        client_orgs: getClientOrgs(),
        client_email_list: selectedProject.email_list,
        project_manager_user_id: selectedProject.project_manager_id,
        data_collection_manager_id:
          selectedProject.solar_orders[0] &&
          selectedProject.solar_orders[0].data_collection_manager_id,
        signed_proposal_link: selectedProject.proposal_url,
        dsp_contract_url: selectedProject.dsp_contract_url,
        hubspot_deal_url: selectedProject.hubspot_deal_url,
        services:
          selectedProject.solar_orders[0] && selectedProject.solar_orders[0].services.slice(),
        other_context: selectedProject.other_context,
        data_collection_org_id: null,
        contract_type: null,
        order_type: null,
        requested_delivery_date: null,
        requested_flight_date: null,
        is_turnkey: false,
        typeOfSubmit: null
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue }) => (
        <Form
          noValidate
          id="sales-onboarding-form"
          onSubmit={values => handleSubmit(values)}
          onKeyPress={onFormKeyPress}
          style={{ marginBottom: '2rem' }}
        >
          <Row className="sales-onboarding-form-section">
            <Col md={12}>
              <Row style={{ marginBottom: '.5rem' }}>
                <Col md={12}>
                  <h6>General Info</h6>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormInput
                    label="Project Name"
                    name="project_name"
                    value={values.project_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disableOnCancelledStatus}
                  />
                  {touched.project_name && errors.project_name && (
                    <Warn>{errors.project_name}</Warn>
                  )}
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">Customer Org</Form.Label>
                    <InputGroup>
                      {orgs.length > 1 && (
                        <Typeahead
                          id="client_orgs"
                          name="client_orgs"
                          labelKey="name"
                          className="sales-onboarding-typeahead-with-button customer-org-typeahead"
                          selected={values.client_orgs}
                          maxResults={5}
                          multiple
                          options={orgs}
                          onChange={val =>setFieldValue('client_orgs', val)}
                          disabled={disableWhenStatusNotReview}
                        />
                      )}
                      <InputGroup.Prepend>
                        <Button
                          variant="primary"
                          style={{ width: 60 }}
                          onClick={() => {
                            setCreateOrgType(ORG_TYPES.SMALL);
                            setShowCreateOrgModal(true);
                          }}
                          disabled={disableWhenStatusNotReview}
                        >
                          New
                        </Button>
                      </InputGroup.Prepend>
                      {touched.client_orgs && errors.client_orgs && (
                        <Warn>{errors.client_orgs}</Warn>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Customer Email List</Form.Label>
                    <Typeahead
                      id="client-email-list"
                      labelKey="client-email-list"
                      className="client-email-list-typeahead"
                      multiple
                      ref={clientEmailRef}
                      selected={values.client_email_list}
                      options={values.client_email_list}
                      open={false}
                      allowNew
                      placeholder="Press enter after each email address to add"
                      onKeyDown={e => handleAddEmail(e, values, setFieldValue)}
                      onChange={val => setFieldValue('client_email_list', val)}
                      disabled={disableOnCancelledStatus}
                    />
                    {emailError && <Warn>{emailError}</Warn>}
                    {touched.client_email_list && errors.client_email_list && (
                      <Warn>{errors.client_email_list}</Warn>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">Project Manager</Form.Label>
                    {adminUsers.length > 0 && (
                      <SalesOnboardingTypeahead
                        className="project-manager-typeahead"
                        id="project_manager_id"
                        selectedId={values.project_manager_user_id}
                        options={adminUsers}
                        maxResults={5}
                        labelKey={u => u.email}
                        onChange={userId => setFieldValue('project_manager_user_id', userId)}
                        disabled={disableWhenStatusNotReview}
                      />
                    )}
                    {touched.project_manager_user_id && errors.project_manager_user_id && (
                      <Warn>{errors.project_manager_user_id}</Warn>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="onboard-form-input-label">
                      Data Collection Manager
                    </Form.Label>
                    {adminUsers.length > 0 && (
                      <SalesOnboardingTypeahead
                        className="project-manager-typeahead"
                        id="data_collection_manager_id"
                        selectedId={values.data_collection_manager_id}
                        options={adminUsers}
                        maxResults={5}
                        labelKey={u => u.email}
                        onChange={userId => setFieldValue('data_collection_manager_id', userId)}
                        disabled={disableWhenStatusNotReview}
                      />
                    )}
                    {touched.data_collection_manager_id && errors.data_collection_manager_id && (
                      <Warn>{errors.data_collection_manager_id}</Warn>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <FormInput
                    label="Link to Hubspot Deal"
                    name="hubspot_deal_url"
                    value={values.hubspot_deal_url}
                    placeholder="Please enter link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disableOnCancelledStatus}
                  />
                  {touched.hubspot_deal_url && errors.hubspot_deal_url && (
                    <Warn>{errors.hubspot_deal_url}</Warn>
                  )}
                  <FormInput
                    label="Link to Signed Proposal"
                    name="signed_proposal_link"
                    value={values.signed_proposal_link}
                    placeholder="Please enter link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disableOnCancelledStatus}
                  />
                  {touched.signed_proposal_link && errors.signed_proposal_link && (
                    <Warn>{errors.signed_proposal_link}</Warn>
                  )}
                  <FormInput
                    label="Link to DSP Contract"
                    name="dsp_contract_url"
                    value={values.dsp_contract_url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disableOnCancelledStatus}
                  />
                  {touched.dsp_contract_url && errors.dsp_contract_url && (
                    <Warn>{errors.dsp_contract_url}</Warn>
                  )}
                  <Row>
                    {values.services &&
                      services.map(service => (
                        <Col key={service.name} md={6}>
                          <div style={{ marginBottom: '1rem' }}>
                            <Form.Label className="onboard-form-input-label">
                              {service.name}
                            </Form.Label>
                            <Form.Check
                              type="radio"
                              label="Yes"
                              checked={!!values.services.find(s => s.uuid === service.uuid)}
                              onChange={() =>
                                setFieldValue('services', values.services.concat(service))
                              }
                              disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
                            />
                            <Form.Check
                              type="radio"
                              label="No"
                              checked={!values.services.find(s => s.uuid === service.uuid)}
                              onChange={() =>
                                setFieldValue(
                                  'services',
                                  values.services.filter(s => s.uuid !== service.uuid)
                                )
                              }
                              disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
                            />
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Other Context</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="other_context"
                      value={values.other_context}
                      rows="3"
                      onChange={handleChange}
                      disabled={disableOnCancelledStatus}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: '.5rem' }}>
            <Col>
              <h6>Order Defaults</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                disabled={
                  !values.client_orgs.length || disableOnCancelledStatus || disableWhenStatusNotReview
                }
                onClick={() => setShowCSVModal(true)}
              >
                CSV Upload
              </Button>
              <a href={GOOGLE_SHEET_TEMPLATE_URL} style={{ paddingLeft: '10px' }} target="_blank" rel="noreferrer">
                Template
              </a>
            </Col>
          </Row>
          {!values.client_orgs.length && (
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Warn>Must have customer org selected to upload CSV.</Warn>
              </Col>
            </Row>
          )}
          <Row className="sales-onboarding-form-section">
            <Col md={2}>
              <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                <Form.Check
                  type="checkbox"
                  label="Turnkey Services"
                  checked={values.is_turnkey}
                  onChange={() => setFieldValue('is_turnkey', !values.is_turnkey)}
                  disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
                />
              </div>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label className="onboard-form-input-label">Data Collection Org</Form.Label>
                <InputGroup>
                  {orgs.length > 1 && (
                    <SalesOnboardingTypeahead
                      id="data_collection_org_id"
                      className="sales-onboarding-typeahead-with-button"
                      selectedId={values.data_collection_org_id}
                      options={orgs.filter(o => o.type === ORG_TYPES.DATA_COLLECTOR)}
                      onChange={orgId => setFieldValue('data_collection_org_id', orgId)}
                      disabled={disableWhenStatusNotReview}
                    />
                  )}
                  <InputGroup.Prepend>
                    <Button
                      variant="primary"
                      style={{ width: 60 }}
                      onClick={() => {
                        setCreateOrgType(ORG_TYPES.DATA_COLLECTOR);
                        setShowCreateOrgModal(true);
                      }}
                      disabled={disableWhenStatusNotReview}
                    >
                      New
                    </Button>
                  </InputGroup.Prepend>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Label className="onboard-form-input-label">Type of Project</Form.Label>
              <OrderValueSetDropdown
                type="contract_type"
                selectedValue={values.contract_type}
                onSelect={type => setFieldValue('contract_type', type)}
                disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
              />
            </Col>
            <Col md={2}>
              <Form.Label className="onboard-form-input-label">Type of Inspection</Form.Label>
              <OrderValueSetDropdown
                type="order_type"
                selectedValue={values.order_type}
                onSelect={type => setFieldValue('order_type', type)}
                disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
              />
            </Col>
            <Col md={1}>
              <Form.Label className="onboard-form-input-label">Requested Flight Date</Form.Label>
              <div style={{ display: 'block', marginBottom: '1rem' }}>
                <SalesOnboardingDatePicker
                  value={values.requested_flight_date}
                  onUpdate={val => setFieldValue('requested_flight_date', val)}
                  disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
                />
              </div>
            </Col>
            <Col md={1}>
              <Form.Label className="onboard-form-input-label">Requested Delivery Date</Form.Label>
              <div style={{ display: 'block', marginBottom: '1rem' }}>
                <SalesOnboardingDatePicker
                  value={values.requested_delivery_date}
                  onUpdate={val => setFieldValue('requested_delivery_date', val)}
                  disabled={disableOnCancelledStatus || disableWhenStatusNotReview}
                />
              </div>
            </Col>
          </Row>
          {orderList && orderList.length > 0 && (
            <ProjectViewerFarmOrdersComponent
              orderList={orderList}
              setOrderList={setOrderList}
              clientOrgId={values.client_orgs.length ? values.client_orgs[0].id : null}
              defaultValues={{
                data_collection_org_id: values.data_collection_org_id,
                contract_type: values.contract_type,
                order_type: values.order_type,
                requested_delivery_date: values.requested_delivery_date,
                is_turnkey: values.is_turnkey,
                requested_flight_date: values.requested_flight_date
              }}
              activeStatus={activeStatus}
              disableOnReviewStatus={disableOnReviewStatus}
              disableWhenStatusNotReview={disableWhenStatusNotReview}
              disableOnCancelledStatus={disableOnCancelledStatus}
            />
          )}
          {orderListTooShort && (
            <Warn>At least one order must be added to the list for submission</Warn>
          )}
          <Row>
            <Col className={'project-viewer-onboarding-modal-footer'}>
              <Button
                style={{ float: 'right' }}
                type="submit"
                onClick={() =>
                  setFieldValue('typeOfSubmit', SALES_PROJECT_HANDLE_SUBMIT_TYPES.SAVE_CHANGES)
                }
                disabled={isLoadingSubmission || disableOnCancelledStatus}
              >
                Save Changes
              </Button>
              {handleDisplayCancelProjectBtn()}
              {selectedProject.status === SALES_PROJECT_STATUS.REVIEW ? (
                <Button
                  style={{ float: 'right', margin: '0 10px' }}
                  type="submit"
                  onClick={() => {
                    setFieldValue(
                      'typeOfSubmit',
                      SALES_PROJECT_HANDLE_SUBMIT_TYPES.SUBMIT_TO_ONGOING
                    );
                    // handleSubmit(formValues);
                  }}
                  disabled={isLoadingSubmission}
                >
                  Submit to Ongoing
                </Button>
              ) : (
                <></>
              )}
              <Alert variant="danger" show={showCancelWarning} style={{ width: '50%' }}>
                <Alert.Heading>CAUTION</Alert.Heading>
                <p>Are you sure you want to cancel this order? This action cannot be undone.</p>
                <Button
                  style={{ float: 'right', margin: '0 10px' }}
                  variant="danger"
                  onClick={handleCancelSalesProject}
                >
                  Yes, Cancel Order
                </Button>
                <Button
                  style={{ float: 'right', margin: '0 10px' }}
                  variant="primary"
                  onClick={closeCancelWarning}
                >
                  No, Return to Order
                </Button>
              </Alert>
            </Col>
          </Row>
          <CreateOrganizationModal
            show={showCreateOrgModal}
            toggle={() => setShowCreateOrgModal(false)}
            orgType={createOrgType}
            onCreateOrgSuccess={org => handleCreateOrgSuccess(org, setFieldValue)}
          />
          <CSVDropzoneModal
            show={showCSVModal}
            toggle={() => setShowCSVModal(false)}
            onDrop={acceptedFiles => handleCSVDrop(acceptedFiles, values.client_orgs.length ? values.client_orgs[0].id : null)}
            isLoading={isLoadingCSVValidate}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  orgs: state.session.orgs || []
});

export default connect(mapStateToProps, {})(ProjectViewerSalesOnboardingForm);
