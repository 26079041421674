import * as types from '../actions/actionTypes';

const initialState = {
  orgId: null,
  orgs: [],
  user: null,
  solarModule: null
};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ORG_ID:
      return {
        ...state,
        orgId: action.orgId
      };
    case types.LOAD_ORGS_SUCCESS:
      return Object.assign({}, state, {
        orgs: [...action.orgs]
      });

    case types.CREATE_ORG_SUCCESS:
      return {
        ...state,
        orgs: [...state.orgs, action.org]
      };

    default:
      return state;
  }
};
