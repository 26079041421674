import { Org } from '@raptormaps/raptor-models';
import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";


class OrgsApiClient {
  constructor() {
    this.api = new RestApiV2();
  }

  /**
   * @function loadOrgs - api_get_orgs
   * Gets all orgs from the database
   * @return {function(*=): Promise}
   */
  getOrgs = () => {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/raptor_admin/orgs')
        .then(res => {
          resolve(res.orgs.map(o => Org.initFromDBOrg(o)));
        })
        .catch(err => {
          reject(err);
          toastr.error('Error getting orgs');
        });
    });
  };

  getOrgAllowedList = (orgId) => {
    return new Promise((resolve, reject) => {
      const api = new RestApiV2(orgId);
      const params = {
        org_id: orgId,
      };
      api
        .get('org/allowed_emails', params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
          toastr.error('Error getting allowed emails');
        });
    });
  };
}

export default OrgsApiClient;
