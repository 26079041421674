import React, { Component } from "react";

// Raptor App
import { Container, Row, Button, ButtonGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestApiV2 from "../api/RestApiV2";

/**
 * @class TasksViewer -  Let's users with raptoradmin role see the Task Queue
 * @extends {Component}
 */
class TasksViewer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      task_flows: [],
      task_list: [],
      // number of tasks to get from the database
      n_tasks: 10
    };
    this.api = new RestApiV2();
  }

  componentDidMount() {
    this.getTasks(this.state.n_tasks);
  }

  /**
   * Gets last n_tasks from the database
   */
  getTasks = n_tasks => {
    this.api
      .get(`raptor_admin_tasks?n_tasks=${n_tasks}`)
      .then(res => {
        if (res["exit_status"] === 0) {
          let task_flows = res["task_flows"];

          // Concatenate all tasks into one large list of Objects.
          // and append the task flow status to each dictionary
          let task_list = Array.prototype.concat.apply(
            [],
            task_flows.map(tf =>
              tf.task_list.map(t => {
                return { task_flow_status: tf.status, ...t };
              })
            )
          );

          this.setState({
            task_flows: task_flows,
            task_list: task_list
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  setNTasks = n_tasks => {
    // update the list of tasks from the database and set state
    this.getTasks(n_tasks);
    this.setState({ n_tasks });
  };

  taskDetails = {
    renderer: row => (
      <div>
        <pre>{JSON.stringify(row, null, 2)}</pre>
      </div>
    )
  };

  taskColumns = () => {
    const timeFormatter = (cell, row) => {
      let d = new Date(0);
      d.setUTCSeconds(cell);
      return d.toLocaleString();
    };

    return [
      {
        dataField: "id",
        text: "Task ID",
        sort: true
      },
      {
        dataField: "name",
        text: "Task Name",
        sort: true
      },
      {
        dataField: "created_tsecs",
        text: "Created",
        sort: false,
        formatter: timeFormatter
      },
      {
        dataField: "completed_tsecs",
        text: "Completed",
        sort: false,
        formatter: timeFormatter
      },
      {
        dataField: "status",
        text: "Task Status",
        sort: true
      }
    ];
  };

  taskTable = {
    renderer: row => (
      <div>
        <BootstrapTable
          keyField="id"
          data={
            this.state.task_flows.find(item => item.id === row.id).task_list
          }
          columns={this.taskColumns()}
          expandRow={this.taskDetails}
        />
      </div>
    )
  };

  render() {
    const cloudwatchLinkFormatter = cell => {
      if (cell) {
        return (
          <a href={cell} target="_blank">
            Logs
          </a>
        );
      } else {
        return null;
      }
    };

    const taskFlowColumns = [
      {
        dataField: "id",
        text: "Group ID",
        sort: true
      },
      {
        dataField: "name",
        text: "Group Name",
        sort: false
      },
      {
        dataField: "user_id",
        text: "User",
        sort: false
      },
      {
        dataField: "location",
        text: "Location",
        sort: false
      },
      {
        dataField: "cloudwatch_link",
        text: "Cloudwatch",
        sort: false,
        formatter: cloudwatchLinkFormatter
      },
      {
        dataField: "status",
        text: "Status",
        sort: true
      }
    ];

    return (
      <div className="raptor-admin-view">
        <Container fluid>
          <Row>
            <h3>Worker Tasks</h3>
          </Row>
          <Row>
            <ButtonGroup aria-label="Show Number of Tasks">
              <Button onClick={() => this.setNTasks(10)}>10</Button>
              <Button onClick={() => this.setNTasks(100)}>100</Button>
              <Button onClick={() => this.setNTasks(1000)}>1000</Button>
              <Button onClick={() => this.setNTasks(this.state.n_tasks)}>
                Refresh
              </Button>
            </ButtonGroup>
          </Row>
          <Row>
            <div style={{width: '100%'}}>
              <BootstrapTable
                keyField="id"
                data={this.state.task_flows}
                columns={taskFlowColumns}
                expandRow={this.taskTable}
              />
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TasksViewer;
