import { SolarFarm, SolarInspection } from '@raptormaps/raptor-models';
import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";


export const AdminActionTypes = {
  GET_SOLAR_FARM_BY_UUID_SUCCESS: 'GET_SOLAR_FARM_BY_UUID_SUCCESS',
  GET_SOLAR_FARM_BY_UUID_ERROR: 'GET_SOLAR_FARM_BY_UUID_ERROR',
  LOAD_INSPECTIONS_FOR_FARM_SUCCESS: 'LOAD_INSPECTIONS_FOR_FARM_SUCCESS',
  LOAD_INSPECTIONS_FOR_FARM_FAILURE: 'LOAD_INSPECTIONS_FOR_FARM_FAILURE',
  GET_FILES_SUCCESS: 'GET_FILES_SUCCESS',
  GET_FILES_FAILURE: 'GET_FILES_FAILURE',
  CLEAR_SOLAR_FARM: 'CLEAR_SOLAR_FARM'
};

export const AdminActionCreator = {
  clearSolarFarm: () => ({
    type: AdminActionTypes.CLEAR_SOLAR_FARM
  }),
  getSolarFarmByUuidSuccess: solarFarm => ({
    type: AdminActionTypes.GET_SOLAR_FARM_BY_UUID_SUCCESS,
    solarFarm
  }),
  getSolarFarmByUuidError: () => ({
    type: AdminActionTypes.GET_SOLAR_FARM_BY_UUID_ERROR
  }),
  getSolarFarmByUuid: solarFarmUuid => {
    return function(dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`raptor_admin/solar_farms/${solarFarmUuid}`)
          .then(res => {
            const solarFarm = new SolarFarm().deserialize(res.solar_farm);
            dispatch(AdminActionCreator.getSolarFarmByUuidSuccess(solarFarm));
          })
          .catch(err => {
            reject(err);
            dispatch(AdminActionCreator.getSolarFarmByUuidError());
          });
      });
    };
  },
  getSolarFarmInspectionsSuccess: (farmUuid, inspections) => ({
    type: AdminActionTypes.LOAD_INSPECTIONS_FOR_FARM_SUCCESS,
    farmUuid,
    inspections
  }),
  getSolarFarmInspectionsError: () => ({}),
  /**
   * Gets all solar farm inspections
   * @param farmUuid {uuid} - solar farm uuid
   * @returns {function(*=, *=): Promise<unknown>}
   */
  getSolarFarmInspections: farmUuid => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`raptor_admin/solar_farms/${farmUuid}/inspections`)
          .then(res => {
            if (res.exit_status === 0) {
              const inspections = res.solar_inspections.map(ins =>
                new SolarInspection().deserialize(ins)
              );
              dispatch(AdminActionCreator.getSolarFarmInspectionsSuccess(farmUuid, inspections));
              resolve(inspections);
            }
          })
          .catch(err => {
            reject(err);
            dispatch(AdminActionCreator.getSolarFarmInspectionsError());
            toastr.err(err);
          });
      });
    };
  },
  getFilesForInspectionSuccess: (inspectionUuid, orderUuid, files) => ({
    type: AdminActionTypes.GET_FILES_SUCCESS,
    inspectionUuid,
    orderUuid,
    files
  }),
  getFilesForInspectionFailure: () => ({
    type: AdminActionTypes.GET_FILES_FAILURE
  }),
  /**
   * Gets the files associated with a specific inspection
   */
  getFilesForInspection: (inspectionUuid, orderUuid) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`raptor_admin/solar_inspections/${inspectionUuid}/files`)
          .then(res => {
            dispatch(
              AdminActionCreator.getFilesForInspectionSuccess(inspectionUuid, orderUuid, res.files)
            );
            resolve(res.files);
          })
          .catch(err => {
            reject(err);
            dispatch(AdminActionCreator.getFilesForInspectionFailure());
          });
      });
    };
  }
};

/**
 * getFarmOrgUsers - called when user or farm is selected. Sets hook used for table data
 */
export const getFarmOrgUsers = (solarFarmUuid, userId) => {
  // if neither is selected, set table data empty
  return new Promise((resolve, reject) => {
    const api = new RestApiV2();
    api
      .get('raptor_admin/solar_farm_org_users', {
        ...(solarFarmUuid ? { solar_farm_uuid: solarFarmUuid } : {}),
        ...(userId ? { user_id: userId } : {})
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * getFarms - used by typeahead to get list of farms that match search key
 * @param {string} farmName
 */
export const getFarms = farmName => {
  return new Promise((resolve, reject) => {
    const api = new RestApiV2();
    api
      .get('raptor_admin/solar_farms', { search_key: farmName })
      .then(res => {
        resolve(res['solar_farms']);
      })
      .catch(err => reject(err));
  });
};

/**
 * getUsers - used by typeahead to get list of users that match search type
 * @param {string} userEmail
 */
export const getUsers = userEmail => {
    return new Promise((resolve, reject) => {
      const api = new RestApiV2();
      api.get('raptor_admin/users', { search_key: userEmail }).then(res => {
      resolve(res['users'])
  }).catch(err=>reject(err))
})
};
