/* eslint-disable no-undef */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, FormCheck, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderActionCreator } from '../../actions/orderActions';
import { orderValuesets } from './util/constants';

const ServiceEdit = ({show, order, services, toggle, add, remove, orderActions}) => {
  // Create generic state to store the values selected in the form
  const [formValues, setFormValues] = useState({});
  const [originalUuids, setOriginalUuids] = useState([])

  const handleInitialFormValues = () => {
    // Set the inital form values based on the services initially attached to the order before the modal is opened
    setFormValues(
      order.services && order.services.reduce( (acc, curr) => {
        acc[curr['uuid']] = curr;
        return acc;
      }, {})
    );
    // Keep track of the original so we know which services are to be removed or added
    setOriginalUuids(order.services && order.services.map(item => item.uuid));
  }
  
  useEffect(() => {
    // Use the list of service uuids to prepopulate checkboxes
    
    handleInitialFormValues();
  }, [services]);
  /**
   * Adds the service values from state to the order services
   * Uses the original uuids from the services attached to the order to
   * determine which services selected in the form are newly added and which
   * of the originals are no longer present and need to be removed
   */
  const handleUpdate = () => {
    const orderCopy = _.cloneDeep(order);

    // All uuids from the checkboxes
    const newServices = Object.values(formValues);
    const newServicesUuids = newServices.map(item => item.uuid);
    orderCopy.services = newServices;

    // Uuids to be added
    const uuidsToRemove = originalUuids.filter(uuid => !newServicesUuids.includes(uuid))
    
    // Remove the other uuids that are no longer on the order
    uuidsToRemove.map(removedItemUuid => remove(orderCopy, removedItemUuid));
    // Add the new uuids
    add(orderCopy);
    toggle();
  }
  /**
   * Handles clicking of checkboxes which either adds, or removes a service from the order
   * @param {*} service 
   */
  const handleCheckClick = service => {
    if(formValues[service.uuid]) {
      // Service is already requested, remove it
      
      const formValuesWithoutType = Object.values(formValues).reduce((acc,curr) => {
        if(curr['uuid'] !== service.uuid) {
          acc[curr['uuid']] = curr;
        }
        return acc; 
      }, {});
      setFormValues(formValuesWithoutType || {});
    } else {
      // Service needs to be added to the order
      setFormValues({...formValues, [service.uuid]: service});
    }
  }
  /**
   * Gets the services for the order type then populates the values
   */
  const handleModalShow = () => {
    const invertedValueset = _.invert(orderValuesets['order_type']);
    const nonDisplayValue = invertedValueset[order.order_type];
    
    orderActions.getAllServices(nonDisplayValue)
  }
  return (
    <Modal
      show={show}
      centered
      onShow={handleModalShow}
    >
      <Modal.Header>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
          <Modal.Title>{`Add Service`}</Modal.Title>
          <FontAwesomeIcon
            className="rm-close-modal"
            icon={faTimes}
            onClick={() => toggle()}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {services && services.map(service => 
          (
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', padding: '5px'}}>
              <span>{service.name}</span>
              <FormCheck 
                checked={formValues.hasOwnProperty(service.uuid)} 
                onChange={() => handleCheckClick(service)}
              /> 
            </div>
          ) 
        )}
        </div>
        <Button onClick={() => handleUpdate()}>
          Save
        </Button>
      </Modal.Body> 
    </Modal>
  );
}

const mapStateToProps = (state, props) => ({
  services: state.order && state.order.services || []
})
const mapDispatchToProps = dispatch => {
  return {
    orderActions: bindActionCreators(OrderActionCreator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEdit);