import React from 'react';
import { ModalTitle } from 'react-bootstrap';

import { CancelOrderModalTitle } from '../../styles/OrderStatus_styles';

const CancelModalTitle = ({ isOrderCancelled }) => {
  return (
    <ModalTitle>
      {isOrderCancelled ? (
        <CancelOrderModalTitle>
          Caution: Are you sure you want to restore this cancelled order?
        </CancelOrderModalTitle>
      ) : (
        <CancelOrderModalTitle>
          Caution: Are you sure you want to cancel this order?
        </CancelOrderModalTitle>
      )}
    </ModalTitle>
  );
};

export default CancelModalTitle;