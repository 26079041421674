import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { faArrowDown, faArrowUp, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { orderTableTabs } from './constants';

/**
 * Gets inspected power of inspections on an order
 * @param {*} order {Order}
 */
export const getPowerInspected = order => {
  return order && order.inspections && order.inspections.map(i => i.power_inspected).join(', ');
};

/**
 * Safely get the org name associated to the order
 */
export const getOrgName = order => {
  if (order && order.org && order.org.name) {
    return order.org.name;
  }
  return '';
};

/**
 * Date -> tsecs
 */
export const getTsecsFromDate = date => {
  return moment(date).unix();
};

/**
 * Tsecs -> date
 */
export const getDateStringFromTsecs = epochSeconds => {
  if (epochSeconds) {
    return moment.unix(epochSeconds).format('MM/DD/YYYY');
  }
  return 'None';
};

/**
 * Gets the farm size from an order and all solar farms
 * @param solarFarm {SolarFarm}
 * @returns {*}
 */
export const getFarmSize = solarFarm => {
  return (solarFarm && solarFarm.size) || '';
};

export const getFarmBuilderLink = solarFarmId => {
  const url = window.REACT_APP_FARMBUILDER_URL;
  return url + '/site/' + solarFarmId;
};



export const getReportViewerLink = solarInspectionUuid => {
  let url = window.REACT_APP_CUSTOMER_FE_URL
  return url + '/solar-report/' + solarInspectionUuid;
};

/**
 * @param orders - list of orders to filter
 * @param {field: string, value: string} filter - filter to apply
 */
export const applyOrderSearchFilter = (filter, orders) => {
  if (filter.field && filter.value) {
    return orders.filter(order => {

      if (order[filter.field] && typeof order[filter.field] === 'string') {
        const lowerCaseOrderField = order[filter.field].toLowerCase();
        const lowerCaseFilterValue = filter.value.toLowerCase();
        return lowerCaseOrderField.includes(lowerCaseFilterValue)
      } else if (order[filter.field] && typeof order[filter.field] === 'number') {
        const stringFieldValue = order[filter.field].toString();
        return stringFieldValue.includes(filter.value);
      }
      return false;
    })
  }
  return orders;
}

/**
 * Returns the columns for the order status tables
 * @param {*} changePriority - Function to update the priority of a single order row
 * @param {*} activeTab - The currently open tab in the order status table
 * @param {*} expandedColumns - Only included in multi table, list of expanded status rows
 * @param {*} handleSort - Only included in Multitable, cb to be executed on table sort
 */
export const getStatusColumns = (changePriority, activeTab, expandedColumns=[], handleSort=()=>{}) => {
  return [
  {
    dataField: 'tableStatus',
    formatter: (cell, row, _, expandedColumns) => {
      if (row.tableStatus) {
        return (
          <>
            <FontAwesomeIcon icon={expandedColumns.includes(row.tableStatus) ? faArrowDown : faArrowUp} />
            &nbsp;
            &nbsp;
            {cell}
          </>
        )
      } else {
        return (
          <div className='order-priority-edit'>
            <Button 
              variant="order-priority" 
              onClick={(e) => {
                e.stopPropagation();
                changePriority(row, true)}
              }
              style={{backgroundColor: 'white'}}
            >
              <FontAwesomeIcon icon={faAngleUp} style={{height: '20px'}}/>
            </Button>
            <Button 
              variant="order-priority" 
              onClick={(e) => {
                e.stopPropagation();
                changePriority(row, false)
              }}
              style={{backgroundColor: 'white'}}
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </Button>
          </div> 
        )
      }
      
    },
    formatExtraData: expandedColumns,
    style: () => ({ width: '120px' }),
    headerStyle: () => ({ width: '120px' }),
    hidden: activeTab !== orderTableTabs.ONGOING
  },
  {
    dataField: 'id',
    text: 'Order ID',
    sort: true,
    style: () => ({ width: '120px' }),
    headerStyle: () => ({ width: '120px' }),
    onSort: handleSort,
    sortFunc: (a, b, order) => {
      // Use the tracking number to sort, ignore hold sign component
      if (a.props && a.props.children && b.props && b.props.children) {
        if (order === 'asc') {
          return a.props.children[0] - b.props.children[0];
        }
        return b.props.children[0] - a.props.children[0];
      }
      return 0;
    }
  },
  {
    dataField: 'uuid',
    hidden: true
  },
  {
    dataField: 'farm_name',
    text: 'Farm Name',
    sort: true,
    onSort: handleSort,

  },
  {
    dataField: 'client_org',
    text: 'Client Org',
    editable: false,
    sort: true,
    onSort: handleSort,
  },
  {
    dataField: 'turnkey_services',
    text: 'Turnkey',
    headerStyle: () => ({ width: '80px' }),
    style: () => ({ width: '80px' }),
    formatter: cell => {
      if (cell === undefined) {
        return '';
      } else if (cell) {
        return 'Y';
      } else {
        return 'N';
      }
    }
  },
  {
    dataField: 'power_inspected',
    text: 'Power Inspected',
    sort: true,
    onSort: handleSort,
    sortFunc: (a, b, order) => {
      const numA = parseFloat(a);
      const numB = parseFloat(b);

      if (order === 'asc') {
        return numA - numB;
      }
      return numB - numA;
    }
  },
  {
    dataField: 'status',
    text: 'Order Status',
    style: () => ({ width: '150px' }),
    headerStyle: () => ({ width: '150px' }),
  },
  {
    dataField: 'priority',
    text: 'Priority',
    sort: true,
    headerClasses: 'order-table-priority',
    style: () => ({ width: '120px' }),
    headerStyle: () => ({ width: '120px' }),
    hidden: activeTab !== orderTableTabs.ONGOING
  },
  {
    dataField: 'internal_comments',
    text: 'Internal Comments',
    style: () => ({ width: '250px' }),
    headerStyle: () => ({ width: '250px' }),
  },
  {
    dataField: 'data_received_tsecs',
    text: 'Data Received',
    sort: true,
    onSort: handleSort,
    sortFunc: (a, b, order) => {
      const dateA = moment(a);
      const dateB = moment(b);

      if (order === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    },
    hidden: ![orderTableTabs.ONGOING, orderTableTabs.COMPLETED, orderTableTabs.CANCELLED].includes(activeTab)
  },
  {
    dataField: 'delivered_tsecs',
    text: 'Delivered Date',
    sort: true,
    onSort: handleSort,
    sortFunc: (a, b, order) => {
      const dateA = moment(a);
      const dateB = moment(b);

      if (order === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    },
    hidden: activeTab !== orderTableTabs.COMPLETED
  }
]
};

export const EventStatusById = ['pending', 'approved', 'canceled', 'on-hold'];

export const EventStatus = {
  "PENDING": 'pending',
  "APPROVED": 'approved',
  "CANCELED": 'canceled',
  "ONHOLD": 'on-hold',
}

export const EventType = {
  "flightWindow": 2,
  "flightDate": 1
}

export const EventStatusNumber = {
  "pending": 1,
  "approved": 2,
  "canceled": 4,
  "on-hold": 3
}

export const getEventStatusView = (eventStatusId) => {
  switch (EventStatusById[eventStatusId]) {
    case EventStatus.PENDING:
      return 'Pending';
    case EventStatus.ONHOLD:
      return 'On-Hold';
    case EventStatus.CANCELED:
      return 'Canceled';
    case EventStatus.APPROVED:
      return 'Approved';
    default:
      return '';
  }
};

export const handleDisabledDates = ({ startTsecs = null, endTsecs = null }) => {
  if (startTsecs !== null && endTsecs !== null) {
    const startDate = getDateStringFromTsecs(startTsecs);
    const endDate = getDateStringFromTsecs(endTsecs);
    return [
      { start: new Date(startDate), end: new Date(endDate) },
    ];
  }
  return [];
};

export const reasonForChange = [
  "Weather",
  "Government Approval",
  "Pilot availability",
  "Site not ready/site down",
  "Other work scheduled",
  "Access approval",
  "Insufficient notice",
  "Other (Specify)",
];