
import logo from "../static/img/admin.png";

/** @class RaptorAdminHome - Lets raptoradmin user have access to admin tasks */
const RaptorAdminHome = () => {
  return (
    <div className="center">
      <h1 style={{ paddingTop: "40px" }}>ADMIN MODE</h1>
      <img src={logo} alt="" />
    </div>
  );
};
export default RaptorAdminHome;
