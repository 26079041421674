// @ts-nocheck
// App.js
import React, { FunctionComponent } from 'react';

// Import React Table
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';

import { DefaultColumnFilter, fuzzyTextFilterFn, GlobalFilter } from './Filters';
import { RaptorTable, TableBody, TableHeader } from './styles/table_styles';

// Icons
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../Pagination';

// Create a default prop getter
const defaultPropGetter = () => ({})

interface ITableContainerProps {
  data: any,
  columns: any,
  paginated: boolean,
  showGlobalFilter: boolean,
  onPageChange?: any,
  currentPage?: number,
  pageSize?: number,
  setPageSize?: any,
  getHeaderProps?: any,
  getRowProps?: any,
  noNextPage?: boolean
}

const TableComponent: FunctionComponent<ITableContainerProps> = ({
  columns,
  data,
  paginated = false,
  showGlobalFilter,
  onPageChange,
  currentPage,
  pageSize,
  setPageSize,
  noNextPage,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
}) => {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  )

  return (
    <>
      <RaptorTable {...getTableProps()}>
        <TableHeader>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th>
                  <span>{column.render('Header')}</span>
                  <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {<FontAwesomeIcon icon={column.isSortedDesc ? faChevronDown : faChevronUp} />}
                  </span>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
          {showGlobalFilter && (
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: 'left'
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          )}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              //@ts-ignore
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map(cell => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </TableBody>
      </RaptorTable>
      {paginated && (
        <Pagination
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          noNextPage={noNextPage}
        />
      )}
    </>
  );
};

export default TableComponent;
