import React from 'react';

import { StatusDisplay } from './shared';

import { Button } from 'react-bootstrap';

import { orderValuesets, orderStatuses } from '../../../../util/constants';

/**
 * SubmittedDisplaySection - Presentation component for submitted status
 * @param {*} status - status of order
 * @param {[]} nextStatus - array that holds possible next statuses
 * @param {*} updateOrderStatus - function to update order status
 * @param {*} togglePermissionsModal - opens modal to update order status
 */
const SubmittedDisplaySection = ({
  status,
  nextStatus,
  updateOrderStatus,
  togglePermissionsModal,
  setNextStatus
}) => {
  let displayStatus = orderValuesets.status[status];
  let displayNextStatus = orderValuesets.status[nextStatus];
  return (
    <>
      <div>
        <StatusDisplay label="Current Status" status={displayStatus} />
        <div style={{ fontWeight: 'bold' }}>Next Statuses:</div>
      {nextStatus.map(ns =><div> {orderValuesets.status[ns]}</div>)}
      </div>

      <div>
        {//map through next status array, open warning modal if going to dispatch
        nextStatus.map(ns => (
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
            togglePermissionsModal()
            setNextStatus(ns)
            }}
          >{`Move to ${orderValuesets.status[ns]}`}</Button>
        ))}
      </div>
    </>
  );
};

export default SubmittedDisplaySection;
