import React from 'react';
import './Switch.css';

/**
 * An IOS like toggle switch. Supports different colors for its enabled state.
 * @param {bool} isOn Enabled property of the switch
 * @param {Callable} handleToggle Method that is fired when a user toggles the switch
 * @param {string} onColor Hexcode of the color to display when the switch is toggled on
 * @param {number} index # HACK unique index used to generate a unique id for the input label.
 * @param {string} value Optional object argument to store the value assigned to the toggle.
 */
const Switch = ({ isOn, handleToggle, onColor, index, value = '' }) => {
  return (
    <div>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new-${index}`}
        type="checkbox"
        value={value}
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new-${index}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;
