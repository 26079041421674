import { SolarFarm } from '@raptormaps/raptor-models';
import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";

class RaptorAdminRoutesApiClient {
  constructor() { }

  /**
   * Creates a new org
   * @param orgName {string} -
   * @param orgType {ORG_TYPES} -
   * @param masterEmail {string} -
   * @returns {function(*=): Promise}
   */
  createOrg = (orgName, orgType, masterEmail) => {
    return new Promise((resolve, reject) => {
      const data = {
        org_name: orgName,
        org_type: orgType,
        master_email: masterEmail
      };

      const api = new RestApiV2(null, `${window.REACT_APP_API_ENDPOINT}/api/v2`);

      console.log(data);

      api
        .post('/raptor_admin_create_organization', data)
        .then(res => {
          resolve(res.org);
          toastr.success('Successfuly created org, an email was sent to ' + masterEmail);
        })
        .catch(err => {
          reject(err);
          toastr.error(err);
          console.log(err);
        });
    });
  };

  /**
   * Post request to submit sales onboarding form
   * @param formData {object} -
   * @returns {Promise}
   */
  submitSalesOnboarding = formData => {
    return new Promise((resolve, reject) => {
      const data = formData;

      const api = new RestApiV2(null, `${window.REACT_APP_API_ENDPOINT}/api/v2`);

      api
        .post('/submit_sales_onboarding', data)
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };

  /**
   * Post request to validate data from csv upload
   * in sales onboarding form
   * api_validate_sales_onboarding_data
   * @param data {object} - csv json data object
   * @param client_org_id {number} - client org
   * @returns {Promise}
   */
  validateSalesOnboardingData = (data, client_org_id) => {
    return new Promise((resolve, reject) => {
      const api = new RestApiV2(null, `${window.REACT_APP_API_ENDPOINT}/api/v2`);
      api
        .post('/validate_sales_onboarding_data', { order_list: data, client_org_id })
        .then(res => {
          // Cast the solar farms to front end models
          const updatedOrderList = res.validated_order_list.map(order => {
            order.solar_farm = new SolarFarm().deserialize(order.solar_farm);
            order.solar_farm.latitudeEditing = String(order.solar_farm.location.lat);
            order.solar_farm.longitudeEditing = String(order.solar_farm.location.lng);
            return order;
          });
          resolve(updatedOrderList);
        })
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };
}

export default RaptorAdminRoutesApiClient;
