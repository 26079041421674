import styled, { css } from 'styled-components';

export const PaginationContainer = styled.div.attrs(() => ({
  className: 'pagination_container'
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  align-items: center;
`

export const PaginationItem = styled.div.attrs(() => ({
  className: 'pagination_item'
}))`
  margin: 0 10px;
  font-weight: 600;
`;

export const PaginationArrow = styled.span.attrs(() => ({
  className: 'pagination_arrow'
}))`
  cursor: pointer;
`;

export const ActivePage = styled.span.attrs(() => ({
  className: 'pagination_activepage'
}))`
    border: 0px;
    padding: 5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.GlobalColor.rOrange1};
    color: ${({ theme }) => theme.colors.GlobalColor.rWhite};
`;

export const GoToPage = styled.div.attrs(() => ({
  className: 'pagination_GoToPage'
}))`
  span{
    margin: 0 5px;
  }
`;

export const SelectPage = styled.input.attrs(() => ({
  className: 'pagination_selectpage'
}))`
    border: solid 2px;
    border-radius: 5px;
    padding: 0 5px;
    width: 50px;
`;

export const PaginationSelect = styled.select.attrs(() => ({
  className: 'pagination_select'
}))`
  border-radius: 5px;
  border: solid 2px ${({ theme }) => theme.colors.GlobalColor.rBlack1};
  font-weight: 500;
`