import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Col, Container, Row } from 'react-bootstrap';

import { accountingItemTypes } from '@raptormaps/raptor-lib';
import { OrderActionCreator } from '../../../../actions/orderActions';

import OrderFinancialsEditRow from './OrderFinancialsEditRow';
import OrderFinancialsTable from './OrderFinancialsTable';

import { Button, Form } from 'react-bootstrap';

/**
 * Allows the user to view, add, and remove a solar order's accounting info items.
 * Can toggle the row to add an item by clicking plus icon. Can select an existing row
 * and hit the delete icon to remove from order
 *
 * @param order {Order}
 * @param orderActions {object}
 * @param accountingInfoItems {object} - accounting info items keyed on internal_code
 * @returns {*}
 * @constructor
 */
const OrderFinancialsTab = ({ order, orderActions, accountingInfoItems }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [editFormOpen, setEditFormOpen] = useState(false);

  useEffect(() => {
    orderActions.getAccountingInfoItems();
  }, []);

  /**
   * Sets or un-sets the selectedItem to internal_code when a row is clicked
   * @param internalCode {string}
   */
  const handleRowClick = internalCode => {
    setSelectedItem(selectedItem => (selectedItem === internalCode ? null : internalCode));
  };

  /**
   * De selects the selectedItem and toggles the edit row to be open
   */
  const handleAddItemClick = () => {
    // eslint-disable-next-line no-undef
    mixpanel.track('Order add revenue/expense item');
    setSelectedItem(null);
    setEditFormOpen(editFormOpen => !editFormOpen);
  };

  /**
   * Builds the solar order accounting info item and adds it to
   * the order. Fires off action to make api call
   * @param values {object} - form values from edit row
   */
  const onAddAccountingInfoItem = values => {
    const solarOrderAccountingInfoItem = {
      order_uuid: order.uuid,
      accounting_item_codekey: values.internal_code,
      item_type: values.item_type,
      name: values.name,
      description: '',
      status: 0,
      amount: values.amount
    };

    const updatedOrder = {
      ...order,
      accounting_info_items: [...order.accounting_info_items, solarOrderAccountingInfoItem]
    };

    orderActions.addOrderAccountingInfoItem(updatedOrder);
  };

  /**
   * Calls action to remove accounting item from the order. On success,
   * deselects the selected item.
   */
  const onRemoveAccountingInfoItem = () => {
    orderActions
      .removeOrderAccountingInfoItem(order.uuid, selectedItem)
      .then(() => setSelectedItem(null));
  };

  /**
   * Gets the table data, filters by item type
   * @param itemType {object} - REVENUE or EXPENSE
   * @returns {T[]|Array}
   */
  const getTableData = itemType => {
    if (order && order.accounting_info_items) {
      const accountingInfoItems = order.accounting_info_items.filter(i => i.item_type === itemType);
      // Add a Total value
      if (accountingInfoItems && accountingInfoItems.length > 1) {
        const total = accountingInfoItems.reduce((acc, curr) => acc + curr.amount, 0
        )
        accountingInfoItems.push({accounting_item_codekey: 'TOTAL', name: 'Total', amount: total })
      }
      return accountingInfoItems;
    } else return [];
  };

  /**
   * Returns an array of the accounting info items that have not yet
   * been added to the order.
   * @returns {T[]|Array}
   */
  const getAvailableAccountingInfoItems = () => {
    if (accountingInfoItems) {
      const orderAccountingInfoItems = (order && order.accounting_info_items) || [];
      return Object.values(accountingInfoItems).reduce((acc, curr) => {
        if (!orderAccountingInfoItems.find(i => i.accounting_item_codekey === curr.internal_code)) {
          return acc.concat(curr);
        } else return acc;
      }, []);
    }
  };

  return (
    <Container>
      <Accordion defaultActiveKey="0" activeKey={editFormOpen ? '0' : null}>
        <Row style={{ marginTop: '1rem' }}>
          <Col sm={12} style={{ textAlign: 'right' }}>
            <FontAwesomeIcon
              style={{ marginRight: '1rem', color: 'green' }}
              className="icon-button"
              icon={faPlusCircle}
              onClick={handleAddItemClick}
            />
            &nbsp;
            <FontAwesomeIcon
              style={{ color: 'red' }}
              className={!selectedItem ? 'disabled-icon-button' : 'icon-button'}
              icon={faTrash}
              onClick={() => selectedItem && onRemoveAccountingInfoItem()}
            />
          </Col>
        </Row>
        <Accordion.Collapse eventKey="0">
          <OrderFinancialsEditRow
            accountingInfoItems={getAvailableAccountingInfoItems()}
            onSubmit={onAddAccountingInfoItem}
          />
        </Accordion.Collapse>
      </Accordion>
      <Row>
        <Col xs={12}>
          <h5>Revenue</h5>
          <OrderFinancialsTable
            selectedItem={selectedItem}
            onSelectRow={handleRowClick}
            data={getTableData(accountingItemTypes.REVENUE)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col xs={12}>
          <h5>Expenses</h5>
          <OrderFinancialsTable
            selectedItem={selectedItem}
            onSelectRow={handleRowClick}
            data={getTableData(accountingItemTypes.EXPENSE)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form
            onSubmit={e => {
              e.preventDefault();
              orderActions.updateOrder({
                ...order,
                accounting_invoice_number: e.target.elements.accounting_invoice_number.value
              });
            }}
            style={{ display: 'inline-flex' }}
          >
            <Form.Group>
              <Form.Label>Invoice No.</Form.Label>
              <Form.Control
                type="text"
                name="accounting_invoice_number"
                defaultValue={order.accounting_invoice_number}
              />
            </Form.Group>
            <Button style={{ width: '300px', height: '38px', marginTop: '32px' }} type="submit">
              Save Invoice Number
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch)
});

const mapStateToProps = state => ({
  accountingInfoItems: state.order.accountingInfoItems
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFinancialsTab);
