/* eslint-disable no-undef */
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AdminActionCreator } from '../../actions/adminActions';
import { OrderActionCreator } from '../../actions/orderActions';
import OrderModal from './OrderModal';
import { getDateStringFromTsecs, getOrgName, getStatusColumns } from './util';
import {
  instructionStatuses, orderValuesets
} from './util/constants';

import { useDispatch } from 'react-redux';
import { useApi } from '../../hooks/useApi';
import { SolarFarmsApi, SolarOrdersApi, SolarOrderToJSON, SolarFarmToJSON, AdminApi } from '@raptormaps/raptor-internal-client-ts';



/**
 * Displays a table of orders that supports drill down into and individual order
 */
const OrderTable = ({
  orders,
  editable,
  orderActions,
  adminActions,
  limitHeight,
  orgs,
  sort,
  activeTab,
  onOrderModalClose,
  onOrderModalOpen,
  showHeader=true
}) => {
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedOrderUuid, setSelectedOrderUuid] = useState(null);

  const orderApi = useApi(SolarOrdersApi, {
    basePath: window.REACT_APP_API_ENDPOINT_V2
  })

  const adminApi = useApi(AdminApi, {
    basePath: window.REACT_APP_API_ENDPOINT_V2
  })

  const dispatch = useDispatch();

  /**
   * Returns the site size from the inspections on an order
   * Multiple site sizes will be returned as a single string with all inspection power inspected values
   */
  const getInspectionsPower = order => {
    return (
      order &&
      order.inspections &&
      order.inspections
        .map(i => i.power_inspected)
        .reduce((acc, curr) => (acc = acc + curr + ' '), '')
    );
  };

  /**
   * If order on hold, display with a pause sign next to the tracking number
   * @param {} order
   */
  const getTrackingNumber = order => (
    <>
      {order.id}
      &nbsp; &nbsp;
      {order.processing_hold && <FontAwesomeIcon icon={faPauseCircle} style={{ color: 'red' }} />}
    </>
  );
  /**
   * Format the order data for react-bootstrap table
   */
  const formatOrderData = (orders) => {
    return orders.map(order => {
      return {
        ...order,
        id: getTrackingNumber(order),
        org_name: getOrgName(order),
        farm_name: order && order.solar_farm_name,
        power_inspected: getInspectionsPower(order),
        status: orderValuesets.status[order.status],
        priority: order.priority,
        internal_comments: order.internal_comments,
        data_received_tsecs: getDateStringFromTsecs(order.data_received_tsecs),
        delivered_tsecs: getDateStringFromTsecs(order.delivered_tsecs),
        created_tsecs: getDateStringFromTsecs(order.created_tsecs)
      };
    });
  };

  /**
   * Restricts row priority update between 0 and 10
   */
  const updateRowPriority = (row, increment) => {
    const order = orders.find(o => o.uuid === row.uuid);
    const orderCopy = _.cloneDeep(order);
    
    if (increment) {
      if (row.priority < 10) {
        orderCopy.priority++;
        orderActions.updatePriority(orderCopy, increment);
      }
    } else {
      if (row.priority > 0) {
        orderCopy.priority--;
        orderActions.updatePriority(orderCopy, increment);
      }
    }
  }

  /**
   * Gets the style for a row dependent on if there are channel instructions in the order for the row that require assistance
   * @param {*} row
   * @param {*} rowIndex
   */
  const getRowClass = (row, rowIndex) => {
    if (row.channel_instructions) {
      const instructionWithAssistanceNeeded = row.channel_instructions.find(
        instruction => instruction.status === instructionStatuses['Assistance needed']
      );
      if (instructionWithAssistanceNeeded) {
        return 'order-row-alert';
      }
    }
    return 'order-row';
  };
  /**
   * Colors the row based on the PM user color
   * @param {*} row
   */
  const getRowStyle = row => {
    if (row.color) {
      return {background: row.color};
    }
    return {};
  }
  const rowEvents = {
    onClick: (e, row) => {
      mixpanel.track('Open order edit');

      const tableSelectedOrder = orders.find(order => order.uuid === row.uuid);
      setSelectedOrderUuid(tableSelectedOrder.uuid);

      orderApi
      .getOrderByUuid({ order_uuid: tableSelectedOrder.uuid })
      .then((res) => {
        const loadedOrder = SolarOrderToJSON(res.order);
        dispatch(OrderActionCreator.updateOrderSuccess(loadedOrder));
        if (loadedOrder.solar_farm_uuid) {
          adminApi.getFarmWithInspections({ farm_uuid: loadedOrder.solar_farm_uuid})
          .then(res => {
            const solarFarm = SolarFarmToJSON(res.solar_farm);
            dispatch(AdminActionCreator.getSolarFarmByUuidSuccess(solarFarm));
          })
          .catch(err => {
            dispatch(AdminActionCreator.getSolarFarmByUuidError());
          });
        }
      })
      .catch((err) => {
        orderActions.updateOrderFailure();
        toastr.error(err);
      });
      
      
      if (onOrderModalOpen) {
        onOrderModalOpen()
      }
      setShowOrderModal(true);
    }
  };
  return (
    <div className="order-table-container">
      {orders && orders.length > 0 && (
        <BootstrapTable
          keyField="uuid"
          classes={showHeader ? '' : 'hide-order-header'}
          wrapperClasses={limitHeight ? 'order-table-wrapper-limited' : 'order-table-wrapper'}
          headerClasses={showHeader ? 'multitable-header' : ''}
          bordered
          bootstrap4
          data={formatOrderData(orders)}
          columns={getStatusColumns(updateRowPriority, activeTab)}
          rowEvents={rowEvents}
          rowStyle={getRowStyle}
          sort={sort}
        />
      )}
      {selectedOrderUuid && (
        <OrderModal
          orderUuid={selectedOrderUuid}
          orderActions={orderActions}
          show={showOrderModal}
          toggle={() => {
              setSelectedOrderUuid(null);
              adminActions.clearSolarFarm();
              setShowOrderModal(showOrderModal => !showOrderModal);
          }}
          onOrderModalClose={onOrderModalClose}
          editable={editable}
          orgs={orgs}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch),
  adminActions: bindActionCreators(AdminActionCreator, dispatch)
});
export default connect(null, mapDispatchToProps)(OrderTable);
