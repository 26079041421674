import * as Yup from 'yup';

const isRequiredString = ' is required!';

const schema = Yup.object({
  client_orgs: Yup.array()
    .required('Client org' + isRequiredString)
    .nullable(),
  client_email_list: Yup.array().required('Customer email list' + isRequiredString),
  project_manager_user_id: Yup.number().nullable(true),
  signed_proposal_link: Yup.string()
    .max(1000, 'Link too long!')
    .required('Proposal link' + isRequiredString)
    .url('Link to Signed Proposal must be a valid URL')
    .nullable(),
    hubspot_deal_url: Yup.string()
    .max(1000, 'Link too long!')
    .required('Hubspot link' + isRequiredString)
    .url('Link to Hubspot Deal must be a valid URL')
    .nullable(),
  other_context: Yup.string()
    .max(5000, 'Too long!')
    .nullable(),
  project_name: Yup.string()
    .max(100, 'Too long!')
    .required('Project name' + isRequiredString)
    .nullable(),
  dsp_contract_link: Yup.string()
    .max(1000, 'Too long!')
    .nullable()
});

export default schema;
