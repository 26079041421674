import { SolarFarm } from '@raptormaps/raptor-models';
import RestApiV2 from "../api/RestApiV2";

class SolarFarmsApiClient {
  constructor() {
    this.base_url = '/solar_farms';
    this.api = new RestApiV2();
  }

  /**
   * Gets solar farms by org id
   * @param orgId {number} - org id
   * @param searchKey {string} - farm name search key
   * @param limit {number} - number of results to return
   * @returns {Promise}
   */
  getByOrgId = (orgId, searchKey, limit) => {
    return new Promise((resolve, reject) => {
      const params = {
        org_id: orgId,
        search_key: searchKey,
        limit: limit
      };

      this.api
        .get(this.base_url, params)
        .then(res => {
          const farms = res.solar_farms.map(sf => new SolarFarm().deserialize(sf));
          resolve(farms);
        })
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };

  /**
   * Gets all solar farms
   * @returns {Promise}
   */
   getAllSolarFarms = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get(this.base_url + '/all', {})
        .then(res => {
          const farms = res.solar_farms.map(sf => new SolarFarm().deserialize(sf));
          resolve(farms);
        })
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };  
}

export default SolarFarmsApiClient;
