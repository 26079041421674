import { useEffect, useState } from 'react';

import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SolarFarm } from '@raptormaps/raptor-models';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import OrdersApiClient from '../../apiClient/OrdersApiClient';
import SolarFarmsApiClient from '../../apiClient/SolarFarmsApiClient';
import { SALES_PROJECT_STATUS } from './constants';
import ProjectViewerFarmOrdersTable from './ProjectViewerFarmOrdersTable';

const FARM_SEARCH_LIMIT = 10;

/**
 * Component holding state for the farm orders list table.
 * Handles farm searched
 *
 * @param orderList {[{}]} - list of order objects
 * @param setOrderList {function} -
 * @param clientOrgId {number} -
 * @param defaultValues {object} - default form values
 * @returns {*}
 * @constructor
 */
const ProjectViewerFarmOrdersComponent = ({
  orderList,
  setOrderList,
  clientOrgId,
  defaultValues,
  activeStatus,
  disableOnReviewStatus,
  disableWhenStatusNotReview,
  disableOnCancelledStatus
}) => {
  const [farmsList, setFarmsList] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [selectedAddFarm, setSelectedAddFarm] = useState(null);
  const [selectedSwapFarmInd, setSelectedSwapFarmInd] = useState(null);
  const [newFarmName, setNewFarmName] = useState('');
  const [loadingFarmName, setLoadingFarmName] = useState(false);
  const [selectedFarmContact, setSelectedFarmContact] = useState(null);

  /**
   * Pull the latest contact from the farm if existing farm is selected
   */
  useEffect(() => {
    if (!selectedAddFarm || !selectedAddFarm.uuid) {
      setSelectedFarmContact(null);
      return;
    }
    const api = new OrdersApiClient();
    api.getLatestContact(selectedAddFarm.uuid).then(res => {
      setSelectedFarmContact(res.uuid);
    });
  }, [selectedAddFarm, selectedSwapFarmInd]);

  /**
   * Appends a new entry to the table list
   */
  const handleAddOrderListItem = (solarFarmUuid, existingSolarFarm) => {
    let nextPosition = Math.max.apply(
      Math,
      orderList.map(o => o.id)
    );

    if (nextPosition === Number.NEGATIVE_INFINITY) {
      nextPosition = 0;
    }
    const farm = existingSolarFarm
      ? selectedAddFarm
      : new SolarFarm().deserialize({
          name: newFarmName,
          size: ''
        });

    const emptyOrderListItem = {
      id: nextPosition + 1,
      solar_farm: farm,
      contact: selectedFarmContact,
      existingFarm: existingSolarFarm,
      errors: {},
      ...defaultValues
    };
    setOrderList([...orderList, emptyOrderListItem]);
    handleCancelAddOrder();
  };

  const handleCancelAddOrder = () => {
    setSelectedAddFarm(null);
    setNewFarmName('');
    setShowAddOrderModal(false);
    setSelectedFarmContact(null);
  };

  /**
   * Removes the selected rows from the table
   */
  const handleRemoveOrderListItems = () => {
    const newList = orderList.reduce((acc, curr) => {
      if (!selectedOrderIds.some(uuid => uuid === curr.uuid)) {
        acc.push(curr);
      }
      return acc;
    }, []);
    setOrderList(newList);
    setSelectedOrderIds([]);
  };

  /**
   * Adds and removes rows from selected rows
   * @param row
   * @param isSelect
   */
  const handleSelectRow = (row, isSelect) => {
    if (isSelect) {
      setSelectedOrderIds([...selectedOrderIds, row.uuid]);
    } else {
      setSelectedOrderIds(selectedOrderIds.filter(r => r !== row.uuid));
    }
  };

  /**
   * Selects all rows or clears all selected rows
   * @param isSelect {bool} - whether the user is selecting or deselecting
   * @param rows {[]} - list of all rows
   */
  const handleSelectAllRows = (isSelect, rows) => {
    if (isSelect) {
      setSelectedOrderIds(rows.map(r => r.uuid));
    } else setSelectedOrderIds([]);
  };

  /**
   * Updates a single row key value pair
   * @param rowIndex {number} - index of row in order list
   * @param row {{}} - order list item
   * @param key {string} -
   * @param value {Any} -
   */
  const updateRowValue = (rowIndex, row, key, value) => {
    if (row) {
      row[key] = value;
    }
    // clear the row errors when the user goes back in to make changes
    if (row.errors) {
      row.errors = {};
    }
    setOrderList(orderList => Object.assign([...orderList], { [rowIndex]: row }));
  };

  /**
   * Handle the farm search, use clientOrgId and search key as params
   * Sets the farm list on return
   */
  const handleFarmSearch = searchKey => {
    if (!clientOrgId) {
      // eslint-disable-next-line no-undef
      toastr.error('Please select customer org before searching');
      return;
    }

    setNewFarmName(searchKey);
    if (selectedAddFarm && searchKey !== selectedAddFarm.name) setSelectedAddFarm(null);

    if (searchKey.length < 3) {
      return;
    }

    setLoadingFarmName(true);
    const farmsApi = new SolarFarmsApiClient();

    farmsApi.getByOrgId(clientOrgId, searchKey, FARM_SEARCH_LIMIT).then(farms => {
      setFarmsList(farms);
      setLoadingFarmName(false);
    });
  };

  const handleSwapOrAddFarm = () => {
    if (activeStatus === SALES_PROJECT_STATUS.REVIEW && selectedSwapFarmInd !== null) {
      let updatedOrderList = orderList.slice();

      // Handles if farm was selected from list or is new farm
      if (selectedAddFarm) {
        updatedOrderList[selectedSwapFarmInd].solar_farm = selectedAddFarm;
      } else {
        const newSolarFarm = new SolarFarm().deserialize({
          name: newFarmName,
          size: '',
          address: ''
        });
        updatedOrderList[selectedSwapFarmInd].solar_farm = newSolarFarm;
      }

      // Reset is repeat inspection on swap
      updatedOrderList[selectedSwapFarmInd].is_repeat_inspection = false;

      setOrderList(updatedOrderList);
      setSelectedSwapFarmInd(null);
      setShowAddOrderModal(false);
    } else {
      handleAddOrderListItem(selectedAddFarm, selectedAddFarm);
    }
  };

  return (
    <>
      <Modal show={showAddOrderModal} onHide={handleCancelAddOrder}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Solar Farm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsyncTypeahead
            isLoading={loadingFarmName}
            className="farm-name-input"
            id="farm-name-input-id"
            value={newFarmName}
            options={farmsList}
            placeholder="Type to search..."
            labelKey={farm => farm.name}
            onChange={val => {
              if (val && val[0]) {
                setNewFarmName(val[0].name);
                setSelectedAddFarm(val[0]);
              }
            }}
            onSearch={val => handleFarmSearch(val)}
          />
          <br />
          {newFarmName && (
            <>
              Add order for {newFarmName}, which is a{' '}
              <strong>{selectedAddFarm ? 'existing' : 'new'}</strong> site
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelAddOrder}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwapOrAddFarm} disabled={!newFarmName}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Row style={{ marginBottom: '.5rem' }}>
        <Col>
          <h6>Order List ({orderList.length})</h6>
          <OverlayTrigger
            overlay={
              <Tooltip id="add-button-tooltip">
                Customer org selection is required before adding orders
              </Tooltip>
            }
            placement="left"
          >
            <FontAwesomeIcon
              style={{ color: 'green' }}
              className={`${
                clientOrgId && !disableWhenStatusNotReview ? 'icon-button' : 'disabled-icon-button'
              } sales-onboarding-button-icon plus`}
              icon={faPlusCircle}
              onClick={() => setShowAddOrderModal(true)}
            />
          </OverlayTrigger>
          <FontAwesomeIcon
            style={{ color: 'red', marginRight: '1rem' }}
            className={`${
              selectedOrderIds.length && !disableWhenStatusNotReview
                ? 'icon-button'
                : 'disabled-icon-button'
            } sales-onboarding-button-icon trash`}
            icon={faTrash}
            onClick={() => handleRemoveOrderListItems()}
          />
        </Col>
      </Row>
      <Row className="sales-onboarding-form-section">
        <Col>
          <ProjectViewerFarmOrdersTable
            clientOrgId={clientOrgId}
            orderList={orderList}
            setOrderList={setOrderList}
            handleSelectRow={handleSelectRow}
            handleSelectAllRows={handleSelectAllRows}
            selectedRows={selectedOrderIds}
            updateRowValue={updateRowValue}
            disableOnReviewStatus={disableOnReviewStatus}
            disableOnCancelledStatus={disableOnCancelledStatus}
            disableWhenStatusNotReview={disableWhenStatusNotReview}
            setSelectedSwapFarmInd={setSelectedSwapFarmInd}
            setShowAddOrderModal={setShowAddOrderModal}
            activeStatus={activeStatus}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProjectViewerFarmOrdersComponent;
