import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
// api clients
import OrgsApiClient from '../../apiClient/OrgsApiClient';
import RaptorInternalApiClient from '../../apiClient/RaptorInternalApiClient';

import { RAPTOR_X_ORG_ID } from '@raptormaps/raptor-lib';

import toastr from 'toastr';

const DATA_TYPE_UPLOAD_SESSION = 0;
const DATA_TYPE_TILE_MAP = 1;

const RAPTOR_ML_ORG_ID = 101;

/**
 * Functional component that holds state logic and views for moving data between orgs
 * Currently handles tile maps and upload sessions
 */
const MoveData = () => {
  const [dataType, setDataType] = useState(DATA_TYPE_UPLOAD_SESSION);
  const [data, setData] = useState([]);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [newOrgId, setNewOrgId] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const orgsApi = new OrgsApiClient();
  const raptorInternalApi = new RaptorInternalApiClient();

  // Object for mapping control values to display values
  const dataTypeNameMapping = {
    [DATA_TYPE_UPLOAD_SESSION]: 'Upload Session',
    [DATA_TYPE_TILE_MAP]: 'Tile Map'
  };

  useEffect(() => getOrgs(), []);
  //reset state to empty when type is switched
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setSelectedDataIds([]), setData([]);
  }, [dataType]);

  // Get and set all orgs, filter by type BPO/RaptorX for now
  const getOrgs = () => {
    orgsApi.getOrgs().then(res => setOrgs(res.filter(o => o.type === 'BPO' || o.id == RAPTOR_X_ORG_ID || o.id == RAPTOR_ML_ORG_ID)));
  };

  /**
   * Handles changing input for data name field, only called with input length >= 3
   * @param {*} value - value of input
   */
  const handleInputChange = value => {
    if (dataType == DATA_TYPE_UPLOAD_SESSION) {
      raptorInternalApi.searchUploadSessions(value, currentOrgId).then(res => setData(res));
    } else {
      raptorInternalApi.searchTileMaps(value, currentOrgId).then(res => setData(res));
    }
  };

  /**
   * Handles calling internal api update logic
   */
  const handleUpdate = () => {
    if (dataType == DATA_TYPE_UPLOAD_SESSION) {
      raptorInternalApi
        .updateUploadSessionsOrgId(selectedDataIds, newOrgId)
        .then(res => toastr.success('Successfully updated upload session org id'));
    } else {
      raptorInternalApi
        .updateTileMapsOrgId(selectedDataIds, newOrgId)
        .then(res => toastr.success('Successfully updated tile map org id'));
    }
  };

  /**
   * Function for getting columns for each data type
   * @returns column array
   */
  const getColumns = () => {
    if (dataType == DATA_TYPE_UPLOAD_SESSION) {
      return [
        {
          dataField: 'id',
          text: 'ID',
          sort: true
        },
        {
          dataField: 'name',
          text: 'Name',
          sort: true
        },
        {
          dataField: 'created_tsecs',
          text: 'Created Date',
          formatter: cell => moment.unix(cell).format('MM/DD/YYYY'),
          sort: true
        }
      ];
    } else {
      return [
        {
          dataField: 'id',
          text: 'ID',
          sort: true
        },
        {
          dataField: 'filename',
          text: 'Name',
          sort: true
        },
        {
          dataField: 'created_tsecs',
          text: 'Created Date',
          formatter: cell => moment.unix(cell).format('MM/DD/YYYY'),
          sort: true
        }
      ];
    }
  };

  /**
   * Adds and removes rows from selected rows
   * @param {*} row
   * @param {bool} isSelect
   */
  const handleSelectRow = (row, isSelect) => {
    if (isSelect) {
      setSelectedDataIds([...selectedDataIds, row.id]);
    } else {
      setSelectedDataIds(selectedDataIds.filter(d => d !== row.id));
    }
  };

  /**
   * Selects all rows or clears all selected rows
   * @param isSelect {bool} - whether the user is selecting or deselecting
   * @param rows {[]} - list of all rows
   */
  const handleSelectAllRows = (isSelect, rows) => {
    if (isSelect) {
      setSelectedDataIds(rows.map(r => r.id));
    } else setSelectedDataIds([]);
  };

  return (
    <Container style={{ paddingLeft: 20, paddingRight: 20, height: '100%' }} fluid>
      <Row style={{ paddingTop: 20 }}>
        <Col>
          <label>Item Type</label>
          <Dropdown>
            <Dropdown.Toggle>{dataTypeNameMapping[dataType]}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setDataType(DATA_TYPE_UPLOAD_SESSION)}>
                Upload Session
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDataType(DATA_TYPE_TILE_MAP)}>
                Tile Map
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{ paddingTop: 20 }}>
        <Col>
          Current Org
          <Dropdown>
            <Dropdown.Toggle>
              {currentOrgId ? orgs.find(o => o.id === currentOrgId).name : 'Select Current Org'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {orgs.map(o => (
                <Dropdown.Item onClick={() => setCurrentOrgId(o.id)}>{o.name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          New Org
          <Dropdown>
            <Dropdown.Toggle>
              {newOrgId ? orgs.find(o => o.id === newOrgId).name : 'Select New Org'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {orgs.map(
                o =>
                  o.id != currentOrgId && (
                    <Dropdown.Item onClick={() => setNewOrgId(o.id)}>{o.name}</Dropdown.Item>
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{ paddingTop: 20 }}>
        <Col>
          <label>Item Name</label>
          <Form.Control
            placeholder={
              currentOrgId
                ? `Search ${dataTypeNameMapping[dataType]}s`
                : 'Select a current org to search'
            }
            onChange={e => e.target.value.length >= 3 && handleInputChange(e.target.value)}
            disabled={currentOrgId === null}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 20 }}>
        <Col>
          <BootstrapTable
            keyField="id"
            columns={getColumns()}
            data={data}
            selectRow={{
              mode: 'checkbox',
              selected: selectedDataIds,
              onSelect: handleSelectRow,
              onSelectAll: handleSelectAllRows,
              clickToSelect: true,
              headerColumnStyle: { width: 40 }
            }}
          />
        </Col>
      </Row>
      <Row style={{ position: 'fixed', bottom: '0', paddingBottom: '20px', right: '30px' }}>
        <Button disabled={!(selectedDataIds.length && newOrgId)} onClick={() => handleUpdate()}>
          Update Items
        </Button>
      </Row>
    </Container>
  );
};

export default MoveData;
