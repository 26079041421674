import Papa from 'papaparse';
import toastr from 'toastr';

import { SolarFarm } from '@raptormaps/raptor-models';
import RaptorAdminRoutesApiClient from '../../apiClient/RaptorAdminRoutesApiClient';

/**
 * Class responsible for handling the CSV uploads for the sales onboarding
 * form.
 *
 * @param csvFile {File} - file to process
 * @param clientOrgId {number} -
 */
class OrderListCSVProcessor {
  constructor(csvFile, clientOrgId) {
    this.csvFile = csvFile;
    this.clientOrgId = clientOrgId;
  }

  /**
   * Processes the CSV by first formatting it into an array of objects that
   * can be interpreted by the backend and then calls the api client to hit the
   * validation route.
   *
   * @returns {Promise}
   */
  processCSV = () => {
    return new Promise((resolve, reject) => {
      this._formatCSVToJSON()
        .then(data => {
          const raptorAdminApi = new RaptorAdminRoutesApiClient();
          raptorAdminApi
            .validateSalesOnboardingData(data, this.clientOrgId)
            .then(validatedOrderList => {
              let rejected = false;
              validatedOrderList.forEach((order, index) => {
                if(order.errors) {
                  const errors = Object.keys(order.errors).map(key => {
                    return `Row ${index + 1} (${key}): ${order.errors[key]}`;
                  });
                  if(errors.length > 0) {
                    rejected = true;
                    reject(errors.join('\n'));
                    return;
                  }
                }
              });

              if(!rejected) resolve(validatedOrderList)
            })
            .catch(err => {
              reject(err);
              toastr.error(err);
            });
        })
        .catch(err => reject(err));
    });
  };

  /**
   * Calls the parse, and then formats the objects into ones that can be
   * read by the backend. The farm also needs to be cased into a SolarFarm object
   *
   * @returns {Promise}
   * @private
   */
  _formatCSVToJSON = () => {
    return new Promise((resolve, reject) => {
      this._parse().then(resultsArray => {
        if (resultsArray.data.length === 0) {
          reject('No orders in csv list');
        }

        // Need to assign an id for the table
        let id = 0;
        const jsonArray = resultsArray.data.reduce((acc, curr) => {
          let farmInfo = {
            id: id,
            solar_farm: new SolarFarm().deserialize({
              name: curr['Site Name'],
              size: curr['Site Size (MW)'],
              latitude: curr['Site Latitude'],
              longitude: curr['Site Longitude'],
              address: curr['Site Address'],
              ppa_rate: curr['Site PPA Rate ($/kWh)'],
              access_code: curr['Gate Access Code']
            }),
            existingFarm: curr['Existing Site'],
            contract_type: curr['Type of Project'],
            order_type: curr['Type of Inspection'],
            requested_delivery_date: curr['Requested Delivery Date'],
            requested_flight_date: curr['Requested Flight Date'],
            is_turnkey: curr['Is Turnkey'],
            data_collection_org: curr['Data Collection Org'],
            analytics_revenue: curr['Analytics Revenue'],
            flight_revenue: curr['Flight Revenue'],
            contact: curr['Site Contact'],
            errors: {}
          }
          farmInfo.solar_farm.latitudeEditing = String(farmInfo.solar_farm.location.lat);
          farmInfo.solar_farm.longitudeEditing = String(farmInfo.solar_farm.location.lng);
          acc.push(farmInfo);
          id += 1;
          return acc;
        }, []);
        resolve(jsonArray);
      });
    });
  };

  /**
   * Parses the CSV file to an array of objects
   *
   * @returns {Promise}
   * @private
   */
  _parse = () => {
    return new Promise((resolve, reject) => {
      Papa.parse(this.csvFile, {
        complete: results => {
          resolve(results);
        },
        header: true
      });
    });
  };
}

export default OrderListCSVProcessor;
