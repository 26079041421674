// @ts-nocheck
import { useEffect, useState } from 'react';
import SalesProjectApiClient from '../../apiClient/SalesProjectApiClient';
import ProjectViewerTable from './ProjectViewerTable';

import { PROJECT_STATUS_TABS, SALES_PROJECT_STATUS } from './constants';

// Raptor App
import { Container, Row, Tab } from 'react-bootstrap';

// styled Components
import { CustomTabs, ProjectViewerContainer } from './styles/ProjectViewer_styles';

/**
 * Component to view all projects.
 * By default, the Staging tab is set
 * @param props
 * @returns {*}
 * @constructor
 */
const ProjectViewerComponent = ({ history }) => {
  const [projectList, setProjectList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(SALES_PROJECT_STATUS.REVIEW);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [noNextPage, setNoNextPage] = useState<boolean>(false);

  /**
   * Makes API call to status endpoint and updates projectList based on selected status
   * @param {number} status - The selected status code of projects to view
   * @returns {*}
   */
  const getProjects = (status, pageNumber: number, itemsPerPage: number) => {
    const api = new SalesProjectApiClient();

    if (status === SALES_PROJECT_STATUS.CANCELLED) {
      status = SALES_PROJECT_STATUS.API_CANCELLED_CODE;
    }

    api.getSalesProjectSummaries(status, pageNumber, itemsPerPage).then(projects => {
      projects.length > 0 ? handleProjectData(projects, pageNumber) : handleEmptyData()
    });
  };

  const handleEmptyData = () => {
    setCurrentPage(currentPage - 1);
    setNoNextPage(true);
  }

  const handleProjectData = (projectdata: any, pageNumber: number) => {
    setCurrentPage(pageNumber)
    setProjectList(projectdata)
    setNoNextPage(pageSize > projectdata.length);
  }

  const handleChangePage = (pageNumber: number) => {
    getProjects(activeStatus, pageNumber, pageSize)
  }

  /**
   * Updates active status when ProjectViewer tab is selected
   * @param {string} key - The key corresponding to the selected tab
   * @returns {*}
   */
  const onTabChange = key => {
    setActiveStatus(Number(key));
    setCurrentPage(1)
    setProjectList([]);
    setNoNextPage(false);
  };

  useEffect(() => getProjects(activeStatus, currentPage, pageSize), [activeStatus]);

  useEffect(() => getProjects(activeStatus, currentPage, pageSize), [pageSize]);

  return (
    <ProjectViewerContainer>
      <Container fluid>
        <Row>
          <h3>Projects</h3>
        </Row>
        <Row>
          <CustomTabs
            mountOnEnter
            unmountOnExit
            animation
            defaultActiveKey={SALES_PROJECT_STATUS.REVIEW}
            activeKey={activeStatus}
            id="project-viewer-status-tabs"
            onSelect={onTabChange}
          >
            {PROJECT_STATUS_TABS.map((tab, ind) => (
              <Tab eventKey={ind} title={`${tab}`}>
                <ProjectViewerTable
                  key={`${tab}`}
                  activeStatus={ind}
                  projectList={projectList}
                  history={history}
                  setActiveStatus={setActiveStatus}
                  setProjectList={setProjectList}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  noNextPage={noNextPage}
                  handleChangePage={handleChangePage}
                />
              </Tab>
            ))}
          </CustomTabs>
        </Row>
      </Container>
    </ProjectViewerContainer>
  );
};

export default ProjectViewerComponent;
