import { useEffect, useState } from 'react';

//Bootstrap Table Next
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';


//Bootstrap Typeahead
import { Typeahead } from 'react-bootstrap-typeahead';

//Bootstrap
import { Button, Form } from 'react-bootstrap';

//Axios and Constant
import { RAPTOR_X_ORG_ID } from '@raptormaps/raptor-lib';

//API
import RestApiV2 from '../api/RestApiV2';

//Models
import { Org, User } from '@raptormaps/raptor-models';

/**
 * RolesViewer -container for the roles table and contains logic for getting table data and updating roles
 * @return {*}
 * @constructor
 */
const RolesViewer = () => {
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [buttonDisabled, setButtonDisable] = useState(true);
  const [selectOrg, setSelectOrg] = useState(RAPTOR_X_ORG_ID);

  useEffect(() => getOrgs(), []);
  useEffect(() => getOrgUsers(), [selectOrg]);

  const api = new RestApiV2();

  /**
   * Gets all users for the given org id
   * api - api_raptor_admin_get_org_users
   */
  const getOrgUsers = () => {
    api
      .get(`raptor_admin/${selectOrg}/users`)
      .then(res => {
        if (res['exit_status'] === 0) {
          setUsers(res['users'].map(u => User.initFromDBUser(u)));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   * Gets all orgs
   * api - api_v2_raptor_admin_get_all_orgs
   */
  const getOrgs = () => {
    api
      .get('raptor_admin/orgs', { params: { populate: 0 } })
      .then(res => {
        if (res['exit_status'] === 0) {
          setOrgs(res['orgs'].map(o => Org.initFromDBOrg(o)));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   * Updates roles when checkbox is clicked and flips save button disable to false
   * @param {*} user row of user in table
   * @param {*} role- role to be added or removed
   * @param {*} userlist - array of users in state
   */
  const updateRole = (user, role, userlist) => {
    let changedUser = {};

    //if user does not have role add it, otherwise remove it
    if (!user['roles'].includes(role)) {
      changedUser = { ...user, roles: [...user['roles'], role] };
    } else {
      changedUser = { ...user, roles: user['roles'].filter(r => r != role) };
    }

    const newUsers = userlist.map(u => {
      return u.id === user.id ? changedUser : u;
    });
    setUsers(newUsers);
    setButtonDisable(false);
  };

  /**
   * Formats data and sends roles to backend to be saved
   * api - api_v2_raptor_admin_roles
   */
  const saveRoles = () => {
    //format data for route
    const data = users.reduce((acc, user) => {
      acc[user.id] = {
        raptoradmin: user['roles'].includes('raptoradmin'),
        solar: user['roles'].includes('solar'),
        reportbuilder: user['roles'].includes('reportbuilder'),
        farmbuilder: user['roles'].includes('farmbuilder')
      };
      return acc;
    }, {});

    //send data
    api
      .put('/raptor_admin/roles', data)
      .then(res => {
        setButtonDisable(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const formatData = (cell, row, rowIndex, params) => {
    return <Form.Check checked={row['roles'].includes(params.col)} onChange={() => updateRole(row, params.col, params.users)} />;
  };

  const getColumns = users => [
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: { width: '50%' }
    },
    {
      formatter: formatData,
      formatExtraData: { col: 'raptoradmin', users },
      text: 'Raptor Admin',
      align: 'center',
      headerAlign: 'center'
    },
    {
      formatter: formatData,
      formatExtraData: { col: 'solar', users },
      text: 'Solar',
      align: 'center',
      headerAlign: 'center'
    },
    {
      formatter: formatData,
      formatExtraData: { col: 'reportbuilder', users },
      text: 'Report Builder',
      align: 'center',
      headerAlign: 'center'
    },
    {
      formatter: formatData,
      formatExtraData: { col: 'farmbuilder', users },
      text: 'Farm Builder',
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const { SearchBar } = Search;

  return (
    <div className="roles-viewer">
      <ToolkitProvider keyField="id" data={users} columns={getColumns(users)} search>
        {props => (
          <>
            <div className="header">
              <Typeahead
                id="org"
                options={Object.values(orgs)}
                placeholder="Type to Search Orgs..."
                labelKey={org => `${org.name}`}
                onChange={selected => selected.length > 0 && setSelectOrg(selected[0].id)}
                defaultInputValue={'RaptorX'}
              />
            </div>

            <div className="search-div">
              <SearchBar {...props.searchProps} placeholder="Filter emails" style={{ width: '600px' }} />
            </div>

            <div className="table-div">
              <BootstrapTable headerClasses="roles-table-header" wrapperClasses="roles-table" className="test" style={{ overflowY: 'auto', height: '100%' }} striped {...props.baseProps} />
            </div>
          </>
        )}
      </ToolkitProvider>
      <Button className="button" onClick={saveRoles} disabled={buttonDisabled}>
        {buttonDisabled ? 'Saved' : 'Save Roles'}
      </Button>
    </div>
  );
};

export default RolesViewer;
