import React from 'react';

import { Modal } from 'react-bootstrap';

import CreateOrganizationComponent from '../CreateOrganizationComponent';

const orgTypeTitle = {
  small: 'Customer',
  data_collector: 'Data Collection'
};

/**
 * Modal to create a new org
 *
 * @param show
 * @param toggle
 * @param orgType
 * @param onCreateOrgSuccess
 * @returns {*}
 * @constructor
 */
const CreateOrganizationModal = ({ show, toggle, orgType, onCreateOrgSuccess }) => (
  <Modal show={show} onHide={toggle}>
    <Modal.Header closeButton>
      <Modal.Title>Create {orgTypeTitle[orgType]} Org</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CreateOrganizationComponent
        className="create-org-modal-component"
        type={orgType}
        onCreateSuccess={onCreateOrgSuccess}
      />
    </Modal.Body>
  </Modal>
);

export default CreateOrganizationModal;
