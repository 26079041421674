/* eslint-disable no-undef */
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { Component } from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Route, Router, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import toastr from "toastr";
import { getOrgs } from "./actions/sessionActions";

//GLOBAL STYLE PROVIDER and THEME
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import { setConfig } from "react-hot-loader";
import { hot } from "react-hot-loader/root";

// CSS
import "./static/css/application.css";
import "./static/css/navigationbar.css";
import "./static/css/raptoradmin.css";

// Components
import { datadogRum } from "@datadog/browser-rum";
import { User } from "@raptormaps/raptor-models";
import RestApiV2 from "./api/RestApiV2";
import CopyJob from "./components/CopyJob";
import CreateOrganizationView from "./components/CreateOrganizationView";
import DefaultClientOrg from "./components/DefaultClientOrg";
import ManageOrganizations from "./components/ManageOrganizations";
import ManageModuleTypes from "./components/ManageModuleTypes";
import DefaultOrgGroups from "./components/DefaultOrgGroup";
import FarmOrgUsersViewer from "./components/FarmOrgUsersViewer.js";
import MoveData from "./components/MoveData";
import Navigation from "./components/Navigation";
import OrderStatus from "./components/OrderStatus";
import FeaturePermission from "./components/OrgPermissions/index";
import ProjectViewerComponent from "./components/ProjectViewer";
import RaptorAdminHome from "./components/RaptorAdminHome";
import RequestDataPage from "./components/RequestDataPage";
import RolesViewer from "./components/RolesViewer";
import OrgUsersViewer from "./components/OrgUsers/OrgUsersViewer";
import SalesOnboarding from "./components/SalesOnboarding";
import TasksViewer from "./components/TasksViewer";
import { OrgAllowedList } from "./views/OrgAllowedList";

import { IntlProvider } from "react-intl";

import { sec } from "./util/security";

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();
const location = history.location;
const growthbook = new GrowthBook();

setConfig({
  reloadHooks: false,
});

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: null,
    };
    document.title = "Raptor Maps";
    this.api = null;
    this.DEFAULT_COMPONENT_VIEW = ["/", "/order-status"];

    if (window.REACT_APP_NODE_ENV !== "local") {
      datadogRum.init({
        applicationId: "8bc672c8-7de9-4011-a053-4dc23b4011d7",
        clientToken: "pub9390a9f409f4f1f066ad5a8e3896ff01",
        site: "datadoghq.com",
        service: "raptor-admin",
        env: `${window.REACT_APP_API_ENDPOINT}`,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.raptormaps\.app/],
      });

      datadogRum.startSessionReplayRecording();
    }
  }

  componentDidMount() {
    fetch(
      `https://cdn.growthbook.io/api/features/${window.REACT_APP_GROWTHBOOK_API_KEY}`
    )
      .then((res) => res.json())
      .then((parsed) => {
        growthbook.setFeatures(parsed.features);
      });
    const { getAccessTokenSilently } = this.props.auth0;
    sec.setAccessTokenSilently(getAccessTokenSilently);
    this.api = new RestApiV2(false, `${window.REACT_APP_API_ENDPOINT}/api/v1`);
    this.appEnter();
    this.props.actions.getOrgs();
  }

  noMatch = () => (
    <div className="App fill" style={{ textAlign: "center" }}>
      <h1>404 - URL Not Found :(</h1>
    </div>
  );

  appEnter = () => {
    const replace = history.replace;

    // Auth via getUser.
    this.getUser(replace)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toastr.error("ERROR: FATAL USER LOGIN STATUS RECEIVED");
      });
  };

  getUser = (replace) => {
    return new Promise((resolve, reject) => {
      this.api
        .post("enter_app")
        .then((res) => {
          const data = res.user;
          const user = User.initFromDBUser(data);
          // Set sessionStorage properties.
          sessionStorage.setItem("accessToken", user.token);
          sessionStorage.setItem("email", user.email);
          sessionStorage.setItem("user_id", user.id);
          sessionStorage.setItem("role", user.role);
          // 2018-05-29 (EWO) added roles so authorizeOnChange can use many roles
          sessionStorage.setItem("roles", user.roles);
          sessionStorage.setItem("name", user.name);
          sessionStorage.setItem("isAuthenticated", true);
          sessionStorage.setItem(
            "isReturningCustomer",
            res.is_returning_customer
          );
          sessionStorage.setItem("debugMode", res.debug_mode);
          sessionStorage.setItem("runMode", res.run_mode);

          // If user's first time, direct them to the uploader page.
          if (data.first_time) {
            replace("/image-sets/uploader");
          }

          // If user is no longer active, direct them to the paywall.
          if (!user.active) {
            replace("/login");
          }

          // Assign the Mixpanel identity of the user
          // mixpanel.identify(user.id.toString());
          // mixpanel.people.set({ $email: user.email });

          // Assign the Google Analytics identity of the user
          // if (window.gtag) window.gtag('set', { user_id: user.email }); // Set the user ID using signed-in user_id.

          // record page enter in mixpanel
          // mixpanel.track('Page Enter', { pathname: location.pathname });

          // Pass data into the App component via location state
          location.state = {
            user: user,
          };

          this.setState(
            {
              user: user,
              location: location,
            },
            () => resolve
          );
        })
        .catch((err) => {
          console.log(err);
          // alert('ERROR: FATAL USER LOGIN STATUS RECEIVED');
          reject(err);
        });
    });
  };

  render() {
    const language = "en";
    const { isAuthenticated } = this.props.auth0;
    if (!this.state.user) return <div />;
    return (
      isAuthenticated && (
        <IntlProvider locale={language}>
          <GrowthBookProvider growthbook={growthbook}>
            <ThemeProvider theme={theme}>
              <Router history={history}>
                <div className="app-fill-raptor-admin">
                  <Navigation {...this.state} pathname={location} />
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/home"}>
                        Home
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/upload"}>
                        Upload Request
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/copy-job"}>
                        Copy Job
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/create-organization"}>
                        Create Organization
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/tasks"}>
                        Tasks
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/roles"}>
                        Roles
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/org-users"}>
                        Org Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/farm-org-users"}>
                        Farm Org Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/order-status"}>
                        Order Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/project-onboarding"}>
                        Project Onboarding
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/move-data"}>
                        Move Data
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/org-permissions"}>
                        Org Permissions
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/project"}>
                        Project Viewer
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/default-org-groups"}>
                        Default Org Groups
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/default-client-org"}>
                        Default Client Org
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/manage-organizations"}>
                        Manage Organizations
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/manage-module-types"}>
                        Manage Module Types
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to={"/org-allowed-list"}>
                        Org Allowed List
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Switch>
                    <Route path="/home" exact component={RaptorAdminHome} />
                    <Route
                      path={"/upload"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <RequestDataPage {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/copy-job"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <CopyJob {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/create-organization"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <CreateOrganizationView {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/create-organization"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <CreateOrganizationView {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/tasks"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <TasksViewer {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/roles"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <RolesViewer {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/org-users"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <OrgUsersViewer {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/farm-org-users"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <FarmOrgUsersViewer {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={this.DEFAULT_COMPONENT_VIEW}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <OrderStatus {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/project-onboarding"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <SalesOnboarding {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/move-data"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <MoveData {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/org-permissions"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <FeaturePermission {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={["/project", "/project/:project_id"]}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <ProjectViewerComponent {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/default-org-groups"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <DefaultOrgGroups {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/default-client-org"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <DefaultClientOrg {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/manage-organizations"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <ManageOrganizations {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/manage-module-types"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <ManageModuleTypes {...props} {...this.state} />
                      )}
                    />
                    <Route
                      path={"/org-allowed-list"}
                      exact
                      user={this.state.user}
                      authRequirements={{ role: ["raptoradmin"] }}
                      component={(props) => (
                        <OrgAllowedList {...props} {...this.state} />
                      )}
                    />
                    <Route component={this.noMatch} />
                  </Switch>
                </div>
              </Router>
            </ThemeProvider>
          </GrowthBookProvider>
        </IntlProvider>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getOrgs,
      },
      dispatch
    ),
  };
};

export default hot(
  connect(null, mapDispatchToProps)(withAuth0(withAuthenticationRequired(App)))
);
