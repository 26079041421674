import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Toast } from 'react-bootstrap';
import toastr from 'toastr';
import _ from 'lodash';

import Switch from '../shared/Switch/Switch';
import DumbTable from './DumbTable';

import FeatureApiClient from '../../apiClient/FeatureApiClient';

const featureTableHeaderMap = {
  name: 'Feature Name',
  description: 'Feature Description',
  status: 'Status'
};

/**
 * Modal that is rendered when a user selects an org from the table view of org permissions.
 * @param {bool} show If the popup modal should be rendered
 * @param {Callable} handleClose Method used to close the modal
 * @param {Object} selectedOrg Solar org that has been clicked on the table.
 * @param {Array.<Object>} orgFeatures Array of all features for an org
 * @param {Callable} setOrgFeatures Sets the new org features after enabling/disabling feature.
 * @param {Array.<Object>} allFeatures Array of all created features
 * @param {boolean} farmLevel Whether or not the API to use should be on org level or farm level.
 */
const PermissionModal = ({
  show,
  handleClose,
  selectedOrg,
  orgFeatures,
  setOrgFeatures,
  allFeatures,
  farmLevel = false
}) => {
  const [featureDiff, setFeatureDiff] = useState([]);
  const [featureTableData, setFeatureTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * Generate an array of booleans where true if an org has an enabled feature
   * and false if not.
   */
  useEffect(() => {
    const enabledFeatures = new Set(orgFeatures.map(o => o.feature_id));
    setFeatureDiff(allFeatures.map(f => enabledFeatures.has(f.id)));
  }, [orgFeatures]);

  /**
   * Creates an array of feature table data that is rendered inside DumbTable
   */
  useEffect(() => {
    const data = allFeatures.map((f, idx) => ({
      id: f.id,
      name: f.name,
      description: f.description,
      status: (
        <Switch
          key={idx}
          isOn={featureDiff[idx]}
          handleToggle={() => handleToggle(f.id, idx)}
          onColor="#52d869"
          index={idx}
          value={f.id}
        />
      )
    }));
    setFeatureTableData(data);
  }, [featureDiff]);

  /**
   * Toggles a feature for a given org.
   * @param {number} featureId ID of the feature to toggle
   * @param {number} index Index of status within the featureDiff array
   */
  const handleToggle = (featureId, index) => {
    const updatedFeatureDiff = featureDiff.map((status, idx) => (idx === index ? !status : status));
    setFeatureDiff(updatedFeatureDiff);
  };

  /**
   * Zip featureDiff into allFeatures and check which features should be disabled before enabling
   * new features.
   */
  const handleSave = async () => {
    // Start the loading spinner
    setLoading(true);

    const zippedDiff = _.zip(allFeatures, featureDiff);
    const enabledFeatures = new Set(orgFeatures.map(o => o.feature_id));

    // If a feature was enabled but then toggled to false
    const featuresToDisable = zippedDiff.filter(
      ([feature, toggledStatus]) => enabledFeatures.has(feature.id) && !toggledStatus
    );

    const featuresToEnable = zippedDiff.filter(
      ([feature, toggledStatus]) => !enabledFeatures.has(feature.id) && toggledStatus
    );

    const featureApiClient = new FeatureApiClient();
    // function aliasing based on the level of feature permission (org or farm)
    const key = farmLevel === true ? 'farmLevel' : 'orgLevel';
    const featureApi = {
      farmLevel: {
        enableFeature: featureApiClient.enableFarmFeature,
        disableFeature: featureApiClient.disableFarmFeature,
        params: { solar_farm_id: selectedOrg.id }
      },
      orgLevel: {
        enableFeature: featureApiClient.enableOrgFeature,
        disableFeature: featureApiClient.disableOrgFeature,
        params: { org_id: selectedOrg.id }
      }
    };

    // Disable features if any
    try {
      if (!_.isEmpty(featuresToDisable)) {
        const disabledPromises = featuresToDisable.map(([feature, _]) =>
          featureApi[key].disableFeature(selectedOrg.id, feature.id)
        );
        await Promise.all(disabledPromises);
      }

      // Enable features if any
      if (!_.isEmpty(featuresToEnable)) {
        const enabledPromises = featuresToEnable.map(([feature, _]) =>
          featureApi[key].enableFeature(selectedOrg.id, feature.id)
        );

        await Promise.all(enabledPromises);
      }
      toastr.success('Permissions Saved', 'Success', { timeOut: 3000, closeButton: true });
    } catch (error) {
      console.error(error);
      toastr.error(error);
    }

    featureApiClient.getOrgFeatures(featureApi[key].params).then(res => setOrgFeatures(res));

    // Stop the loading spinner
    setLoading(false);
  };

  const featureTableObjectKeys = Object.keys(featureTableHeaderMap);
  const featureTableHeaderValues = Object.values(featureTableHeaderMap);

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <div className="permission-modal-title">
        <Modal.Title> 
          {farmLevel && `Org: ${selectedOrg.org_id} - ${selectedOrg.org_name}`}
        </Modal.Title>
        <Modal.Title>
          # {selectedOrg.id} - {selectedOrg.name}
        </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <DumbTable
            objectArray={featureTableData}
            objectRowValueKeys={featureTableObjectKeys}
            headers={featureTableHeaderValues}
            onClick={() => {}}
            tdStyle={{ maxWidth: '120px' }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            Saving...
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionModal;
