import styled, { css } from "styled-components";
import theme from "@raptormaps/theme";
import { Row } from "@raptormaps/layout";
import { Text } from "@raptormaps/text";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;
export const Container = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  grid-gap: 15px;
`;

export const ListItem = styled.div`
  display: block;
  overflow: auto;
  padding: 4px 12px;
  max-height: 416px;
`;

export const List = styled.div`
  width: 50%;
  height: 500px;
  box-shadow: ${theme.shadows.small};
`;

export const ListTitleSection = styled(Row)`
  margin-bottom: 4px;
  padding: 8px;
  box-shadow: ${theme.shadows.small};
`;

export const ListRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  margin: 12px 0;
  padding: 8px;
  box-shadow: ${theme.shadows.small};
  :hover {
    background-color: ${theme.colors.grey_100};
  }
  transition: all 0.2s ease-in-out;
`;

export const AddContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-gap: 8px;
`;

export const LoadingBar = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.orange_400};
  animation: loader 0.8s infinite alternate;

  @keyframes loader {
    0%,
    44%,
    88.1%,
    100% {
      transform-origin: left;
    }

    0%,
    100%,
    88% {
      transform: scaleX(0);
    }

    44.1%,
    88% {
      transform-origin: right;
    }

    33%,
    44% {
      transform: scaleX(1);
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.neutral_50};
  padding: 10px;
  width: fit-content;
`;

export const WarningText = styled(Text)`
  strong {
    color: ${theme.colors.warning_400};
  }
`;

export const InfoText = styled(Text)`
  strong {
    color: ${theme.colors.info_400};
  }
`;

export const ErrorText = styled(Text)`
  strong {
    color: ${theme.colors.error_400};
  }
`;

export const UserContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 12px;
  grid-gap: 12px;
`;

export const UserItem = styled.div`
    background-color: ${theme.colors.neutral_100};
    border-radius: 8px;
    padding: 2px 8px;
    text-align: center;
`;