import axios from 'axios';
// can remove. see code here:
// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
import fileDownload from 'js-file-download';
import { sec } from '../util/security';


export default class RestApiV2 {
  /**
   * @constructs SolarFarmsApi
   * Initializes axios.
   * sets default headers and baseURL for all ajax calls.
   */
  constructor(org_id = '', baseURL = '', token = null) {

    this.axiosInstance = axios.create({
      baseURL: baseURL ? baseURL : `${window.REACT_APP_API_ENDPOINT}/api/v2`,
      headers: {
        'Content-Type': 'application/json',
      }
    });
    // Sets the token generator function to the singleton function reference
    // created on app component mount
    this.tokenGenerator = sec.getAccessTokenSilently()
    //Per Auth0 recommendation, call get token on each request
    this.axiosInstance.interceptors.request.use(
      async config => {
        const token = await this.getToken();
        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      error => {
        Promise.reject(error);
      },
    );

    this.org_id = org_id ? org_id : '';
    return this;
  }

  getToken() {
    return this.tokenGenerator();
  }

  generateParams = (params) => {

    let urlParams = '';
    let keys = params && Object.keys(params).length > 0 ? Object.keys(params) : [];

    if (!keys.includes('org_id') && this.org_id) {
      urlParams += `?org_id=${this.org_id}`;
      keys = keys.filter(k => k !== 'org_id');
    }

    if (keys.length) {
      keys.map(key => {
        if (urlParams.length === 0) {
          urlParams += '?';
        } else {
          urlParams += '&';
        }
        urlParams += `${key}=${params[key]}`;
      });
    }
    return urlParams;
  };

  static errorMessageExtractor = function (err) {
    if (err.response?.data?.match) {
      let test = err.response.data.match(/<p>(.*)<\/p>/);
      if (test && test[1]) {
        return test[1];
      }

      test = err.response.data.match(/<title>(.*)<\/title>/);
      if (test && test[1]) {
        return test[1];
      }
    }

    return err.response;
  };
}

RestApiV2.prototype.get = function (url, params) {

  const urlParams = this.generateParams(params);
  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} get start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .get(url + urlParams)
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} get took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        if (!res.data.exit_status || parseInt(res.data.exit_status) === 0) {
          resolve(res.data);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        reject(RestApiV2.errorMessageExtractor(err));
      });
  });
};

RestApiV2.prototype.head = function (url, params) {

  const urlParams = this.generateParams(params);
  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} head start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .head(url + urlParams)
      .then(res => {
        if (process.env.LOG_LEVEL >= 2) {
          console.log(`${url} head took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

RestApiV2.prototype.post = function (url, data, params) {
  const urlParams = this.generateParams(params);

  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} post start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .post(url + urlParams, data)
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} post took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        if (!res.data.exit_status || parseInt(res.data.exit_status) === 0) {
          resolve(res.data);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log(RestApiV2.errorMessageExtractor(err));
        reject(`${url} post error: ${RestApiV2.errorMessageExtractor(err)}`);
      });

  });
};

RestApiV2.prototype.put = function (url, data, params) {
  const urlParams = this.generateParams(params);

  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} put start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .put(url + urlParams, data)
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} put took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        if (!res.data.exit_status || parseInt(res.data.exit_status) === 0) {
          resolve(res.data);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log(RestApiV2.errorMessageExtractor(err));
        reject(`${url} put error: ${RestApiV2.errorMessageExtractor(err)}`);
      });

  });
};

RestApiV2.prototype.patch = function (url, data, params) {
  const urlParams = this.generateParams(params);

  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} patch start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .patch(url + urlParams, data)
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} patch took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        if (!res.data.exit_status || parseInt(res.data.exit_status) === 0) {
          resolve(res.data);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log(RestApiV2.errorMessageExtractor(err));
        reject(`${url} patch error: ${RestApiV2.errorMessageExtractor(err)}`);
      });

  });
};

RestApiV2.prototype.delete = function (url, params, data = {}) {
  const urlParams = this.generateParams(params);

  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} delete start:   ${timeNow.toTimeString()}`);
    this.axiosInstance
      .delete(url + urlParams, { data: data })
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} delete took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        if (!res.data.exit_status || parseInt(res.data.exit_status) === 0) {
          resolve(res.data);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log(RestApiV2.errorMessageExtractor(err));
        reject(`${url} delete error: ${RestApiV2.errorMessageExtractor(err)}`);
      });

  });
};

RestApiV2.prototype.download = function (url, params, fileName) {
  const urlParams = this.generateParams(params);

  return new Promise((resolve, reject) => {
    const timeNow = new Date();
    if (process.env.LOG_LEVEL >= 2)
      console.log(`${url} download start:   ${timeNow.toTimeString()}`);
    // important to specify the responseType as blob
    this.axiosInstance
      .get(url + urlParams, { responseType: 'blob' })
      .then(res => {
        if (process.env.LOG_LEVEL >= 2)
          console.log(`${url} download took:   ${new Date().getTime() - timeNow.getTime()} ms`);
        // fileDownload takes the raw data from the response
        fileDownload(res.data, fileName);
        resolve("Successfully downloaded " + fileName);
      })
      .catch(async err => {
        err = await (new Response(err.response.data)).text();
        let text = err.match(/<p>(.*)<\/p>/);
        console.log(text && text[1]);
        reject(`${url} download error. ${text && text[1]}`);
      });
  }
  );
};
