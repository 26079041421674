// App.js
import React, { FunctionComponent } from 'react';
import TableComponent from './Table';

interface ITableProps {
  rows: any,
  columns: any,
  rowAction: any,
  showGlobalFilter?: boolean,
  paginated: boolean,
  onPageChange?: any,
  currentPage?: number,
  pageSize?: number,
  setPageSize?: any,
  noNextPage?: boolean,
}

const TableContainer: FunctionComponent<ITableProps> = ({
  columns,
  rows,
  rowAction,
  showGlobalFilter=false,
  paginated=false,
  onPageChange,
  currentPage,
  pageSize,
  setPageSize,
  noNextPage,
}) => {
  return (
    <TableComponent
      columns={columns}
      data={rows}
      showGlobalFilter={showGlobalFilter}
      //@ts-ignore
      getHeaderProps={column => ({
        onClick: () => {}
      })}
      //@ts-ignore
      getRowProps={row => ({
        onClick: () => rowAction(row.values)
      })}
      paginated={paginated}
      onPageChange={onPageChange}
      currentPage={currentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      noNextPage={noNextPage}
    />
  );
};

export default TableContainer;
