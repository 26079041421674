import { User } from '@raptormaps/raptor-models';
import RestApiV2 from "../api/RestApiV2";

class UsersApiClient {
  constructor() {
    this.base_url = '/users';
    this.api = new RestApiV2(null, `${window.REACT_APP_API_ENDPOINT}/api/v1`);
  }

  /**
   * Gets all raptor admin users
   * @returns {Promise}
   */
  getRaptorAdminUsers = () => {
    return new Promise((resolve, reject) => {
      const data = {
        request_params: {
          request_type: 'get_by_role',
          param_1: 'raptoradmin'
        }
      };

      this.api
        .post(this.base_url, data)
        .then(res => {
          const users = res.users.map(u => {
            u.orgs = [];
            return User.initFromDBUser(u)
          });
          resolve(users);
        })
        .catch(err => {
          reject(err);
          console.log(err);
        });
    });
  };
}

export default UsersApiClient;
