import React from 'react';

import { Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ReactLoading from 'react-loading';
// import uploadIcon from '../../../../../plant-labeler/customer/static/icons/upload/color-x2.png';

/**
 * Component containing dropzone for CSVs with order list
 *
 * @param show {bool}
 * @param toggle {function}
 * @param onDrop {function}
 * @param isLoading {bool}
 * @returns {*}
 * @constructor
 */
const CSVDropzoneModal = ({ show, toggle, onDrop, isLoading }) => {
  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>CSV Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="file-drop-container">
              <input {...getInputProps()} />
              {/* <img width={25} height={25} src={uploadIcon} className="uploader-drop-icon" /> */}
              <p className="uploader-drop-text">Drag files here or click to upload</p>
            </div>
          )}
        </Dropzone>
        {isLoading && (
          <div style={{marginTop: '1rem'}}>
            <ReactLoading type="spin" className="order-loading-spinner" />
            <span style={{marginLeft: '1rem'}}>Processing CSV Data</span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CSVDropzoneModal;
