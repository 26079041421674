import { combineReducers } from "redux";
import orderReducer from './orderReducer';
import ajaxCallsInProgress from "./ajaxStatusReducer";
import { sessionReducer } from "./sessionReducer";

const rootReducer = combineReducers({
  ajaxCallsInProgress,
  order: orderReducer,
  session: sessionReducer
});

export default rootReducer;
