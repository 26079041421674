import React from 'react';


const EmptyOrgUsers = () => {
  return (
    <div className='empty-org-users'>
      <span>Please select an organization to update</span>
    </div>
  )
};

export default EmptyOrgUsers;