import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import {
  ViewAllReports,
  DownloadAllReports,
  EditAllReports,
  CreateReports,
  DeleteReports,
  UploadFiles,
  DeleteFiles,
  RemoveUserButton
} from './OrganizationEditHelper';
//TODO:(sean) replace with the newer table
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedMessage } from 'react-intl';

/**@const JobPermittance - Table that renders the permissions for jobs for a user.
 * The formatting for the headers can be found in the OrganizationEditHelper file. */
const JobPermittance = props => {
  return (
    <Row>
      <Col xs={12}>
        <div className="panel">
          <BootstrapTable
            data={props.users}
            keyField="email"
            headerStyle={{ fontSize: '12px' }}
            bordered={false}
            className="rm-permissions-table"
          >
            <TableHeaderColumn
              dataField="email"
              width="28%"
              dataAlign="left"
              className="rm-column-header"
            >
              <FormattedMessage
                id="app.orgeditpage.email"
                defaultMessage="Email"
                description="Email column header"
              />
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_view_jobs_by_default"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              {ViewAllReports}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_download_jobs_by_default"
              dataAlign="center"
              width="10%"
              className="rm-column-header"
            >
              {DownloadAllReports}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_edit_jobs_by_default"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
              columnClassName="dotted-border"
            >
              {EditAllReports}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_create_jobs"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              {CreateReports}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_delete_jobs"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              {DeleteReports}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_upload_files"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              {UploadFiles}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={props.formatData}
              formatExtraData="can_delete_files"
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              {DeleteFiles}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataFormat={(row, cell) => {
                return <RemoveUserButton onRemoveUser={props.onRemoveUser} cell={cell} users={props.users} />;
              }}
              dataAlign="center"
              width="9%"
              className="rm-column-header"
            >
              <FormattedMessage
                id="app.orgeditpage.remove"
                defaultMessage="Remove"
                description="Remove column header"
              />
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </Col>
    </Row>
  );
};

PropTypes.JobPermittance = {
  users: PropTypes.array.isRequired,
  formatData: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired
};

export default JobPermittance;
