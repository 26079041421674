import { SolarInspection, SolarInspectionFile } from '@raptormaps/raptor-models';
import toastr from 'toastr';
import RestApiV2 from '../api/RestApiV2';

class SolarInspectionsApiClient {
  constructor(orgId) {
    this.base_url = '/solar_inspections';
    this.api = new RestApiV2(orgId);
  }

  /**
   * Gets solar farms by org id
   * @param orgId {number} - org id
   * @param searchKey {string} - farm name search key
   * @param limit {number} - number of results to return
   * @returns {Promise}
   */
  getSolarInspection = solarInspectionUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${this.base_url}/${solarInspectionUuid}`)
        .then(res => {
          const solarInspection = new SolarInspection().deserialize(res.solar_inspection);
          resolve(solarInspection);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  /**
   * Adds a file to a solar inspection
   * api - api_add_file_to_inspection
   *
   * @param solarInspectionUuid {uuid} -
   * @param fileId {number} -
   * @param fileType {string} -
   * @returns {Promise<SolarInspectionFile|*>}
   */
  addFile = async (solarInspectionUuid, fileId, fileType) => {
    const data = {
      file_id: fileId,
      inspection_file_type: fileType
    };

    try {
      const res = await this.api.post(`${this.base_url}/${solarInspectionUuid}/files`, data);
      return new SolarInspectionFile().deserialize(res.file);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Requests for a PDF of a report to be emailed to the user
   * @param {*} solarInspectionUuid - solar inspection to print
   * @param {*} filterParams - priority and tag filters to be applied to the report
   */
  getSolarInspectionPDF = (solarInspectionUuid, filterParams) => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this.base_url}/${solarInspectionUuid}/pdf`, filterParams)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  /**
   * Launches a tetris tile map creation/update for an inspection
   * @param {*} solarInspectionUuid - solar inspection associated with the tetris data
   */
  tetrisToTilemap = solarInspectionUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this.base_url}/${solarInspectionUuid}/tetris_to_tilemap`)
        .then(res => {
          toastr.success('Tetris to tile map process launched.');
          resolve(res);
        })
        .catch(err => {
          toastr.error(err);
          reject(err);
        });
    });
  };

  /**
   * Fetches tetris tile maps for a given inspection
   * @param inspectionUuid
   */
  getTetrisTileMaps = async inspectionUuid => {
    if (!inspectionUuid) throw Error('No inspection uuid provided to api call');

    return this.api.get(`${this.base_url}/${inspectionUuid}/tetris_tile_maps`);
  };

  /**
   * Fetches tetris tile maps for a given inspection
   * @param inspectionUuid
   */
  getTokenTetrisTileMaps = async (accessToken, inspectionUuid) => {
    if (!inspectionUuid) throw Error('No inspection uuid provided to api call');

    return this.api.get(
      `/token/${accessToken}/solar_inspections/${inspectionUuid}/tetris_tile_maps`
    );
  };

  /**
   * Updates a solar inspections flight boundary
   * @param {*} solarInspectionUuid - solar inspection associated with the boundary
   * @param {GeoJson} boundaryGeoJson - new boundary details
   */
  updateInspectionFlightBoundary = (solarInspectionUuid, boundaryGeoJson) => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this.base_url}/${solarInspectionUuid}/flight_boundary`, {
          flight_boundary: boundaryGeoJson
        })
        .then(res => {
          toastr.success('Flight boundary updated');
          resolve(res);
        })
        .catch(err => {
          toastr.error(err);
          reject(err);
        });
    });
  };
  /**
   * Updates the color palette on image sets associated with an inspection
   * @param {*} solarInspectionUuid - solar inspection to update image sets on
   * @param {*} colorPalette - new boundary details
   */
  launchInspectionImageColorUpdate = (solarInspectionUuid, colorPalette) => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this.base_url}/${solarInspectionUuid}/image_palette`, {
          color_palette: colorPalette
        })
        .then(res => {
          toastr.success('Launching image color update');
          resolve(res);
        })
        .catch(err => {
          toastr.error(err);
          reject(err);
        });
    });
  };
  /**
   * Updates all tags with the currentTagId to have the newTagId
   * for a given inspection
   * @param {*} solarInspectionUuid - solarInspection to update
   * @param {*} currentTagId - tag to update
   * @param {*} newTagId - tag to end up with
   */
  updateInspectionTags = (solarInspectionUuid, currentTagId, newTagId) => {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this.base_url}/${solarInspectionUuid}/update_tags`, {
          current_tag_id: currentTagId,
          new_tag_id: newTagId
        })
        .then(res => {
          toastr.success('Tags updated.');
          resolve(res);
        })
        .catch(err => {
          toastr.error(err);
          reject(err);
        });
    });
  };
  /**
   * Gets an image by id with shapes populated
   * @param imageId
   */
  getImage = (inspectionUuid, imageId) => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`${this.base_url}/${inspectionUuid}/images/${imageId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  /**
   * Gets an image with shapes populated using token
   * 
   * @param accessToken 
   * @param inspectionUuid 
   * @param imageId 
   */
  getTokenImage = async (accessToken, inspectionUuid, imageId) => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/token/${accessToken}/solar_inspections/${inspectionUuid}/images/${imageId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };
}

export default SolarInspectionsApiClient;
