import React, { Component } from "react";
import {
    Button, Dropdown, Form
} from "react-bootstrap";
import { connect } from 'react-redux';
import toastr from "toastr";
import { getDropDownFilterItem } from '../util';

//Models

import { populateOrgUsers } from '../actions/sessionActions';
import RestApiV2 from "../api/RestApiV2";

/**
 * @class DataRequestForm - form to fill out for a data request
 * @extends {Component}
 */
class DataRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      requestName: "",
      selectedOrg: null,
      selectedUser: null,
      notify: true,
      orgFilterText: "",
      userFilterText: ""
    };
    this.api = new RestApiV2()
  }
  
  onNameChange = e => {
    this.setState({
      requestName: e.target.value
    });
  };

  submitForm = () => {
    this.props
      .createUploadRequest({
        name: this.state.requestName,
        org_id: this.state.selectedOrg.id,
        creator_id: this.state.selectedUser.id,
        notify: this.state.notify
      })
      .then(() => {
        this.setState(
          {
            requestName: ""

          }, 
        );
        alert('Data Requested');
      })
      .catch(err => {
        toastr.error("Failed to create Upload Request");
      });
  };

  onSelectOrg = selectOrg=> {

    populateOrgUsers(selectOrg.id).then(users =>
        this.setState({
            selectedOrg:  {...selectOrg, users },
            selectedUser: null
          })
        )
  };

  onSelectUser = user => {
    this.setState({
      selectedUser: user
    });
  };

  onChangeNotify = e => {
    const val = e.target.checked;
    this.setState({
      notify: val
    });
  };

  handleOrgFilter = e => {
    this.setState({ orgFilterText: e.target.value });
  };

  handleUserFilter = e => {
    this.setState({ userFilterText: e.target.value });
  };
  
  render() {
    let filteredUsers = [];
    if (this.state.selectedOrg) {
      filteredUsers = this.state.selectedOrg.users;
      filteredUsers = filteredUsers.filter(user =>
        user.email
          .toLowerCase()
          .includes(this.state.userFilterText.toLowerCase())
      );
    }

    let filteredOrgs = this.props.orgs.filter(org =>
      org.name.toLowerCase().includes(this.state.orgFilterText.toLowerCase())
    );

    let formIsValid =
      this.state.requestName.length > 5 &&
      this.state.selectedOrg !== null &&
      this.state.selectedUser !== null;

    return (
      <div>
        <Form>
          <Form.Group>
            <Form.Label>Request Name</Form.Label>
            <Form.Control
              name={"Request Name"}
              type={"text"}
              disabled={false}
              value={this.state.requestName.toString()}
              error={this.state.errors.requestName}
              rows={"1"}
              onChange={this.onNameChange}
              placeholder={"Name request"}
              maxLength={"50"}
              onBlur={() => {}}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Destination Org</Form.Label>&nbsp;&nbsp;
            <Dropdown id={"dst_org_select"}>
              <Dropdown.Toggle>
                {this.state.selectedOrg
                  ? this.state.selectedOrg.name
                  : "-Select Org-"}
            </Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item as={() => getDropDownFilterItem(this.state.orgFilterText, "Filter Orgs", this.handleOrgFilter)}/>
                {filteredOrgs.map(org => (
                  <Dropdown.Item
                    onSelect={() => this.onSelectOrg(org)}
                    active={
                      this.state.selectedOrg &&
                      this.state.selectedOrg.id === org.id
                    }
                    key={org.id}
                    eventKey={org}
                  >
                    {org.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group>
            <Form.Label>Requesting User</Form.Label>&nbsp;&nbsp;
            <Dropdown id={"dst_user_select"} >
              <Dropdown.Toggle>
                {this.state.selectedUser
                  ? this.state.selectedUser.email
                  : "-Select User-"}
              </Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item as={() => getDropDownFilterItem(this.state.userFilterText, "Filter Users", this.handleUserFilter)} />
                {filteredUsers.map(user => (
                  <Dropdown.Item
                    active={
                      this.state.selectedUser &&
                      this.state.selectedUser.id === user.id
                    }
                    onSelect={() => this.onSelectUser(user)}
                    key={user.id}
                    eventKey={user}
                  >
                    {user.email}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group>
            <span>
              <Form.Label>Notify Requester of new uploads</Form.Label>
              <Form.Check
                checked={this.state.notify}
                onChange={this.onChangeNotify}
              ></Form.Check>
            </span>
          </Form.Group>
        </Form>
        <Button
          bsStyle="success"
          onClick={this.submitForm}
          disabled={!formIsValid}
        >
          Submit
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        orgs: state.session.orgs
    }
}

export default connect(mapStateToProps, null)(DataRequestForm);
