import { hasManyTrailingZeros, LOCATION_PRECISION, validateIsAccurate } from '@raptormaps/raptor-lib';
import * as Yup from 'yup';
import { orderValuesets } from '../../OrderStatus/util/constants';

// Matches floats. No negatives
const decimalRegex = /^\d*(\.\d+)?$/;

// Matches dollar value, no $ sign, two decimals, no negatives
const revenueRegex = /^\d*\.?\d{1,2}$/;
const isRequired = ' is required!';

const isExistingFarm = self => {
  const pathRegex = /\[(\d+)\]/;
  const [_, idx] = pathRegex.exec(self.path);

  return self.options.context?.orderList[idx]?.existingFarm;
};

const schema = Yup.array().of(
  Yup.object({
    solar_farm: Yup.object({
      name: Yup.string()
        .max(100)
        .required('Site name' + isRequired)
        .nullable(),
      size: Yup.string()
        .max(100)
        .matches(decimalRegex, 'Please enter a valid number')
        .min(0, 'Size size cannot be negative!')
        .nullable()
        .test('is-not-zero', 'Site size cannot be zero', function(val) {
          return (Number(val) !== 0); 
        })
        .test('is-site-req', `Site size ${isRequired}`, function(value) {
          /* 
            - Function determines if site size is a required field based on order type. If 
              it is a CAD order then site size is not required.

            - Yup does not provide access to parent objects so we must use the path and 
              context to access the correct SolarOrder to obtain the current order type. 
          */

          const pathRegex = /\[(\d+)\]/;
          const [_, idx] = pathRegex.exec(this.path);
          const order_type =
            orderValuesets.order_type[this.options.context?.orderList[idx]?.order_type];

          return orderValuesets.order_type.cad === order_type ? true : Boolean(value);
        }),
      ppa_rate: Yup.string()
        .nullable()
        .test('is-number', 'PPA Rate must be a number', function(val) { return isExistingFarm(this) || !val || !isNaN(val); })
        .test('is-positive', 'PPA Rate must be positive and not 0', function(val) { return isExistingFarm(this) || !val || parseFloat(val) > 0}),
      location: Yup.object({
        lat: Yup.number()
          .min(-90, 'Latitude must be between -90 and 90!')
          .max(90, 'Latitude must be between -90 and 90!')
          .test('is-latitude', `Latitude must be between -90 and 90.`, function(val) {
            return isExistingFarm(this) || (val ? isFinite(val) && Math.abs(val) <= 90 : true);
          }),
        lng: Yup.number()
          .min(-180, 'Longitude must be between -180 and 180!')
          .max(180, 'Longitude must be between -180 and 180!')
          .test('is-longitude', `Longitude must be between -180 and 180`, function(val) {
            return isExistingFarm(this) || (val ? isFinite(val) && Math.abs(val) <= 180 : true);
          })
      }),
      latitudeEditing: Yup.string()
      .test('allow-one-trailing-zero', 'Location may only end in one trailing zero', function(val) {
        return !hasManyTrailingZeros(val)
      })
      .test(
        'is-accurate',
        `Location must be at least ${LOCATION_PRECISION} decimal places accurate`,
        function(val) {
          return (
            isExistingFarm(this) || (val ? validateIsAccurate(val, LOCATION_PRECISION) : true)
          );
        }
      ),
      longitudeEditing: Yup.string()
      .test('allow-one-trailing-zero', 'Location may only end in one trailing zero', function(val) {
        return !hasManyTrailingZeros(val)
      })
      .test(
        'is-accurate',
        `Location must be at least ${LOCATION_PRECISION} decimal places accurate`,
        function(val) {
          return (
            isExistingFarm(this) || (val ? validateIsAccurate(val, LOCATION_PRECISION) : true)
          );
        }
      ),
    }),
    data_collection_org_id: Yup.number().nullable(true),
    contract_type: Yup.string()
      .required('Project type' + isRequired)
      .nullable(),
    order_type: Yup.string()
      .required('Order type' + isRequired)
      .nullable(),
    requested_flight_date: Yup.string()
      .required('Requested flight date' + isRequired)
      .nullable(),
    requested_delivery_date: Yup.string()
      .required('Requested delivery date' + isRequired)
      .nullable(),
    analytics_revenue: Yup.string()
      .matches(revenueRegex, 'Please enter a valid number')
      .max(100, 'Too long')
      .nullable(),
    flight_revenue: Yup.string()
      .matches(revenueRegex, 'Please enter a valid number')
      .max(100, 'Too long')
      .nullable()
  })
);

export default schema;
