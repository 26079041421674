// @ts-nocheck
import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import OrderTable from './OrderTable';
import { getStatusColumns } from './util';
import {
  defaultTableSort, orderStatuses, orderTableTabs,
  orderValuesets
} from './util/constants';

/**
 * Table with collapsable rows. Each row in the outer table expands to show another table
 * @param {order_status: [],...} ordersByStatus 
 * @param {func} setIsPolling - control the order summary polling
 * @param {[]} orgs - list of all available orgs
 * @param {field: string, value: string} - current filter value to apply to all tables
 */
const MultistatusTable = ({
  ordersByStatus,
  setIsPolling,
  orgs
}) => {

  const orderStatusesDisplay = orderValuesets.status;

  const [expandedRows, setExpandedRows] = useState([
    orderStatusesDisplay[orderStatuses.PROCESSED],
    orderStatusesDisplay[orderStatuses.QC],
    orderStatusesDisplay[orderStatuses.DISPATCHED],
    orderStatusesDisplay[orderStatuses.SUBMITTED],
    orderStatusesDisplay[orderStatuses.SITE_SETUP]
  ]);
  const [currentSort, setCurrentSort] = useState(defaultTableSort);

  // Sets the current desired sort to apply to all children tables
  const handleColumnSort = (dataField, order) => {
    setCurrentSort({dataField, order})
  }

  const data = [
    {
      tableStatus: orderStatusesDisplay[orderStatuses.PROCESSED]
    },
    {
      tableStatus: orderStatusesDisplay[orderStatuses.DISPATCHED]
    },
    {
      tableStatus: orderStatusesDisplay[orderStatuses.SITE_SETUP]
    },
    {
      tableStatus: orderStatusesDisplay[orderStatuses.SUBMITTED]
    },
  ];

  // If the row is already expanded, collapse
  // If the row is collapsed, expand
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpandedRows([...expandedRows,row.tableStatus])
    } else {
      setExpandedRows(expandedRows.filter(r => r !== row.tableStatus));
    }
  }
  /**
   * Gets all orders of a particular status and applies the current text filter
   * @param {*} ordersByStatus - orders indexed by their status 
   * @param {*} subTableDisplayStatus - status of orders to apply filter to
   */
  const getTableOrdersForStatus = (ordersByStatus, subTableDisplayStatus) => {
    // Get the integer status from the table display status
    const subtableIntegerStatus = Object.keys(orderStatusesDisplay).find(displayStatus => subTableDisplayStatus === orderStatusesDisplay[displayStatus]);

    if (ordersByStatus[subtableIntegerStatus]) {
      return ordersByStatus[subtableIntegerStatus]
    }
    return [];
  } 
  /**
   * Renders a table in each expanded space of the parent table rows
   * Row expanding is externally controlled by the parent component
   */
  const rowExpand = {
    renderer: row =>  (
      <OrderTable
        orders={getTableOrdersForStatus(ordersByStatus, row.tableStatus)}
        editable
        onOrderModalOpen
        orgs={orgs}
        sort={currentSort}
        activeTab={orderTableTabs.ONGOING}
        onOrderModalOpen={() => setIsPolling(false)}
        onOrderModalClose={() => setIsPolling(true)}
        showHeader={false}
      />
    ),
    expanded: expandedRows,
    onExpand: handleOnExpand
  }

  return (
    <BootstrapTable
      expandRow={rowExpand}
      id='multistatus_table'
      data={data}
      bootstrap4
      keyField='tableStatus'
      columns={getStatusColumns(null, orderTableTabs.ONGOING, expandedRows, handleColumnSort)}
      headerClasses='multitable-header'
      rowStyle={{cursor: 'pointer'}}
      rowClasses={'multitable-row'}
      bordered={false}
      sort={{}}
    />
  );
}

export default MultistatusTable;