import React, { useEffect, useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrgs } from '../actions/sessionActions';
//API
// import { RestApiV2 } from '@raptormaps/raptor-lib';
// Bootstrap
import { Button, Row } from 'react-bootstrap';
//Bootstrap Typeahead
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

//Constant

//Bootstrap Table Next
import BootstrapTable from 'react-bootstrap-table-next';

// Models

import { getFarmOrgUsers, getFarms, getUsers } from '../actions/adminActions';
import RestApiV2 from "../api/RestApiV2";

// map status number to boolean value
const statusMap = {
  47: true,
  48: false
};

/**
 * FarmOrgUsersViewer - container for the displaying a table for farm org users, with filtering on farm and org
 * @return {*}
 * @constructor
 */
const FarmOrgUsersViewer = props => {
  const [farms, setFarms] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectFarm, setSelectFarm] = useState(null);
  const [farmOrgUsers, setFarmOrgUsers] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const api = new RestApiV2();

  useEffect(() => {
    selectFarm || selectUser
      ? getFarmOrgUsers(selectFarm, selectUser).then(res =>
          setFarmOrgUsers(res['solar_farm_org_users'])
        )
      : setFarmOrgUsers([]);
  }, [selectFarm, selectUser]);

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true
    },
    {
      dataField: 'solar_farm_name',
      text: 'Farm'
    },
    {
      dataField: 'org_name',
      text: 'Org',
      sort: true
    },
    {
      dataField: 'user_email',
      text: 'User Email',
      sort: true,
      headerStyle: { width: '30%' }
    },
    {
      dataField: 'can_edit',
      text: 'Can Edit',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: cell => statusMap[cell]
    }
  ];

  /**
   * Generates a unique id for the table columns
   * @param {*} data
   */
  const generateId = data => data.map((d, i) => ({ id: i, ...d }));

  // Refs for typeahead
  let farmInput = React.useRef(null);
  let userInput = React.useRef(null);

  return (
    <div style={{ width: '90%', margin: 'auto' }}>
      <div style={{ display: 'flex' }}>
        <AsyncTypeahead
          options={farms}
          onSearch={query => getFarms(query).then(res => setFarms(res))}
          labelKey={farm => `${farm.name}`}
          minLength={3}
          placeholder="Type to Search Farms..."
          onChange={selected =>
            selected.length > 0 ? setSelectFarm(selected[0].uuid) : setSelectFarm(null)
          }
          ref={farmInput}
          className="farm-org-user-typeahead"
        />

        <Button
          onClick={() => {
            farmInput.current.getInstance().clear();
            setSelectFarm(null);
          }}
          style={{ height: '38px' }}
        >
          Clear
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        <AsyncTypeahead
          options={users}
          onSearch={query => getUsers(query).then(res => setUsers(res))}
          labelKey={user => `${user.email}`}
          minLength={3}
          placeholder="Type to Search Users..."
          onChange={selected =>
            selected.length > 0 ? setSelectUser(selected[0].id) : setSelectUser(null)
          }
          ref={userInput}
          className="farm-org-user-typeahead"
        />
        <Button
          onClick={() => {
            userInput.current.getInstance().clear();
            setSelectUser(null);
          }}
          style={{ height: '38px' }}
        >
          Clear
        </Button>
      </div>
      <Row>
        <BootstrapTable keyField="id" columns={columns} data={generateId(farmOrgUsers)} />
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    orgs: state.session.orgs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getOrgs
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmOrgUsersViewer);
