import React from 'react';

export const ContactDisplay = contact => {
  return (
    <>
      <div>
        Name:{' '}
        {contact.first_name === contact.last_name
          ? `${contact.first_name} `
          : `${contact.first_name} ${contact.last_name}`}
      </div>
      <div>Company: {contact.company_name}</div>
      <div>Email: {contact.email}</div>
      <div>Phone: {contact.primary_phone}</div>
    </>
  );
};
