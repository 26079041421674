import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormGroup, Form } from 'react-bootstrap';

/**
 * Typeahead wrapper for selecting a contact for the solar inspection
 * @param onSelectContact {func} callback when contact is selected
 * @param selectedContactUuid {uuid} selected contact to show in typeahead
 * @param contacts {Contact[]} all contact options to choose from
 * @param toggleNewContact {func} toggle the modal to create a new contact
 * @return {*}
 * @constructor
 */
const ContactPicker = ({ onSelectContact, selectedContactUuid, contacts, toggleNewContact, showNewContact=true, disabled }) => {
  const selectedContact = contacts.find(c => c.uuid === selectedContactUuid);
  return (
    <FormGroup>
      <Typeahead
        name="contact"
        id="contact"
        selected={selectedContact ? [selectedContact] : []}
        options={contacts}
        placeholder="Type to search..."
        labelKey={contact =>
          contact.first_name === contact.last_name
            ? `${contact.first_name} ${contact.company_name}`
            : `${contact.first_name} ${contact.last_name} ${contact.company_name}`
        }
        onChange={val => {
          val.length > 0 && onSelectContact(val[0].uuid);
        }}
        disabled={disabled}
      />
      {showNewContact && 
        <Form.Text className="text-muted">
          <i>
            <span className="new-equiptment-button" onClick={toggleNewContact}>
              + New Contact
            </span>
          </i>
        </Form.Text>
      }
    </FormGroup>
  );
};

export default ContactPicker;

