import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col, DropdownButton, Form } from "react-bootstrap";
import FlightBoundaryMap from "./FlightBoundaryMap";
import OrdersApiClient from "../../../../../apiClient/OrdersApiClient";

/**
 * Allows for the editing of an order's inspection's flight boundaries.
 * @param {*} order - currently selected order
 * @param {*} orderActions - suite of redux actions for the order
 * @param {*} solarFarm - farm associated with the currently selected order
 */
const FlightBoundaryTab = ({ order, orderActions, solarFarm }) => {
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [setOrderUploadSessions] = useState([]);

  useEffect(() => {
    handleFetchUploadSessions();
    setSelectedInspection(order && order.inspections && order.inspections[0]);
  }, [order]);

  /**
   * Launch api call to update boundary geojson
   * @param {*} boundaryGeojson
   */
  const updateInspectionFlightBoundary = (boundaryGeojson) => {
    if (
      order &&
      selectedInspection &&
      selectedInspection.flight_boundary !== boundaryGeojson
    ) {
      orderActions.updateInspectionFlightBoundary(
        order.uuid,
        selectedInspection.uuid,
        boundaryGeojson
      );
    }
  };

  /**
   * Fetch Order Upload Sessions for the current order
   */
  const handleFetchUploadSessions = () => {
    const ordersApi = new OrdersApiClient();

    ordersApi
      .getOrderUploadSessions(order.uuid)
      .then((orderUploadSessions) =>
        setOrderUploadSessions(orderUploadSessions)
      );
  };

  return (
    <Row style={{ paddingTop: "30px" }}>
      <Col sm={10}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Inspection:</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  (selectedInspection && selectedInspection.name) ||
                  "Select Inspection"
                }
                variant="primary"
              >
                {order &&
                  order.inspections &&
                  order.inspections.map((i) => (
                    <Dropdown.Item onClick={() => setSelectedInspection(i)}>
                      {i.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <FlightBoundaryMap
            inputBoundary={
              selectedInspection && selectedInspection.flight_boundary
            }
            onBoundaryUpdate={(boundaryGeojson) =>
              updateInspectionFlightBoundary(boundaryGeojson)
            }
            solarFarm={solarFarm}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default FlightBoundaryTab;
