
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS';

// Session items
export const UPDATE_ORG_ID = 'UPDATE_ORG_ID';
export const LOAD_ORGS_SUCCESS = 'LOAD_ORGS_SUCCESS';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';




