/*
 * File: security.js
 * This file contains a singleton that we use to do 2 things:
 * 1) set a reference to the Auth0 SDK's getAccessTokenSilently() function when
 *    a FE app is initialized (function requires react context to access)
 * 2) allow this 'sec' object to be imported into RestAPIV2 (non-React file) to
 *    embed use of the getAccessTokenSilently() call before all API calls
 *    per Auth0 guidance
 */

// Singleton to hold a reference to the Auth0 function
let getAccessTokenSilently = null;

export const sec = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: (func) => (getAccessTokenSilently = func)
};
