import { Container } from "react-bootstrap";
import { FunctionComponent } from "react";
import { Text, TextVariant } from "@raptormaps/text";
import { Row } from "@raptormaps/layout";
import { Button } from "@raptormaps/button";
import useAppsmith from "../../hooks/useAppsmith";

const ManageOrganizations: FunctionComponent = () => {
  const { canAccessDev, canAccessQA, canAccessProd } = useAppsmith(
    window.location.href
  );

  return (
    <Container style={{ marginTop: "10px" }}>
      <Text variant={TextVariant.h1}>Manage Organizations</Text>
      <Container style={{ marginTop: "10px" }}>
        <Text variant={TextVariant.paragraph_large}>
          Organization Management is available in Appsmith. If you do not
          currently have access to Appsmith, please contact
          software@raptormaps.com to gain access.
        </Text>
      </Container>
      <Container style={{ marginTop: "10px" }}>
        <Row align="center" justify="center">
          {canAccessDev && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/dev-manage-orgs/org-editor-63e540c9b0cf3a60c8245624"
                )
              }
            >
              Manage Orgs in Dev
            </Button>
          )}
          {canAccessQA && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/qa-manage-orgs/org-editor-63e540b9b0cf3a60c824561d"
                )
              }
            >
              Manage Orgs in QA
            </Button>
          )}
          {canAccessProd && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/prod-manage-orgs/org-editor-63e5416fb0cf3a60c824562e"
                )
              }
            >
              Manage Orgs in Production
            </Button>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default ManageOrganizations;
