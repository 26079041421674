import React, { useState } from 'react';
import { Modal, ModalBody, ModalTitle, Button } from 'react-bootstrap';
import CancelModalTitle from './OrderEditCancelModal/CancelModalTitle';
import { orderStatuses, orderValuesets } from '../util/constants';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import toastr from 'toastr';
import SolarOrdersApiClient from '../../../apiClient/SolarOrdersApiClient';

// styled components
import { CancelOrderModalBody } from '../styles/OrderStatus_styles';
/**
 * Modal to confirm whether a user wishes to cancel an order
 * @param {}
 */
const OrderEditConfirmCancelModal = ({
  showCancelModal,
  handleSubmit,
  handleModalClose,
  isOrderCancelled,
  orderUuid
}) => {
  const [restoreStatus, setRestoreStatus] = useState(null);

  
  /**
   * Displays the status dropdown with a restricted list of available statuses.
   */
  const getStatusDropdown = (value) => {
    // Order the statuses chronologically
    const orderedOrderStatuses = [
      orderStatuses.STARTED,
      orderStatuses.SUBMITTED
    ];
    return (
      <div>
        <br/>
        <i>Select order restore status</i><DropdownButton menuAlign={"right"} size="sm" title={orderValuesets.editable_statuses[restoreStatus] || `Select Status`}>
          {orderedOrderStatuses.map((p) => (
            <Dropdown.Item
              value={orderValuesets.editable_statuses[p]}
              onSelect={() =>
                setRestoreStatus(p)
              }
            >
              {orderValuesets.editable_statuses[p]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <br/>
      </div>
    );
  };

  const handleRestoreCancelledOrder = () => {
    const solarOrdersApi = new SolarOrdersApiClient();
    solarOrdersApi
      .restoreCancelledOrder(orderUuid, restoreStatus)
      .then(() => {
        handleModalClose();
        setRestoreStatus(null);
      })
      .catch(error => {
        toastr.error(error)
        console.log(error);
      });
  };

  const handleModalCloseComponentLocal = () => {
    handleModalClose();
    setRestoreStatus(null);
  }

  return (
    <Modal show={showCancelModal} style={{ textAlign: 'center' }}>
      <CancelModalTitle isOrderCancelled={isOrderCancelled}/>
      <ModalBody>
        {isOrderCancelled ?
        <div>
          <CancelOrderModalBody>
            Updates to the order will not be saved during the un-cancellation process.
            <br />
            Note: <b>ALL</b> deleted or cancelled inspections attached to the order will be
            restored.
          </CancelOrderModalBody>
          {getStatusDropdown(restoreStatus)}
          <Button variant={'danger'} onClick={handleRestoreCancelledOrder} style={{ marginRight: '15px' }}>
              {`Yes Restore Cancelled Order`}
          </Button>
          <Button onClick={handleModalCloseComponentLocal}>No, Return to Order</Button>
        </div>
        : 
        <div>
          <CancelOrderModalBody>
              Cancelling an order will cancel <b>ALL</b> associated inspections.{' '}
          </CancelOrderModalBody>
          <Button variant={'danger'} onClick={handleSubmit} style={{ marginRight: '15px' }}>
            {`Yes Cancel Order`}
          </Button>
          <Button onClick={handleModalCloseComponentLocal}>No, Return to Order</Button>
        </div>
        }
      </ModalBody>
    </Modal>
  );
};

export default OrderEditConfirmCancelModal;
