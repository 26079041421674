import { AdminActionTypes } from "../actions/adminActions";
import * as types from "../state/types/orderActionTypes";

const initialState = {
  order: null,
  orders: null,
  filteredOrders: null,
  filtered: false,
  channel_instructions: null,
  services: null,
  accountingInfoItems: null,
  metrics: null,
  visibleOrderCount: null,
  solarFarm: null,
  loadedEvents: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
      };

    case types.GET_ALL_ORDERS_SUCCESS:
      // Replaces all orders with new orders
      return {
        ...state,
        orders: action.orders.reduce((acc, curr) => {
          // Orders are an object with keys being orderUuid and values being the order itself
          acc[curr.uuid] = curr;
          return acc;
        }, {}),
      };
    case types.SET_ITS_FILTERED:
      return {
        ...state,
        filtered: action.payload.flag,
      };

    case types.GET_MORE_ORDERS_SUCCESS:
      // Appends new orders to the list of existing orders
      const newlyAddedOrdersByUuid = action.orders.reduce((acc, curr) => {
        acc[curr.uuid] = curr;
        return acc;
      }, {});

      return {
        ...state,
        orders: {
          ...state.orders,
          ...newlyAddedOrdersByUuid,
        },
      };
    case types.UPDATE_ORDER_SUCCESS:
      const newOrders = { ...state.orders };
      let newOrder = action.order;

      // set missing values in order obj
      newOrder.solar_farm_name = newOrders[action.order.uuid].solar_farm_name;
      newOrder.client_org = newOrders[action.order.uuid].client_org;
      newOrder.color = newOrders[action.order.uuid].color;

      newOrders[action.order.uuid] = newOrder;

      return {
        ...state,
        orders: newOrders,
      };
    case types.GET_ALL_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        channel_instructions: action.instructions,
      };
    case types.GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.services,
      };
    case types.UPDATE_INSPECTION_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            inspections: [
              ...state.orders[action.orderUuid].inspections,
              action.solarInspection,
            ],
          },
        },
      };
    case types.UPDATE_ACCESS_TOKEN_SUCCESS:
      const uploadRequests = state.orders[action.orderUuid].upload_requests;
      // Remove the previous version of the upload request
      const filteredRequests = uploadRequests.filter(
        (u) => u.uuid !== action.uploadRequest.uuid
      );
      // Replace it with the updated version
      filteredRequests.push(action.uploadRequest);
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            upload_requests: filteredRequests,
          },
        },
      };
    case types.ADD_REQUEST_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.order.uuid]: {
            ...state.orders[action.order.uuid],
            upload_requests: [
              ...state.orders[action.order.uuid].upload_requests,
              ...action.order.upload_requests,
            ],
          },
        },
      };
    case types.REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            upload_requests: [
              ...state.orders[action.orderUuid].upload_requests.filter(
                (u) => u.uuid !== action.uploadRequestUuid
              ),
            ],
          },
        },
      };
    case types.REMOVE_INSPECTION_ORDER_SUCCESS:
      const inspectionsWithoutRemoved = state.orders[
        action.orderUuid
      ].inspections.filter((ins) => ins.uuid !== action.solarInspection.uuid);
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            inspections: inspectionsWithoutRemoved,
          },
        },
      };
    case types.UPDATE_INSTRUCTION_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            channel_instructions:
              // Remove the entire instruction option from the list of instruction orders, replace with the newly updated instruction
              [
                ...state.orders[action.orderUuid].channel_instructions.filter(
                  (i) => i.uuid !== action.instruction.uuid
                ),
                action.instruction,
              ],
          },
        },
      };
    case types.DELETE_ORDER_SUCCESS:
      const orderKeysWithoutDeleted = Object.keys(state.orders).filter(
        (uuid) => uuid !== action.orderUuid
      );
      return {
        ...state,
        orders: {
          ...orderKeysWithoutDeleted.map((k) => state.orders[k]),
        },
      };
    case AdminActionTypes.GET_FILES_SUCCESS:
      const orderInspection = {
        ...state.orders[action.orderUuid].inspections.find(
          (i) => i.uuid === action.inspectionUuid
        ),
      };
      orderInspection.files = action.files;

      const filteredInspections = [
        ...state.orders[action.orderUuid].inspections,
      ].filter((i) => i.uuid !== action.inspectionUuid);
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            inspections: [...filteredInspections, orderInspection],
          },
        },
      };

    case types.GET_ACCOUNTING_INFO_ITEMS_SUCCESS:
      return {
        ...state,
        accountingInfoItems: keyBy(action.accountingInfoItems, "internal_code"),
      };

    case types.ADD_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.order.uuid]: {
            ...state.orders[action.order.uuid],
            accounting_info_items: [...action.order.accounting_info_items],
          },
        },
      };

    case types.REMOVE_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            accounting_info_items: [
              ...state.orders[action.orderUuid].accounting_info_items,
            ].filter(
              (i) => i.accounting_item_codekey !== action.accountingItemCode
            ),
          },
        },
      };

    case types.GET_METRICS_SUCCESS:
      return {
        ...state,
        metrics: action.metrics,
      };
    case types.SET_VISIBLE_ORDER_COUNT:
      return {
        ...state,
        visibleOrderCount: {
          ...state.visibleOrderCount,
          [action.orderTabKey]: action.numberToIncrement,
        },
      };

    case "GET_SOLAR_FARM_BY_UUID_SUCCESS":
      return {
        ...state,
        solarFarm: action.solarFarm,
      };
    case "CLEAR_SOLAR_FARM":
      return {
        ...state,
        solarFarm: null,
      };
    case "UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY_SUCCESS":
      const actionInspection = {
        ...state.orders[action.orderUuid].inspections.find(
          (i) => i.uuid === action.inspectionUuid
        ),
      };
      actionInspection.flight_boundary = action.boundaryGeojson;

      const orderInspectionsWithoutActionIns = [
        ...state.orders[action.orderUuid].inspections,
      ].filter((i) => i.uuid !== action.inspectionUuid);

      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderUuid]: {
            ...state.orders[action.orderUuid],
            inspections: [
              ...orderInspectionsWithoutActionIns,
              actionInspection,
            ],
          },
        },
      };
    case types.GET_REPORT_PROVIDER_SUCCESS:
      const report_providers = [...action.report_providers];
      let reportProviderObj = {};
      report_providers.map((item) => {
        const id = item.id;
        const key = item.name.replace(" ", "_").toLowerCase();
        const value = item.name;
        reportProviderObj[key] = {
          id,
          key,
          value,
        };
      });
      return {
        ...state,
        report_providers: reportProviderObj,
      };

    case types.GET_REPORT_PROVIDER_FAILURE:
      return {
        ...state,
        report_providers: {},
      };
    case types.LOADED_GET_EVENTS:
      const loadedEvents = action.payload;
      return {
        ...state,
        loadedEvents
      };
    case types.GET_EVENTS_BY_ORDERID_SUCCESS:
      const newEvents = [...action.payload.events];
      return {
        ...state,
        events: newEvents,
      };
    case types.GET_EVENTS_BY_ORDERID_FAILURE:
      return {
        ...state,
        events: [],
      };
    default:
      return state;
  }
};

/**
 * Keys by an given object property
 * @param object
 * @param property
 * @returns {*}
 */
const keyBy = (object, property) => {
  return object.reduce((acc, curr) => {
    acc[curr[property]] = curr;
    return acc;
  }, {});
};

export default orderReducer;
