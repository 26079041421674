import styled, { css } from 'styled-components';

export const Section = styled.div.attrs(() => ({
  className: 'Section'
}))`
  margin: 20px;
`;

export const TableSection = styled.div.attrs(() => ({
  className: 'TableSection'
}))`
  padding-bottom: 40px;
`;

export const Title = styled.h1.attrs(() => ({
  clasName: 'Title'
}))`
  text-align: center;
  font-weight: 500;
  margin: 20px 0;
  font-size: 26px;
`;

export const ModalContent = styled.div.attrs(() => ({
  className: 'ModalContent'
}))`
  display: flex;
  flex-direction: column;
`;

export const ModalInputsContainer = styled.div.attrs(() => ({
  className: 'ModalInputsContainer'
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ModalOrgTitle = styled.div.attrs(() => ({
  className: 'ModalOrgTitle'
}))`
  font-size: 22px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.GlobalColor.rGrey1};
  border: solid 1px ${({ theme }) => theme.colors.GlobalColor.rBlack3};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rGrey5};
  padding: 4px 15px;
  cursor: not-allowed;
`;

export const CustomSelect = styled.select.attrs(() => ({
  className: 'ModalOrgSelect'
}))`
  font-size: 22px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.GlobalColor.rBlack2};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rGrey5};
  padding: 4px 15px;
  margin-left: 10px;
`;

export const FormContent = styled.div.attrs(() => ({
  className: 'FormContent'
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const FormInputContainer = styled.div.attrs(() => ({
  className: 'FormInputContainer'
}))`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  div{
    margin-right: 10px;
  }
`;

export const ErrorText = styled.span.attrs(() => ({
  className: 'ErrorText'
}))`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.GlobalColor.rRed1};
  transition: all 1s;
`;
