import React from 'react';

/**
 * StatusDisplay - Presentational component for displaying a label and status
 * @param {*} label
 * @param {*} status
 */
export const StatusDisplay = ({ label, status }) => (
  <div style={{ marginTop: '20px' }}>
    <label style={{ fontWeight: 'bold' }}>{label}:</label>
    <div>{status}</div>
  </div>
);

/**
 * OrgDetailsDisplay - Presentational component for displaying org and channel names with message 
 * @param {*} clientOrgNames 
 * @param {*} channelOrgNames 
 */
export const OrgDetailsDisplay = ({ clientOrgNames, channelOrgNames }) => (
  <div>
    <div style={{ fontWeight: 'bold' }}>
      If you continue with the actions the following orgs will recieve access:
    </div>
    <div>{clientOrgNames.join(', ')}</div>
    <div style={{ fontWeight: 'bold' }}>And the following orgs with lose access:</div>
    {channelOrgNames.join(', ')}
  </div>
);
