import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import { Button, Container } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { OrderActionCreator } from '../../../../../actions/orderActions';
import { orderValuesets } from '../../../util/constants';

/**
 * Button for initiating a task to duplicate a construction order.
 * @param {Object} order - The order to be duplicated if it is of type construction
 */
const DuplicateConstructionOrderButton = ({ order }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionName, setInspectionName] = useState('');

  useEffect(() =>
    order.inspections?.length
      ? setInspectionName(order.inspections[order.inspections.length - 1].name)
      : setInspectionName('Duplicate Inspection Name')
  );
  const isConstructionOrder =
    order.order_type === orderValuesets.order_type.construction.toLowerCase();
  const handleClick = values => {
    setIsLoading(true);
    OrderActionCreator.duplicateConstructionOrder(order.uuid, values.newInspectionName).then(res =>
      setIsLoading(false)
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        newInspectionName: inspectionName
      }}
      onSubmit={values => handleClick(values)}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <div>
            <Row>
              <Form.Group>
                <Form.Label>New Inspection Name</Form.Label>
                <FormControl
                  placeholder="New Inspection Name"
                  value={values.newInspectionName}
                  onChange={e => setFieldValue('newInspectionName', e.target.value)}
                  style={{ width: '500px' }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Button
                value="Copy Construction Order"
                variant="primary"
                disabled={!isConstructionOrder}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <ReactLoading type="spin" width="30px" height="30px" color="#97C93D" />
                ) : (
                  'Copy Construction Order'
                )}
              </Button>
            </Row>
          </div>
        );
      }}
    </Formik>
  );
};

export default DuplicateConstructionOrderButton;
