import RaptorAdminRoutesApiClient from '../apiClient/RaptorAdminRoutesApiClient';
import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
//import {loadOrgsSuccess} from './tagActions';
import toastr from 'toastr';

import { User } from '@raptormaps/raptor-models';
import RestApiV2 from "../api/RestApiV2";

export function loadOrgsSuccess(orgs) {
  return { type: types.LOAD_ORGS_SUCCESS, orgs };
}

export const createOrgSuccess = org => ({
  type: types.CREATE_ORG_SUCCESS,
  org
});

export function updateOrgId(orgId) {
  return { type: types.UPDATE_ORG_ID, orgId };
}

/**
 * @function loadOrgs - api_get_orgs
 * Gets all orgs from the database
 * @return {function(*=): Promise}
 */
export function getOrgs() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return new Promise((resolve, reject) => {
      const api = new RestApiV2();
      return api
        .get('/raptor_admin/orgs')
        .then(res => {
          resolve();
          dispatch(loadOrgsSuccess(res.orgs));
        })
        .catch(err => {
          reject(err);
          console.log(err);
          toastr.error(err);
        });
    });
  };
}

/**
 * Creates a new org
 * @param orgName {string} -
 * @param orgType {ORG_TYPES} -
 * @param masterEmail {string} -
 * @returns {function(*=): Promise}
 */
export const createOrg = (orgName, orgType, masterEmail) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const api = new RaptorAdminRoutesApiClient();

      api
        .createOrg(orgName, orgType, masterEmail)
        .then(org => {
          dispatch(createOrgSuccess(org));
          resolve(org);
        })
        .catch(err => reject(err));
    });
  };
};

/**
 * Gets all users for the given org id
 * api - api_raptor_admin_get_org_users
 */
export const populateOrgUsers = orgId => {
  const api = new RestApiV2();
  return new Promise((resolve, reject) => {
    api
      .get(`raptor_admin/${orgId}/users`)
      .then(res => {
        if (res['exit_status'] === 0) {
          let users = res['users'].map(u => User.initFromDBUser(u));
          resolve(users);
        }
      })
      .catch(err => {
        reject(err);
        console.log(err);
      });
  });
};
