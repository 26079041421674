import styled, { css } from 'styled-components';

export const ModalOverlay = styled.div.attrs(() => ({
  className: 'RaptorModal_Overlay'
}))`
  position: fixed;
  display: block;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

export const ModalContainer = styled.div.attrs(() => ({
  className: 'RaptorModal_Container'
}))`
  margin: 5% auto;
  background-color: white;
  border-radius: 0.25rem;
  width: fit-content;
  max-width: 900px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rWhite};
  color: ${({ theme }) => theme.colors.GlobalColor.rBlack3};
`;

export const ModalHeader = styled.div.attrs(() => ({
  className: 'RaptorModal_Header'
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  min-width: 400px;
  padding: 1rem 2rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rWhite};
  svg{
    cursor: pointer;
  }
`;

export const ModalBody = styled.div.attrs(() => ({
  className: 'RaptorModal_Body'
}))`
  padding: 1rem 2rem;
`;