import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderActionCreator } from '../../actions/orderActions';
import InstructionEdit from './InstructionEdit';
import ServiceEdit from './ServiceEdit';

/**
 * Component to add and remove instructions and services attached to an order
 */
const OrderServiceInstruction = ({order, orderActions, channel_instructions, editable}) => {
  const [addingService, toggleAddingService] = useState(false);
  const [addingInstruction, toggleAddingInstruction] = useState(false);

  /**
   * Remove properties on an order row that shouldn't be sent over the api
   */
  const removeNonOrderProperties = orderRow => {
    // eslint-disable-next-line no-undef
    const order = _.cloneDeep(orderRow)
    delete order['copy_to_clip']

    return order;
  }
  /**
   * Remove a service to the expanded order
   * @param {*} serviceUuid 
   */
  const removeService = (serviceUuid) => {
    const orderWithoutService = {...order, services: [...order.services.filter(service => service.uuid !== serviceUuid)]}
    const cleanedOrder = removeNonOrderProperties(orderWithoutService); 
    orderActions.removeServiceFromOrder(cleanedOrder, serviceUuid);
  }
  /**
   * Remove an instruction to the expanded order
   * @param {*} instructionUuid 
   */
  const removeInstruction = (instructionUuid) => {
    const orderWithoutInstruction = {...order, channel_instructions: order.channel_instructions.filter(instruction => instruction.uuid !== instructionUuid)}
    const cleanedOrder = removeNonOrderProperties(orderWithoutInstruction);
    orderActions.removeInstructionFromOrder(cleanedOrder, instructionUuid);
  }
  /**
   * Cleans and order row object to remove all unnecassary UI elements before performing and operation on that order
   * cb - functional operation that is to be performed on an order
   * order, uuid - arguments to be passed to cb when the order has been cleaned
   */
  const cleanOrderOperation = cb => (order, uuid) => {
    const cleanedOrder = removeNonOrderProperties(order);
    return cb(cleanedOrder, uuid);
  };
  return (
    <>
      <Container fluid style={{padding: '10px'}}>
        <Row style={{marginLeft: '40px', marginRight: '40px', padding: '5px', border: '2px solid #efefef'}}>
          <Col xs={6} style={{backgroundColor: ''}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <strong>Services requested:</strong>
              <div style={{display: 'flex'}}>
                {editable && <Button size="sm" variant="outline-success" className='order-add-remove-button' onClick={() => toggleAddingService(!addingService)}> 
                  <span>Add Service</span>
                  &nbsp;
                  <FontAwesomeIcon icon={faPlus} />
                </Button>} 
              </div>
            </div>
            <ul>
              {
                order && order.services && order.services.map(service => 
                  (
                    <li>
                      <div style={{display: 'flex', justifyContent: 'space-between', minWidth: '300px'}}>
                        {service.name}
                        {editable && <Button size="sm" variant="outline-danger" className='order-add-remove-button' onClick={() => removeService(service.uuid)}>
                          <span>Remove</span>
                          &nbsp;
                           <FontAwesomeIcon icon={faTimes}/>
                        </Button>}
                      </div>
                    </li>
                  )  
                )
              }
            </ul>
          </Col>
          {/* <Col xs={6}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <strong>Dispatch Instructions:</strong>
              <div style={{display: 'flex'}}>
                {editable && 
                  <Button size="sm" variant="outline-success" className='order-add-remove-button' onClick={() => toggleAddingInstruction(!addingInstruction)}>
                    <span>Add Instruction</span>
                    &nbsp;
                    {addingInstruction ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} /> }
                </Button>}
              </div>
            </div>
            <ul>
              {
                order && order.channel_instructions && order.channel_instructions.map(instruction => 
                  (
                    <li>
                      <div style={{display: 'flex', justifyContent: 'space-between', minWidth: '300px'}}>
                        {instruction.description}
                        {instruction.status === instructionStatuses['Assistance needed'] && <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}} />}
                        {instruction.status === instructionStatuses['Complete'] && <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />}
                        {instruction.status === instructionStatuses['In progress'] && <FontAwesomeIcon icon={faClock} style={{color: '#fcba03'}} />}
                        {editable && 
                          <Button size="sm" variant="outline-danger" className='order-add-remove-button' onClick={() => removeInstruction(instruction.uuid)}>
                            <span>Remove</span>
                            &nbsp;
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        }
                      </div>
                    </li>
                  )  
                )
              }
            </ul>
          </Col> */}
        </Row>
      </Container>
      <InstructionEdit 
        order={order} 
        add={cleanOrderOperation(orderActions.addInstructionToOrder)}
        remove={cleanOrderOperation(orderActions.removeInstructionFromOrder) }
        show={addingInstruction} 
        instructions={channel_instructions}
        toggle={() => toggleAddingInstruction(!addingInstruction)}
      />
      <ServiceEdit
        order={order}
        add={cleanOrderOperation(orderActions.addServiceToOrder)}
        remove={cleanOrderOperation(orderActions.removeServiceFromOrder) }
        show={addingService} 
        toggle={() => toggleAddingService(!addingService)}
      />
    </>
    
  )
}
const mapStateToProps = (state, props) => ({
  channel_instructions: state.order && state.order.channel_instructions || []
})
const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderServiceInstruction);