import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import FarmPermissions from './FarmPermissions';
import OrgPermissions from './OrgPermissions';

const SEARCH_OPTIONS = {
  searchByOrg: {
    title: 'Search Orgs',
    component: <OrgPermissions />
  },
  searchByFarm: {
    title: 'Search Farms',
    component: <FarmPermissions />
  }
};

const FeaturePermissions = () => {
  const [searchLevel, setSearchLevel] = useState('searchByOrg');

  return (
    <div className="feature-permissions-container">
      <div className="feature-permissions-nav">
      <Nav variant="pills" defaultActiveKey={searchLevel}>
        {/* Create the Navigation Link options based on the available values
          in SEARCH_OPTIONS constant */}
        {Object.keys(SEARCH_OPTIONS).map((k, idx) => (
          <Nav.Item key={idx}>
            <Nav.Link eventKey={k} onSelect={selectedKey => setSearchLevel(selectedKey)}>
              {SEARCH_OPTIONS[k].title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      </div>
      {searchLevel && SEARCH_OPTIONS[searchLevel].component}
    </div>
  );
};

export default FeaturePermissions;
