import React from "react";
import moment from "moment";
import { ContactDisplay } from "./shared";
import { dataCollectionStatus } from "../../../../util/constants";
import { Orderevent } from "../../OrderEvents";
import { OrderActionCreator } from "../../../../../../actions/orderActions";
import { connect, useSelector } from "react-redux";
import { handleDisabledDates, EventType, EventStatusNumber } from "../../../../util";

/**
 * Read View component for data collection
 * Contains logic for finding view data and render as well
 * @param {{} order - frontend object of selected order
 * @param {[]} contacts - list of contacts for order client org id
 */
const ReadView = ({ order, contacts, orgs, solarFarm }) => {
  const siteContact = contacts.find((c) => c.uuid == order.site_contact_uuid);
  const pilotContact = contacts.find((c) => c.uuid == order.pilot_contact_uuid);
  const dataCollectionOrg = orgs.find(
    (o) => o.id == order.data_collection_org_id
  );

  const events = useSelector((state) => state.order.events);
  const loadedEvents = useSelector((state) => state.order.loadedEvents);

  const allFlightDates =
    events &&
    events.filter((event) => {
      return event.event_type_id === EventType.flightDate;
    });
  const allFlightWindows =
    events &&
    events.filter((event) => {
      return event.event_type_id === EventType.flightWindow;
    });

  return (
    <div>
      <span style={{ paddingTop: "10px" }}>
        {`Turnkey Services: ${order.turnkey_services ? "Yes" : "No"}`}
      </span>

      {order.turnkey_services ? (
        <>
          <p>
            <span style={{ paddingTop: "10px" }}>
              {`Requested Flight Start Date: ${
                order?.flight_start_date
                  ? moment.unix(order?.flight_start_date).format("MM/DD/YYYY")
                  : "NA"
              }`}
            </span>
          </p>
          <span style={{ paddingTop: "10px" }}>
            {`Requested Flight End Date: ${
              order?.flight_end_date
                ? moment.unix(order?.flight_end_date).format("MM/DD/YYYY")
                : "NA"
            }`}
          </span>
          <span style={{ paddingTop: "10px" }}>
            Requested Flight Date: &nbsp;
            {order.requested_flight_date
              ? moment.unix(order.requested_flight_date).format("MM/DD/YYYY")
              : null}
          </span>
          <span style={{ paddingTop: "10px" }}>
            Anticipated Flight Date: &nbsp;
            {order.estimated_flight_date
              ? moment.unix(order.estimated_flight_date).format("MM/DD/YYYY")
              : null}
          </span>
          <span style={{ paddingTop: "10px" }}>
            Data Collection Status: &nbsp;
            {dataCollectionStatus[order.data_collection_status]}
          </span>
          <span style={{ paddingTop: "10px" }}>{`Site Access Code: ${
            solarFarm.access_code ? solarFarm.access_code : ""
          }`}</span>
          <h6 style={{ paddingTop: "10px" }}>Site Contact:</h6>
          {siteContact && ContactDisplay(siteContact, "Site")}
          <span style={{ paddingTop: "10px" }}>
            Data Collection Org: &nbsp;
            {dataCollectionOrg && dataCollectionOrg.name}
          </span>
          <h6 style={{ paddingTop: "10px" }}>Pilot Contact:</h6>
          {pilotContact && ContactDisplay(pilotContact, "Pilot")}
        </>
      ) : (
        <>
          <span>{`Site Access Code: ${
            solarFarm?.access_code ? solarFarm.access_code : ""
          }`}</span>
          <h6 style={{ paddingTop: "10px" }}>Site Contact:</h6>
          {siteContact && ContactDisplay(siteContact, "Site")}
        </>
      )}
      <div>
        {loadedEvents && order.turnkey_services && (
          <>
            {allFlightWindows?.length > 0 ? (
              allFlightWindows.map((event, key) => {
                return <Orderevent orderId={order.id} event={event} />;
              })
            ) : (
              <Orderevent
                orderId={order.id}
                event={{ event_type_id: EventType.flightWindow, event_status_id: EventStatusNumber.pending }}
                createFlightWindow={true}
                uuid={order?.uuid}
                solarFarmName={solarFarm?.name}
              />
            )}
            {allFlightDates?.length > 0 ? (
              allFlightDates.map((event, key) => {
                return (
                  <Orderevent
                    orderId={order.id}
                    event={event}
                    disabledDates={handleDisabledDates({
                      startTsecs:
                        allFlightWindows &&
                        allFlightWindows[0]?.start_date_tsecs,
                      endTsecs:
                        allFlightWindows &&
                        allFlightWindows[0]?.end_date_tsecs,
                    })}
                  />
                );
              })
            ) : (
              <Orderevent
                orderId={order.id}
                event={{ event_type_id: EventType.flightDate, event_status_id: EventStatusNumber.pending }}
                createFlightDate={true}
                disabledDates={handleDisabledDates({
                  startTsecs:
                    allFlightWindows &&
                    allFlightWindows[0]?.start_date_tsecs,
                  endTsecs:
                    allFlightWindows &&
                    allFlightWindows[0]?.end_date_tsecs,
                })}
                uuid={order?.uuid}
                solarFarmName={solarFarm?.name}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReadView;
