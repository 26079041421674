import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";

class SolarOrdersApiClient {
  constructor() {
    this.runMode = sessionStorage.getItem('runMode');
    
    let baseUrl = `${window.REACT_APP_API_ENDPOINT}`;
    // if (this.runMode === 'local') {
    //   baseUrl = 'http://0.0.0.0:5008/';
    // } else {
    //   // Logic for a deployed environment
    //   if (this.runMode === 'prod' || this.runMode === 'staging') {
    //     baseUrl = 'https://ops.raptormaps.com';
    //   } else {
    //     baseUrl = `https://ops-${this.runMode}.raptormaps.com`;
    //   }
    // }

    this.base_url = baseUrl;
    this.api = new RestApiV2(null, this.base_url);
  }

  updateAccountInfoItems = solarOrder => {
    return new Promise((resolve, reject) => {
      this.api
        .put(`/solar_orders/${solarOrder.uuid}/accounting_info_items`, solarOrder)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  getFilesForFarm = solarFarmUuid => {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/solar_farms/${solarFarmUuid}/files`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  restoreCancelledOrder = (orderUuid, restoreStatus) => {
    return new Promise((resolve, reject) => {
      this.api
        .put(`/solar_orders/${orderUuid}/restore_cancelled_order`, null, {restore_status: restoreStatus})
        .then(res => {
          toastr.success('Sucessfully restored order.')
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };
}

export default SolarOrdersApiClient;
