import { FormControl, FormGroup } from "react-bootstrap";
import { EventTypes, EventStatusIds, EventStatusById } from "../constants/events";
import { FlightSchedulingMixPanelEvents } from "../mixpanel/events";

/**
 * Generates a filter item that is intended to be used in the "as" prop of a dropdown item
 */
export const getDropDownFilterItem = (value, placeholder, onChange) => {
  return (
    <FormGroup>
      <FormControl
        type={"text"}
        maxLength={"25"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus
      />
    </FormGroup>
  );
};

/**
 * Takes in a desired plant labeler path and opens
 * the new url in a separate browser tab
 * @param path {string} - plant labeler path (ex. '/solar')
 */
export const plantLabelerLegacyRedirect = path => {
  if (typeof window !== "undefined") {
    // opens tab such as http://localhost:5000/solar
    let url = `${
      window.REACT_APP_PLANTLABELER_LEGACY_URL
    }${path}?auth_token=${window.sessionStorage.getItem("accessToken")}`;

    window.open(url, "_blank");
  }
};

/**
 * Takes in a desired plant labeler path and opens
 * the new url in a separate browser tab
 * @param path {string} - plant labeler path (ex. '/solar')
 */
export const plantLabelerRedirect = (path) => {
  if (typeof window !== "undefined") {
    // opens tab such as http://localhost:5000/solar
    let url = `${
      window.REACT_APP_PLANTLABELER_URL
    }${path}&auth_token=${window.sessionStorage.getItem("accessToken")}`;

    window.open(url, "_blank");
  }
};


/**
 * Takes in a desired plant labeler path and opens
 * the new url in a separate browser tab
 * @param path {string} - plant labeler path (ex. '/solar')
 */
export const newCustomerRedirect = path => {
  if (typeof window !== "undefined") {
    let url = "";
    // opens tab such as http://localhost:5000/solar
    url = window.REACT_APP_CUSTOMER_FE_URL;
    window.open(url + path, "_blank");
  }
};

/**
 * Takes in a desired plant labeler path and opens
 * the new url in a separate browser tab
 * @param path {string} - plant labeler path (ex. '/solar')
 */
export const getCustomerFEUrl = path => {
  if (typeof window !== "undefined") {
    // opens tab such as http://localhost:5000/solar
    return window.REACT_APP_CUSTOMER_FE_URL + path;
  }
};

export const ORG_TYPES = {
  SMALL: "small",
  DATA_COLLECTOR: "data_collector",
};

/**
 * Retrieves the generic name for ops platform
 */
export const handleCustomerPlatform = () => {
  return "https://ops.raptormaps.com";
};

/**
 * Get name for the proper mixpanel track action
 * @param {*} eventTypeId
 * @param {*} eventStatusId
 * @returns
 */
export const getUpdateEventTrackName = (eventTypeId, eventStatusId) => {
  const trackNameMapping = {
    [EventTypes.FLIGHT_DATE]: {
      [EventStatusIds.PENDING]:
        FlightSchedulingMixPanelEvents.OrderFlightDateRequested,
      [EventStatusIds.APPROVED]:
        FlightSchedulingMixPanelEvents.OrderFlightDateApproved,
    },
    [EventTypes.FLIGHT_WINDOW]: {
      [EventStatusIds.PENDING]:
        FlightSchedulingMixPanelEvents.OrderFlightWindowRequested,
      [EventStatusIds.APPROVED]:
        FlightSchedulingMixPanelEvents.OrderFlightWindowApproved,
    },
  };

  return trackNameMapping[eventTypeId][eventStatusId];
};

/**
 * Triggers the mixPanel tracking whenever an event gets updated from ops.raptormaps.com
 * @param {*} event
 * @param {*} orderId
 * @returns
 */
export const mixPanelEventUpdated = (event, orderId) => {
  /* Mixpanel Tracking */
  const { description, event_status_id, event_type_id, id } = event;
  const trackName = getUpdateEventTrackName(event_type_id, event_status_id);
  const trackProps = {
    order_id: orderId,
    org_id: null,
    event_id: id,
    reason_for_change: description || "",
    event_status: EventStatusById[event_status_id - 1],
    platform: handleCustomerPlatform(),
  };
  if (trackName) {
    window.mixpanel.track(trackName, trackProps);
  }
};

