// @ts-nocheck
import { useEffect, useState } from 'react';
import { Accordion, Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';

// Redux
import { useDispatch } from 'react-redux';
import { OrderActionCreator } from '../../actions/orderActions';

// Hooks
import { withRouter } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';

// Components
import debounce from 'lodash/debounce';
import ExportOrdersDropdown from './ExportOrdersDropdown';
import { orderValuesets } from './util/constants';

// Map display text to the field values
const searchFieldMappings = {
  'Tracking Number': 'id',
  'Farm Name': 'solar_farm_name',
  'Client Org': 'client_org_name',
  'Order Type': 'order_type',
};

// Temporary object
const searchFieldOpions = {
  id: {
    id: 'id',
    title: 'Tracking Number'
  },
  solar_farm_name: {
    id: 'solar_farm_name',
    title: 'Farm Name'
  },
  client_org_name: {
    id: 'client_org_name',
    title: 'Client Org'
  },
  order_type: {
    id: 'order_type',
    title: 'Order Type'
  }
};

/**
 * Contains a search bar, advanced search options and export options for the order table
 * @param {func} setTableFilter - sets the parent component filter
 * @param {*} orderActions - suite of order redux actions
 */
const OrderTableSearch = ({
  setTableFilter,
  orderActions,
  setIsPolling,
  fetchOrdersForActiveTab,
  activeTab,
  history
}:any) => {
  const dispatch = useDispatch();

  const defaultTextSearchValues = { display: searchFieldOpions.solar_farm_name.title, field: searchFieldOpions.solar_farm_name.id };
  const [searchField, setSearchField] = useState(defaultTextSearchValues);
  const [searchText, setSearchText] = useState(null);
  const [disableSearchText, setDisableSearchText] = useState(false);

  const defaultOrderTypeTitle = 'Filter by Order Type';
  const searchFieldMappingsOrderType = 'Order Type';
  const [searchOrderType, setSearchOrderType] = useState(defaultOrderTypeTitle);

  // Hooks
  const searchTextDebounced = useDebounce(searchText, 1000);

  /**
   * If a search field is selected, sets the filter to be the user input
   * Else sets an error message
   */
  const handleTextSearch = debounce((formValue:string, searchFieldOverride = searchField) => {
    if (formValue && searchFieldOverride.display) {
      setIsPolling(false);
      setTableFilter(formValue);
      orderActions.getFilteredOrderSummaries(searchFieldOverride.field, formValue);
    } else {
      // Return to state without filter
      setIsPolling(true);
      setTableFilter(null);
      dispatch(OrderActionCreator.setItsFiltered(false));
      fetchOrdersForActiveTab(activeTab, false);
    }
  }, 300);

  useEffect(() => {
    if(searchTextDebounced !== null){
      handleTextSearch(searchTextDebounced);
    }
    if (searchTextDebounced === ''){
      history.push({ search: '' });
    }
  }, [searchTextDebounced]);

  /**
   *
   * @param {*} type - filter type
   * @param {*} value - filter value
   */
  const handleAddQueryParams = (type:string, value:string) => {
    let newParams = `?type=${type}&value=${value}`;
    history.push({ search: newParams.toString() });
  };

  useEffect(() => {
    if (searchText && searchField) {
      handleAddQueryParams(searchField.field, searchText);
    }
  }, [searchText, searchField]);

  /**
   * Validates if the URL has query parameters (filterType and values)
   * if === true - call handleTextSearch
   */
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let filterType = params.get('type');
    let filterText = params.get('value');

    if(![null, '', ' ', undefined].includes(filterType) && ![null, '', ' ', undefined].includes(filterText)){
      setSearchField({
        display: searchFieldOpions[filterType].title,
        field: searchFieldOpions[filterType].id
      });
      setDisableSearchText(false);
      setSearchText(filterText);
      handleTextSearch(filterText);
    }
  }, []);

  // Reset order type filter and apply text search
  const setSearchFieldFromDropdown = field => {
    setDisableSearchText(false);
    setSearchOrderType(defaultOrderTypeTitle);
    setSearchField({ display: field, field: searchFieldMappings[field] });
    handleTextSearch(searchText, { display: field, field: searchFieldMappings[field] });
    setSearchOrderType(defaultOrderTypeTitle);
  };

  // Disable text search and apply order type filter
  const handleDropDownSearch = (key, value) => {
    setIsPolling(false);
    setDisableSearchText(true);
    setSearchField(defaultTextSearchValues);
    setTableFilter(key);
    setSearchOrderType(value);
    orderActions.getFilteredOrderSummaries(searchFieldMappings[searchFieldMappingsOrderType], key);
  };

  return (
    <Accordion>
      <div className="orders-search-bar">
        <Form.Group>
          <Form.Control
            type="text"
            onChange={e => setSearchText(e.target.value)}
            disabled={disableSearchText}
            value={searchText}
          />
        </Form.Group>
        <DropdownButton
          title={searchField.display}
          style={{ marginBottom: '1rem' }}
          variant="orders-search-bar"
        >
          {Object.keys(searchFieldMappings)
            // Moved order type filter to its own button
            .filter(k => k !== searchFieldMappingsOrderType)
            .map(field => (
              <Dropdown.Item onSelect={() => setSearchFieldFromDropdown(field)}>
                {field}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="0"
          style={{ marginBottom: '1rem', fontSize: '12px', textShadow: '0px' }}
        >
          Advanced
        </Accordion.Toggle>
        <Form.Group style={{ paddingRight: '5px' }}>
          <DropdownButton
            title={searchOrderType}
            variant="orders-search-bar"
            style={{
              // aesthetic
              boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)'
            }}
          >
            {Object.entries(orderValuesets.order_type).map(([key, value]) => (
              <Dropdown.Item onSelect={() => handleDropDownSearch(key, value)}>
                {value}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Form.Group>
      </div>
      <Accordion.Collapse eventKey="0">
        <ExportOrdersDropdown exportSolarOrders={orderActions.exportSolarOrders} />
      </Accordion.Collapse>
    </Accordion>
  );
};
export default withRouter(OrderTableSearch);
