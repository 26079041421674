
import toastr from 'toastr';
import RestApiV2 from "../api/RestApiV2";

class DefaultClientOrgChannelApiClient {
  constructor() {
    this.runMode = sessionStorage.getItem('runMode');
    this.api = new RestApiV2(false, `${window.REACT_APP_API_ENDPOINT}`);
  }

  getDefaultOrgChannelList = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get('/default_client_org_channels')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  getAllBpoOrgs = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get('/default_client_org')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  getAllNotBpoOrgs = () => {
    return new Promise((resolve, reject) => {
      this.api
        .get('/default_client_org_not_bpo')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  updateBPOOrgChannel = org => {
    return new Promise((resolve, reject) => {
      this.api
        .put('/default_client_org_channels', org)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };

  postBPOOrgChannel = org => {
    return new Promise((resolve, reject) => {
      this.api
        .post('/default_client_org_channels', org)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          toastr.error(err);
          reject(err);
        });
    });
  };
}

export default DefaultClientOrgChannelApiClient;
