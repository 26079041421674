import React from 'react';
import OrderTable from './OrderTable';
import { Button } from 'react-bootstrap';
import {
  tableTabStatuses
} from './util/constants';

/**
 * An order table with a button used to fetch more values for the table
 * @param {*} orders - orders to display in the table
 * @param {*} orgs - full list of org objects 
 * @param {*} activeTab - currently selected tab displaying orders
 * @param {*} getMoreOrderSummaries - function to call on load more
 * @param {*} loadMoreIncrementSize - number of orders added on load more
  */
const LoadMoreOrderTable = ({
  orders,
  orgs,
  activeTab,
  getMoreOrderSummaries,
  loadMoreIncrementSize
}) => {
  // Total number of visible orders
  const ORDER_COUNT = orders && orders.length;

  /**
   * Fired when Load More is clicked
   * Fetches loadMoreIncrementSize number of orders and appends
   * to the current display of orders
   */
  const loadMore = () => {
    const statuses = tableTabStatuses[activeTab];
    if (statuses && statuses.length === 1) {
      const status = statuses[0];
      // Fetch the new orders
      getMoreOrderSummaries(status, loadMoreIncrementSize, ORDER_COUNT)
    }
  }
  /**
   * Refreshes the list of currently open orders
   */
  const onOrderModalClose = () => {
    const statuses = tableTabStatuses[activeTab];
    if (statuses && statuses.length === 1) {
      const status = statuses[0];
      getMoreOrderSummaries(status, ORDER_COUNT, 0);
    }
  }
  return (
    <div className='load-more-order-table'>
      <OrderTable 
        orders={orders}
        editable
        orgs={orgs}
        activeTab={activeTab}
        onOrderModalClose={onOrderModalClose}
      />
      <Button className='load-more-btn' onClick={loadMore}>LOAD MORE</Button>
    </div>
  );
}

export default LoadMoreOrderTable;