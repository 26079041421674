import { Container } from "react-bootstrap";
import { FunctionComponent } from "react";
import { Text, TextVariant } from "@raptormaps/text";
import { Row } from "@raptormaps/layout";
import { Button } from "@raptormaps/button";
import useAppsmith from "../../hooks/useAppsmith";

const ManageModuleTypes: FunctionComponent = () => {
  const { canAccessDev, canAccessQA, canAccessProd } = useAppsmith(
    window.location.href
  );

  return (
    <Container style={{ marginTop: "10px" }}>
      <Text variant={TextVariant.h1}>Manage Module Types</Text>
      <Container style={{ marginTop: "10px" }}>
        <Text variant={TextVariant.paragraph_large}>
          Module Type management is available in Appsmith. If you do not
          currently have access to Appsmith, please contact
          software@raptormaps.com to gain access.
        </Text>
      </Container>
      <Container style={{ marginTop: "10px" }}>
        <Row align="center" justify="center">
          {canAccessDev && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/dev-manage-module-types/page1-63e550b6b0cf3a60c8245656"
                )
              }
            >
              Manage Module Types in Dev
            </Button>
          )}
          {canAccessQA && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/qa-manage-module-types/page1-63da79c47b01553ee5857bdd"
                )
              }
            >
              Manage Module Types in QA
            </Button>
          )}
          {canAccessProd && (
            <Button
              variant="primary"
              size="large"
              onClick={() =>
                window.open(
                  "http://172.31.65.128/app/prod-manage-module-types/page1-63e550f0b0cf3a60c8245666"
                )
              }
            >
              Manage Module Types in Production
            </Button>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default ManageModuleTypes;
