import toastr from "toastr";
import RestApiV2 from "../api/RestApiV2";

class EventsApiClient {
  constructor() {
    this.api = new RestApiV2();
  }

  getEventsByOrderId = (order_id) => {
    return new Promise((resolve, reject) => {
      const api = new RestApiV2();
      api
        .get(`/events/${order_id}`)
        .then((res) => {
          resolve(res.events);
        })
        .catch((err) => {
          reject(err);
          toastr.error(err);
        });
    });
  };
  /**
   * Updates flight Scheduling dates
   */
  updateEventById = (orderId, event) => {
    const url = `events/${event.id}`;
    const params = {
      event,
      orderId,
    };

    return new Promise((resolve, reject) => {
      const api = new RestApiV2();
      api
        .put(url, params)
        .then((res) => {
          toastr.success("Flight Scheduling updated");
          resolve(event);
        })
        .catch((err) => {
          reject(err);
          toastr.error("Failed to update Flight Scheduling " + err);
        });
    });
  };
  /**
   * Create flight Scheduling dates
   */
  createEventById = (orderId, event) => {
    const url = `events`;
    const params = {
      event,
      orderId,
    };

    return new Promise((resolve, reject) => {
      const api = new RestApiV2();
      api
        .post(url, params)
        .then((res) => {
          toastr.success("Flight Scheduling created");
          resolve(event);
        })
        .catch((err) => {
          reject(err);
          toastr.error("Failed to create Flight Scheduling " + err);
        });
    });
  };
}

export default EventsApiClient;
