import styled, { css } from 'styled-components';


export const InfoItem = styled.p.attrs(() => ({
  className: 'EditTabs__InfoItem',
}))`
  margin: 0px;
`;

export const InfoItemTitle = styled.span.attrs(() => ({
  className: 'EditTabs__InfoItem_Title',
}))`
  font-weight: 500;

  color: ${({ required, theme }) => {
    if (required) {
      return theme.colors.GlobalColor.rRed1;
    } else {
      return theme.colors.GlobalColor.rBlack1;
    }
  }};
`;