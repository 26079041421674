import React from 'react';
import { Button, Modal } from 'react-bootstrap';

/**
 * Generic warning modal...you give it a message, and you can either continue
 * or cancel.
 * @param show {boolean} -
 * @param toggle {function} -
 * @param onContinue {function} -
 * @param message {string} - 
 * @returns {*}
 * @constructor
 */
const PermissionsWarningModal = ({show, toggle, onContinue, message }) => {
  return (
    <Modal show={show} onHide={toggle} className="delete-warning-modal">
      <Modal.Header>
       <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onContinue();
            toggle();
          }}
          variant="danger"
        >
          Continue
        </Button>
        <Button onClick={toggle}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionsWarningModal;