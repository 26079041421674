import React from 'react';

import { StatusDisplay } from './shared';

import { Button } from 'react-bootstrap';

import { orderValuesets, orderStatuses } from '../../../../util/constants';

/**
 * SubmittedDisplaySection - Presentation component for submitted status
 * @param {*} status - status of order
 * @param {[]} nextStatus - array that holds possible next statuses
 * @param {*} updateOrderStatus - function to update order status
 */
const GenericDisplaySection = ({ status, nextStatus, updateOrderStatus, togglePermissionsModal, setNextStatus }) => {
  const displayStatus = orderValuesets.status[status];
  const displayNextStatus = orderValuesets.status[nextStatus];
  return (
    <>
      <div>
        <StatusDisplay label="Current Status" status={displayStatus} />

        {nextStatus ? (
          <StatusDisplay label="Next Status" status={displayNextStatus} />
        ) : (
          <span>🎉🎉🎉</span>
        )}
      </div>
      {nextStatus && (
        <div style={{ marginTop: '30px' }}>
          <Button
            onClick={() => {
              if (status === orderStatuses['SITE_SETUP']) {
                togglePermissionsModal()
                setNextStatus(nextStatus);
              } else {
                updateOrderStatus(nextStatus);
              }
            }}
          >{`Move to ${displayNextStatus}`}</Button>
        </div>
      )}
    </>
  );
};

export default GenericDisplaySection;
