import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Pagination } from 'react-bootstrap';

import DumbPagination from './DumbPagination';
import DumbTable from './DumbTable';
import PermissionModal from './PermissionModal';

import OrgsApiClient from '../../apiClient/OrgsApiClient';
import FeatureApiClient from '../../apiClient/FeatureApiClient';

import './orgPermissions.css';


const dumbTableStyles = {
  display: 'flex',
  width: '100%',
  minHeight: '360px',
  maxHeight: '1200px',
  overflow: 'auto'
};

const validSearchKeyMap = {
  id: 'Organization ID',
  name: 'Organization Name'
};
/**
 * Component to let admins search for orgs and enable feature toggling.
 */
const OrgPermissions = () => {
  const [solarOrgs, setSolarOrgs] = useState([]);

  //   Pagination related state
  const paginateBy = 50;
  const [paginatedIndices, setPaginatedIndices] = useState();
  const [paginatedTables, setPaginatedTables] = useState([]);
  const [activePaginateIndex, setActivePaginateIndex] = useState(0);

  //   Search related state
  const [searchInput, setSearchInput] = useState('');

  //   Org Modal related state
  const [showModal, setShowModal] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [orgFeatures, setOrgFeatures] = useState([]);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    new OrgsApiClient().getOrgs().then(res => setSolarOrgs(res));
    new FeatureApiClient().getCreatedFeatures().then(res => setFeatures(res));
  }, []);

  /**
   * setPaginatedTables transforms a list of orgs into a 2-dimensional list where each list has
   * {paginateBy} number of elements.
   *
   * setPaginatedIndices takes the length of the list of lists and returns a 1-dimensional
   * list of containing the indicies of the paginatedTables
   *
   */
  useEffect(() => {
    const chunky = _.chunk(solarOrgs, paginateBy);
    setPaginatedTables(chunky);
    setPaginatedIndices(chunky.map((_, idx) => idx));
  }, [solarOrgs]);

  /**
   * Cast search value to string and lowercase then fuzzy match on solar orgs.
   * @param {InputEvent} e event fired when a user types in the search bar.
   */
  const handleInput = e => {
    setSearchInput(e.target.value);
    const input = (e.target.value + '').toLowerCase().replace(/\s/g, '');
    const filteredResults = solarOrgs.filter(
      org =>
        (org.id + '').includes(input) ||
        (org.name + '')
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(input)
    );
    const chunky = _.chunk(filteredResults, paginateBy);
    setPaginatedTables(chunky);
    setPaginatedIndices(chunky.map((_, idx) => idx));
  };

  const handleTableClick = o => {
    const params = { org_id: o.id };
    new FeatureApiClient()
      .getOrgFeatures(params)
      .then(res => setOrgFeatures(res))
      .then(() => setSelectedOrg(o))
      .then(() => setShowModal(true));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const tableObjectKeys = Object.keys(validSearchKeyMap);
  const tableHeaders = Object.values(validSearchKeyMap);

  return (
    <div className="parent-div-org-permissions">
      <PermissionModal
        show={showModal}
        handleClose={handleModalClose}
        selectedOrg={selectedOrg}
        orgFeatures={orgFeatures}
        setOrgFeatures={setOrgFeatures}
        allFeatures={features}
      />
      {!_.isEmpty(paginatedIndices) && !_.isEmpty(paginatedTables) ? (
        <div className="child-div-org-permissions">
          <InputGroup className="mb-3">
            <FormControl
              autoFocus
              placeholder={'Type to filter by organization name or ID..'}
              onChange={handleInput}
              value={searchInput}
            />
          </InputGroup>
          <div style={dumbTableStyles}>
            <DumbTable
              objectArray={Array.from(paginatedTables[activePaginateIndex])}
              objectRowValueKeys={tableObjectKeys}
              headers={tableHeaders}
              onClick={handleTableClick}
              tdStyle={{ maxWidth: '120px' }}
            />
          </div>
          <DumbPagination
            paginatedIndices={paginatedIndices}
            onNavClick={setActivePaginateIndex}
            activePaginateIndex={activePaginateIndex}
          />
        </div>
      ) : (
        <div className="child-div-org-permissions">
          <InputGroup className="mb-3">
            <FormControl
              autoFocus
              placeholder={'Type to filter by organization name or ID..'}
              onChange={handleInput}
              value={searchInput}
            />
            <div style={dumbTableStyles} />
          </InputGroup>
          <Pagination>
            <Pagination.Prev disabled />
            <Pagination.Item disabled>1</Pagination.Item>
            <Pagination.Next disabled />
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default OrgPermissions;
