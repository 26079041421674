// @ts-nocheck
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ProjectViewerModal from './ProjectViewerModal';

//Mock API
import SalesProjectApiClient from '../../apiClient/SalesProjectApiClient';

// Util
import { getDateStringFromTsecs } from '../OrderStatus/util';

// Constants
import { SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND } from './constants';

// Components
import RaptorTable from '../shared/Table';


interface IProjectViewerTableProps{
  activeStatus: number;
  projectList: any;
  history: any;
  setActiveStatus: any;
  setProjectList: any;
  noNextPage: boolean;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  handleChangePage: any;
}
/**
 * Component to view project table depending on selected status
 * @param props includes projectList array that populates table rows
 * @returns {*}
 * @constructor
 */
const ProjectViewerTable: FunctionComponent<IProjectViewerTableProps> = ({
  activeStatus,
  projectList,
  history,
  setActiveStatus,
  setProjectList,
  noNextPage,
  currentPage,
  pageSize,
  setPageSize,
  handleChangePage
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [message, setMessage] = useState<string>('');

  /**
   * Handles project viewer modal open and close
   * When modal is closed, returns to /project path
   * By default, modal is closed
   * @param {*}
   * @returns {*}
   */
  const handleModalClose = () => {
    setShowModal(false);
    setSelectedProject({});
    history.push('/project');
  };
  const handleModalShow = () => setShowModal(true);

  /**
   * Refreshes project viewer table by closing modal and clearing project list
   * @param {*}
   * @returns {*}
   */
  const refreshProjectViewerTable = () => {
    handleModalClose();
    setProjectList([]);
  };

  /**
   * Makes API call to specific project endpoint to obtain solar order data from that project
   * Catches errors but updating message that is sent to the modal
   * @param {number} salesProjectId - The id of the selected project from the table
   * @returns {*}
   */
  const getProjectOnboardingDetails = (salesProjectId: number) => {
    const api = new SalesProjectApiClient();
    api
      .getSalesProjectById(salesProjectId)
      .then(project => {
        setMessage('Loading...');
        setSelectedProject(project);
      })
      .catch(err => {
        setMessage(
          'Error: Sales Project ID not found. Double check sales project id and try again.'
        );
        setSelectedProject({});
      });
  };

  const getProjectIdFromUrl = path => {
    return Number(path.split('/')[2]);
  };

  const handleSolarOrderNullError = project => {
    if (project.solar_orders.length === 0) {
      project.solar_orders.push(SOLAR_ORDER_DEFAULT_VALS_IF_NOT_FOUND);
    }

    return project;
  };

  useEffect(() => {
    if (history.location.pathname !== '/project') {
      const projectId = getProjectIdFromUrl(history.location.pathname);
      handleModalShow();
      getProjectOnboardingDetails(projectId);
    }
  }, []);

  const handleRowClick = (row: any) => {
    handleModalShow();
    getProjectOnboardingDetails(row.id);
    history.push(`/project/${row.id}`);
  };

  const handleProjectRowData = (data: any) => {
    let rowData = [];
    data?.map(project =>
      rowData.push({
        id: project.id,
        projectName: project.name,
        clientOrg: project.client_org_name,
        dateSubmitted: getDateStringFromTsecs(project.created_tsecs),
        submittedBy: project.sales_associate_email
      })
    );

    return rowData;
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Project Name',
        accessor: 'projectName'
      },
      {
        Header: 'Client Org',
        accessor: 'clientOrg'
      },
      {
        Header: 'Date Submitted',
        accessor: 'dateSubmitted'
      },
      {
        Header: 'Submitted By',
        accessor: 'submittedBy'
      }
    ],
    []
  );

  return (
    <div style={{ width: '100%' }}>
      <RaptorTable
        columns={tableColumns}
        rows={handleProjectRowData(projectList)}
        rowAction={handleRowClick}
        paginated={true}
        onPageChange={handleChangePage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        noNextPage={noNextPage}
      />
      { showModal && 
        <ProjectViewerModal
          showModal={showModal}
          handleModalClose={handleModalClose}
          selectedProject={selectedProject}
          message={message}
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
          refreshProjectViewerTable={refreshProjectViewerTable}
        />
      }
      
    </div>
  );
};

export default ProjectViewerTable;
