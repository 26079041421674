/* eslint-disable no-undef */
import {
  faLink,
  faExternalLink,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { AdminActionCreator } from "../../actions/adminActions";
import { OrderActionCreator } from "../../actions/orderActions";
import { plantLabelerLegacyRedirect, plantLabelerRedirect } from "../../util";
import { getFarmBuilderLink } from "./util";
import { orderValuesets } from "./util/constants";
import { OrderDataItem } from "./styles/OrderStatus_styles";
import { Text, TextVariant } from "@raptormaps/text";

const getInspectionColumns = (order) => [
  {
    dataField: "id",
    text: "Inspection ID",
    hidden: true
  },
  {
    dataField: "farm",
    text: "Farm Name (ID)",
    style: { minWidth: "280px" },
  },
  {
    dataField: "name",
    text: "Inspection Name (ID)",
    style: { minWidth: "280px" },
  },
  {
    dataField: "siteBuilderLink",
    text: "Site Builder",
    style: { minWidth: "100px" },
  },
  {
    dataField: "reportBuilderLink",
    text:
      order.order_type === orderValuesets.order_type.construction.toLowerCase()
        ? "Construction Report Builder"
        : "Report Builder",
    style: { minWidth: "100px" },
  },
  {
    dataField: "tetrisLink",
    text: "Tetris",
    style: { minWidth: "100px" },
  },
  {
    dataField: "removeInspection",
    text: "Remove Inspection",
  },
];

const OrderInspections = ({ order, solarFarm, orderActions }) => {
  const [selectingFarm, toggleSelectingFarm] = useState(false);

  /**
   * Handles link button redirect to site-builder, report-builder or tetris
   * @param {*} newLocation
   * @param {*} inspection
   */
  const redirect = (newLocation, inspection) => {
    switch (newLocation) {
      case "site-builder":
        if (solarFarm && solarFarm.uuid) {
          mixpanel.track("Order edit to farm builder");
          window.open(
            getFarmBuilderLink(solarFarm.id) + `/inspection/${inspection.id}`,
            "_blank"
          );
        }
        break;
      case "legacy-report-builder":
        if (solarFarm && solarFarm.uuid) {
          mixpanel.track("Order edit to report builder");
          plantLabelerLegacyRedirect(
            `/report-builder/${solarFarm.uuid}/${inspection.uuid}`
          );
        }
        break;
      case "report-builder":
        if (solarFarm && solarFarm.uuid) {
          mixpanel.track("Order edit to report builder");
          plantLabelerRedirect(
            `/report-builder?farm=${solarFarm.uuid}&inspection=${inspection.uuid}`
          );
        }
        break;
      case "construction-report-builder":
        if (solarFarm && solarFarm.uuid) {
          mixpanel.track("Order edit to construction report builder");
          plantLabelerLegacyRedirect(
            `/construction-report-builder/${solarFarm.uuid}/${inspection.uuid}`
          );
        }
        break;
      case "tetris":
        if (inspection.uuid) {
          mixpanel.track("Order edit to tetris");
          plantLabelerLegacyRedirect(`/tetris/${inspection.uuid}`);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Formats inspections for the sub table in the orders view
   * @param {*} inspections
   * @param {*} farmName
   */
  const getInspectionTableData = (inspections) =>
    inspections.map((inspection) => ({
      ...inspection,
      name: `${inspection.name} (${inspection.id})`,
      farm: solarFarm && `${solarFarm.name} (${solarFarm.id})`,
      siteBuilderLink: (
        <OrderDataItem onClick={() => redirect("site-builder", inspection)}>
          <Text variant={TextVariant.paragraph_xsmall}>Open Farm Builder</Text>
          <FontAwesomeIcon className="icon-button" icon={faExternalLink} />
        </OrderDataItem>
      ),
      reportBuilderLink: (
        <OrderDataItem
          onClick={() => {
            redirect("report-builder", inspection);
          }}
        >
          <Text variant={TextVariant.paragraph_xsmall}>
            Open report builder
          </Text>
          <FontAwesomeIcon className="icon-button" icon={faExternalLink} />
        </OrderDataItem>
      ),
      tetrisLink: (
        <OrderDataItem onClick={() => redirect("tetris", inspection)}>
          <Text variant={TextVariant.paragraph_xsmall}>Open Tetris</Text>
          <FontAwesomeIcon className="icon-button" icon={faExternalLink} />
        </OrderDataItem>
      ),
      removeInspection: (
        <OrderDataItem onClick={() => removeInspection(inspection)}>
          <Text variant={TextVariant.paragraph_xsmall}>Remove</Text>
          <FontAwesomeIcon className="icon-button" icon={faTimes} />
        </OrderDataItem>
      ),
    }));

  /**
   * Remove an inspection from the current order
   * @param {*} inspection
   */
  const removeInspection = (inspection) => {
    const inspectionWithoutOrderUuid = _.cloneDeep(inspection);
    inspectionWithoutOrderUuid.order_uuid = null;

    // FarmName is added for the view summary, it can be removed before the inspection is updated
    delete inspectionWithoutOrderUuid["farmName"];
    orderActions.updateSolarInspectionForOrder(
      inspectionWithoutOrderUuid,
      order.uuid
    );
  };

  /**
   * Add an inspection to the current order
   * @param {*} inspection
   */
  const addInspectionToOrder = (inspection) => {
    const inspectionWithOrderUuid = _.cloneDeep(inspection);
    inspectionWithOrderUuid.order_uuid = order.uuid;
    orderActions.updateSolarInspectionForOrder(
      inspectionWithOrderUuid,
      order.uuid,
      solarFarm.name
    );
  };

  /**
   * Filters the list of inspections on the currently selected farm to not include inspections already on the order
   */
  const getInspectionsNotOnOrder = (solarFarm) => {
    const allFarmInspections = (solarFarm && solarFarm.solar_inspections) || [];
    // let difference = arr1.filter(x => !arr2.includes(x));
    const orderInspections = order.inspections;
    return allFarmInspections.filter(
      (x) => !orderInspections.find((i) => i.uuid === x.uuid)
    );
  };

  return order ? (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <br />
            <strong>Order Inspections:</strong>
            <br />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={getInspectionTableData(order.inspections)}
                columns={getInspectionColumns(order)}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ display: "flex", width: "100%" }}>
              <div>
                <span>Add Linked Inspection: </span>
                &nbsp;
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={() => toggleSelectingFarm((s) => !s)}
                >
                  {selectingFarm ? (
                    <FontAwesomeIcon icon={faTimes} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </Button>
              </div>
              &nbsp;
              {selectingFarm && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>Inspections: </span>
                  <ul>
                    {getInspectionsNotOnOrder(solarFarm).map((inspection) => (
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{inspection.name}</span>
                          &nbsp;
                          <Button
                            variant="outline-success"
                            onClick={() => addInspectionToOrder(inspection)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};
const mapStateToProps = (state, props) => ({
  channel_instructions: (state.order && state.order.channel_instructions) || [],
});
const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch),
  adminActions: bindActionCreators(AdminActionCreator, dispatch),
});
const ConnectedOrderInspections = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderInspections)
);
export default ConnectedOrderInspections;
