import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

/**This is a collection of modals and other constants that are shared across both the job permissions
 * and the farm permissions. */

/**Modal that shows when the user un-checks the View All box for a users. */
export const RemoveUserAll = props => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="app.orgeditpage.warning"
            defaultMessage="Warning"
            description="Warning modal title"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormattedMessage
            id="app.orgeditpage.unchecking_user"
            values={{ name: props.user ? props.user.email : '' }}
            defaultMessage={`Unchecking the CAN VIEW ALL REPORTS permission will remove {name} from all of your jobs. You will still have to save for this update to persist.`}
          />
          <hr />
          <hr />
          <Button onClick={props.onHide}>
            <FormattedMessage
              id="app.orgeditpage.continue"
              defaultMessage="Continue"
              description="Continue modal button"
            />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PropTypes.RemoveUserAll = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

/**Renders a single trash bin icon for the permissios table*/
export const RemoveUserButton = props => {
  const TooFewUsersOverlay = (
    <Tooltip id="tooltip" className="rm-tooltip tooltip">
      <FormattedMessage
        id="app.orgeditpage.cannot_remove_org"
        defaultMessage="An organization cannot have zero users."
        description="Cannot remove org tool tip"
      />
    </Tooltip>
  );
  const CannotDeleteSelfOverlay = (
    <Tooltip id="tooltip" className="rm-tooltip tooltip">
      <FormattedMessage
        id="app.orgeditpage.cannot_remove_self_from_org"
        defaultMessage="cannot delete signed in user from org."
        description="Cannot remove org tool tip"
      />
    </Tooltip>
  );
  //Disable user removal if only one user remains
  if (props.users.length === 1) {
    return (
      <OverlayTrigger rootClose placement="top" overlay={TooFewUsersOverlay}>
        <div
          style={{
            color: '#e4e4e4',
            display: 'inline-block',
            cursor: 'not-allowed',
            pointerEvents: 'all'
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </OverlayTrigger>
    );
  } else {
    return (
      <FontAwesomeIcon
        className="report-link"
        icon={faTrash}
        onClick={props.onRemoveUser.bind(this, props.cell)}
      />
    );
  }
};

PropTypes.RemoveUserButton = {
  onRemoveUser: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired
};

/**Styles for the permissions table headers*/
export const HeaderOverlay = (headerText, tooltipText) => {
  return (
    <OverlayTrigger
      rootClose
      placement="top"
      overlay={
        <Tooltip bsPrefix="rm-tooltip tooltip" id="tooltip">
          {tooltipText}
        </Tooltip>
      }
    >
      <div
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          pointerEvents: 'all'
        }}
      >
        {headerText}
      </div>
    </OverlayTrigger>
  );
};

/**Each of the actual headers to be rendered by the columns*/
export const ViewAllReports = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.view_all"
      defaultMessage="View All"
      description="View all column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.image_sets"
      defaultMessage="Image Sets"
      description="Image Sets column header"
    />,

    <br key="1" />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_view_reports"
    defaultMessage="Allow user to view all reports in your organization."
    description="User view tool tip"
  />
);

export const DownloadAllReports = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.download"
      defaultMessage="Download"
      description="Download column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.all_image_sets"
      defaultMessage="All Image Sets"
      description="All Image Sets column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_download_reports"
    defaultMessage="Allow user to download all reports (KML, XLS) in your organization."
    description="User download tool tip"
  />
);

export const EditAllReports = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.edit_all"
      defaultMessage="Edit All"
      description="Edit all column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.image_sets"
      defaultMessage="Image Sets"
      description="Image Sets column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_edit_reports"
    defaultMessage="Allow user to edit all reports in your organization."
    description="User edit tool tip"
  />
);

export const CreateReports = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.create"
      defaultMessage="Create"
      description="Create column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.image_sets"
      defaultMessage="Image Sets"
      description="Image Sets column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_create_reports"
    defaultMessage="Allow user to create reports. This will allow them to view all of your organization's images."
    description="User create tool tip"
  />
);

export const DeleteReports = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.delete"
      defaultMessage="Delete"
      description="Delete column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.image_sets"
      defaultMessage="Image Sets"
      description="Image Sets column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_delete_reports"
    defaultMessage="Allow user to delete any reports from your organization."
    description="User delete tool tip"
  />
);

export const UploadFiles = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.upload"
      defaultMessage="Upload"
      description="Upload column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.files"
      defaultMessage="Files"
      description="Files column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_upload_files"
    defaultMessage="Allow user to upload files to your organization."
    description="User upload tool tip"
  />
);

export const DeleteFiles = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.delete"
      defaultMessage="Delete"
      description="Delete column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.files"
      defaultMessage="Files"
      description="Files column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_delete_files"
    defaultMessage="Allow user to delete any files from your organization."
    description="User upload tool tip"
  />
);

//solar farms headers
export const ViewAllFarms = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.view_all"
      defaultMessage="View All"
      description="View all column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.solar_reports"
      defaultMessage="Solar Reports"
      description="Solar Reports column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_view_farms"
    defaultMessage="Allow user to view all farms in your organization."
    description="User view farms tool tip"
  />
);

export const EditAllFarms = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.edit_all"
      defaultMessage="Edit All"
      description="Edit all column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.solar_reports"
      defaultMessage="Solar Reports"
      description="Solar Reports column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_edit_farms"
    defaultMessage="Allow user to edit all farms in your organization."
    description="User edit farms tool tip"
  />
);

export const CreateFarms = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.create"
      defaultMessage="Create"
      description="Create column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.solar_reports"
      defaultMessage="Solar Reports"
      description="Solar Reports column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_create_farms"
    defaultMessage="Allow user to create farms in your organization."
    description="User create farms tool tip"
  />
);

export const DeleteFarms = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.delete"
      defaultMessage="Delete"
      description="Delete column header"
    />,

    <br key="1" />,

    <FormattedMessage
      id="app.orgeditpage.solar_reports"
      defaultMessage="Solar Reports"
      description="Solar Reports column header"
    />
  ],

  <FormattedMessage
    id="app.orgeditpage.user_delete_farms"
    defaultMessage="Allow user to delete any farm from your organization."
    description="User delete farms tool tip"
  />
);

export const ManageWorkOrders = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.manage_work_orders"
      defaultMessage="Manage Work Orders"
      description="Manage Work Orders"
    />,
    <br key="1" />,
  ],

  <FormattedMessage
    id="app.orgeditpage.manage_work_orders"
    defaultMessage="Allow user to manage work orders for any farm from your organization."
    description="User manage work orders tool tip"
  />
);

export const CompleteWorkOrders = HeaderOverlay(
  [
    <FormattedMessage
      id="app.orgeditpage.complete_work_orders"
      defaultMessage="Complete Work Orders"
      description="Complete Work Orders"
    />,
    <br key="1" />,
  ],

  <FormattedMessage
    id="app.orgeditpage.complete_work_orders"
    defaultMessage="Allow user to complete assigned work orders for any farm from your organization."
    description="User complete work orders tool tip"
  />
);
