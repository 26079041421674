import PropTypes from "prop-types";
import React, { Component } from "react";
import Loading from "react-loading";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

//API
import { TextInput } from "@raptormaps/raptor-lib";

import {
  Button,
  Col, Dropdown, Form,
  FormGroup, Row
} from "react-bootstrap";

import toastr from "toastr";

import { populateOrgUsers } from '../actions/sessionActions';
import RestApiV2 from "../api/RestApiV2";

/** @class CopyJob - Copy a job  */
class CopyJob extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      selected_org_id: null,
      selectedOrgUsers: null,
      selected_user_id: null,
      form: {
        job_id: "",
        new_org_id: "",
        new_user_id: ""
      },
      errors: {},
    };
    this.api = new RestApiV2();
  }

  updateFormState = e => {
    const field = e.target.name;
    if (field === "job_id" && isNaN(e.target.value)) return;
    let form = this.state.form;
    form[field] = e.target.value;
    return this.setState({ form: form });
  };

  onSelectOrg = key => {
    const orgIdAsNumber = parseInt(key); // React bootstrap4 automatically converts keys to strings
    let form = this.state.form;
    form["new_org_id"] = orgIdAsNumber
    this.setState({ form: form, selected_org_id: orgIdAsNumber, selectedOrgUsers: null });
    populateOrgUsers(orgIdAsNumber).then(res => {
     this.setState({selectedOrgUsers : res})
    })
  };

  onSelectUser = key => {
    const userIdAsNumber = parseInt(key); // React bootstrap4 automatically converts keys to strings
    let form = this.state.form;
    form["new_user_id"] = userIdAsNumber;
    this.setState({ form: form, selected_user_id: userIdAsNumber });
  };

  copyJob = e => {
    // copy job to a new user
    e.preventDefault();
    
    if (this.formIsValid()) {
      this.setState({ loading: true });
      this.api
        .post("/raptor_admin_copy_job", {
          new_org_id: this.state.form.new_org_id,
          new_user_id: this.state.form.new_user_id,
          job_id: this.state.form.job_id
        })
        .then(res => {
          if (res.exit_status === 0) {
            toastr.success(
              `Received request to copy job ${this.state.form.job_id} to org ${this.state.form.new_org_id}. An email will be sent to you when complete.`
            );
            this.setState({ errors: {} });
          }
        })
        .catch(err => {
          alert("Copying Failed: " + err);
          console.log(err);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  //no empty fields
  formIsValid = () => {
    let formIsValid = true;
    let errors = {};

    if (!this.state.form.job_id) {
      errors.job_id = "Job Id is Required!";
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  //clear the state when the modal is closed.
  onClose = () => {
    this.setState({
      form: {
        job_id: "",
        new_org_id: "",
        new_user_id: ""
      },
      errors: {}
    });
  };

  render() {
    const { selected_org_id, selected_user_id } = this.state;
    const selectedOrg = this.props.orgs.find(b => b.id === selected_org_id);
    const selectedOrgText = selectedOrg ? selectedOrg.name : "Select New Org";

    const selectedUser = this.state.selectedOrgUsers
      ? this.state.selectedOrgUsers.find(b => b.id === selected_user_id)
      : null;
    const selectedUserText = selectedUser
      ? selectedUser.email
      : "Select New User";

    return (
        <div className="raptor-admin-view">
          <Form>
            <Row>
              <Col xs={4}>
                <FormGroup>
                  <TextInput
                    name={"job_id"}
                    type={"text"}
                    maxLength={"10"}
                    rows={"1"}
                    label={"Job Id"}
                    placeholder={"1234"}
                    onChange={this.updateFormState}
                    error={this.state.errors.job_id}
                    value={this.state.form.job_id.toString()}
                  />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup style={{ paddingTop: "24px" }}>
                  <Dropdown id="newOrg" onSelect={this.onSelectOrg}>
                    <Dropdown.Toggle>{selectedOrgText}</Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {this.props.orgs.map((p, i) => (
                        <Dropdown.Item
                          active={selected_org_id === p.id}
                          key={i}
                          eventKey={p.id}
                        >
                          {p.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup style={{ paddingTop: "24px" }}>
                  <Dropdown id="newUser" onSelect={this.onSelectUser}>
                    <Dropdown.Toggle>{selectedUserText}</Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {this.state.selectedOrgUsers
                        ? this.state.selectedOrgUsers.map((user, i) => (
                            <Dropdown.Item
                              active={selected_user_id === user.id}
                              key={i}
                              eventKey={user.id}
                            >
                              {user.email}
                            </Dropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Button variant="success" onClick={this.copyJob}>
            {this.state.loading ? (
              <Loading
                width="30px"
                height="30px"
                type="spokes"
                color="#97C93D"
              />
            ) : (
              "Copy"
            )}
          </Button>
        </div>

    );
  }
}


CopyJob.propTypes = {
  orgs: PropTypes.array.isRequired
};

const mapStateToProps = state => {
    return {
        orgs: state.session.orgs
    }
}
export default withRouter(connect(mapStateToProps, null)(CopyJob));
