import { moment } from '@raptormaps/raptor-lib';
import React, { useState } from 'react';
//redux
import { connect } from 'react-redux';

// constants imports for state and displays
import {
    nonWorkflowStatusOrder,
    orderStatuses, orderValuesets,
    workflowState,
    workflowStatusOrder
} from '../../../util/constants';

//components
import PermissionsWarningModal from '../../PermissionsWarningModal';
import GenericDisplaySection from './displaySections/GenericDisplaySection';
import ProcessedDisplaySection from './displaySections/ProcessedDisplaySection';
import SubmittedDisplaySection from './displaySections/SubmittedDisplaySection';

/**
 * WorkflowListItem - handles styling for list items
 * @param {*} bold - boolean for font weight value
 * @param {*} string - string for status display
 */
const WorkflowListItem = ({ bold, status }) => {
  return <div style={{ fontWeight: bold && 'bold' }}>{status}</div>;
};

/**
 * updateOrderState - returns wrapped for order update action
 * @param {*} order
 * @param {*} orderActions
 * @returns {function} wraps order update action and passes in next status
 */
const updateOrderStatus = (order, orderActions) => nextStatus => {
  let newOrder = { ...order, status: nextStatus };
  orderActions.updateOrder(newOrder, true);
};

/**
 * WorkFlowTab - Container component that handles display logic and list of statuses
 * @param {*} order - single order object
 * @param {*} orderActions - order action creator
 * @param {object[]} orgs - array of orgs objects
 */
const WorkflowTab = ({ order, orderActions, orgs }) => {
  const [showPermissionsWarning, setShowPermissionsWarning] = useState(false);
  const [nextStatus, setNextStatus] = useState('');

  /**
   * displaySwitch - function that holds logic to render proper display section
   * @param {*} orderStatus
   * @param {*} order
   * @param {*} orderActions
   * @param {*} orgs
   */
  const displaySwitch = (order, orderActions, orgs) => {
    switch (order.status) {
      case 2:
        return (
          <SubmittedDisplaySection
            status={order.status}
            nextStatus={workflowState[order.status].nextStatus}
            updateOrderStatus={updateOrderStatus(order, orderActions)}
            togglePermissionsModal={() => setShowPermissionsWarning(true)}
            setNextStatus = {setNextStatus}
          />
        );
      case 4:
        const clientOrgNames = order.hasOwnProperty('client_orgs') ? order.client_orgs.map(o => o.name) : [];
        const channelOrgNames = order.hasOwnProperty('channel_orgs') ? order.channel_orgs.map(o => o.name) : [];
        return (
          <ProcessedDisplaySection
            status={order.status}
            nextStatus={workflowState[order.status] && workflowState[order.status].nextStatus}
            updateOrderStatus={updateOrderStatus(order, orderActions)}
            clientOrgNames={clientOrgNames}
            channelOrgNames={channelOrgNames}
            togglePermissionsModal={() => setShowPermissionsWarning(true)}
            setNextStatus = {setNextStatus}
          />
        );

      default:
        return (
          <GenericDisplaySection
            status={order.status}
            nextStatus={workflowState[order.status] && workflowState[order.status].nextStatus}
            updateOrderStatus={updateOrderStatus(order, orderActions)}
            togglePermissionsModal={() => setShowPermissionsWarning(true)}
            setNextStatus = {setNextStatus}
          />
        );
    }
  };
  /**
   * getPermissionMessage - function returns message for modal given an order status
   * @param {*} orderStatus
   */
  const getPermissionsMessage = orderStatus => {
    switch (orderStatus) {
      case orderStatuses['SITE_SETUP']:
      case orderStatuses['SUBMITTED']:
        return 'WARNING: Channel orgs will gain access to the farm.';
      case orderStatuses['PROCESSED']:
        return 'WARNING: Channel orgs will lose access to the farm. Client orgs will now have access.';
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', height: '400px' }}>
        <div
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {workflowStatusOrder.map(wso => (
            <WorkflowListItem bold={order.status === wso} status={orderValuesets['status'][wso]} />
          ))}
          {nonWorkflowStatusOrder.map(wso => (
            <WorkflowListItem bold={order.status === wso} status={orderValuesets['status'][wso]} />
          ))}
        </div>
        <div
          style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          {displaySwitch(order, orderActions, orgs)}
        </div>
        {/* <ReworkButton orderUuid={order.uuid} duplicateOrder={orderActions.duplicateOrder}/> */}
      </div>
      <PermissionsWarningModal
        show={showPermissionsWarning}
        onContinue={() => {
          // if status change, update the order
          let newOrder = { ...order, status: nextStatus, delivered_tsecs:  moment().unix() };
          orderActions.updateOrder(newOrder, true);
        }}
        message={getPermissionsMessage(order.status)}
        toggle={() => setShowPermissionsWarning(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  orgs: state.session.orgs
});

export default connect(mapStateToProps, null)(WorkflowTab);
