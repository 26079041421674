import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, DropdownButton, FormCheck, Modal } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';

// TODO: This should be coming from the database in some capacity. 
const typeToDisplayText = {
    'photoshop_overlay': 'Photoshop Overlay',
    'georeference': 'Georeference',
    'farm_building': 'Farm Building',
    'thermal_boxing': 'Thermal Boxing',
    'rgb_boxing': 'RGB Boxing',
    'reverse_boxing': 'Reverse Boxing',
    'localization': 'Localization',
    'custom_numbering':'Custom Numbering'
}

/**
 * Modal used to edit instructions on an order
 * @param {{}} order - order currently being editted
 * @param {{}[]} instructions - array of all instruction objects 
 */
const InstructionEdit = ({ show, order, instructions, toggle, add, remove }) => {
  // Create generic state to store the values selected in the form
  const [formValues, setFormValues] = useState({});
  const [originalUuids, setOriginalUuids] = useState([])

  const handleInitialFormValues = () => {
    if (order.channel_instructions) {
      // Set the inital form values based on the instructions initially attached to the order before the modal is opened
      setFormValues(
        order.channel_instructions && order.channel_instructions.reduce( (acc, curr) => {
          acc[curr['instruction_type']] = curr;
          return acc;
        }, {})
      );
      // Keep track of the original so we know which instructions are to be removed or added
      setOriginalUuids(order.channel_instructions && order.channel_instructions.map(item => item.uuid));
    }
  }

  useEffect(() => {
    // Use the list of instruction uuids to prepopulate dropdown values
    
    handleInitialFormValues();
  }, [order]);
  /**
   * Adds the instructions values from state to the order instructions
   * Uses the original uuids from the instructions attached to the order to
   * determine which instructions selected in the form are newly added and which
   * of the originals are no longer present and need to be removed
   */
  const handleUpdate = () => {
    // eslint-disable-next-line no-undef
    const orderCopy = _.cloneDeep(order);

    // All uuids from the dropdowns/checkboxes
    const newInstructions = Object.values(formValues);
    const newInstructionUuids = newInstructions.map(i => i.uuid);
    orderCopy.channel_instructions = newInstructions;

    // Uuids to be added
    const uuidsToRemove = originalUuids.filter(uuid => !newInstructionUuids.includes(uuid))
    
    // Remove the other uuids that are no longer on the order
    uuidsToRemove.map(removedItemUuid => remove(orderCopy, [removedItemUuid]));
    // Add the new uuids
    add(orderCopy);
    toggle();
  }
  /**
   * Handles clicking of checkboxes which either adds, or removes an instruction from the order
   * @param {*} instructionType 
   * @param {*} instruction 
   */
  const handleCheckClick = (instructionType, instruction = null) => {
    if(formValues[instructionType]) {
      // Instruction is already requested, remove it
      const formValuesWithoutType = Object.keys(formValues).reduce((acc,curr) => {
        if(curr['instruction_type'] !== instructionType) {
          acc[curr['instruction_type']] = curr;
        }
        return acc; 
      }, {});
      setFormValues(formValuesWithoutType);
    } else {
      // Instruction needs to be added to the order
      setFormValues({...formValues, [instructionType]: instruction});
    }
  }
  /**
   * Based on the number of instructions with matching instruction types returns
   * either a checkbox for those with only one in the type group, or dropdown for those with 
   * multiple of a single type
   * 
   * Example: include farmbuilding -> checkbox (either do or don't include farmbuilding)
   *          Georeference with Google && Georeference with Mapbox -> dropdown (not boolean, could add more mediums to georeference in the future)
   * @param {*} instructionType 
   */
  const getFormElement = (instructionType) => {
    const instructionsWithType = instructions.filter(item => item['instruction_type'] === instructionType);

    // If there are more than one instruction with the same group, use a dropdown to display the options
    if (instructionsWithType.length > 1) {
      return (
        <DropdownButton 
          title={formValues[instructionType] ? formValues[instructionType].description : `Select Instruction...`}
          variant={formValues[instructionType] ? 'primary' : 'secondary'}
        >
          {instructionsWithType.map(item => {
            return (
              <DropdownItem 
                onSelect={() => setFormValues({...formValues, [instructionType]: item })}
              >
                {item.description}
              </DropdownItem>
            )
          }) 
          }
        </DropdownButton>
      );
    }
    // If there is only one, display as a checkbox
    return (
      <FormCheck 
        checked={formValues[instructionType]} 
        onChange={() => handleCheckClick(instructionType, instructionsWithType[0])}
      /> 
    );
  }
  return (
    <Modal
      show={show}
      centered
      onShow={handleInitialFormValues}
    >
      <Modal.Header>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
          <Modal.Title>{`Add Instruction`}</Modal.Title>
          <FontAwesomeIcon
            className="rm-close-modal"
            icon={faTimes}
            onClick={() => toggle()}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {Object.keys(typeToDisplayText).map(type => 
          (
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', padding: '5px'}}>
              <span>{typeToDisplayText[type]}</span>
              {getFormElement(type)}
            </div>
          ) 
        )}
        </div>
        <Button onClick={() => handleUpdate()}>
          Save
        </Button>
      </Modal.Body> 
    </Modal>
  );
}

export default InstructionEdit;
