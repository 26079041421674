import styled, { css } from 'styled-components';
import { Tabs } from 'react-bootstrap';

interface TotalRevenueContainerProp {
  width: number;
}

export const ProjectViewerContainer = styled.table.attrs(() => ({
  className: 'raptor-project-viewer-view',
}))`
  width: 100%;
  display: block;
  padding: 10px 20px;
`;


export const CustomTabs = styled(Tabs).attrs(() => ({
  className: 'raptor-project__Tabs',
}))`
  font-weight: 500;
  border-radius: 0px;
  // Custom Tab Item
  .nav-item{
    background-color: ${({ theme }) => theme.colors.GlobalColor.rGrey5};
    color: ${({ theme }) => theme.colors.GlobalColor.rBlack2};
    border: solid 1px ${({ theme }) => theme.colors.GlobalColor.rGrey2};
    border-radius: 0px;
    padding: 10px !important;
    border: 0px;
  }

  .nav-item:hover{
    border-bottom: solid 3px ${({ theme }) => theme.colors.GlobalColor.rOrange1} !important;
  }

  .active{
    background-color: ${({ theme }) => theme.colors.GlobalColor.rOrange1}!important;
    color: ${({ theme }) => theme.colors.GlobalColor.rWhite} !important;
    border: 0px;
  }
`

export const TotalRevenueContainer = styled.div.attrs(() => ({
  className: 'totalRevenue__Container'
}))<TotalRevenueContainerProp>`
  display: flex;
  justify-content: flex-end;
  width: ${({ width }) => `${width}px`};
`;

export const TotalRevenueItem = styled.div.attrs(() => ({
  className: 'totalRevenue__Item'
}))`
  width: 135px;
  text-align: center;
`;

export const TotalRevenueTitle = styled.div.attrs(() => ({
  className: 'totalRevenue__Title'
}))`
  font-weight: 500;
`;

export const TotalRevenueValue = styled.div.attrs(() => ({
  className: 'totalRevenue__Value'
}))``;


