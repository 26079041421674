export const EventStatusById = ["pending", "approved", "canceled", "on-hold"];
export const EventTypeById = ["flight date", "flight window"];
export enum EventStatus {
  PENDING = "pending",
  APPROVED = "approved",
  CANCELED = "canceled",
  ONHOLD = "on-hold",
}
export enum EventStatusIds {
  PENDING = 1,
  APPROVED = 2,
  CANCELED = 3,
  ON_HOLD = 4,
}
export enum EventTypes {
  FLIGHT_DATE = 1,
  FLIGHT_WINDOW = 2,
}
