import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

/**
 * Component which displays a table containing metrics related to the total amount of power per order status
 * @param {*} getOrderMetrics - func to fetch metrics for orders
 * @param {*} metrics - sum of total MW by order status  
 */
const OrderMetrics = ({ getOrderMetrics, metrics }) => {
  useEffect(() => {
    getOrderMetrics()
  }
  , []
  )
  /**
   * Adds units for table display values
   */
  const mwDisplayFormatter = mw => `${mw || 0} MW`;

  return (
    metrics ? <BootstrapTable
      keyField={'dispatched_total'}
      bootstrap4
      classes='metric-table'
      data={[metrics]}
      headerClasses='metric-row'
      rowClasses='metric-row'
      columns={[
        {
          dataField: 'dispatched_total',
          text: 'Dispatched Total:',
          formatter: mwDisplayFormatter
        },
        {
          dataField: 'submitted_total',
          text: 'Submitted Total:',
          formatter: mwDisplayFormatter
        },
        {
          dataField: 'site_setup_total',
          text: 'Site Setup Total:',
          formatter: mwDisplayFormatter
        }
      ]}
    /> : null
  );
}

const mapStateToProps = (state, props) => ({
  metrics: state.order && state.order.metrics || []
})

export default connect(mapStateToProps, null)(OrderMetrics);