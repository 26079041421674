import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";

import { Auth0Provider } from "@auth0/auth0-react";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../node_modules/toastr/build/toastr.min.css";
import App, { history } from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";

const store = configureStore();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history?.replace(appState?.returnTo || window.location.pathname);
};

const container = document.getElementById('content');
const root = createRoot(container);
root.render(
  <Auth0Provider
    domain={window.REACT_APP_AUTH0_CONFIG.domain}
    clientId={window.REACT_APP_AUTH0_CONFIG.clientId}
    redirectUri={window.location.origin}
    audience={window.REACT_APP_AUTH0_CONFIG.audience}
    scope=""
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={false}
    cacheLocation={"localstorage"}
    connection={window.REACT_APP_AUTH0_CONFIG.connection}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();