import React from 'react';
import { Form, FormControl, InputGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/index';

const FormInput = React.forwardRef(
  (
    {
      label,
      name,
      type,
      placeholder,
      value,
      isValid,
      isInvalid,
      helpText,
      error,
      onChange,
      onBlur,
      onClick,
      rows
    },
    ref
  ) => {
    return (
      <Form.Group>
        <Form.Label className="onboard-form-input-label">{label}</Form.Label>
        <Row>
          <Col>
            <FormControl
              ref={ref}
              name={name}
              // global max limit of 250 characters
              maxLength={250}
              className="onboard-form-input"
              type={type}
              value={value}
              placeholder={placeholder}
              isValid={isValid}
              isInvalid={isInvalid}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
              rows={rows}
              as={type === 'textarea' ? 'textarea' : 'input'}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </Col>
          {helpText && (
            <Col sm={1}>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip " bsPrefix="rm-tooltip tooltip">
                    {helpText}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} color="grey" className="order-help-icon" />
              </OverlayTrigger>
            </Col>
          )}
        </Row>
      </Form.Group>
    );
  }
);

export default FormInput;
